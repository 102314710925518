import { PrimaryLobbyist } from './../../../../core/models/primary-lobbyist-model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { combineLatest, Observable, Subject, partition, merge } from 'rxjs';
import {
    map,
    switchMap,
    share,
    shareReplay,
    mapTo,
    startWith,
    withLatestFrom,
    tap,
} from 'rxjs/operators';
import {
    FilerInformationService,
    SnackbarService,
} from 'src/app/core';
import { PhonePipe } from 'src/app/shared/pipes/format-phone.pipe';
import { ActivityReportService } from '../../services/activity-report.service';
import { Item } from '@maplight/models';
import { NewClientRegistrationService } from '../../../new-client-registration/services';
import { TargetTimePipe } from 'src/app/shared/pipes/denver-time.pipe';
import { ZipPipe } from 'src/app/shared/pipes/format-zip.pipe';

@Component({
    selector: 'app-no-activity-report',
    templateUrl: './no-activity-report.component.html',
    styleUrls: ['./no-activity-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class NoActivityReportComponent implements OnInit {
    constructor(
        private readonly filerInformationService: FilerInformationService,
        private readonly route: ActivatedRoute,
        private readonly activityReportService: ActivityReportService,
        private readonly snackbarService: SnackbarService,
        private readonly i18n: TranslocoService,
        private readonly router: Router,
        private readonly phonePipe: PhonePipe,
        private readonly clientRegistrationService: NewClientRegistrationService,
        private readonly targetTimePipe: TargetTimePipe,
        private readonly zipPipe: ZipPipe,
    ) {}

    protected declarationControl: FormControl = new FormControl(false, [
        Validators.requiredTrue,
    ]);

    private readonly submitNoActivityReportSubject$: Subject<void> =
        new Subject<void>();
    private readonly downloadNoActivityReportSubject$: Subject<void> =
        new Subject<void>();

    protected reportMonthName$: Observable<string>;
    protected registrationName$: Observable<string>;
    protected loading$: Observable<boolean>;
    protected column1$: Observable<Item[]>;
    protected column2$: Observable<Item[]>;
    protected downloadingReport$: Observable<boolean>;

    private primaryLobbyistData$: Observable<any>;

    ngOnInit() {
        const lobbyistId$ = this.filerInformationService.current$.pipe(
            map((f) => f.filerId),
            shareReplay(),
        );

        const filingId$ = this.route.params.pipe(
            map((p) => p.filingId),
            shareReplay(),
        );

        const noActivityReport$ = filingId$.pipe(
            switchMap((f) =>
                this.activityReportService.getMonthlyReportFilingInformation(f),
            ),
            shareReplay(),
        );

        this.reportMonthName$ = noActivityReport$.pipe(
            map((r) => r.filingPeriod.name),
            share(),
        );

        const clientRegistrationId$ = noActivityReport$.pipe(
            map((r) => r.clientRegistrationId),
            shareReplay(),
        );
        const filingPeriodId$ = noActivityReport$.pipe(
            map((r) => r.filingPeriodId),
            shareReplay(),
        );

        const clientRegistration$: Observable<any> = clientRegistrationId$.pipe(
            switchMap((c) =>
                this.clientRegistrationService.getClientRegistration(c),
            ),
            shareReplay(),
        );

        this.registrationName$ = clientRegistration$.pipe(
            map((c) => c?.primaryLobbyist?.name + ' for ' + c?.client?.name),
        );

        this.primaryLobbyistData$ = clientRegistration$.pipe(
            map((c) => c.primaryLobbyist.id),
            switchMap((l) => this.activityReportService.getLobbyistData(l)),
            shareReplay(),
        );

        const noReportHeader$ = this.i18n
            .selectTranslateObject('noReport')
            .pipe(shareReplay());
        this.column1$ = combineLatest([
            noReportHeader$,
            noActivityReport$,
            this.primaryLobbyistData$,
        ]).pipe(
            map(([t, r, d]) => [
                {
                    label: t.filingPeriod,
                    value:
                        this.targetTimePipe.transform(
                            r.filingPeriod.startDate,
                            'MM/dd/yyyy',
                        ) +
                        ' - ' +
                        this.targetTimePipe.transform(
                            r.filingPeriod.endDate,
                            'MM/dd/yyyy',
                        ),
                },
                {
                    label: t.dueDate,
                    value: this.targetTimePipe.transform(
                        r.filingPeriod.dueDate,
                    ),
                },
                { label: t.firstName, value: d.primaryUser.firstName },
                { label: t.lastName, value: d.primaryUser.lastName },
                {
                    label: t.phoneNumber,
                    value: this.phonePipe.transform(d.primaryUser.phone),
                },
                { label: t.email, value: d.primaryUser.email },
            ]),
        );
        this.column2$ = combineLatest([
            noReportHeader$,
            this.primaryLobbyistData$,
        ]).pipe(
            map(([t, d]) => [
                { label: t.firm, value: d.firm?.name ?? 'N/A' },
                { label: t.address, value: d.businessAddress.address1 + (d.businessAddress.address2 ? ' ' + d.businessAddress.address2 : '') },
                { label: t.city, value: d.businessAddress.city },
                { label: t.state, value: d.businessAddress.stateCode },
                { label: t.zipCode, value: this.zipPipe.transform(d.businessAddress.zip) },
            ]),
        );

        const loadingData$ = this.primaryLobbyistData$.pipe(
            mapTo(false),
            startWith(true),
        );

        const submitNoActivityReport$ =
            this.submitNoActivityReportSubject$.pipe(
                withLatestFrom(
                    lobbyistId$,
                    clientRegistrationId$,
                    filingPeriodId$,
                ),
                switchMap(([_, l, c, fp]: [any, string, string, number]) =>
                    this.activityReportService.submitNoActivityReport(l, c, fp),
                ),
                share(),
            );
        const [success$, fail$] = partition(
            submitNoActivityReport$,
            (x) => !!x,
        );
        const submittingRequest$ = merge(
            this.submitNoActivityReportSubject$.pipe(mapTo(true)),
            success$.pipe(
                tap((_) => this.notifyOnSuccess()),
                mapTo(false),
            ),
            fail$.pipe(
                tap((_) => this.notifyOnFail()),
                mapTo(false),
            ),
        ).pipe(startWith(false));

        const downloadReport$ = this.downloadNoActivityReportSubject$.pipe(
            withLatestFrom(
                this.reportMonthName$,
                this.registrationName$,
                filingId$
            ),
            switchMap(([_, m, c, id]) =>
                this.activityReportService.downloadNoActivityReport(
                    `${m} - ${c} - No Activity Report`,
                    id,
                    this.declarationControl.value,
                ),
            ),
            share(),
        );
        this.downloadingReport$ = merge(
            this.downloadNoActivityReportSubject$.pipe(mapTo(true)),
            downloadReport$.pipe(mapTo(false)),
        ).pipe(startWith(false), share());

        this.loading$ = combineLatest([
            loadingData$,
            submittingRequest$,
            this.downloadingReport$,
        ]).pipe(
            map((x) => x.some((y) => y)),
            shareReplay(),
        );
    }

    submit() {
        if (this.declarationControl.invalid) return;
        this.submitNoActivityReportSubject$.next();
    }

    notifyOnSuccess() {
        this.snackbarService.snackbarSuccess(
            this.i18n.translate(
                'noReport.noActivityReportSuccessfullySubmitted',
            ),
        );
        this.goToDashboard();
    }
    notifyOnFail() {
        this.snackbarService.snackbarError(
            this.i18n.translate(
                'noReport.errorWhileSubmittingNoActivityReport',
            ),
        );
    }

    // This method should redirect the user to the filer dashboard page
    // however, that page is not still implemented
    goToDashboard() {
        this.router.navigate(['/lobbyist']);
    }

    preViewPDF() {
        this.downloadNoActivityReportSubject$.next();
    }
}
