import { Injectable, OnDestroy, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MasterScrollService implements OnDestroy {
    private readonly scroller = inject(ViewportScroller);
    private readonly router = inject(Router);

    private readonly exclude = ['advanced-search', 'messages/inbox', 'messages/view'] as const;
    private readonly scrollTarget = [0, 0] satisfies [number, number];

    private readonly navigateToNonExcludedRoutes$ = this.router.events.pipe(
        filter((it): it is NavigationEnd => it instanceof NavigationEnd),
        filter((it) => !this.exclude.some((one) => it.url.includes(one))),
    );

    private readonly scrollEffect = this.navigateToNonExcludedRoutes$.subscribe(
        () => this.scroller.scrollToPosition(this.scrollTarget),
    );

    ngOnDestroy() {
        this.scrollEffect.unsubscribe();
    }
}