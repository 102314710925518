import { Component, Input } from "@angular/core";
import { GridPageSize, gridPageSizes } from "../../services/table-paging.service";

@Component({
    selector: 'app-table-pager',
    templateUrl: './table-pager.component.html',
    styleUrls: ['./table-pager.component.scss'],
})
export class TablePagerComponent {
    @Input() dataLength: number;
    @Input() pageCount: number;

    protected readonly pageSizes: ReadonlyArray<GridPageSize> = gridPageSizes;
}