import { DialogResult } from "@progress/kendo-angular-dialog";
import {
    SimpleFilerModel,
    UserFilers,
    FilerTypeEnum,
    FilerTypeStringEnum,
    UserEntityDetailsResponseApiModel,
} from "src/app/app.model";

export { FilerTypeEnum as Filer } from "src/app/app.model";
export { FilerTypeStringEnum as FilerName } from "src/app/app.model";
export { UserMenuResponseApiModel as UserData } from "src/app/app.model";
export { UserEntityDetailsResponseApiModel as Entity } from "src/app/app.model";

export type Request = { type: number; items: SimpleFilerModel[] };
export type ConfirmedRequest = {
    result: DialogResult;
    ids: number[];
    note: string;
};
export type Keyed = { key: string };
export type KeyedFiler = UserFilers & Keyed;
export type KeyedEntity = UserEntityDetailsResponseApiModel & Keyed;
export type FilerGroup = { [P in FilerTypeEnum]?: KeyedFiler[] };
export type FilerItems = { type: FilerTypeStringEnum; items: KeyedFiler[] };
