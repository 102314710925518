import { inject } from '@angular/core';
import { type CanActivateFn, Router } from '@angular/router';
import { FilerInformationService, SharedService } from '@maplight/services';
import { isAgent } from '@maplight/utils';
import { combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';

/**
 * A function-based route guard for ensuring that a route is only
 * accessed through a non-Authorized Agent main profile.
 *
 * If it detects that there is no current filer set, it will try to set it as long as it
 * makes sense by calling `FilerInformationService.setCurrent`. If it detects that there is
 * no way to trivially select an option, it will try to redirect the user to the appropriate
 * dashboard to try and select a non-AA filer to act as.
 *
 * @param _route the activated route (unused)
 * @param state the current router state
 * @returns whether we can continue to desired route or an additional redirection
 */
export const requiresActiveNonAgentFiler: CanActivateFn = (_route, state) => {
    const options$ = inject(SharedService).loadUserEntities();
    const filer = inject(FilerInformationService);

    // pre-configured redirect
    // switch will always lead to the AA or admin dashboard
    const redirect = inject(Router).createUrlTree(['/switch'], {
        queryParams: { continueTo: state.url },
    });

    // the currently set filer
    // notice the 'take(1)' to avoid triggering a refresh
    // when setting the current filer in some of the following handlers
    const active$ = filer.current$.pipe(take(1));

    // determine if the current filer is valid
    // otherwise check if we have a trivial choice to make
    // or we need user input
    return combineLatest([active$, options$]).pipe(
        map(([it, options]) => {
            // simplest case is if the filer is already set
            // and not an authorized agent
            // in that case we just allow navigation to continue
            if (it?.filerId && !isAgent(it)) return true;

            // else we need to verify if we have a valid option
            // available (that we can take by default)
            // or if we need to prompt the user to select a filer
            // from the available options

            // first, get non-agent options, if any
            const nonAgents = options.userFilers?.filter((it) => !isAgent(it));

            // we can only decide on an option for the user if it is trivial
            // to do so, so if we have more than one option (or no option)
            // we tell the user to choose one
            if (nonAgents?.length !== 1) return redirect;

            // otherwise set that value as the current filer and continue
            filer.setCurrent(nonAgents[0]);

            return true;
        }),
    );
};
