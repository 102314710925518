import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';

export enum FillModeType {
  Solid = 'solid',
  Flat = 'flat',
  Outline = 'outline',
  Clear = 'clear',
  Link = 'link',
  None = 'none'
}

enum SizeType {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  None = 'none'
}

enum RoundedType {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Full = 'full',
  None = 'none'
}
export enum ThemeColorType {
  Base = 'base',
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Dark = 'dark',
  Light = 'light',
  Inverse = 'inverse',
  None = 'none'
}

@Directive({
  selector: '[appLinkButton]',
  standalone: true
})
export class LinkButtonDirective implements OnInit, OnChanges {

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  @Input() fillMode: FillModeType;
  @Input() size: SizeType;
  @Input() rounded: RoundedType;
  @Input() themeColor: ThemeColorType;
  @Input() showShadow: boolean = false;

  private appendedClasses: string[] = [];

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'k-button');
    this.setFillModeClasses();
    this.setSizeClasses();
    this.setRoundedClasses();
    this.setPropertyClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.fillMode || changes.size || changes.rounded){
      this.removeClasses();
      this.setFillModeClasses();
      this.setSizeClasses();
      this.setRoundedClasses();
      this.setPropertyClasses();
    }
  }

  private setFillModeClasses(): void {
    switch(this.fillMode) {
      case FillModeType.Solid:
      case FillModeType.Flat:
      case FillModeType.Outline:
      case FillModeType.Clear:
      case FillModeType.Link:
        this.appendClasses(
          this.setThemeColor(`k-button-${this.fillMode}`)
        );
        break;
      case FillModeType.None:
        break;
      default:
        this.appendClasses(
          this.setThemeColor('k-button-solid')
        );
        break;
    }
  }

  private setSizeClasses(): void {
    switch(this.size) {
      case SizeType.Small:
        this.appendClasses(['k-button-sm']);
        break;
      case SizeType.Medium:
        this.appendClasses(['k-button-md']);
        break;
      case SizeType.Large:
        this.appendClasses(['k-button-lg']);
        break;
      case SizeType.None:
          break;
      default:
        this.appendClasses(['k-button-md']);
        break;
    }
  }

  private setRoundedClasses(): void {
    switch(this.rounded) {
      case RoundedType.Small:
        this.appendClasses(['k-rounded-sm']);
        break;
      case RoundedType.Medium:
        this.appendClasses(['k-rounded-md']);
        break;
      case RoundedType.Large:
        this.appendClasses(['k-rounded-lg']);
        break;
      case RoundedType.Full:
        this.appendClasses(['k-rounded-full']);
        break;
      case RoundedType.None:
          break;
      default:
        this.appendClasses(['k-rounded-md']);
        break;
    }
  }

  private setThemeColor(fill: string): string[] {
    const result = [fill]
    let color: string;
    switch(this.themeColor) {
      case ThemeColorType.Base:
      case ThemeColorType.Primary:
      case ThemeColorType.Secondary:
      case ThemeColorType.Tertiary:
      case ThemeColorType.Info:
      case ThemeColorType.Success:
      case ThemeColorType.Warning:
      case ThemeColorType.Error:
      case ThemeColorType.Dark:
      case ThemeColorType.Light:
      case ThemeColorType.Inverse:
        color = `${fill}-${this.themeColor}`;
        result.unshift(color);
        break;
      case ThemeColorType.None:
          break;
      default:
        result.unshift(`${fill}-base`);
        break;
    }

    return result;
  }

  private setPropertyClasses(): void {
    if(!this.showShadow){
      const className = 'no-shadow';
      this.appendedClasses.push(className);
      this.renderer.addClass(this.el.nativeElement, className)
    }
  }

  private appendClasses(values: string[]): void {
    this.appendedClasses.concat(values)
    values.forEach((value) => {
      this.renderer.addClass(this.el.nativeElement, value)
    })
  }

  private removeClasses(): void {
    this.appendedClasses.forEach((x) => {
      this.renderer.removeClass(this.el.nativeElement, x);
    });
    this.appendedClasses = []
  }
}
