import { Directive, ElementRef, Renderer2, OnInit, OnDestroy } from '@angular/core';

@Directive({
  selector: '[syncTabIndex]'
})
export class SyncTabIndexDirective implements OnInit, OnDestroy {
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  private observer: MutationObserver;

  ngOnInit() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName !== 'tabindex') return; 

        const newTabIndex = this.el.nativeElement.parentNode.getAttribute('tabindex');
        
        if (newTabIndex !== mutation.oldValue) {
          !!this.el.nativeElement.getAttribute('tabindex') ? 
          this.setTabIndex(this.el.nativeElement, newTabIndex) : 
          this.updateChildrenTabIndex(newTabIndex);
        }
      });
    });

    const config = { attributes: true, attributeFilter: ['tabindex'], attributeOldValue: true };

    this.observer.observe(this.el.nativeElement.parentNode, config);
  }

  ngOnDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private updateChildrenTabIndex(newTabIndex: string): void {
    const focusableChildren = this.el.nativeElement.querySelectorAll('[tabindex]');
    focusableChildren.forEach(child => {
      this.setTabIndex(child, newTabIndex);
    });
  }

  private setTabIndex(node: any, newTabIndex: string): void {
    this.renderer.setAttribute(node, 'tabindex', newTabIndex);
  }
}