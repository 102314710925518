import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ILobbyistFirmAddress } from "../../../shared/models/app-lobbyist-business";
import { ClientService, MasterUrlService } from "@maplight/services";
import { catchError, mapTo } from "rxjs/operators";
import { BusinessAddress } from "@maplight/models";

@Injectable()
export class FirmBusinessAddressService {
    private _businessAddress: ILobbyistFirmAddress;

    /**
     *
     * @private _addBusinessAddress add new Value
     */
    private _addBusinessAddress = new BehaviorSubject<ILobbyistFirmAddress>(
        undefined
    );
    addBusinessAddress$ = this._addBusinessAddress.asObservable();

    private _editBusinessAddress = new BehaviorSubject<ILobbyistFirmAddress>(
        undefined
    );
    editBusinessAddress$ = this._editBusinessAddress.asObservable();

    /***
     * to delete Firm
     * @private
     */
    private _deleteBusinessAddress = new BehaviorSubject<boolean>(false);
    deleteBusinessAddress$ = this._deleteBusinessAddress.asObservable();

    constructor(
        private readonly client: ClientService,
        private readonly urls: MasterUrlService,
    ) {}

    get businessAddress(): ILobbyistFirmAddress {
        return this._businessAddress;
    }

    set businessAddress(value: ILobbyistFirmAddress) {
        this._businessAddress = value;
    }

    /**
     *
     * @param value new Firm
     */
    addNewFirm(value: ILobbyistFirmAddress) {
        this._addBusinessAddress.next(value);
        this._deleteBusinessAddress.next(false);
        this._editBusinessAddress.next(null);
        this._businessAddress = value;
    }

    editNewFirm() {
        this._editBusinessAddress.next(this._businessAddress);
    }

    delete() {
        this._deleteBusinessAddress.next(true);
        this._addBusinessAddress.next(null);
        this._editBusinessAddress.next(null);
        this._businessAddress = null;
    }
    cancel() {
        this._addBusinessAddress.next(this._businessAddress);
        this._editBusinessAddress.next(null);
    }
}
