import { ChangeDetectorRef, Component, Input, Output, EventEmitter } from "@angular/core";
import { ILobbyistFirm } from "../../../../shared/models/app-lobbyist";
import { LobbyistService } from "../../services/lobbyist.service";

@Component({
  selector: "app-create-account-firm",
  templateUrl: "./create-account-firm.component.html",
  styleUrls: ["./create-account-firm.component.scss"],
})
export class CreateAccountFirmComponent {
  @Input() firm: ILobbyistFirm;

  editFirm: ILobbyistFirm = null; // Represents the firm being edited (if any).
  newFirm: ILobbyistFirm = null; // Represents the newly added firm (if any).
  isEditing = false; // Flag to track whether the update form was canceled.

  constructor(private readonly lobbyistService: LobbyistService, private cdr: ChangeDetectorRef,) {}

  // Called when a new firm is added from the form.
  addNewFirm(value: ILobbyistFirm) {
    this.newFirm = value;
    this.lobbyistService.newFirmWithAddress = value;
    this.isEditing = false;
  }

  // Called when the user initiates editing a firm.
  isEdition(value: ILobbyistFirm) {
    this.isEditing = true;
    this.editFirm = value || this.newFirm; // Set the firm being edited to the provided value, or if null, set it to the newly added firm (if any).
  }

  // Called when the user deletes the selected firm.
  deleteSelectedFirm() {
    this.editFirm = null; // Clear the edited firm.
    this.firm = null; // Clear the selected firm.
    this.lobbyistService.removeFirmWithAddress();
  }

  // Called when the user cancels the update form.
  cancel() {
    this.isEditing = false; // Set the cancelEdit flag to true to indicate that the update form was canceled.
    this.editFirm = null; // Clear the edited firm.
  }
}
