import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { ClientService, MasterUrlService } from "src/app/core";
import { Message, TextDataModel } from "@maplight/models";

@Injectable()
export class HelpService {
    constructor(
        private readonly clientService: ClientService,
        private readonly urlService: MasterUrlService,
    ) { }

    getTextData(): Observable<TextDataModel> {
        return this.clientService.getData(this.urlService.AdminHelp).pipe(
            catchError(_ => of(null))
        );
    }
    
    sendMessage(message: Message) {
        return this.clientService.postData(this.urlService.helpEmail, message).pipe(
            catchError(_ => of(null))
        );
    }

    sendDataModel(textData: TextDataModel) {
        return this.clientService.postData(this.urlService.AdminHelp, textData).pipe(
            catchError(_ => of(null))
        );
    }
}