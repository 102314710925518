import { Component, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MasterUrlService } from "../../../../configs/api-urls";
import { ClientService, ErrorMessageService, MasterDataService } from "../../../../core";
import { FirmBusinessAddressService } from "../../services/firm-business-address.service";
import { map, mapTo, tap } from "rxjs/operators";
import { Observable, merge } from "rxjs";
import { StateListResponseApiModel } from "src/app/app.model";
import { ILobbyistFirmAddress } from "src/app/shared/models/app-lobbyist-business";

@Component({
    selector: "app-create-account-business-address-form",
    templateUrl: "./create-account-business-address-form.component.html",
    styleUrls: ["./create-account-business-address-form.component.scss"],
})
export class CreateAccountBusinessAddressFormComponent
    implements OnInit
{
    addBusinessAddressForm: FormGroup;
    states$: Observable<StateListResponseApiModel>;
    edit$: Observable<boolean>;
    isEditingSameFirm = false;

    constructor(
        private readonly formBuilder: FormBuilder,
        private urlService: MasterUrlService,
        private service: ClientService,
        public errorService: ErrorMessageService,
        private readonly businessService: FirmBusinessAddressService,
        private readonly formatting: MasterDataService,
    ) {}

    ngOnInit(): void {
        this.addBusinessAddressForm = this.formBuilder.group({
            address1: ["", [Validators.required]],
            address2: ["", ],
            city: ["", [
                Validators.required,
                Validators.pattern(this.formatting.cityValidation)
            ]],
            stateCode: ["", [Validators.required]],
            zip: ["", [
                Validators.required,
                Validators.pattern(this.formatting.zipcodePattern)
            ]],
            phoneNumber: ["", [
                Validators.required,
                Validators.pattern(this.formatting.phoneNumberHyphenValidation)
            ]],
        });
        this.states$ = this.service.getData(this.urlService.getStatesList);

        this.edit$ = merge(
            this.businessService.editBusinessAddress$.pipe(
                tap((value: ILobbyistFirmAddress) => {
                    if (value) {
                        this.addBusinessAddressForm.setValue({ ...value});
                    } else {
                        this.addBusinessAddressForm.reset();
                    }
                }),
                map((value) => !!value),
            ),
            this.businessService.deleteBusinessAddress$.pipe(
                tap((value) => {
                    if (value) {
                        this.addBusinessAddressForm.reset();
                    }
                }),
                mapTo(false)
            )
        )
    }

    submit() {
        if (this.addBusinessAddressForm.valid) {
            this.businessService.addNewFirm({ ...this.addBusinessAddressForm.value});
            this.addBusinessAddressForm.reset();
        } else {
            this.addBusinessAddressForm.markAllAsTouched();
            this.addBusinessAddressForm.updateValueAndValidity();
        }
    }

    cancel() {        
        this.businessService.cancel();
    }
}
