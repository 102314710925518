import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {MainActivityReportComponent} from "./components/main-activity-report/main-activity-report.component";
import {NoActivityReportComponent} from "./components/no-activity-report/no-activity-report.component";
import {MonthlyReportGuard} from "./guards/monthly-report.guard";

const routes: Routes = [
    {
        path: "no-activity/:filingPeriodId/:clientRegistrationId",
        canActivate: [MonthlyReportGuard],
        component: NoActivityReportComponent
    },
    {
        path: "no-activity/:filingId",
        canActivate: [MonthlyReportGuard],
        component: NoActivityReportComponent,
    },
    {
        path: ":filingPeriodId/:clientRegistrationId",
        component: MainActivityReportComponent,
        canActivate: [MonthlyReportGuard]
    },
    {
        path: ":filingId",
        component: MainActivityReportComponent,
        canActivate: [MonthlyReportGuard]
    },
    {
        path: "amend",
        component: MainActivityReportComponent,
        canActivate: [MonthlyReportGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ActivityReportRoutingModule {
}
