import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FilerTypeEnumMaine as Filers } from 'src/app/app.model';

import type { manageAccount } from 'src/assets/i18n/en.json';
type Tag = `manageAccount.userType.${keyof typeof manageAccount.userType}`;
type Option = { id: Filers; name: Tag; description?: Tag };

@Component({
    selector: 'app-create-account-choose-user-type',
    templateUrl: './choose-user-type.component.html',
    styleUrls: ['./choose-user-type.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseUserTypeComponent implements OnInit {
    @Output() readonly filerTypeSelected = new EventEmitter<Filers>();

    @Output() readonly back = new EventEmitter<void>();
    @Output() readonly next = new EventEmitter<void>();

    @Input() filerTypeId: Filers;
    @Input() default: Filers = undefined;

    readonly options: Option[] = [
        {
            id: Filers.Lobbyists,
            name: 'manageAccount.userType.lobbyistLabel',
            description: 'manageAccount.userType.lobbyistDescription',
        },
        {
            id: Filers.AuthorizedAgent,
            name: 'manageAccount.userType.authorizerAgentSupport',
            description: 'manageAccount.userType.authorizerAgentSupportLabel',
        },
        {
            id: Filers.LegislativeDesignee,
            name: 'manageAccount.userType.legislativeDesigneeLabel',
        },
        {
            id: Filers.Grassroot,
            name: 'manageAccount.userType.grassrootsFileLabel',
            description: 'manageAccount.userType.grassrootsFileDescription',
        },
    ];

    ngOnInit(): void {
        if (this.default) {
            this.filerTypeSelected.emit(this.default);
        }
    }

    selectFilerType() {
        this.filerTypeSelected.emit(this.filerTypeId);
    }
}
