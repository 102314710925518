import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Injectable()
export class GrassrootService {
    private _form: FormGroup;

    constructor(private readonly formBuilder: FormBuilder) {
        this.form = this.formBuilder.group({
            name: [null, [Validators.required]],
        });
    }

    get form(): FormGroup {
        return this._form;
    }

    set form(value: FormGroup) {
        this._form = value;
    }
}
