import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { UserService } from "../services/user-service";
import { SharedService } from "../services";

@Injectable({ providedIn: "root" })
export class RequiresCreateAccountGuard implements CanActivate {
    constructor(
        private readonly user: UserService,
        private readonly router: Router,
        private readonly shared: SharedService
    ) {}

    canActivate(_next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
        return this.user.getUserStatus().pipe(
            switchMap((s) =>
                this.shared.loadUserEntities(true).pipe(
                    catchError(_ => of(null)),
                    map((userApi) => s.status == "LoggedIn" &&
                        (!s.user && (!userApi || (!userApi.isAdmin && userApi.userFilers.length == 0)))
                        ? true : this.router.createUrlTree(["switch"])
                    )
                )
            )
        );
    }
}
