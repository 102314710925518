import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
    LoginActions as Login,
    LogoutActions as Logout,
} from 'src/app/core/api-services/authorization';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RequiresCreateAccountGuard } from 'src/app/core/guards/requires-create-account.guard';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { SignupComponent } from './components/signup/signup.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ManageComponent } from './components/manage/manage.component';
import { AuthorizeGuard } from 'src/app/core/guards';
import { ManageProfileComponent } from './components/manage-profile/manage-profile.component';
import { ManagePasswordComponent } from './components/manage-password/manage-password.component';
import { ManageEmailComponent } from './components/manage-email/manage-email.component';
import { CreateAccountSuccessScreenComponent } from './components/create-account-success-screen/create-account-success-screen.component';
import { loadInvitation } from './resolvers/invitation.resolver';
import { ManageMfaPhoneNumberComponent } from './components/manage-mfa-phone-number/manage-mfa-phone-number.component';

const routes: Routes = [
    { path: Login.Login, component: LoginComponent },
    { path: Logout.Logout, component: LogoutComponent },
    {
        path: '',
        children: [
            {
                path: 'create',
                canActivate: [RequiresCreateAccountGuard],
                resolve: { details: loadInvitation },
                component: CreateAccountComponent,
            },
            {
                path: 'signup',
                component: SignupComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'success',
                canActivate: [RequiresCreateAccountGuard],

                component: CreateAccountSuccessScreenComponent,
            },
            {
                path: 'manage',
                component: ManageComponent,
                canActivate: [AuthorizeGuard],
                children: [
                    {
                        path: 'profile',
                        component: ManageProfileComponent,
                    },
                    {
                        path: 'email',
                        component: ManageEmailComponent,
                    },
                    // Commented out in order to not include MFA pages in the 
                    // production release (Date: June 12th)
                    // {
                    //     path: 'mfa',
                    //     component: ManageMfaPhoneNumberComponent,
                    // },
                    {
                        path: 'password',
                        component: ManagePasswordComponent,
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AccountRoutingModule {}
