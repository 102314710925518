import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AppFeature } from '@maplight/models';
import { FEATURE_MAP } from '@maplight/utils';

/**
 * This is a functional guard that abuses the behavior of Angular route
 * guards to implement custom redirection logic.
 *
 * As such, this guard will never emit a boolean value, but rather will always change
 * the current navigation target to some feature or, if unable to determine which feature
 * to navigate to, it will attempt to send the user into the Authorized Agent dashboard.
 *
 * @param route the current route snapshot
 * @param _state the current router state (unused)
 * @returns a new url tree representing where we should navigate to
 */
export const requiresFeatureTarget: CanActivateFn = (route, _state) =>
    inject(Router).createUrlTree(
        FEATURE_MAP[route.queryParamMap.get('feature') as AppFeature] ?? [
            '/switch',
        ],
    );
