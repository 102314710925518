import { Component, Input, inject, ChangeDetectionStrategy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FilerEntity } from '@maplight/models';
import { ManageProfileService } from '../../services/manage-profile.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { ManageUserFormService } from '../../services/manage-user-form.service';
import { Subject, combineLatest, merge } from 'rxjs';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { UserApiModel } from 'src/app/app.model';

@Component({
    selector: 'app-manage-profile-legislative-designee',
    templateUrl: './manage-profile-legislative-designee.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageProfileLegislativeDesigneeComponent {
    private filer: FilerEntity;

    @Input() set entity(value: FilerEntity) {
        if (!value) return;

        this.filer = value;
        this.userFormService.set(value.primaryUser);
        this.form.patchValue(value);
    }

    private readonly userFormService = inject(ManageUserFormService);
    private readonly manageProfileService = inject(ManageProfileService);
    private readonly fb = inject(FormBuilder);

    protected readonly form = this.fb.group({
        id: this.fb.control<string>(null, [Validators.required]),
        filerTypeId: this.fb.control<number>(null, [Validators.required]),
        stateAgencyId: this.fb.control<string>(null, [Validators.required]),
    });

    readonly user = this.userFormService.user;

    private readonly saveProfileSubject$ = new Subject<FilerEntity>();

    private readonly saveProfile$ = this.saveProfileSubject$.pipe(
        switchMap(f => this.manageProfileService.updateFilerProfile(f)),
    );

    private readonly savingProfile$ = merge(
        this.saveProfileSubject$.pipe(map(_ => true)),
        this.saveProfile$.pipe(map(_ => false))
    ).pipe(startWith(false));

    private readonly organizationChanged$ = this.form.controls.stateAgencyId.valueChanges.pipe(
        map(change => this.filer.stateAgencyId !== change),
        startWith(false)
    );

    private readonly formChanged$ = combineLatest([
        this.userFormService.hasChanged$,
        this.organizationChanged$
    ]).pipe(map(([x, y]) => x || y));

    readonly disableButton$ = combineLatest([
        this.savingProfile$,
        this.formChanged$
    ]).pipe(map(([x, y]) => x || !y));

    protected saveUserInformation()
    {
        const primaryUser = this.user.value as UserApiModel;
        const filer = this.form.value as FilerEntity;

        this.saveProfileSubject$.next(
            {
                ...filer,
                primaryUser: primaryUser
            }
        );
    }

    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };

    protected agencies$ = this.manageProfileService.getStateAgencies();
}
