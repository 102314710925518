import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ClientService, MasterUrlService, SnackbarService, UserService } from '@maplight/services';
import { BusinessAddress, BusinessAddressTemplate, Filer, FilerEntity, Firm, FirmTemplate } from '@maplight/models';
import { TranslocoService } from '@ngneat/transloco';

type FilerModel = Filer & { firm?: Firm | FirmTemplate; businessAddress?: BusinessAddress | BusinessAddressTemplate; }

@Injectable()
export class ManageProfileService {
    constructor(
        private readonly urls: MasterUrlService,
        private readonly client: ClientService,
        private readonly snackbar: SnackbarService,
        private readonly transloco: TranslocoService,
        private readonly userService: UserService,
    ) {}

    getFilerInformation(id: string): Observable<FilerEntity> {
        return this.client
            .getData(this.urls.getUserEntitiesDetails + id)
            .pipe(catchError((_) => of(null)));
    }
    
    getStateAgencies = () =>
        this.client
            .getData(this.urls.getStateAgencies)
            .pipe(catchError((_) => of(null)));

    updateFilerProfile(data: FilerModel): Observable<boolean> {
        return this.client.putData(`${this.urls.filerApi}`, data).pipe(
            tap((x) => !!x && this.userService.forceReload()),
            tap((x) => {
                !x ? 
                this.snackbar.snackbarError(
                    this.transloco.translate('manageAccount.saveError')
                ) : 
                this.snackbar.snackbarSuccess(
                    this.transloco.translate('manageAccount.saveUserSuccess')
                );
            }),
        );
    }
}
