import { BaseTableInformation } from "../../shared/models/table-layout.model";

export class StateAgency {
    id: string;
    version: number;
    name: string;
}
export class StateAgenciesInput {
    name: string;
}

export class StateAgenciesEdit extends StateAgenciesInput {
    id: string;
}

export interface StateAgenciesTableRow extends BaseTableInformation {
    stateAgency: string;
    version: string;
    lastModified: string;
}
