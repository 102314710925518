import { Component, OnInit, Output, EventEmitter, Input, ViewEncapsulation } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

@Component({
    selector: "app-back-next-buttons",
    templateUrl: "./back-next-buttons.component.html",
    styleUrls: ["./back-next-buttons.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class BackNextButtonsComponent{

    @Input() isDisabled: boolean;
    @Input() buttonText: string;
    @Output() readonly back = new EventEmitter();
    @Output() readonly next = new EventEmitter();
    
    constructor() { }
}
