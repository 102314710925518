import { Observable } from 'rxjs/internal/Observable';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Location } from "@angular/common";
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-faq-component',
  templateUrl: './faq-component.component.html',
  styleUrls: ['./faq-component.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FaqComponentComponent implements OnInit {
  faqs$: Observable<any[]>;
  constructor(
    private location: Location,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this.faqs$ = this.translocoService.selectTranslateObject('faqs');
  }

  back = () => {
    this.location.back();
  }

  onAction(){}
}
