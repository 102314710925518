import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { map, startWith } from "rxjs/operators";
import { Observable, Subject, merge } from "rxjs";
import { TranslocoService } from "@ngneat/transloco";
import { CreateAccountDataService } from "../../services/create-account-data.service";
import { LobbyistService } from "../../services/lobbyist.service";
import { SnackbarService } from "../../../../core";
import { ILobbyistData } from "../../models/ILobbyistData";
import { FirmBusinessAddressService } from "../../services/firm-business-address.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
    selector: "app-create-account-firm-options",
    templateUrl: "./create-account-firm-options.component.html",
    styleUrls: ["./create-account-firm-options.component.scss"],
})
export class CreateAccountFirmOptionsComponent implements OnInit {
    @Input() currentPage: number;
    @Output() back: EventEmitter<any> = new EventEmitter();
    @Output() next: EventEmitter<any> = new EventEmitter();

    userData;
    data: ILobbyistData;

    filerTypes$: Observable<any[]>;
    readonly radioButtonsIds = {
        firstRadioButton: 1,
        secondRadioButton: 2,
        thirdRadioButton: 3
    };

    selectFirmOrAddress$: Observable<number>;
    private _selectFirmOrAddressSubject$ = new Subject<number>();
    disableSubmitButton$: Observable<boolean>;

    formGroup: FormGroup;

    constructor(
        private readonly translocoService: TranslocoService,
        private readonly createService: CreateAccountDataService,
        private readonly fb: FormBuilder,
        public readonly firmBussinesAddress: FirmBusinessAddressService,
        public readonly lobbyist: LobbyistService,
        public snackbar: SnackbarService,
    ) {
        this.formGroup = this.fb.group({
            lobbyistOption: [this.createService.lobbyistOption ?? 1, [Validators.required]]
        })
    }

    ngOnInit(): void {
        if (!this.createService.lobbyistOption) this.createService.lobbyistOption = 1;
        const lobbyistOption = this.createService.lobbyistOption;
        this.selectFirmOrAddress$ = this._selectFirmOrAddressSubject$.asObservable();

        this.disableSubmitButton$ = merge(
            merge(
                this.lobbyist.addressSelected$,
                this.lobbyist.firmAddressSelected$,
                this.firmBussinesAddress.addBusinessAddress$
            ).pipe(
                map((value) => !value)
            ),
            this.formGroup.controls.lobbyistOption.valueChanges.pipe(
                map((lobbyistOpt) => this.checkDisableConditions(lobbyistOpt)),
                startWith(this.checkDisableConditions(lobbyistOption))
            )
        );

        this.filerTypes$ = this.translocoService
            .selectTranslateObject("manageAccount.lobbyist")
            .pipe(
                map((u) => [
                    {
                        lobbyistOption: this.radioButtonsIds.firstRadioButton,
                        name: u.searchLobbyingFirms,
                        description: u.searchLobbyingFirmsLabel,
                    },
                    {
                        lobbyistOption: this.radioButtonsIds.secondRadioButton,
                        name: u.addNewFirm,
                        description: u.addNewFirmLabel,
                    },
                    {
                        lobbyistOption: this.radioButtonsIds.thirdRadioButton,
                        name: u.businessAddress,
                        description: u.businessAddressLabel,
                    },
                ])
            );
    }

    private checkDisableConditions(lobbyistOption) {
        switch (lobbyistOption) {
            case this.radioButtonsIds.firstRadioButton: {
                return !this.lobbyist.addressSelected
            }
            case this.radioButtonsIds.secondRadioButton: {
                return !this.lobbyist.newFirmWithAddress
            }
            case this.radioButtonsIds.thirdRadioButton: {
                return !this.firmBussinesAddress.businessAddress
            }
        }
    }

    optionSelected() {
        this.createService.lobbyistOption = this.formGroup.controls.lobbyistOption.value;
    }

    selectFirmOrAddress(value) {
        this._selectFirmOrAddressSubject$.next(value)
    }
}
