export enum FilingStatusEnum {
    Submitted = 1,
    NeedsInformation = 2,
    NeedsAudit = 3,
    Approved = 4,
    ApprovedNotAudited = 5,
    AuditCompleted = 6,
    InAuditProcess = 7,
    NotApproved = 8,
    Draft = 9,
    Unfiled = 10
}