import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ErrorMessageService, MasterDataService, UserService } from "@maplight/index";
import { Observable, Subject, merge, of, partition } from "rxjs";
import { catchError, map, share, startWith, switchMap, tap } from "rxjs/operators";
import { IUserSession } from "@maplight/api-services/authorization/auth.service";

@Component({
    selector: "app-manage-mfa-phone-number",
    templateUrl: "./manage-mfa-phone-number.component.html",
    styleUrls: ["./manage-mfa-phone-number.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ManageMfaPhoneNumberComponent implements OnInit {

    constructor(
        private readonly masterDataService: MasterDataService,
        public readonly errorService: ErrorMessageService,
        private readonly userService: UserService,
        private readonly fb: FormBuilder,
    ) {}

    private readonly userStatus$ = this.userService.getUserStatus();

    readonly isPhoneNumberVerified$ = this.userStatus$.pipe(map(x => (x.content as IUserSession).phoneNumberVerified), share());

    readonly phoneNumber$ = this.userStatus$.pipe(map(x => (x.content as IUserSession).phoneNumber));

    readonly form = this.fb.group({
        newPhoneNumber: this.fb.control<string>(null, [
            Validators.required,
            Validators.minLength(10),
            Validators.maxLength(10),
            Validators.pattern(this.masterDataService.onlyNumberPattern)
        ]),
        confirmationCode: this.fb.control<string>(null, [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
            Validators.pattern(this.masterDataService.onlyNumberPattern)
        ])
    });

    sendingUpdate$: Observable<boolean>;
    sendingVerify$: Observable<boolean>;
    showVerifyPhoneNumberDialog$: Observable<boolean>;

    private readonly sendUpdatePhoneNumberSubject$ = new Subject<void>();
    private readonly sendVerifyPhoneNumberSubject$ = new Subject<void>();
    private readonly closeDialogSubject$ = new Subject<boolean>();
    private readonly attributeName = 'phone_number';
    private readonly countryCode = "+1";

    ngOnInit(): void {
        const sendUpdatePhoneNumber$ : Observable<boolean> = this.sendUpdatePhoneNumberSubject$.pipe(
            switchMap(_ => this.userService.updateAttribute(this.attributeName, this.countryCode + this.form.value.newPhoneNumber)),
            map(_ => true),
            catchError(_ => of(false)),
            share()
        );

        const [updateSuccess$, updateFail$] = partition(
            sendUpdatePhoneNumber$,
            (x) => x
        );
    
        this.sendingUpdate$ = merge(
            this.sendUpdatePhoneNumberSubject$.pipe(map(_ => true)),
            updateSuccess$.pipe(map(_ => false)),
            updateFail$.pipe(map(_ => false)),
        ).pipe(startWith(false));
    
        // Send Verify Phone Number Update
        const sendVerifyPhoneNumber$ = this.sendVerifyPhoneNumberSubject$.pipe(
            switchMap(_ => this.userService.verifyAttribute(this.attributeName, this.form.value.confirmationCode)),
            map(_ => true),
            catchError(_ => of(false)),
            share()
        );
    
        const [verifySuccess$, verifyFail$] = partition(
            sendVerifyPhoneNumber$,
            (x) => x
        );
        const success$ = verifySuccess$.pipe(
            tap(_ => this.form.reset()),
            switchMap(_ => this.userService.refreshToken()),
            share()
        );
    
        this.sendingVerify$ = merge(
            this.sendVerifyPhoneNumberSubject$.pipe(map(_ => true)),
            success$.pipe(map(_ => false)),
            verifyFail$.pipe(map(_ => false)),
        ).pipe(startWith(false));
    
        this.showVerifyPhoneNumberDialog$ = merge(
            updateSuccess$,
            success$.pipe(map(_ => false)),
            this.closeDialogSubject$.pipe(map(x => !x))
        ).pipe(startWith(false));
    }

    closeDialog() {
        this.closeDialogSubject$.next(true);
    }

    updatePhoneNumber() {
        this.sendUpdatePhoneNumberSubject$.next();
    }

    verifyPhoneNumberUpdate() {
        this.sendVerifyPhoneNumberSubject$.next();
    }
}
