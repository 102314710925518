import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HelpComponent } from "../helps/help/help.component";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { ConcurrentWorkComponent } from "./concurrent-work/concurrent-work.component";
import { FaqComponentComponent } from "./faq-component/faq-component.component";

const routes: Routes = [
    {
        path: "",
        children: [
            {
                path: "",
                component: HelpComponent,
            }
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HelpsRoutingModule {}
