import { Component, Input, ViewEncapsulation } from '@angular/core';
import { DialogContentBase } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-view-message',
  templateUrl: './view-message.component.html',
  styleUrls: ['./view-message.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ViewMessageComponent extends DialogContentBase {

  @Input() header: string;
  @Input() subject: string = null;
  @Input() text: string = null;
  @Input() sentTime: Date = null;
  ngOnInit() { }
}