import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { merge, Observable, of, partition } from "rxjs";
import {
    map,
    mapTo,
    startWith,
    switchMap,
    share,
    tap,
    withLatestFrom,
} from "rxjs/operators";
import { FilerInformationService } from "src/app/core";
import { Filer, UserData } from "../switch-commitee.model";
import { FilerDataService } from "./filer-data.service";
import { takeFirst } from "./helper";
import { SwitchTargetService } from "./switch-target.service";
import { UserDataService } from "./user-data.service";

@Injectable({ providedIn: "root" })
export class RedirectorService {
    redirecting$: Observable<boolean>;

    private readonly featureRouting: Record<Filer, string[]> = {
        [Filer.Committee]: ["/committee-info-dashboard"],
        [Filer.EthicsFiler]: ["/ethics-dashboard"],
        [Filer.Lobbyists]: ["/lobbyist"],
        [Filer.IndependentExpenditure]: ["/dashboard/ie-home"],
        [Filer.AllFilers]: ["/manage/committee"],
        [Filer.Unaffiliated]: ["/public/non-affiliated"],
        [Filer.Admin]: ["/manage/committee"],
        [Filer.AuthorizedAgent]: ["/"],
        [Filer.LegislativeDesignee]: ["/"],
        [Filer.Grassroot]: ["/"],
    };

    constructor(
        private readonly userData: UserDataService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly switchTo: SwitchTargetService,
        private readonly targets: FilerDataService,
        private readonly filer: FilerInformationService
    ) {
        const isLogin$ = this.route.queryParamMap.pipe(
            map((params) => !!params.get("login"))
        );

        const [needImmediateRedirect$, noRedirect$] = partition(
            this.userData.data$.pipe(withLatestFrom(isLogin$)),
            this.isSingleFiler
        );

        const immediateRedirect$ = this.switchTo.target$.pipe(
            switchMap((id) =>
                (!id ? needImmediateRedirect$ : noRedirect$).pipe(
                    map(([data, _]) => [data, id] as [UserData, string])
                )
            ),
            switchMap(([res, id]) => {
                const items = id
                    ? res?.userFilers?.filter(
                          (_) => _.filerId === id
                      )
                    : res?.userFilers;

                const check =
                    (!!id && items?.length === 1) || items?.length >= 1;

                if (!check) return of(res);

                const { filerId, filerTypeId, isPrimary } = takeFirst(items);

                return this.targets
                    .loadEntity(filerId, filerTypeId, isPrimary)
                    .pipe(
                        tap((data) =>
                            this.filer.setCurrent({
                                ...data,
                                isPrimary,
                                isActive: true,
                                filingTypeId: 0,
                                filingTypeName: "",
                            })
                        ),
                        map((data) => ({
                            ...res,
                            userFilers: [data],
                        }))
                    );
            }),
            map(this.extractFilerType)
        );

        const switchAndRedirect$ = this.switchTo.current$.pipe(
            withLatestFrom(this.userData.data$),
            tap(([_, data]) =>
                this.filer.setCurrent({
                    ..._,
                    isPrimary: data.userFilers?.find(
                        (w) => _.filerId === w.filerId
                    )?.isPrimary,
                })
            ),
            tap(([_, data]) =>
                this.filer.setAdminRights(data.isAdmin && !_.filerTypeId)
            ),
            map(([{ filerTypeId }, _]) => filerTypeId as Filer)
        );

        const redirectRequest$ = merge(
            immediateRedirect$,
            switchAndRedirect$
        ).pipe(share());

        const startingRedirect$ = redirectRequest$.pipe(mapTo(true));

        const completeRedirect$ = redirectRequest$.pipe(
            switchMap(this.doRedirectByFiler),
            mapTo(false)
        );

        this.redirecting$ = merge(
            startingRedirect$,
            completeRedirect$,
            noRedirect$.pipe(mapTo(false))
        ).pipe(startWith(true));
    }

    readonly isSingleFiler = ([
        { userFilers: _, isAdmin },
        isLogin,
    ]: [UserData, boolean]): boolean =>
        isLogin && (_?.length ?? 0) + (isAdmin ? 1 : 0) <= 1;

    private readonly extractFilerType = ({
        isAdmin,
        userFilers: _,
    }: UserData): Filer =>
        isAdmin
            ? Filer.AllFilers
            : takeFirst(_)?.filerTypeId ?? Filer.Unaffiliated;

    private readonly doRedirectByFiler = (
        filerType: Filer
    ): Observable<boolean> =>
        this.route.queryParamMap.pipe(
            switchMap((params) =>
                !!params.get("returnUrl")
                    ? this.router.navigateByUrl(params.get("returnUrl"))
                    : this.router.navigate(this.featureRouting[filerType])
            )
        );
}
