import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ErrorMessageService } from "../../../../configs/error-message";
import { ILegislative } from "../../../../shared/models/legislative";
import { Observable, Subject } from "rxjs";
import { CreateAccountDataService } from "../../services/create-account-data.service";
import { SnackbarService } from "../../../../core";
import { UserService } from "../../../../core/services/user-service";
import { TranslocoService } from "@ngneat/transloco";
import { BaseClassWithForm } from "../create-account/create-account.component";
import { GrassrootService } from "../../services/grassroot.service";

@Component({
    selector: "app-create-account-grassroots-filers",
    templateUrl: "./create-account-grassroots-filers.component.html",
    styleUrls: ["./create-account-grassroots-filers.component.scss"],
})
export class CreateAccountGrassrootsFilersComponent
    extends BaseClassWithForm
    implements OnInit
{
    @Output() back: EventEmitter<any> = new EventEmitter();
    @Output() next: EventEmitter<any> = new EventEmitter();
    submit$ = new Subject<ILegislative>();
    loading$: Observable<boolean>;

    public form: FormGroup;

    constructor(
        readonly errorService: ErrorMessageService,
        private readonly translocoService: TranslocoService,
        private readonly grassrootsService: GrassrootService
    ) {
        super();
    }

    get isValid(): boolean {
        return this.form.valid;
    }

    ngOnInit() {
        this.form = this.grassrootsService.form;
    }
}
