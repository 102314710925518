import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { JoinableEntitySelectorComponent } from '../components/joinable-entity-selector/joinable-entity-selector.component';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { TranslocoModule } from "@ngneat/transloco";
import { TextAreaModule } from "@progress/kendo-angular-inputs";
@NgModule({
    declarations: [JoinableEntitySelectorComponent],
    imports: [CommonModule, ComboBoxModule, ListViewModule, TranslocoModule, TextAreaModule],
    exports: [JoinableEntitySelectorComponent]
})
export class JoinableEntitySelectorModule {}
