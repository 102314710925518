import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { PrincipalMenu } from '../app-header/app-header.component';

@Component({
  selector: 'app-header-menu',
  templateUrl: './app-header-menu.component.html',
  styleUrls: ['./app-header-menu.component.scss'],
})
export class AppHeaderMenuComponent {
  @ViewChild("menuButton", { read: ElementRef }) public menuButton: ElementRef;
  
  @Input() menu: PrincipalMenu;
  protected openMenu: boolean = false;
  protected identifier: string = "app-menu-toggler";

  public onToggle(): void {
    this.openMenu = !this.openMenu;
  }

  protected getMenuClasses() {
    const classes = {
      "menu-button": true,
      "opened": this.openMenu,
      "closed": !this.openMenu
    };
    classes[this.identifier] = true;

    return classes;
  }

  //for hamburger menu
  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.openMenu = false;
    }
  }

  @HostListener("document:click", ["$event"])
  public clickOutside(event: any): void {
    if(!event.target?.classList.contains(this.identifier)){
      this.openMenu = false;
    }
  }
}
