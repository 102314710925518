export type ActivityReport = {
    primaryLobbyist: string;
    phoneNumber: string;
    lobbyistEmail: string;
    lobbyingFirm: string;
    firmAddress: string;
    filingPeriod: string;
    clientName: string;
    principalContactForClient: string;
    clientEmail: string;
    clientPhone: string;
    clientAddress: string;
    dueDate: string;
};

export enum COLUMN_TYPE {
    DROPDOWN = 0,
    NUMBER = 1,
    STRING = 2,
    DATE = 3,
    AUTOCOMPLETE = 4,
}
