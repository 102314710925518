import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { switchMap, startWith, filter, tap, share, map } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';

enum CurrentPage {
    EmailVerificationForm,
    LoginForm,
    MFAVerificationForm,
    Loading,
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
    public CurrentPageEnum = CurrentPage;
    public email: string;

    private password: string;
    private readonly loginSubject$ = new Subject<void>();
    private readonly mfa$ = new Subject<void>();

    constructor(
        private readonly router: Router,
        private readonly accountService: AccountService,
        private readonly route: ActivatedRoute,
    ) {}

    private readonly status$ = this.accountService
        .getUserStatusType()
        .pipe(share());

    readonly currentPage$ = merge(
        this.status$.pipe(
            filter((it) => it === 'RequiresConfirmation'),
            map(() => CurrentPage.EmailVerificationForm),
        ),
        this.status$.pipe(
            filter((it) => it === 'RequiresMFA'),
            map(() => CurrentPage.MFAVerificationForm),
        ),
        merge(
            this.status$.pipe(
                filter((it) => it === 'LoggedIn'),
                tap(() => this.redirect()),
            ),
            this.mfa$,
            this.loginSubject$,
        ).pipe(map(() => CurrentPage.Loading)),
        this.loginSubject$.pipe(
            switchMap(() => this.loginUser()),
            filter((it) => !it),
            map(() => CurrentPage.LoginForm),
        ),
    ).pipe(startWith(CurrentPage.LoginForm));

    private redirect() {
        const url = this.route.snapshot.queryParamMap.get('returnUrl');
        this.router.navigateByUrl(url ?? '/switch');
    }

    loginRequest(e) {
        this.email = e.email;
        this.password = e.password;
    }

    readonly confirmationSuccess = () => this.loginSubject$.next();

    loginUser = () =>
        this.accountService.loginUser(
            this.email,
            this.password,
            false,
            'login.invalidLoginAttempt',
        );

    readonly mfaVerificationSuccess = () => this.mfa$.next();
}
