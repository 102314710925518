import { FormGroup } from "@angular/forms";
import { Observable } from "rxjs";

export type GridDataModel<T = any> = {
    heading: string;
    subtitle?: string;
    data?: (url: string) => Observable<T[]>;
    delete?: (id: string, url: string) => Observable<T>;
    add?: (value: T, url?: string) => Observable<T>;
    edit?: (value: T, url: string) => Observable<T>;
    actionButtons?: string;
    table: DataTable[];
    search?: <T>(text: string, url:string) => Observable<T[]>;
    formGroup?: FormGroup;
    pageable?: boolean;
};

export type DataTable<T = string | number> = {
    title?: string;
    field: string;
    value?: any;
    editMapper?: (value: any) => any;
    dynamicValue?: (args?: any) => any;
    afterTransform?: (args?: any) => any;
    type: COLUMN_TYPE;
    placeholder?: string;
    required?: boolean;
    format? : (value: T) => string;
    canEdit?: boolean;
    visible?: boolean;
    width?: string;
    height?: string;
};

export enum COLUMN_TYPE {
    DROPDOWN = 0,
    NUMBER = 1,
    STRING = 2,
    DATE = 3,
    AUTOCOMPLETE = 4,
    NUMBER_INTEGER = 5
}

export enum EventType {
    Add = 1,
    Del = 2,
    Get = 3,
    Edit = 4,
    Reset = 5,
    Error = 6
}

export type MyEvent<T> =
    { _: EventType.Add; res: T }
    | { _: EventType.Del; res: T }
    | { _: EventType.Get; res: T[] }
    | { _: EventType.Edit; res: T }
    | { _: EventType.Reset; res: T }
    | { _: EventType.Error; res: null };