import { Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DialogContentBase, DialogModule } from '@progress/kendo-angular-dialog';
import { IconsModule } from '@progress/kendo-angular-icons';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    standalone: true,
    imports: [
        DialogModule,
        TranslocoModule,
        ButtonsModule,
        IconsModule,
    ],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogComponent extends DialogContentBase {

    @Input() header: string;
    @Input() actionName: string;
    @Input() objectName: string;
    @Input() text: string = null;

    ngOnInit() { }

    public onNoAction = () => this.dialog.close();

    public onYesAction = () => this.dialog.close({ text: 'ok' });
}
