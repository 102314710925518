import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ErrorMessageService, MasterDataService } from "@maplight/index";
import { Observable, Subject, merge, partition } from "rxjs";
import { filter, map, mapTo, share, shareReplay, startWith, switchMap, tap } from "rxjs/operators";
import { AccountService } from "../../services/account.service";

@Component({
    selector: "app-manage-email",
    templateUrl: "./manage-email.component.html",
    styleUrls: ["./manage-email.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ManageEmailComponent implements OnInit {

    userEmail$: Observable<string>;
    readonly formGroup = this.fb.group({
        newEmail: this.fb.control<string>(null, [
            Validators.required,
            Validators.pattern(this.masterDataService.emailValidations)
        ]),
        confirmationCode: this.fb.control<string>(null, [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(6),
            Validators.pattern(this.masterDataService.onlyNumberPattern)
        ])
    });
    sendingUpdate$: Observable<boolean>;
    sendingVerify$: Observable<boolean>;
    showVerifyEmailDialog$: Observable<boolean>;

    private readonly sendUpdateEmailSubject$ = new Subject<void>();
    private readonly sendVerifyEmailSubject$ = new Subject<void>();
    private readonly closeDialogSubject$ = new Subject<boolean>();
    private readonly attributeName = 'email';

    constructor(
        private readonly masterDataService: MasterDataService,
        public readonly errorService: ErrorMessageService,
        private readonly accountService: AccountService,
        private readonly fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        const user$ = this.accountService.getUserData().pipe(shareReplay());

        this.userEmail$ = user$.pipe(
            map(user => user?.email)
        );
        
        // Send Update Email
        const sendUpdateEmail$ = this.sendUpdateEmailSubject$.pipe(
            switchMap(_ => this.accountService.updateUserAttribute(this.attributeName, this.formGroup.value.newEmail)),
            shareReplay()
        );

        const [updateSuccess$, updateFail$] = partition(
            sendUpdateEmail$,
            (x) => x
        );

        this.sendingUpdate$ = merge(
            this.sendUpdateEmailSubject$.pipe(mapTo(true)),
            updateSuccess$.pipe(mapTo(false)),
            updateFail$.pipe(mapTo(false)),
        ).pipe(startWith(false));

        // Send Verify Email Update
        const sendVerifyEmail$ = this.sendVerifyEmailSubject$.pipe(
            switchMap(_ => this.accountService.verifyUserAttribute(this.attributeName, this.formGroup.value.confirmationCode)),
            shareReplay()
        );

        const [verifySuccess$, verifyFail$] = partition(
            sendVerifyEmail$,
            (x) => x
        );
        const success$ = verifySuccess$.pipe(
            tap(_ => this.formGroup.reset()),
            switchMap(_ => this.accountService.refreshToken()),
            share()
        );

        this.sendingVerify$ = merge(
            this.sendVerifyEmailSubject$.pipe(mapTo(true)),
            success$.pipe(mapTo(false)),
            verifyFail$.pipe(mapTo(false)),
        ).pipe(startWith(false));

        this.showVerifyEmailDialog$ = merge(
            updateSuccess$,
            success$.pipe(mapTo(false)),
            this.closeDialogSubject$.pipe(map(x => !x))
        ).pipe(startWith(false));
    }

    closeDialog() {
        this.closeDialogSubject$.next(true);
    }

    updateEmail() {
        this.sendUpdateEmailSubject$.next();
    }

    verifyEmailUpdate() {
        this.sendVerifyEmailSubject$.next();
    }
}
