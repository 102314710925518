import { Pipe, PipeTransform } from '@angular/core';
import {
    FilerTypeEnumMaine,
    InvitationDetails,
    LobbyistUserTypeEnum,
} from 'src/app/app.model';

@Pipe({ name: 'asDefaultFilerChoice' })
export class AsDefaultFilerChoicePipe implements PipeTransform {
    transform(value: InvitationDetails) {
        return value?.expectedUserType === LobbyistUserTypeEnum.AuthorizedAgent
            ? FilerTypeEnumMaine.AuthorizedAgent
            : null;
    }
}
