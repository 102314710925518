import { inject, Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ApiResponse } from "src/app/app.model";
import { ClientService, MasterUrlService } from "src/app/core";
import {
    Notification,
    SystemDate,
    Template,
    UnreadMessage,
} from "../data";
import { FilerType, ArchivedMessage } from "@maplight/models";

@Injectable()
export class AdminNotificationService {
    private readonly client = inject(ClientService);
    private readonly urls = inject(MasterUrlService);

    getTemplates(): Observable<Template[]> {
        return this.client
            .getData(this.urls.GetTemplates)
            .pipe(catchError(() => of([] as Template[])));
    }

    isNameInUse(name: string): Observable<boolean | "error"> {
        return this.client
            .getData(this.urls.templateNameExists + name)
            .pipe(catchError(() => of("error")));
    }

    saveTemplate(request: Partial<Template>): Observable<boolean> {
        return this.client.postData(this.urls.saveTemplate, request).pipe(
            map(([{ hasError }, ..._]: ApiResponse[]) => hasError === false),
            catchError(() => of(false))
        );
    }

    deleteTemplate(id: number | string): Observable<boolean> {
        return this.client.postData(this.urls.deleteTemplate + id, null).pipe(
            map(([{ hasError }, ..._]: ApiResponse[]) => hasError === false),
            catchError(() => of(false))
        );
    }

    sendTestEmail(request: Partial<Template>): Observable<boolean> {
        return this.client
            .postData(this.urls.notificationEmailTest, request)
            .pipe(catchError(() => of(false)));
    }

    getNotificationById(id: number): Observable<Notification> {
        return this.client.getData(this.urls.getScheduledNotification(id)).pipe(
            map(([it]) => it),
            catchError(() => of(null))
        );
    }

    removeNotification(id: number): Observable<boolean> {
        return this.client
            .delete(`${this.urls.deleteNotification}?notificationId=${id}`)
            .pipe(
                map((it: { hasError: boolean }) => !it.hasError),
                catchError(() => of(false))
            );
    }

    getAllNotifications(): Observable<Notification[]> {
        return this.client
            .getData(this.urls.getAllScheduledNotifications())
            .pipe(catchError(() => of([] as Notification[])));
    }

    getArchivedNotifications(): Observable<ArchivedMessage[]> {
        return this.client
            .getData(this.urls.archivedNotifications)
            .pipe(
                map((res: ArchivedMessage[]) =>
                    res.map(
                        (x) =>
                            ({
                                ...x,
                                sentTime:
                                    x.sentTime === null
                                        ? null
                                        : new Date(x.sentTime),
                            } as ArchivedMessage)
                    )
                ),
                catchError(() => of([] as ArchivedMessage[]))
            )         
    }

    saveNotification(data: Partial<Notification>): Observable<boolean> {
        return this.client.postData(this.urls.saveEmailNotification, data).pipe(
            map(([it, ..._]: { hasError: boolean }[]) => !it.hasError),
            catchError(() => of(false))
        );
    }

    getUnreadMessages(): Observable<UnreadMessage[]> {
        return this.client
            .getData(this.urls.getAdminUnreadMessages)
            .pipe(catchError(() => of([] as UnreadMessage[])));
    }

    getUnreadMessagesCount(filerId?: string): Observable<{count: number;}> {
        const url = filerId ? 
            this.urls.getLobbyistUnreadMeassagesCount(filerId) : 
            this.urls.getAdminUnreadMessagesCount;

        return this.client.getData(url)
            .pipe(catchError(() => of({count: 0})));
    }

    getSystemDates(): Observable<SystemDate[]> {
        return this.client.getData(this.urls.getSystemDates).pipe(
            map((them: SystemDate[]) =>
                them.map((it) => ({
                    ...it,
                    date: new Date(it.date),
                    key: `${it.type}|${it.id}`,
                }))
            ),
            catchError(() => of([] as SystemDate[]))
        );
    }

    getFilerTypes(): Observable<FilerType[]> {
        return this.client.getData(this.urls.getNotificationUserTypesList).pipe(
          catchError(() => of([] as UnreadMessage[]))
        );
    }    
}
