import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { UserFilers } from "src/app/shared/models";
import { SharedService } from "./Shared-services";
import { FilerTypeEnum } from '../../shared/models';
import { catchError, map, shareReplay } from "rxjs/operators";
import { FilerDataCacheService } from "src/app/modules/switch-committee/services/filer-data-cache.service";
import { ClientService, MasterUrlService } from ".";

@Injectable({ providedIn: 'root' })
export class FilerInformationService {
    // TODO - change the name of this to filerController or filerSubject
    private readonly filer$ = new BehaviorSubject<UserFilers>(null);
    // TODO - change the name of this to filer$ or currentFiler$
    readonly current$ = this.filer$.asObservable();

    isAdmin: boolean = false;
    filerTypeName$: Observable<string>;

    constructor(
        private readonly shared: SharedService,
        private readonly cache: FilerDataCacheService,
        private readonly client: ClientService,
        private readonly urls: MasterUrlService,
    ) {
        const optionA = this.shared.getCurrentCommittee();
        const optionB = this.shared.getCurrentSelectedUserFiler(true);

        const current = optionA ?? optionB;

        this.isAdmin = !!current?.isAdmin;

        this.filer$.next({
            ...current?.selectedFiler,
            isPrimary: false,
            isActive: true,
        });
        this.filerTypeName$ = this.current$.pipe(
            map((x) => {
                switch (x.filerTypeId) {
                    case FilerTypeEnum.Committee:
                        return 'committee';
                    case FilerTypeEnum.EthicsFiler:
                        return 'ethics';
                    case FilerTypeEnum.IndependentExpenditure:
                        return 'independent expenditure';
                    case FilerTypeEnum.Lobbyists:
                        return 'lobbyist';
                    case FilerTypeEnum.Unaffiliated:
                        return 'unaffiliated';
                }
            }),
            shareReplay(1),
        );
    }

    setAdminRights(value: boolean): void {
        this.isAdmin = value;
    }

    setCurrent<T extends UserFilers>(value: T): void {
        this.shared.setCurrentFiler(value);
        this.filer$.next(this.shared.setCurrentCommittee(value, true));
    }

    update<T extends UserFilers>(updater: (_: UserFilers) => T): void {
        const lookup = {
            filerTypeId: this.filer$.value.filerTypeId,
            filerId: this.filer$.value.filerId,
        };

        const updated = updater(this.filer$.value);

        this.cache.updateCached(lookup, (existing) => ({
            ...existing,
            committeeTypeId: updated.filerTypeId,
            entityTypeId: updated.filerTypeId,
            entityType: updated.filerTypeName,
            entityName: updated.name,
        }));

        this.setCurrent(updated);
    }

    clear(): void {
        this.setCurrent(null);
        this.isAdmin = false;
    }

    loadFilerData(filerId: string) {
        return this.client.getData(this.urls.filerApi + `/${filerId}`)
            .pipe(catchError(_ => of(null)));
    }

    setFilerAccount(filer): UserFilers {
        let item = {
            filerId: filer.id,
            publicId: filer.publicId,
            filerVersion: filer.version,
            version: filer.version,
            name: filer.name,
            isActive: filer.recordStatusId == 1,
            createdAt: filer.createdAt,

            filerStatusId: filer.filerStatusId,
            filerTypeId: filer.filerTypeId,
            isPrimary: false,
        } as UserFilers;

        this.setCurrent(item);
        return item;
    }
}
