import { FilerTypeEnumMaine } from './app-enums.model';

export interface FilerEntityUser {
    entityUserId: number;
    filerId: number;
    userId: string;
    userRole: string;
    firstName: string;
    lastName: string;
    email: string;
    statusId: number;
    note?: string;
    pendingMakePrimaryRequest: boolean;
    applicationUserStatusId: number;
}

export enum InvitationType {
    CreateAccount = 1,
    CreateAdminAccount = 2,
    JoinFiler = 3,
    PrimaryChange = 4,
}

export interface InvitationDetails {
    type: InvitationType;
    filerId: number;
    filerTypeId: number;
    filerType: FilerTypeEnumMaine;
    targetName: string;
    entityName: string;
    role: string;
    expiryDate: Date | string;
    resolved?: boolean;
    expired?: boolean;
    email: string;
    expectedUserType?: number;
}
