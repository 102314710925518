export type AuthorizedAgent = {
    id: string;
    fullName: string;
    active: boolean;
    request?: number;
    email: string;
    version: number;
    userId: string;
}

export enum AgentRequestStatus {
    Pending = 1,
    Approved = 2,
    Rejected = 3,
}

export type AuthorizedAgentRequest = {
    id: number;
    email: string;
    fullName: string;
    notes: string;
    status: AgentRequestStatus;
}