import { Component, OnInit, Output, ViewEncapsulation, EventEmitter, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { ErrorMessageService, SnackbarService } from "src/app/core";
import { MasterDataService } from "../../../../configs/master-data";
import { AccountService } from "../../services/account.service";
import { CreateAccountDataService } from "../../services/create-account-data.service";
import { BaseClassWithForm } from "../create-account/create-account.component";

@Component({
    selector: "app-create-account-form",
    templateUrl: "./create-account-form.component.html",
    styleUrls: ["./create-account-form.component.scss"],
    encapsulation: ViewEncapsulation.None,
    providers: [
        { provide: BaseClassWithForm, useExisting: CreateAccountFormComponent },
    ],
})
export class CreateAccountFormComponent
    extends BaseClassWithForm
    implements OnInit
{
    @Input() isSubmitting: boolean = false;

    @Output() back: EventEmitter<any> = new EventEmitter();
    @Output() next: EventEmitter<any> = new EventEmitter();

    private _isValid: boolean = false;
    userData: {
        firstName: string;
        lastName: string;
        businessPhone: string;
    } = { firstName: "", lastName: "", businessPhone: null };

    get isValid(): boolean {
        return this.form.valid;
    }

    public form: FormGroup;

    saveUserSubject$: Subject<boolean> = new Subject<boolean>();
    savingUser$: Observable<boolean>;

    constructor(
        public accountService: AccountService,
        public formatting: MasterDataService,
        public errorService: ErrorMessageService,
        private fb: FormBuilder,
        public router: Router,
        public snackbar: SnackbarService,
        public route: ActivatedRoute,
        private readonly createAccountService: CreateAccountDataService
    ) {
        super();
    }

    ngOnInit(): void {
        //init data
        this.userData.lastName = this.createAccountService.value.lastName;
        this.userData.firstName = this.createAccountService.value.name;

        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            firstName: [
                this.userData.firstName || null,
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(80),
                ],
            ],
            lastName: [
                this.userData.lastName || null,
                [
                    Validators.required,
                    Validators.minLength(1),
                    Validators.maxLength(80),
                ],
            ],
            // Commented out in order to not include MFA pages in the 
            // production release (Date: June 12th)
            // businessPhone: [
            //     this.userData.businessPhone || null,
            //     [
            //         Validators.required,
            //         Validators.minLength(10),
            //         Validators.maxLength(10),
            //         Validators.pattern(this.formatting.onlyNumberPattern)
            //     ],
            // ]
        });
    }
}
