import {
    AfterViewInit,
    Directive,
    inject,
    OnDestroy,
    Renderer2,
} from '@angular/core';
import { GridComponent } from '@progress/kendo-angular-grid';

@Directive({
    selector: '[stickyHeader]',
})
export class StickyHeaderDirective implements AfterViewInit, OnDestroy {
    private cleanup: () => void = undefined;

    private readonly host = inject(GridComponent);
    private readonly renderer = inject(Renderer2);

    ngAfterViewInit(): void {
        const grid = this.host.wrapper.nativeElement as HTMLElement;
        const header = grid?.querySelector('.k-grid-header') as HTMLElement;

        if (!header) return;

        this.cleanup = this.renderer.listen(window, 'scroll', () =>
            this.updateClasses(grid, header),
        );
    }

    ngOnDestroy(): void {
        this.cleanup?.();
    }

    private updateClasses(grid: HTMLElement, header: HTMLElement) {
        const offset = window.scrollY;
        const tableOffsetTop = grid.offsetTop;
        const tableOffsetBottom =
            tableOffsetTop + grid.clientHeight - header.clientHeight;

        if (offset < tableOffsetTop || offset > tableOffsetBottom) {
            this.renderer.removeClass(header, 'grid-fixed-header');
        } else if (offset >= tableOffsetTop && offset <= tableOffsetBottom) {
            this.renderer.addClass(header, 'grid-fixed-header');
            this.renderer.setStyle(header, 'width', `${grid.clientWidth}px`);
        }
    }
}
