export enum CommitteeOrCandidateEnums {
    Committee = 1,
    Candidate = 2,
}

export enum CommitteeTypeEnums {
    CandidateCommittee = 1,
    NewCommitee = 2,
    PACCommittee = 3,
    IndependentFiler = 4,
    FilerType = 5,
    CityStaffs = 6,
    SmallDonorCommittee = 7,
    IssueCommittee = 8,
    CoveredOfficials = 9,
}

/**
 * @deprecated Some values do not correspond to Maine Fillers
 */
export enum FilerTypeEnum {
    AllFilers = 0,
    Lobbyists = 1,
    IndependentExpenditure = 2,
    Committee = 3,
    EthicsFiler = 4,
    AuthorizedAgent = 5,
    LegislativeDesignee = 6,
    Grassroot = 7,
    Unaffiliated = 8,
    Admin = 1000,
}

export enum FilerTypeEnumMaine {
    AllFilers = 0,
    Lobbyists = 1,
    AuthorizedAgent = 5,
    LegislativeDesignee = 6,
    Grassroot = 7,
    Unaffiliated = 8,
    Admin = 1000,
}

export enum LobbyistTypeEnum {
    Lobbyist = 1,
    AuthorizedAgent = 5,
    LegislativeDesignee = 6,
    Grassroot = 7,
}

export enum FilerStatusEnum {
    PendingReview = 1,
    Approved = 2,
    Denied = 3,
    ConditionallyApproved = 4,
    Terminated = 5,
    Unfiled = 6,
}

export enum RegistrationApprovalStatusEnum {
    Terminated = 1,
    Inactive = 2,
    New = 3,
    Active = 4,
}

export enum HarassmentPreventionCertificateStringEnum {
    'Completed Training' = 1,
    'Extension Requested' = 2,
    'Exempt From Training' = 3,
    'No Filed' = 4,
}

export enum ApplicationUserStatusesEnum {
    Active = 1,
    Inactive = 2,
    Invite = 3,
}

export enum FilerTypeStringEnum {
    Lobbyists = "Lobbyists",
    IndependentExpenditure = "Independent Expenditure",
    Committee = "Committee",
    EthicsFiler = "Ethics Filer",
    Admin = "Administrator",
}

export enum ElectionTypeEnum {
    AllElections = 0,
    Candidate = 1,
    Issue = 2,
    Special = 3,
}

export enum ElectionTypeStringEnum {
    Candidate = "Candidate",
    Issue = "Issue",
    Special = "Special",
}

export enum IETypeStringEnum {
    Organization = "Organization",
    Individual = "Individual",
}

export enum ContactTypeEnum {
    Individual = 1,
    Organization = 2,
    Committee = 3,
}

export enum LobbyistsTypeEnum {
    Individual = 1,
    Organization = 2,
}

export enum ElectionCycleStatusEnum {
    Active = 1,
    Completed = 2,
    Canceled = 3,
}

export enum FilingTypeEnum {
    MonthlyReport = 1,
    ExpenditureReport = 2,
    GrassrootsLobbyingReport = 3, 
    TerminationStatement = 4,
    HarassmentPreventionCertificate = 5,
    LegislativeDesigneeRegistration = 6,
    NonSessionWaiver = 7,
    ClientRegistration = 1000, // Added in order to match the configuration in the backend
}

export enum FilingMappingRecordTypeEnum {
    Transaction = 1,
    Loan = 2,
    Gift = 3,
    FinancialDisclosureAssetTransfer = 4,
    FinancialDisclosureBusinessInterest = 5,
    FinancialDisclosureCreditor = 6,
    FinancialDisclosureEmployer = 7,
    FinancialDisclosureLobbyingCompensation = 8,
    FinancialDisclosurePrivateTrust = 9,
    FinancialDisclosurePropertyOwnership = 10,
    FinancialDisclosureSourceofIncome = 11,
}

export enum AdminDocumentTypeEnum {
    CandidateAffidavit = 1,
    TreasurerDocuments = 2,
    Filings = 3,
    WaiverRequests = 4,
    AuditReports = 5,
    Complaints = 6,
    Miscellaneous = 7,
}

export enum TransactionTypeEnum {
    Expenditure = 1,
    UnpaidObligation = 2,
    IndependentExpenditure = 3,
    Loan = 4,
    Contribution = 5,
    FairElectionFund = 6,
}

export enum LoanTypeEnum {
    CommercialLoan = 1,
    PersonalLoan = 2,
}

export enum FEFParticipationStatusTypeEnum {
    NotParticipating = 1,
    NotYetQualified = 2,
    Participating = 3,
    Revoked = 4,
    Withdrawn = 5,
}

export enum VerificationStatusEnum {
    New = 1,
    Success = 2,
    Error = 3,
    Cancel = 4,
}

export enum OfficeTypeEnum {
    New = 1,
    CityCouncil = 2,
    Mayor = 3,
    ClerkandRecorder = 4,
    Treasurer = 5,
    Auditor = 6,
    Other = 7,
}

export enum CommitteeUserTypeEnum {
    Treasurer = 1,
    Officer = 2,
    Candidate = 3,
}

export enum LobbyistUserTypeEnum {
    PrimaryRegisteredLobbyist = 1,
    LobbyistAssociate = 3,
    AuthorizedAgent = 4,
}

export enum UserTypeEnum {
    AuthorizedAgent = 4,
    Admin = 5,
}

export enum IndependentExpenditureTargetTypeEnum {
    BallotIssue = 1,
    Candidate = 2,
}

export enum PositionTypeEnum {
    Support = 1,
    Oppose = 2,
}

export enum IndependentExpenditureFilerTypeEnum {
    Individual = 1,
    Organization = 2,
}

export enum LoanSubTransactionTypeEnum {
    InterestPayment = 1,
    Forgiveness = 2,
    Payment = 3,
}

export enum AccessEthicsUserTypesEnum {
    PrimaryUser = 1,
    SecondaryUser = 2,
}

export enum AccessEthicsTypesEnum {
    Elected = 1,
    Appointed = 2,
    Staff = 3,
    Undetermined = 4,
}

export enum OrganizationTypesEnum {
    "Non-Profit" = 1,
    "For Profit" = 2,
    "Governmental Entity" = 3,
}

export enum ReimbursementEnum {
    "YES" = 1,
    "NO" = 2,
}

export enum DidYouAttendThisEventEnum {
    "City" = 0,
    "Personal" = 1,
}
