import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FilerTypeEnumMaine } from "src/app/app.model";

@Component({
    selector: "app-create-account-additional-information",
    templateUrl: "./create-account-additional-information.component.html",
    styleUrls: ["./create-account-additional-information.component.scss"],
})
export class CreateAccountAdditionalInformationComponent {
    @Input() accountType: number;
    @Input() userData;

    @Output() back = new EventEmitter();
    @Output() next = new EventEmitter();

    filerTypeEnum = FilerTypeEnumMaine;
}
