import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation   
} from "@angular/core";
import { ClientService } from "src/app/core";
import { Router } from "@angular/router";
import { MenuEvent } from "@progress/kendo-angular-menu";
import { Observable } from "rxjs";
import { SafeResourceUrl } from "@angular/platform-browser";
import { IOptionModel } from "../../models/option.model";
@Component({
    selector: "app-navigatescreen",
    templateUrl: "./navigatescreen.component.html",
    styleUrls: ["./navigatescreen.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class NavigatescreenComponent implements OnInit {
    @Input() candidateLabels: IOptionModel[];

    constructor(
        private readonly router: Router,
        private readonly client: ClientService,
    ) {}

    data$: Observable<{ unsafe: string; safe: SafeResourceUrl }>;

    ngOnInit(): void {
        this.candidateLabels.forEach((_) => (_.text = _.text ?? _.displayName));
    }

    public onSelect(event: MenuEvent): void {
        const item = event.item as IOptionModel;

        if (item.download) {
            this.client.downloadBlobAttachment(item.routerLink[0], item.routerLink[1]).subscribe();
        }
    }
}
