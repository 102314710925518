import { Component, Output, EventEmitter, OnInit } from "@angular/core";
import { UserData } from "../../models/user-details.model";
import { FormBuilder, Validators } from "@angular/forms";
import {
    ClientService,
    ErrorMessageService,
    MasterUrlService,
} from "../../../../core";

@Component({
    selector: "app-create-account-business-address",
    templateUrl: "./create-account-business-address.component.html",
    styleUrls: ["./create-account-business-address.component.scss"],
})
export class CreateAccountBusinessAddressComponent implements OnInit {
    ngOnInit(): void {}
}
