import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { filerToRoute, isAgent } from '@maplight/utils';
import { combineLatest, iif } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { UserFilers } from 'src/app/app.model';
import { FilerInformationService, SharedService } from 'src/app/core';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationRedirectGuard implements CanActivate {
    constructor(
        private readonly sharedService: SharedService,
        private readonly router: Router,
        private readonly filer: FilerInformationService,
    ) {}

    canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
        // This conditional was added for the specific use case of an admin accessing as
        // an Authorized Agent, when that happens, the AA's id is passed in the route query
        // params, so we need to check for both conditions to fullfill and load the AA data
        // from the API in order to correctly grant access to the filer. When that does not 
        // happen it is enough to just use the filer information stored in the service.
        const active$ = iif(
            () => this.sharedService.isAdminUser() && _route.queryParamMap.has("filer"),

            this.filer.loadFilerData(_route.queryParamMap.get('filer')).pipe(
                map((data) => ({
                    ...data,
                    filerId: data.id,
                    filerVersion: data.version,
                    isActive: data.recordStatusId == 1,
                    isPrimary: false,
                    } as UserFilers
                )),
                tap(x => this.filer.setCurrent(x))
            ),

            this.filer.current$.pipe(take(1))
        );
        
        return combineLatest([
            this.sharedService.loadUserEntities(),
            active$,
        ]).pipe(
            map(([them, current]) => {
                const userFilers = them?.userFilers ?? [];

                if (isAgent(current)) return true;

                if (!!current?.filerTypeId)
                    return this.router.createUrlTree(
                        filerToRoute(current.filerTypeId),
                    );

                if (them.isAdmin)
                    return this.router.createUrlTree([
                        '/system-management/dashboard',
                    ]);

                const agent = userFilers.find((it) => isAgent(it));
                
                if (!!agent) {
                    this.filer.setCurrent(agent);
                    return true;
                }

                if (userFilers.length !== 1) return false;

                current = userFilers[0];
                this.filer.setCurrent(current);

                return this.router.createUrlTree(
                    filerToRoute(current.filerTypeId),
                );
            }),
        );
    }
}
