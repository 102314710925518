
import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

const MonthNameTags = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

@Pipe({ name: "month" })
export class MonthNamePipe implements PipeTransform {

  private readonly months = inject(TranslocoService).translateObject('months');

  // The number of the month, indexed from 1 (January = 1)
  transform(monthNumber: number) {
    return (monthNumber <= 0 || monthNumber > 12) ? null : this.months[MonthNameTags[monthNumber - 1]];
  }
}
