import { inject } from '@angular/core';
import {
    type CanActivateFn,
    type CanActivateChildFn,
    Router,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user-service';

type GuardFn = CanActivateFn & CanActivateChildFn;

export const AuthorizeGuard: GuardFn = (_route, { url: returnUrl }) => {
    const user = inject(UserService);
    const router = inject(Router);

    return user.getUserStatus().pipe(
        map(({ status, user }) => {
            if (user) return true;

            const action = status !== 'LoggedIn' ? 'login' : 'create';

            return router.createUrlTree(['account', action], {
                queryParams: { returnUrl },
            });
        }),
    );
};
