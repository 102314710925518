import {
    Component,
    Input,
    Output,
    ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TotalSection } from '@maplight/models';
import { distinctUntilChanged, filter, skip } from 'rxjs/operators';

@Component({
    selector: 'app-total-section',
    templateUrl: './total-section.component.html',
    styleUrls: ['./total-section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalSectionComponent {
    constructor(private readonly fb: FormBuilder) {}

    private readonly options = {
        validators: [Validators.required, Validators.min(0)],
        updateOn: 'blur' as const,
    };

    readonly form = this.fb.group({
        id: this.fb.control<string>(null),
        legislativeBranch: this.fb.control<number>(null, this.options),
        executiveBranch: this.fb.control<number>(null, this.options),
        constitutionalOfficers: this.fb.control<number>(null, this.options),
    });

    @Input() loading: boolean;

    @Input() set value(value: TotalSection) {
        !!value && this.form.patchValue(value);
    }

    @Output() readonly valueChange = this.form.valueChanges.pipe(
        filter(() => this.form.valid),
        distinctUntilChanged(
            (a, b) =>
                a.legislativeBranch === b.legislativeBranch &&
                a.executiveBranch === b.executiveBranch &&
                a.constitutionalOfficers == b.constitutionalOfficers,
        ),
        skip(1),
    );
}
