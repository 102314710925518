import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '@maplight/services';
import { merge, Observable, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { CreateAccountDataService } from '../../services/create-account-data.service';

@Component({
    selector: 'app-create-account-success-screen',
    templateUrl: './create-account-success-screen.component.html',
    styleUrls: ['./create-account-success-screen.component.scss'],
})
export class CreateAccountSuccessScreenComponent implements OnInit {
    private readonly redirectSubject$ = new Subject<void>();

    loading$: Observable<boolean> = merge(
        this.redirectSubject$.pipe(map((_) => true)),
        this.redirectSubject$.pipe(
            switchMap((_) => this.sharedService.loadUserEntities(true)),
            switchMap((_) => this.router.navigate(['/switch'])),
            map((_) => false),
        ),
    );

    constructor(
        private readonly router: Router,
        private readonly sharedService: SharedService,
        private readonly account: CreateAccountDataService,
    ) {}

    ngOnInit(): void {
        this.account.reset();
    }

    goToDashboard() {
        this.redirectSubject$.next();
    }
}
