import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map } from 'rxjs/operators';
import { SharedService } from '..';

/**
 * A function-based route guard that requires that the filer data
 * for the current user is available.
 *
 * This also handles the special use case for admins, which don't have actual
 * filer entities associated to their profile.
 *
 * @param _route the activated route (unused)
 * @param _state the current router state (unused)
 * @returns whether we can continue the navigation or not
 */
export const requiresFilerDataLoaded: CanActivateFn = (_route, _state) =>
    inject(SharedService)
        .loadUserEntities()
        .pipe(map((data) => data?.isAdmin || !!data?.userFilers?.length));
