import { Injectable } from "@angular/core";
import { Observable, of, merge, Subject } from "rxjs";
import { map, tap, shareReplay, catchError, filter, switchMap, take, share } from "rxjs/operators";
import { UserApiModel } from "src/app/app.model";
import { MasterUrlService } from "..";
import { AuthService, IUserSession, SignInResult, UserAttributeName } from "../api-services/authorization/auth.service";
import { ClientService } from "../api-services/client.service";
import { LocalstorageService } from "../localstorage/localstorage.service";
import { FilerInformationService } from "./filer-information.service";

@Injectable({ providedIn: "root" })
export class UserService {
    private userEvents$: Subject<UserStatus> = new Subject<UserStatus>();
    private reloadSubject$: Subject<boolean> = new Subject<boolean>();

    private userStatus$: Observable<UserStatus>;
    private userSession$: Observable<IUserSession>;

    constructor(
        private readonly auth: AuthService,
        private readonly storage: LocalstorageService,
        private readonly filer: FilerInformationService,
        private readonly clientService: ClientService,
        private readonly urlService: MasterUrlService,
    ) {
        const userSessionEvent$ = merge(
            this.getCurrentSession().pipe(take(1)),
            this.userEvents$,
            this.reloadSubject$.pipe(filter(x => x), switchMap(this.getCurrentSession))
        ).pipe(shareReplay(1));

        this.userSession$ = userSessionEvent$.pipe(map(u => u.status == "LoggedIn" ? u.content as IUserSession : null), shareReplay(1));
        this.userStatus$ = userSessionEvent$.pipe(switchMap(this.getLoggedInUser), shareReplay(1));
    }

    public getUserStatus = () : Observable<UserStatus> => this.userStatus$;

    public isAuthenticated = () : Observable<boolean> => this.userSession$.pipe(map(u => u?.isValid ?? false), shareReplay(1));

    public getAccessToken = () : Observable<string> => this.userSession$.pipe(map(u => u?.isValid ? u.accessToken : null), shareReplay(1));

    public getIdToken = () : Observable<string> => this.userSession$.pipe(map(u => u?.isValid ? u.idToken : null), shareReplay(1));

    public getUserStatusType = () : Observable<StatusType> => this.userStatus$.pipe(map(s => s.status), shareReplay(1));

    public getUserData = () : Observable<UserApiModel> => this.userStatus$.pipe(map(u => u.user), shareReplay(1));

    public getUserEmail = () : Observable<string> => this.userSession$.pipe(map(u => u?.email), shareReplay(1));

    public forceReload = () : void => this.reloadSubject$.next(true);

    public signUp = (email: string, password: string, phoneNumber: string) : Observable<void> => this.auth.signUp({email, password, phoneNumber});

    public signIn = (email: string, password: string, rememberMe: boolean = false) : Observable<SignInResult> =>
        this.auth.signIn({email, password, rememberMe}).pipe(
            tap(r =>
                this.userEvents$.next(r.state === "success" ?
                { content: r.userSession, status: r.userSession.emailVerified ? "LoggedIn" : "RequiresVerification", user: null } :
                { content: r.challengeName, status: "RequiresMFA", user: null })
            ),
            catchError(this.setErrorStatuses)
        );

    public signOut() : void
    {
        this.auth.signOut();
        this.storage.clearAllStorage();
        this.filer.clear();
        this.userEvents$.next({ content: null, status: "NoUser", user: null });
    }

    public confirmRegistration = (code: string, email: string) : Observable<void> => this.auth.confirmRegistration({code, email});

    public resendConfirmationCode = (email: string) : Observable<string> => this.auth.resendConfirmationCode(email);

    public changePassword = (newPassword: string, oldPassword: string) : Observable<void> => this.auth.changePassword({newPassword, oldPassword});

    public initForgotPasswordFlow = (email: string) : Observable<string> => this.auth.initForgotPasswordFlow(email);

    public completeForgotPasswordFlow = (code: string, email: string, newPassword: string) : Observable<void> => this.auth.completeForgotPasswordFlow({code, email, newPassword});

    public refreshToken = () : Observable<IUserSession | null> => this.auth.refreshToken()
        .pipe(
            tap(u => this.userEvents$.next({ content: u, status: u.emailVerified ? "LoggedIn" : "RequiresVerification", user: null })),
            catchError(this.setErrorStatuses)
        );

    public sendMfaCode = (code: string) : Observable<IUserSession> => this.auth.sendMfaCode(code)
        .pipe(
            tap(u => this.userEvents$.next({ content: u, status: u.emailVerified ? "LoggedIn" : "RequiresVerification", user: null })),
            catchError(this.setErrorStatuses)
        );

    public updateAttribute = (name: UserAttributeName, value: string) : Observable<void> => this.auth.updateAttribute({name, value});

    public verifyAttribute = (attributeName: UserAttributeName, code: string) : Observable<void> => this.auth.verifyAttribute({attributeName, code});

    private getCurrentSession = () : Observable<UserStatus> => this.auth.getCurrentSession().pipe(map(u => ({ content: u, status: !!u ? "LoggedIn" : "NoUser", user: null })));

    private setErrorStatuses = e => {
        switch (e.code)
        {
            // Set user status according to the returned error here
            case "UserNotConfirmedException": this.userEvents$.next({ content: null, status: "RequiresConfirmation", user: null });
            default: this.userEvents$.next({ content: null, status: "NoUser", user: null })
        }
        throw e;
    }

    private getLoggedInUser = (s) => (s.status == "LoggedIn"
        ? this.clientService.getData(this.urlService.user).pipe(catchError(_ => of(null)))
        : of(null)).pipe(map(u => ({...s, user: u})));
}

export type StatusType = "NoUser" | "RequiresConfirmation" | "RequiresVerification" | "LoggedIn" | "RequiresMFA";

export type UserStatus = { content: IUserSession | string | null, status: StatusType, user: UserApiModel | null };
