import { Injectable } from '@angular/core';

@Injectable()
export class LocalstorageService {
    static readonly REMEMBERED_USER_KEY: string = 'rememberedUser';

    get(key: string) {
        return localStorage.getItem(key);
    }

    getLocalStorage(key: any) {
        let data = localStorage.getItem(key);
        if (key && data && data != 'undefined') {
            return JSON.parse(data);
        }
        return null;
    }

    setLocalStorage(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    removeStorage(keys: string[]) {
        keys.forEach((element) => {
            localStorage.removeItem(element);
        });
    }

    clearAllStorage() {
        const rememberedUser = localStorage.getItem(
            LocalstorageService.REMEMBERED_USER_KEY,
        );

        localStorage.clear();

        localStorage.setItem(
            LocalstorageService.REMEMBERED_USER_KEY,
            rememberedUser,
        );
    }
}
