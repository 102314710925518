import { ChangeDetectionStrategy, Component, Input, Output, inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ErrorMessageService, MasterDataService } from "@maplight/index";
import { BusinessAddress } from "@maplight/models";
import { map, shareReplay, startWith } from "rxjs/operators";
import { Observable, Subject, combineLatest } from "rxjs";
import { AccountService } from "../../services/account.service";
import { ManageUserFormService } from "../../services/manage-user-form.service";

@Component({
    selector: "manage-business-address-form",
    templateUrl: "./manage-business-address-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageBusinessAddressFormComponent {
    private _initialAddress: BusinessAddress;

    @Input() set address (value: BusinessAddress) { 
        if (!value) return;
        this._initialAddress = value;
        this.form.patchValue(value); 
    }
    @Input() loading: boolean;
    
    private readonly saveAddressSubject$ = new Subject<BusinessAddress>();

    @Output() save: Observable<BusinessAddress> = this.saveAddressSubject$;

    private readonly fb = inject(FormBuilder);
    private readonly formatting = inject(MasterDataService);
    private readonly userForm = inject(ManageUserFormService);
    readonly errorService = inject(ErrorMessageService);

    states$ = inject(AccountService).getStatesList().pipe(shareReplay(1));

    readonly form = this.fb.group({
        address1: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.addressPattern)]),
        address2: this.fb.control<string>(null, [Validators.pattern(this.formatting.addressPattern)]),
        city: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.cityValidation)]),
        stateCode: this.fb.control<string>(null, [Validators.required]),
        zip: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.zipcodePattern)]),
        phoneNumber: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.phoneNumberHyphenValidation)]),
    });

    readonly user = this.userForm.user;

    private readonly hasChanged$ = this.form.valueChanges.pipe(
        map(ba => !!ba &&
            (
                ba.address1 !== this._initialAddress.address1 ||
                ba.address2 !== this._initialAddress.address2 ||
                ba.city !== this._initialAddress.city ||
                ba.stateCode !== this._initialAddress.stateCode ||
                ba.zip !== this._initialAddress.zip ||
                ba.phoneNumber !== this._initialAddress.phoneNumber
            )
        ),
        startWith(false)
    );

    readonly disableSaveButton$ = combineLatest([
        this.hasChanged$,
        this.userForm.hasChanged$
    ]).pipe(map(([x, y]) => !x && !y));

    saveBusinessAddress() { this.saveAddressSubject$.next(this.form.value as BusinessAddress); }
}
