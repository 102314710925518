import {
    Component,
    inject,
    OnInit,
    Output,
    EventEmitter
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ErrorMessageService } from "../../../../configs/error-message";
import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { LegislativeService } from "../../services/legislative.service";
import { ClientService, MasterUrlService } from "src/app/core";
import { map } from "rxjs/operators";

@Component({
    selector: "app-create-account-legislative-agency",
    templateUrl: "./create-account-legislative-agency.component.html",
    styleUrls: ["./create-account-legislative-agency.component.scss"],
})
export class CreateAccountLegislativeAgencyComponent implements OnInit {
    @Output() back: EventEmitter<any> = new EventEmitter();
    @Output() next: EventEmitter<any> = new EventEmitter();
    errorService = inject(ErrorMessageService);

    formGroup: FormGroup;

    agencies$;
    public filterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: "contains",
    };

    constructor(
        private readonly legislativeService: LegislativeService,
        private readonly clientService: ClientService,
        private readonly urlService: MasterUrlService,
    ) {}

    ngOnInit() {
        this.formGroup = this.legislativeService.form;
        this.agencies$ = this.getStateAgencies();
    }

    getStateAgencies = () => this.clientService.getData(this.urlService.getStateAgencies);
}
