import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { PrincipalMenu } from '../app-header/app-header.component';
import { Router } from '@angular/router';

export type DashboardMenu = {
    text: string;
    path: string;
    icon: string;
    isSelect?: boolean;
};

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit {
    @Output() closeMenu = new EventEmitter();

    currentMenu: DashboardMenu[];
    currentPath: string = '';

    private _menu: PrincipalMenu;

    @Input() set menu(value: PrincipalMenu) {
        this._menu = value;
        this.currentPath = this.router.url;
        switch (this.menu) {
            case 'LOBBYIST':
                this.currentMenu = this.loggedUserMenu;
                break;
            case 'ADMIN':
                this.currentMenu = this.menuItemsAdmin;
                break;
            case 'LOBBYIST-AGENT':
                this.currentMenu = this.loggedAgentLobbyistMenu;
                break;
            case 'AGENT':
                this.currentMenu = this.loggedAgentMenu;
                break;
            case 'LOBBYIST-ADMIN':
                this.currentMenu = [
                    this.adminDashboardMenuItem,
                    ...this.loggedUserMenu,
                ];
                break;
            case 'AGENT-ADMIN':
                this.currentMenu = [
                    this.adminDashboardMenuItem,
                    ...this.loggedAgentMenu,
                ];
                break;
            case 'GRASSROOT':
                this.currentMenu = this.loggedGrassrootsUserMenu;
                break;
            case 'GRASSROOT-ADMIN':
                this.currentMenu = [
                    this.adminDashboardMenuItem,
                    ...this.loggedGrassrootsUserMenu,
                ];
                break;
            case 'LEGISLATIVE-DESIGNEE':
                this.currentMenu = this.loggedLegislativeUserMenu;
                break;
            case 'LEGISLATIVE-DESIGNEE-ADMIN':
                this.currentMenu = [
                    this.adminDashboardMenuItem,
                    ...this.loggedLegislativeUserMenu
                ];
                break;
            default:
                this.currentMenu = this.publicMenu;
                break;
        }
    }

    get menu(): PrincipalMenu {
        return this._menu;
    }

    private readonly icons = {
        dashboard: 'space_dashboard',
        grid: 'dataset',
        fee: 'monetization_on',
        calendar: 'calendar_month',
        email: 'email',
        search: 'search',
        management: 'display_settings',
        review: 'rate_review',
    } as const;

    fontWeight?: string;
    lineStyle2: {
        'border-left': string;
        height: string;
        hover?: string;
        fontWeight?: string;
    };

    lineStyle: {
        'border-left': string;
        height: string;
        fontWeight?: string;
    };

    private readonly publicMenu: DashboardMenu[] = [
        {
            text: 'Dashboard',
            path: '/public',
            isSelect: this.currentPath.startsWith('/public'),
            icon: this.icons.dashboard,
        },
        {
            text: 'All Lobbyists Report',
            path: '/public/lobbyists/all',
            icon: this.icons.grid,
        },
        {
            text: 'All Clients Report',
            path: '/public/lobbyists/all-clients',
            icon: this.icons.grid,
        },
        {
            text: 'All Firms Report',
            path: '/public/firms',
            icon: this.icons.grid,
        },
        {
            text: 'Advanced Search',
            path: '/advanced-search',
            icon: this.icons.search,
        },
    ];

    private readonly loggedUserMenu: DashboardMenu[] = [
        {
            text: 'Dashboard',
            path: '/lobbyist',
            icon: this.icons.dashboard,
        },
        {
            text: 'Fines and Fees',
            path: '/fines-and-fees/filer',
            icon: this.icons.fee,
        },
        {
            text: 'Notifications',
            path: '/messages/view',
            icon: this.icons.email,
        },
        {
            text: 'Calendar',
            path: '/calendar',
            icon: this.icons.calendar,
        },
        {
            text: 'Public Dashboard',
            path: '/public',
            icon: this.icons.dashboard,
        },
    ];

    private readonly loggedAgentMenu: DashboardMenu[] = [
        {
            text: 'Switch Lobbyist',
            path: '/switch',
            icon: this.icons.dashboard,
        },
        {
            text: 'Dashboard',
            path: undefined,
            icon: this.icons.grid,
        },
        {
            text: 'Fines and Fees',
            path: undefined,
            icon: this.icons.fee,
        },
        {
            text: 'Notifications',
            path: undefined,
            icon: this.icons.email,
        },
        {
            text: 'Calendar',
            path: undefined,
            icon: this.icons.calendar,
        },
        {
            text: 'Public Dashboard',
            path: '/public',
            icon: this.icons.dashboard,
        },
    ];

    private readonly loggedAgentLobbyistMenu: DashboardMenu[] = [
        {
            text: 'Switch Lobbyist',
            path: '/switch',
            icon: this.icons.dashboard,
        },
        {
            text: 'Dashboard',
            path: '/lobbyist',
            icon: this.icons.grid,
        },
        {
            text: 'Fines and Fees',
            path: '/fine-and-fees',
            icon: this.icons.fee,
        },
        {
            text: 'Notifications',
            path: '/messages',
            icon: this.icons.email,
        },
        {
            text: 'Calendar',
            path: '/calendar',
            icon: this.icons.calendar,
        },
        {
            text: 'Public Dashboard',
            path: '/public',
            icon: this.icons.dashboard,
        },
    ];

    private readonly menuItemsAdmin: DashboardMenu[] = [
        {
            text: 'Dashboard',
            path: 'system-management/dashboard',
            icon: this.icons.dashboard,
        },
        {
            text: 'Review Filings',
            path: '/review-filing',
            icon: this.icons.review,
        },
        {
            text: 'System Management',
            path: '/system-management',
            icon: this.icons.management,
        },
        {
            text: 'Fines and Fees',
            path: '/fines-and-fees',
            icon: this.icons.fee,
        },
        {
            text: 'Notifications',
            path: '/messages',
            icon: this.icons.email,
        },
        {
            text: 'Calendar & Schedule',
            path: '/calendar',
            icon: this.icons.calendar,
        },
        {
            text: 'Public Dashboard',
            path: '/public',
            icon: this.icons.dashboard,
        },
    ];

    private readonly loggedGrassrootsUserMenu: DashboardMenu[] = [
        {
            text: 'Dashboard',
            path: '/grassroot',
            icon: this.icons.dashboard,
        },
        {
            text: 'Fines and Fees',
            path: '/fines-and-fees/filer',
            icon: this.icons.fee,
        },
        {
            text: 'Calendar',
            path: '/calendar',
            icon: this.icons.calendar,
        },
        {
            text: 'Public Dashboard',
            path: '/public',
            icon: this.icons.dashboard,
        },
    ];

    private readonly loggedLegislativeUserMenu: DashboardMenu[] = [
        {
            text: 'Dashboard',
            path: '/legislative-designee',
            icon: this.icons.dashboard,
        },
        {
            text: 'Calendar',
            path: '/calendar',
            icon: this.icons.calendar,
        },
        {
            text: 'Public Dashboard',
            path: '/public',
            icon: this.icons.dashboard,
        },
    ];

    private readonly adminDashboardMenuItem: DashboardMenu = {
        text: 'Admin Dashboard',
        path: 'system-management/dashboard',
        icon: this.icons.dashboard,
    };

    optionSelected: number;

    constructor(private readonly router: Router) { }

    protected setFontSize(selected: number, option: number, currentPath: string, path: string): number {
        return (selected === option || currentPath.startsWith(path, 4)) ? 600 : 300;
    }

    ngOnInit(): void {
        this.lineStyle = {
            'border-left': '8px solid #023e3b',
            height: '100%',
            fontWeight: '600',
        };
        this.lineStyle2 = {
            'border-left': '8px solid transparent',
            height: '100%',
            fontWeight: '300',
        };
    }

    change(i: number, prevent: boolean) {
        if (prevent) return;

        this.optionSelected = i;
        this.closeMenu.emit();
    }
}
