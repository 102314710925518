import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Observable, of } from 'rxjs';
import { catchError, map, filter, tap, mapTo, switchMap } from 'rxjs/operators';
import {
    InvitationDetails,
    StateListResponseApiModel,
} from 'src/app/app.model';
import {
    ClientService,
    LocalStorageService,
    MasterUrlService,
    SnackbarService,
} from '@maplight/services';
import { UserService } from 'src/app/core/services/user-service';

@Injectable()
export class AccountService {
    constructor(
        private readonly urls: MasterUrlService,
        private readonly client: ClientService,
        private readonly user: UserService,
        private readonly snackbar: SnackbarService,
        private readonly translocoService: TranslocoService,
        private readonly localStore: LocalStorageService,
    ) {}

    /*
     *  Create a new Ethic User access
     *
     *  Return an Observable<boolean> that indicates if it was created successfully
     */
    createEthicsFiler() {
        return this.client
            .postData(this.urls.addNewUserAccessEthicsJoinRequest, {})
            .pipe(
                mapTo(true),
                catchError((_) => of(false)),
            );
    }

    /*
     *
     */
    getStatesList(): Observable<StateListResponseApiModel[]> {
        return this.client.getData(this.urls.getStatesList);
    }

    getInvoiceStatusesList(): Observable<{ id: number; name: string }[]> {
        return this.client.getData(this.urls.getInvoiceStatusesList);
    }

    public getUserStatusType(_filter?: string) {
        if (!!_filter)
            return this.user
                .getUserStatusType()
                .pipe(filter((x) => x === _filter));
        return this.user.getUserStatusType();
    }

    public getUserData() {
        return this.user.getUserData();
    }

    updateUserAttribute(attributeName, newValue) {
        return this.user
            .updateAttribute(
                attributeName,
                (attributeName == 'email' ? '' : '+1') + newValue,
            )
            .pipe(
                mapTo(true),
                catchError((_) => {
                    this.snackbar.snackbarError(
                        this.translocoService.translate(
                            'manageAccount.profile.errorUpdatingAttribute',
                        ),
                    );
                    return of(false);
                }),
            );
    }

    verifyUserAttribute(attributeName, code) {
        return this.user.verifyAttribute(attributeName, code).pipe(
            mapTo(true),
            tap(() => {
                this.snackbar.snackbarSuccess(
                    this.translocoService.translate(
                        'manageAccount.profile.emailEditedSuccessfully',
                    ),
                );
            }),
            catchError((_) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        'manageAccount.profile.errorVerifyingAttribute',
                    ),
                );
                return of(false);
            }),
        );
    }

    public updateUser(data: { firstName: string; lastName: string }) {
        return this.client.putData(this.urls.user, data).pipe(
            tap((_) => {
                this.user.forceReload();
                this.snackbar.snackbarInfo(
                    this.translocoService.translate(
                        'manageAccount.contactInfo.savedUserData',
                    ),
                );
            }),
            mapTo(true),
            catchError((_) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        'manageAccount.contactInfo.errorSavingUserData',
                    ),
                );
                return of(false);
            }),
        );
    }

    requestAffiliation(selectedFilers: any[]) {
        return this.client
            .postData(this.urls.requestAffiliation, {
                targets: selectedFilers.map((x) => x.filerId),
            })
            .pipe(
                map((x) => !x.hasError),
                catchError((_) => of(false)),
            );
    }

    public initForgotPasswordFlow(email: string): Observable<string> {
        return this.user.initForgotPasswordFlow(email);
    }

    public completeForgotPasswordFlow(code, email, newPassword) {
        return this.user.completeForgotPasswordFlow(code, email, newPassword);
    }

    public changePassword(oldpass, newpass) {
        return this.user.changePassword(newpass, oldpass).pipe(
            mapTo(true),
            catchError((e) => {
                this.snackbar.snackbarError(e.message);
                return of(false);
            }),
        );
    }

    public signupUser(email: string, pass: string, phone: string) {
        return this.user.signUp(email, pass, phone).pipe(
            mapTo(true),
            catchError((_) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        'signup.invalidSignupAttempt',
                    ),
                );
                return of(false);
            }),
        );
    }

    public loginUser(email, password, rememberMe?, errorMessage?) {
        if (rememberMe) {
            this.setRememberedUser(email);
        } else {
            this.setRememberedUser('');
        }

        return this.user.signIn(email, password, true).pipe(
            switchMap((_) =>
                _.state == 'success' ? this.saveLoginEvent() : of(true),
            ),
            mapTo(true),
            catchError((e) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        !!errorMessage ? errorMessage : e.message,
                    ),
                );
                return of(false);
            }),
        );
    }

    public logoutUser() {
        return this.user.signOut();
    }

    public refreshToken = () => this.user.refreshToken();

    public loadInvitation = (token: string): Observable<InvitationDetails> =>
        this.client
            .getData(this.urls.getInvitationDetails({ token }))
            .pipe(catchError(() => null));

    public setRememberedUser(user: string) {
        this.localStore.setLocalStorage(
            LocalStorageService.REMEMBERED_USER_KEY,
            user,
        );
    }

    public getRememberedUser(): string {
        return JSON.parse(
            this.localStore.get(LocalStorageService.REMEMBERED_USER_KEY),
        );
    }

    public saveLoginEvent() {
        return this.client
            .postData(this.urls.saveLoginEvent, {})
            .pipe(catchError((_) => of(null)));
    }
}
