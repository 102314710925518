import { ClientRegistration } from '@maplight/models';
import { StateListResponseApiModel } from 'src/app/app.model';

export interface Client {
    id: string;
    version: number;
    name: string;
    address1: string;
    address2?: string;
    stateCode: string;
    state?: StateListResponseApiModel;
    city: string;
    zip: string;
    contact: string;
    email: string;
    phone: string;
    clientRegistration: ClientRegistration[];
    edited: boolean;
};