import { Injectable } from '@angular/core';
import { ClientService } from '../api-services/client.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MasterUrlService, SnackbarService } from '..';

import { AlertCategoryName } from '@maplight/models';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Injectable()
export class AlertsDashboardService {
    constructor(
        private readonly urls: MasterUrlService,
        private readonly client: ClientService,
        private readonly snackbarService: SnackbarService,
    ) {}

    getAllElements(data: {
        pageSize: number;
        pageNumber: number;
        sortBy: string;
        direction: string;
    }): Observable<GridDataResult> {
        return this.client.getWithData(this.urls.getAllAlerts, data).pipe(
            map((x) => ({
                data: x.items.map((y) => ({
                    ...y,
                    categoryName: AlertCategoryName[y.category],
                    createdAt: new Date(y.createdAt),
                })),
                total: x.total,
            })),
            catchError((_) => {
                this.snackbarService.snackbarError(
                    'There was a problem while processing your request. Please try again later.',
                );
                return of(null);
            }),
        );
    }

    getAlertsCount(): Observable<{count: number;}> {
        return this.client
            .getData(this.urls.getAlertsCount)
            .pipe(catchError(() => of({count: 0})));
    }

    downloadClientRegistrationPDF(
        id: string,
        version: number,
        filename: string = null,
    ) {
        if (!filename) filename = `Client Registration - ${id} - ${version}`;
        return this.client
            .downloadBlobAttachment(
                this.urls.getLobbyistClientRegistrationPdf(id, version),
                filename,
            )
            .pipe(catchError((_) => of(null)));
    }

    downloadMonthlyReportPDF(
        id: string,
        version: number,
        filename: string = null,
    ) {
        if (!filename) filename = `Monthly Report - ${id} - ${version}`;
        return this.client
            .downloadBlobAttachment(
                this.urls.downloadMonthlyReport(id, version),
                filename,
            )
            .pipe(catchError((_) => of(null)));
    }

    downloadHarassmentPreventionCertificatePDF(
        id: string,
        version: number,
        filename: string = null,
    ) {
        if (!filename)
            filename = `Harassment Prevention Training Certificate - ${id} - ${version}`;
        return this.client
            .downloadBlobAttachment(
                this.urls.downloadHarassmentPreventionCertificate(id, version),
                filename,
            )
            .pipe(catchError((_) => of(null)));
    }

    markCompleteById(id: number): Observable<number> {
        return this.client
            .putData(this.urls.markCompleteById(id), {})
            .pipe(catchError((_) => of(null)));
    }

    markCompleteAll(): Observable<number> {
        return this.client
            .putData(this.urls.markCompleteAll(), {})
            .pipe(catchError((_) => of(null)));
    }
}


