import { ChangeDetectionStrategy, Component, Input, Output, inject } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ErrorMessageService, MasterDataService } from "@maplight/index";
import { BusinessAddress, BusinessAddressTemplate, Firm, FirmTemplate } from "@maplight/models";
import { LobbyistService } from "../../services/lobbyist.service";
import { map, scan, share, shareReplay, startWith, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, merge } from "rxjs";
import { UpdateType } from "src/app/modules/System-Management/models/update-type.model";
import { AccountService } from "../../services/account.service";

type Update = { type: UpdateType } & BusinessAddressTemplate;
type FirmFiler = {firm: FirmTemplate, businessAddress: BusinessAddressTemplate};

@Component({
    selector: "manage-firm-form",
    templateUrl: "./manage-firm-form.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageFirmFormComponent {
    private _initialFirm: Firm;
    private _initialAddress: BusinessAddress;

    @Input() set filerId (value: string) { !!value && this.filerIdSubject$.next(value); }
    @Input() set firm (value: Firm) { 
        if (!value) return;
        this._initialFirm = value;
        this.firmIdSubject$.next(value.firmTemplateId);  
    }
    @Input() set address (value: BusinessAddress) { 
        if (!value) return;
        this.form.controls.selectedAddressId.patchValue(value.businessAddressTemplateId); 
        this._initialAddress = value;
    }
    
    private currentAddressIndex: number = 1;
    private readonly filerIdSubject$ = new ReplaySubject<string>(1);
    private readonly firmIdSubject$ = new ReplaySubject<number>(1);
    private readonly saveAddressSubject$ = new Subject<Update>();
    private readonly saveFirmSubject$ = new Subject<void>();

    private readonly fb = inject(FormBuilder);
    private readonly formatting = inject(MasterDataService);
    private readonly service = inject(LobbyistService);
    readonly errorService = inject(ErrorMessageService);

    states$ = inject(AccountService).getStatesList().pipe(shareReplay(1));

    form = this.fb.group({
        id: this.fb.control<number>(null, [Validators.required]),
        name: this.fb.control<string>(null, [Validators.required]),
        webSite: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.websiteRegex)]),
        phoneNumber: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.phoneNumberHyphenValidation)]),
        selectedAddressId: this.fb.control<number>(null, [Validators.required]),
    });

    addressForm = this.fb.group({
        id: this.fb.control<number>(null),
        address1: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.addressPattern)]),
        address2: this.fb.control<string>(null, [Validators.pattern(this.formatting.addressPattern)]),
        city: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.cityValidation)]),
        stateCode: this.fb.control<string>(null, [Validators.required]),
        zip: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.zipcodePattern)]),
    });

    private readonly searchFirmSubject$ = new BehaviorSubject<string>(null);

    firms$ = combineLatest([
        this.filerIdSubject$,
        this.searchFirmSubject$
    ]).pipe(
        switchMap(([id, text]) => this.service.getLobbyistFirms(text, id)),
        shareReplay(1)
    );

    readonly loadingFirms$ = merge(
        this.searchFirmSubject$.pipe(map(_ => true)),
        this.firms$.pipe(map(_ => false))
    ).pipe(startWith(true));
    
    private readonly apiFirms$ = combineLatest([
        this.firmIdSubject$,
        this.firms$
    ]).pipe(
        map(([id, firms]) => firms.find(x => x.id === id)),
        tap(firm => {
            this.form.patchValue({
                id: firm.id,
                name: firm.name,
                webSite: firm.webSite,
                phoneNumber: firm.phoneNumber
            }, { emitEvent: false });
        }),
        map(firm => firm.addresses),
    );

    readonly addresses$ = merge(
        this.apiFirms$,
        this.saveAddressSubject$
    ).pipe(
        scan((previous: BusinessAddressTemplate[], value: BusinessAddressTemplate[] | Update) => {
            if (value instanceof Array) return value;
            
            const v = value as Update;
            switch (v.type) {
                case UpdateType.Add:
                    previous = [...previous, v as BusinessAddressTemplate];
                    break;
                case UpdateType.Edit:
                    previous = previous.map((x) =>
                        x.id != v.id ? x : v as BusinessAddressTemplate,
                    );
                    break;
                case UpdateType.Remove:
                    previous = previous.filter((x) => x.id != v.id);
                    break;
            }

            return previous as BusinessAddressTemplate[];
        }),
        tap((addresses: BusinessAddressTemplate[]) => {
            if (!addresses.some(x => x.id == this.form.value.selectedAddressId)) this.form.controls.selectedAddressId.reset();
        }),
        share()
    );

    searchFirm(name) { this.searchFirmSubject$.next(name); }

    changeFirm(firmId) { this.firmIdSubject$.next(firmId); }

    editFirmAddress(address: BusinessAddressTemplate) { this.addressForm.patchValue(address); }

    cancelEditFirmAddress() { this.addressForm.reset(); }

    deleteFirmAddress(address: BusinessAddressTemplate) { this.saveAddressSubject$.next({...address, type: UpdateType.Remove}); }

    saveBusinessAddress()
    {
        let value = this.addressForm.value as BusinessAddressTemplate;
        if (!value.id)
        {
            value.id = -this.currentAddressIndex;
            ++this.currentAddressIndex;
            this.saveAddressSubject$.next({...value, type: UpdateType.Add});
        }
        else
        {
            this.saveAddressSubject$.next({...value, type: UpdateType.Edit});
        }
        this.addressForm.reset();
    }

    private readonly firmHasChanged$ = this.form.valueChanges.pipe(
        map(firm => firm.id !== this._initialFirm.firmTemplateId ||
            firm.name !== this._initialFirm.name ||
            firm.webSite !== this._initialFirm.webSite ||
            firm.phoneNumber !== this._initialFirm.phoneNumber
        ),
        startWith(false)
    );

    private readonly addressHasChanged$ = merge(
        this.form.controls.selectedAddressId.valueChanges.pipe(
            withLatestFrom(this.addresses$),
            map(([id, addresses]) => addresses.find(a => a.id === id))
        ),

        this.addresses$.pipe(
            map(addresses => addresses.find(a => a.id === this.form.value.selectedAddressId))
        )
    ).pipe(
        map(ba => !!ba &&
            (
                ba.id !== this._initialAddress.businessAddressTemplateId ||
                ba.address1 !== this._initialAddress.address1 ||
                ba.address2 !== this._initialAddress.address2 ||
                ba.city !== this._initialAddress.city ||
                ba.stateCode !== this._initialAddress.stateCode ||
                ba.zip !== this._initialAddress.zip
            )
        ),
        startWith(false)
    );

    disableSaveButton$ = combineLatest([
        this.firmHasChanged$,
        this.addressHasChanged$
    ]).pipe(
        map(x => !x.some(y => y)),
        startWith(true)
    );

    saveFirmInformation() { this.saveFirmSubject$.next(); }

    @Output() save: Observable<FirmFiler> = this.saveFirmSubject$.pipe(
        withLatestFrom(this.addresses$),
        map(([_, addresses]: [any, BusinessAddressTemplate[]]) => {
            const firm = this.form.value;
            const businessAddress = addresses.find(a => a.id === firm.selectedAddressId);

            return { firm, businessAddress } as FirmFiler;
        })
    )
}
