import { Component, Input, OnInit } from "@angular/core";
import { UserData } from "../../models/user-details.model";
import { FirmBusinessAddressService } from "../../services/firm-business-address.service";
import { Observable } from "rxjs";
import { ILobbyistFirmAddress } from "../../../../shared/models/app-lobbyist-business";
import { switchMap, tap } from "rxjs/operators";

@Component({
    selector: "app-create-account-business-address-view",
    templateUrl: "./create-account-business-address-view.component.html",
    styleUrls: ["./create-account-business-address-view.component.scss"],
})
export class CreateAccountBusinessAddressViewComponent implements OnInit {
    @Input() userData: UserData = {};

    businessSelected: number = -1;
    newFirm$: Observable<ILobbyistFirmAddress>;
    deleteFirm$: Observable<boolean>;
    editFirm$: Observable<ILobbyistFirmAddress>;
    isEditing: boolean = false;

    constructor(private readonly businessService: FirmBusinessAddressService) {}

    ngOnInit(): void {
        this.newFirm$ = this.businessService.addBusinessAddress$;

        this.deleteFirm$ = this.businessService.deleteBusinessAddress$.pipe(
            tap((value) => {
                this.businessSelected = -1;
                this.isEditing = false;
            })
        );
        this.editFirm$ = this.businessService.editBusinessAddress$;
    }

    delete() {
        this.businessService.delete();
    }

    edit() {
        this.businessService.editNewFirm();
    }

    cancel() {
        this.businessService.cancel();
    }
}
