import { FilerTypeEnumMaine as Filers } from 'src/app/app.model';

export const filerToRoute = (it: Filers) => {
    switch (it) {
        case Filers.Lobbyists:
            return ['/lobbyist'];
        case Filers.LegislativeDesignee:
            return ['/legislative-designee'];
        case Filers.Grassroot:
            return ['/grassroot'];
        default:
            return ['/switch'];
    }
};
