import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from "@angular/forms";
import { MasterDataService } from "../../../../configs/master-data";
import { ErrorMessageService } from "../../../../configs/error-message";

@Component({
    selector: "forgot-password-complete-flow",
    templateUrl: "./forgot-password-complete-flow.component.html",
    styleUrls: ["./forgot-password-complete-flow.component.scss"],
})
export class ForgotPasswordCompleteFlowComponent {
    @Input() email: string;
    @Output() submitForm = new EventEmitter<{
        code: string;
        password: string;
    }>();

    codeForm: UntypedFormGroup = this.fb.group({
        code: ["", [Validators.required]],
        password: [
            "",
            [
                Validators.required,
                Validators.pattern(this.masterDataService.password),
                Validators.minLength(8),
                Validators.maxLength(16),
                this.masterDataService.matchValidator(
                    "confirmPassword",
                    true
                ),
            ],
        ],
        confirmPassword: [
            "",
            [
                Validators.required,
                this.masterDataService.matchValidator("password"),
            ],
        ],
    });

    protected showPassword: boolean = false;

    constructor(
        public errorService: ErrorMessageService,
        public masterDataService: MasterDataService,
        private readonly fb: UntypedFormBuilder
    ) {}

    submit() {
        if (this.codeForm.valid) {
            this.submitForm.emit(this.codeForm.value);
        } else {
            this.codeForm.markAllAsTouched();
        }
    }
}
