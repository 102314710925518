import { Pipe, PipeTransform } from '@angular/core';
import { filerToRoute } from '@maplight/utils';
import { FilerTypeEnumMaine as Filers } from 'src/app/app.model';

@Pipe({
  name: 'filerToRoute'
})
export class FilerToRoutePipe implements PipeTransform {
  transform(id: Filers): string[] {
    return filerToRoute(id);
  }
}