import {NgModule} from "@angular/core";
import {CommonModule, DatePipe} from "@angular/common";
import {ActivityReportRoutingModule} from "./activity-report-routing.module";
import {MainActivityReportComponent} from "./components/main-activity-report/main-activity-report.component";
import {TranslocoRootModule} from "../../transloco-root.module";
import {GridModule} from "@progress/kendo-angular-grid";
import {ActivityReportService} from "./services/activity-report.service";
import {LabelModule} from "@progress/kendo-angular-label";
import {FormFieldModule} from "@progress/kendo-angular-inputs";
import {SharedModule} from "../../shared/modules/shared.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DataGridService} from "./services/data-grid.service";
import {NoActivityReportComponent} from "./components/no-activity-report/no-activity-report.component";
import {PageInformationCardModule} from "src/app/shared/modules/page-information-card.module";
import {MonthlyReportGuard} from "./guards/monthly-report.guard";
import {PhonePipe} from "src/app/shared/pipes/format-phone.pipe";
import {TotalSectionComponent} from './components/total-section/total-section.component';
import {FormattingPipe} from "./services/formatting-pipe";
import {TooltipModule} from "@progress/kendo-angular-tooltip";
import { NewClientRegistrationService } from '../new-client-registration/services';
import { BillsAndIssuesComponent } from "./components/bills-and-issues-grid/bills-and-issues-grid.component";
import { GrassrootsLobbyingComponent } from "./components/grassroots-lobbying-grid/grassroots-lobbying-grid.component";
import { EventsForOfficialsComponent } from "./components/events-for-officials-grid/events-for-officials-grid.component";
import { ExpendituresOnBehalfComponent } from "./components/expenditures-on-behalf-grid/expenditures-on-behalf-grid.component";
import { OriginalSourcesComponent } from "./components/original-sources-grid/original-sources-grid.component";
import { PipesModule } from "src/app/shared/modules/pipes.module";
import { TargetTimePipe } from 'src/app/shared/pipes/denver-time.pipe';
import { ClampCurrencyPipe } from "./pipes/clamp-currency.pipe";
import { LoaderModule } from '@progress/kendo-angular-indicators';

@NgModule({
    declarations: [
        MainActivityReportComponent,
        NoActivityReportComponent,
        TotalSectionComponent,
        FormattingPipe,
        BillsAndIssuesComponent,
        GrassrootsLobbyingComponent,
        EventsForOfficialsComponent,
        ExpendituresOnBehalfComponent,
        OriginalSourcesComponent,
        ClampCurrencyPipe,
    ],
    imports: [
        CommonModule,
        ActivityReportRoutingModule,
        TranslocoRootModule,
        GridModule,
        LabelModule,
        FormFieldModule,
        SharedModule,
        ReactiveFormsModule,
        FormsModule,
        PageInformationCardModule,
        TooltipModule,
        PipesModule,
        LoaderModule,
    ],
    providers: [
        ActivityReportService,
        NewClientRegistrationService,
        DataGridService,
        MonthlyReportGuard,
        TargetTimePipe,
        PhonePipe,
    ],
})
export class ActivityReportModule {}
