import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";

@Injectable()
export class MasterDataService {
    readonly timezone: string;
    readonly masterOffset: number;
    readonly baseOffset: number;

    // IANA Timezone code
    private static readonly TARGET_TIMEZONE = 'America/New_York';
    private static readonly EASTERN_STANDARD_TIME = 'UTC-5';

    constructor(private readonly translocoService: TranslocoService) {
        this.baseOffset = this.targetTimezoneOffset(new Date());

        /**
         * Calculate timezone offset for datetime display:
         *
         * Server is guaranteed to deliver dates in UTC, but
         * client-side display logic always creates a new Date object
         * before parsing the date (which comes without any TZ information),
         * thus the local timezone offset is being thrown into the mix.
         *
         * Solution:
         *
         * Get the localtime offset and add it on top of the required offset,
         * so that the final result ends on the target timezone.
         *  */
        this.masterOffset =
            -this.baseOffset - new Date().getTimezoneOffset() / 60;

        this.timezone = `UTC ${this.masterOffset >= 0 ? '+' : ''}${
            this.masterOffset
        }`;
    }

    targetTimezoneOffset(date: string | Date): number {
        const test = new Date(date);

        const string = test.toLocaleString('en-US', {
            timeZone: MasterDataService.TARGET_TIMEZONE,
        });

        const [_, hourText, m] = string.split(' ');

        let hour = +hourText.split(':')[0] + (m === 'PM' ? 12 : 0);
        hour = hour === 12 && m === 'AM' ? 0 : hour;

        const temp = Math.abs((test.getUTCHours() || 24) - hour);

        return temp > 12 ? 24 - temp : temp;
    }

    /**
     * Transform a date object or date string into a new date object for which
     * its UTC time matches that of a new date if the localtime was the same as Denver
     * localtime. Applying `formatDate` or the `date` pipe to the UTC string of the
     * value returned by this method while using `timezone` as the timezone value
     * should yield a datetime representation that looks exactly like what the user
     * originally selected.
     *
     * @param date the date object or string representation to convert
     * @param fromUTC flag to indicate that the date value is on UTC and avoid
     * to use the offset of the client local timezone.
     * @returns a new datetime, such that its UTC representation matches
     * that of the same datetime in Denver localtime (MST)
     */
    intoTargetTimezone(date: string | Date, fromUTC: boolean = false): Date {
        const _ = new Date(date);
        const offset =
            this.targetTimezoneOffset(_) * 60 - (fromUTC ? 0 : _.getTimezoneOffset());

        return new Date(_.getTime() + this.minutesToMilliseconds(offset));
    }

    shouldOffset(date: string | number | Date): boolean {
        return !(typeof date === 'string') || (!/[+-]..:..$/.test(date) && !date.endsWith("Z"));
    }

    intoTargetTimezoneWithTimeString(date: string | Date, time: string): Date {
        const temp = new Date(date);

        if (!!time) {
            const [hours, minutes, ..._] = time.split(':').map((x) => +x);
            temp.setHours(hours, minutes, 0, 0);
        } else {
            temp.setHours(12, 0, 0, 0);
        }

        return this.intoTargetTimezone(temp);
    }

    fromTargetTimezone(date: string | Date): Date {
        const _ = new Date(date);
        return new Date(
            _.getTime() -
                this.minutesToMilliseconds(
                    this.targetTimezoneOffset(_) * 60 -
                        (this.shouldOffset(date) ? 0 : _.getTimezoneOffset()),
                ),
        );
    }

    minutesToMilliseconds(minutes: number): number {
        return minutes * 60 * 1000;
    }

    extractTimeString(date: Date): string {
        const _ = new Date(
            date.getTime() -
                this.minutesToMilliseconds(date.getTimezoneOffset()),
        ).toISOString();

        return _.split('T')[1].split('.')[0].substring(0, 5);
    }

    formatTimeLate(data: string): string {
        const labelDay = this.translocoService.translate(
            'public.lobbying.grid.days',
        );
        const labelHour = this.translocoService.translate(
            'public.lobbying.grid.hours',
        );
        const labelMinute = this.translocoService.translate(
            'public.lobbying.grid.minutes',
        );

        if (data == null || data.startsWith('-')) {
            return null;
        }

        const values = data
            .split('.')[0]
            .split(':')
            .map((v) => parseInt(v));
        let result = '';

        if (values.length === 4) {
            const days = values[0];
            result += `${days} ${days === 1 ? labelDay : labelDay + 's'} `;
            values.shift();
        }

        const hours = values[0];
        result += `${hours} ${hours === 1 ? labelHour : labelHour + 's'} `;

        const minutes = values[1];
        result += `${minutes} ${
            minutes === 1 ? labelMinute : labelMinute + 's'
        } `;

        return result;
    }

    matchValidator(matchTo: string, reverse?: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.parent && reverse) {
                const c = (control.parent?.controls as any)[
                    matchTo
                ] as AbstractControl;
                if (c) c.updateValueAndValidity();
                return null;
            }
            return !!control.parent &&
                !!control.parent.value &&
                control.value ===
                    (control.parent?.controls as any)[matchTo].value
                ? null
                : { matching: true };
        };
    }

    getTimeZone = () => MasterDataService.EASTERN_STANDARD_TIME;

    /**
     * Updates a date to match UTC time, but still conserving the local timezone offset.
     * @param date the date to convert. 
     * @returns date matching UTC time (with same timezone).
     */
    toUTC(date: Date) {
        const offset = date.getTimezoneOffset()
        return new Date(date.getTime() + offset * 60 * 1000);
    }

    isLoader = false;

    readonly refillCreatedMsg = 'Record moved to Verification Queue.';

    // Validation patterns
    //Allow spaces or Hyphens' - intermediate but not at the end or start of the name
    readonly nameValidation = /^\p{Lu}\p{L}*([- '‘’.]+\p{L}+)*[.\p{L}]*$/u;
    readonly transactionContactNameValidation = /^\p{L}([- ]?\p{L}+)*$/u;
    readonly organizationNameValidation = /^((\p{L}|\d|!)+ ?[-',.()&]? ?)+$/u;
    readonly cityValidation =
        "^[a-zA-Z]+[a-zA-Z0-9 ',-\u00E1\u00E9\u00ED\u00F3\u00FA\u00F1]+$";
    readonly phoneNumberHyphenValidation =
        /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    readonly emailValidations =
        '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
    readonly onlyNumberPattern = /^[0-9]+$/;
    readonly zipcodePattern = /^[0-9]{5}(?:[0-9]{4})?$/;
    readonly DOBAge = 'DOB & Age';
    readonly onlyAlpha = /^[a-zA-Z ]+$/;
    readonly onlyAlphaNumber = '^[A-Z]+((?: )?[a-zA-Z0-9])*$';
    readonly alphaNumeric = /^[\p{L}0-9]+$/u;
    readonly addressPattern = /^(((\p{L}+-?)|(#?[0-9]+))\.?,? ?)+$/u;
    readonly password = /^((?!.*[\s])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)).+$/;
    readonly dateFormat = 'MM/dd/yyyy';
    readonly timeFormat = 'hh:mm:ss';
    readonly timeFormat24H = 'HH:mm:ss';
    readonly timeFormat12H = 'hh:mm a';
    readonly dateTimeFormat = 'MM/dd/yyyy hh:mm a';
    readonly websiteRegex =
        /^(?:https?:\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?$/;
    readonly associatedDocAllowedFileTypes = ['jpeg', 'png', 'jpg'];
    readonly associatedDocValidationMsg =
        'Unsupported file format. Only upload Image(Png/Jpeg).';

    // Path name
    readonly id = 'ID';
    readonly login = 'login';
    readonly createCommitte = '/committee/committee-registration';
    readonly save = 'Save';
    readonly edit = 'Edit';
    readonly add = 'Add';
    readonly token = 'token';
    readonly manageFilerType = 'manageCommittee';
    readonly userDetail = 'user';
    readonly user_Type = 'user_type';
    readonly userId = 'userId';
    readonly email: 'email';
    readonly centre = 'center';
    readonly errorMsg = 'Unexpected error occour, please try again later.';
    readonly invalidLogin = 'Email or Password is invalid';
    readonly yes = 'Yes';
    readonly no = 'No';
    readonly cancel = 'Cancel';
    readonly delete = 'Delete';
    readonly deleteConfirmation = 'Are you sure, Do you want to delete ?';
    readonly dashboard = '/dashboard/candidate';
    readonly lobbyDashboard = '/dashboard/lobby';
    readonly switchScreen = '/switch';
    readonly public_funding = 'manage/manage_committee/public-funding';
    readonly message_admin = '/messages/admin';
    readonly switchScreenNav = '/switch/nav-switch';
    readonly manageCommittee = '/manage/committee';
    readonly manageUsers = '/manage/manage_committee/user-management';
}
