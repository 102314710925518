
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "formatzip"
})
export class ZipPipe implements PipeTransform {

  transform(zipCode) {

    if(!zipCode) {
      return "_____-____"
    }

    var value = zipCode.toString().trim();
    var format = `${value.slice(0,5)}`;

    if (value.length > 5) format = `${format}-${value.slice(5,)}`;
    return format;
  }
}
