import { Pipe, type PipeTransform } from '@angular/core';

import { Address } from '@maplight/models';
import { ZipPipe } from './format-zip.pipe';

@Pipe({ name: 'formatBusinessAddress' })
export class BusinessAddressPipe implements PipeTransform {
    constructor(private readonly zip: ZipPipe) {}

    transform(value: Address): string {
        if (!value) return '';

        const parts = [
            `${value.address1}${value.address2 ? ' ' + value.address2 : ''},`,
            value.city,
            value.stateCode,
            this.zip.transform(value.zip),
        ];

        return parts.join(' ');
    }
}
