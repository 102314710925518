import { Component, OnInit, Input, inject, ChangeDetectionStrategy } from '@angular/core';
import { Subject, combineLatest, merge } from 'rxjs';
import {
    BusinessAddress,
    BusinessAddressTemplate,
    Filer,
    Firm,
    FirmTemplate,
} from '@maplight/models';
import {
    UserApiModel,
} from 'src/app/app.model';
import { map, share, startWith, switchMap, tap } from 'rxjs/operators';
import { ManageUserFormService } from '../../services/manage-user-form.service';
import { ManageProfileService } from '../../services/manage-profile.service';
import { ErrorMessageService } from '@maplight/index';

type ExtendedFiler = Filer & { firm?: Firm | FirmTemplate; businessAddress?: BusinessAddress | BusinessAddressTemplate; }

// This component has the logic that corresponds to the Manage Profile page for Lobbyists and Authorized Agent Filers.
// Those Filers have their own user properties, the Address properties and the Firm properties when applicable.
// If the Filer has a Firm, then the page will display the <manage-firm-form> component with the form inputs designed
// for the user to change their Firm and Address information.
// If the Filer is not related to a Firm, the <manage-business-address-form> component will be displayed instead, allowing
// the user to change their Business Address information.
// All the logic to get and display the Firm and Address information for the Filer will be managed for those components,
// this component will only take care to update the corresponding Filer entity each time those components emit a value.

@Component({
    selector: 'app-manage-profile-lobbyist',
    templateUrl: './manage-profile-lobbyist.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageProfileLobbyistComponent implements OnInit {
    @Input() entity: ExtendedFiler;

    private readonly service = inject(ManageProfileService);
    private readonly userForm = inject(ManageUserFormService);
    readonly errorService = inject(ErrorMessageService);

    readonly form = this.userForm.user;

    editingFirm: boolean = false;

    private readonly saveSubject$ = new Subject<ExtendedFiler>();

    private readonly saveFiler$ = this.saveSubject$.pipe(
        switchMap(f => this.service.updateFilerProfile(f)),
    );

    readonly loading$ = merge(
        this.saveSubject$.pipe(map(_ => true)),
        this.saveFiler$.pipe(map(_ => false))
    ).pipe(startWith(false), share());

    readonly disableSaveButton$ = combineLatest([
        this.loading$,
        this.userForm.hasChanged$
    ]).pipe(map(([x, y]) => x || !y));

    ngOnInit(): void {
        this.userForm.set(this.entity.primaryUser);
    }

    saveUser() { 
        const {firm, businessAddress} = this.entity;

        this.saveSubject$.next({
            ...this.entity,
            primaryUser: this.form.value as UserApiModel,
            firm: !firm ? null : { ...firm, id: (firm as Firm).firmTemplateId } as FirmTemplate,
            businessAddress: 
                !businessAddress ?
                null : 
                { 
                    ...businessAddress, 
                    id: (businessAddress as BusinessAddress).businessAddressTemplateId 
                } as BusinessAddressTemplate
        }); 
    }

    saveFirm({firm, businessAddress}) { 
        this.saveSubject$.next({
            ...this.entity,
            primaryUser: this.entity.primaryUser, 
            firm: firm,
            businessAddress: businessAddress
        });
        this.editingFirm = false;
    }

    saveBusinessAddress(businessAddress: BusinessAddress)
    {
        this.saveSubject$.next({
            ...this.entity,
            primaryUser: this.form.value as UserApiModel, 
            firm: null,
            businessAddress: businessAddress
        });
    }
}
