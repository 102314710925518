export type CurrentPage =
    | "SaveUserForm"
    | "ChooseUserType"
    | "AdditionalInformation"
    | "Loading";

export enum CurrentPageEnumerator {
    EditAttributeForm,
    ConfirmationCodeForm,
    SignupForm,
    EmailConfirmationForm,
    Loading,
    MFAVerificationForm,
    ChooseUserType,
}
