import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, merge, Observable, partition } from "rxjs";
import {
    switchMap,
    mapTo,
    startWith,
    filter,
    tap,
    shareReplay,
} from "rxjs/operators";
import { ErrorMessageService } from "src/app/core";
import { AccountService } from "../../services/account.service";
import { UserDetails } from "../../models/user-details.model";

@Component({
    selector: "app-login-form",
    templateUrl: "./login-form.component.html",
    styleUrls: ["./login-form.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class LoginFormComponent implements OnInit {
    @Output() loginRequest: EventEmitter<UserDetails> =
        new EventEmitter<UserDetails>();

    public showPassword: boolean = false;
    public loginForm: FormGroup;

    private loginSubject$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);
    sendingLoginRequest$: Observable<boolean>;

    constructor(
        public errorService: ErrorMessageService,
        private readonly formBuilder: FormBuilder,
        private readonly accountService: AccountService
    ) {}

    ngOnInit(): void {
        const defaultUser = this.accountService.getRememberedUser();
        
        this.loginForm = this.formBuilder.group({
            email: [defaultUser, [Validators.required, Validators.email]],
            password: [null, [Validators.required]],
            rememberMe: [false],
        });

        const successfullLoginStatus$ =
            this.accountService.getUserStatusType("LoggedIn");

        const loginUser$ = this.loginSubject$.pipe(
            filter((x) => x),
            tap(this.shareRequest),
            switchMap(this.loginUser),
            shareReplay()
        );

        const [success$, fail$] = partition(loginUser$, (x) => x);

        this.sendingLoginRequest$ = merge(
            success$.pipe(mapTo(true)),
            successfullLoginStatus$.pipe(mapTo(false)),
            fail$,
            this.loginSubject$.pipe(filter((x) => x))
        ).pipe(startWith(false));
    }

    logIn() {
        if (this.loginForm.valid) this.loginSubject$.next(true);
        else {
            this.loginForm.markAllAsTouched();
            this.loginForm.updateValueAndValidity();
        }
    }

    shareRequest = () =>
        this.loginRequest.emit({
            email: this.loginForm.value.email,
            password: this.loginForm.value.password,
        });

    loginUser = () =>
        this.accountService.loginUser(
            this.loginForm.value.email,
            this.loginForm.value.password,
            this.loginForm.value.rememberMe
        );
}
