import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ClientsApiModel } from "src/app/app.model";
import { ClientService, MasterUrlService } from "src/app/core";
import { UserService } from "src/app/core/services/user-service";
import {
    // TODO - import all of these from @maplight/models
    LegislativeCommittee,
    PaymentType,
    NatureOfBusiness,
    // TODO - rename this model to ClientRegistrationPayload
    ClientRegistration as ClientRegistrationPayload,
} from "../models/new-client-registration.models";
import { catchError, map } from 'rxjs/operators';
import { ClientRegistration } from '@maplight/models';
import { Lobbyist } from "../../Lobbyist/lobbyist.model";
import { AuthorizedAgent } from '@maplight/models/authorized-agent-model';

// TODO - rename this service to `ClientRegistrationService`
@Injectable()
export class NewClientRegistrationService {
    // TODO - move these two lines of state management to the component that uses it
    _success$ = new BehaviorSubject<PaymentType>(PaymentType.Unknown);
    success$ = this._success$.asObservable();

    constructor(
        private readonly user: UserService,
        private readonly client: ClientService,
        private readonly urls: MasterUrlService
    ) { }

    getAllClients(name: string): Observable<ClientsApiModel[]> {
        return this.client.postData(this.urls.getAllClientsByName, { name: name })
            .pipe(map(({ items }) => items));
    }

    /**
     * Retrieves the client registration information for the given ID.
     *
     * @param {string} id - The ID of the client registration.
     * @return {Observable<ClientRegistration | null>} An observable that emits the client registration information.
     */
    getClientRegistration(id: string): Observable<ClientRegistration | null> {
        return this.client
            .getData(this.urls.getClientRegistration + id)
            .pipe(catchError((_) => of(null)));
    }

    getPaymentHistory(id: string) {
        return this.client
            .getData(this.urls.getRegistrationPaymentHistory(id))
            .pipe(catchError((_) => of([])));
    }

    getLegislativeCommittees(): Observable<LegislativeCommittee[]> {
        return this.client.getData(this.urls.getAllLegislativesCommittees)
    }

    getPrimaryNatureBusinessList(): Observable<NatureOfBusiness[]> {
        return this.client.getData(this.urls.getNatureOfBusiness)
    }

    /**
     * Creates a new client registration.
     * @param newClient The client registration to create.
     */
    registerClient(newClient: ClientRegistrationPayload): Observable<ClientRegistration> {
        return this.client.postData(this.urls.addClientRegistration, newClient);
    }

    amendClientRegistration(id: string, clientRegistration: ClientRegistrationPayload): Observable<ClientRegistration> {
        return this.client.putData(this.urls.amendClientRegistration(id), clientRegistration);
    }

    getCurrentLobbyistUserData(lobbyistId: string) {
        return this.client.getWithData(
            this.urls.getCurrentLobbyistUserData,
            { lobbyistId: lobbyistId }
        ).pipe(
            map((user) => {
                user.filerId = lobbyistId
                return user
            })
        )
    }

    getAuthorizedAgentsByName(name: string): Observable<AuthorizedAgent[]> {
        return this.client.getWithData(this.urls.getAuthorizedAgent, { fullname: name });
    }

    getLobbyistsByName(lobbyistId: string, name: string): Observable<Lobbyist[]> {
        let url = this.urls.SearchLobbyistByName;

        const target = name?.trim();

        if (target?.length) {
            const params = new URLSearchParams({ name: target });
            url = `${url}?${params.toString()}`;
        }

        return this.client.getData(url);
    }

    getClientTemplate(templateId: string)
    {
        return this.client.getData(this.urls.getClientTemplate(templateId))
            .pipe(catchError(_ => of(null)));
    }
}