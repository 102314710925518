import {Injectable} from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    createUrlTreeFromSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree
} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";
import {ClientService, FilerInformationService, MasterUrlService, SnackbarService} from "src/app/core";
import {AuthorizationResponse} from "@maplight/models";
import { TranslocoService } from "@ngneat/transloco";

@Injectable()
export class MonthlyReportGuard implements CanActivate {

    constructor(
        private readonly router: Router,
        private readonly filer: FilerInformationService,
        private readonly clientService: ClientService,
        private readonly urlService: MasterUrlService,
        private readonly transloco: TranslocoService,
        private readonly snackbar: SnackbarService,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        const filingPeriodId = route.params.filingPeriodId;
        const clientRegistrationId = route.params.clientRegistrationId;
        const filingId = route.params.filingId;

        return !!filingId ? this.clientService.postData(this.urlService.authorizeMonthlyReportByFilingId(filingId), {}).pipe
            (
                map(x => x.isAuthorized || this.onFailedAuthorization()),
                catchError(_ => of(false))
            ) :
            this.filer.current$.pipe
            (
                switchMap(f => this.clientService.postData(
                    this.urlService.authorizeMonthlyReportByClientRegistration(clientRegistrationId),
                    {lobbyistId: f.filerId, filingPeriodId: filingPeriodId})
                ),
                map((res: AuthorizationResponse) => 
                    res.isAuthorized ? 
                    createUrlTreeFromSnapshot(route, ['../../', res.resource]) : 
                    this.onFailedAuthorization()),
                catchError(_ => of(false)
                )
            );
    }

    private onFailedAuthorization()
    {
        this.snackbar.snackbarError(this.transloco.translate('acquireLockConflict'));
        return this.router.createUrlTree(['/lobbyist']);
    }
}
