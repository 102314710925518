import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FilerEntity } from '@maplight/models';
import { ManageUserFormService } from '../../services/manage-user-form.service';
import { Subject, combineLatest, merge } from 'rxjs';
import { UserApiModel } from 'src/app/app.model';
import { map, startWith, switchMap, tap } from 'rxjs/operators';
import { ManageProfileService } from '../../services/manage-profile.service';

@Component({
    selector: 'app-manage-profile-grassroots',
    templateUrl: './manage-profile-grassroots.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageProfileGrassrootsComponent {
    private filer: FilerEntity;

    @Input() set entity(value: FilerEntity) {
        if (!value) return;

        this.filer = value;
        this.userFormService.set(value.primaryUser);
        this.form.patchValue(value);
    }

    private readonly userFormService = inject(ManageUserFormService);
    private readonly service = inject(ManageProfileService);
    private readonly fb = inject(FormBuilder);

    protected readonly form = this.fb.group({
        id: this.fb.control<string>(null, [Validators.required]),
        filerTypeId: this.fb.control<number>(null, [Validators.required]),
        organizationName: this.fb.control<string>(null, [Validators.required]),
    });

    readonly user = this.userFormService.user;

    private readonly saveProfileSubject$ = new Subject<FilerEntity>();

    private readonly saveProfile$ = this.saveProfileSubject$.pipe(
        switchMap(f => this.service.updateFilerProfile(f)),
    );

    private readonly savingProfile$ = merge(
        this.saveProfileSubject$.pipe(map(_ => true)),
        this.saveProfile$.pipe(map(_ => false))
    ).pipe(startWith(false));

    private readonly organizationChanged$ = this.form.controls.organizationName.valueChanges.pipe(
        map(change => this.filer.organizationName !== change),
        startWith(false)
    );

    private readonly formChanged$ = combineLatest([
        this.userFormService.hasChanged$,
        this.organizationChanged$
    ]).pipe(map(([x, y]) => x || y));

    readonly disableButton$ = combineLatest([
        this.savingProfile$,
        this.formChanged$
    ]).pipe(map(([x, y]) => x || !y));

    protected saveUserInformation()
    {
        const primaryUser = this.user.value as UserApiModel;
        const filer = this.form.value as FilerEntity;

        this.saveProfileSubject$.next(
            {
                ...filer,
                primaryUser: primaryUser
            }
        );
    }
}
