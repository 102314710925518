import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EditEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { filter, map, mapTo, scan, share, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { UpdateType } from 'src/app/modules/System-Management/models/update-type.model';
import { EventOfficial } from '../../models/event-official-model';
import { TranslocoService } from "@ngneat/transloco";
import { ActivityReportService } from '../../services/activity-report.service';
import { TablePagingService } from 'src/app/shared/services/table-paging.service';

type EventOfficialUpdate = EventOfficial & { type: UpdateType };

@Component({
    selector: 'app-events-for-officials-grid',
    templateUrl: './events-for-officials-grid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class EventsForOfficialsComponent implements OnInit {
    constructor(
        private readonly i18n: TranslocoService,
        private readonly activityReportService: ActivityReportService,
        private readonly tablePagingService: TablePagingService,
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
    ) {}

    @Output() modifiedTableEvent = new EventEmitter<undefined>();

    readonly amountLimit = 250;

    deletingItemId: string = undefined;
    editingRowIndex: number = undefined;
    form: FormGroup = this.fb.group({
        id: this.fb.control<string>(null),
        date: this.fb.control<Date>(null, [Validators.required]),
        natureEvent: this.fb.control<string>(null, [Validators.required]),
        costEvent: this.fb.control<number>(null, [Validators.min(this.amountLimit)]),
        coveredOfficials: this.fb.control<string>(null, [Validators.required]),
    });

    eventOfficials$: Observable<EventOfficial[]>;

    private readonly addEventOfficialSubject$: Subject<EventOfficial> = new Subject<EventOfficial>();
    private readonly editEventOfficialSubject$: Subject<EventOfficial> = new Subject<EventOfficial>();
    private readonly removeEventOfficialSubject$: Subject<string> = new Subject<string>();

    loading$: Observable<boolean>;

    ngOnInit(): void {
        const monthlyReportId = this.route.snapshot.params.filingId;

        const apiEventOfficials$ = this.activityReportService.getEventOfficials(monthlyReportId)
            .pipe(shareReplay(1));

        const loadingEventOfficialsFromAPI$ = apiEventOfficials$.pipe(
            mapTo(false), 
            startWith(true)
        );

        const addEventOfficials$ = this.addEventOfficialSubject$.pipe(
            switchMap(x => this.activityReportService.addEventOfficial(monthlyReportId, x)),
            tap(() => this.modifiedTableEvent.emit()),
            share()
        );

        const addingEventOfficials$ = merge(
            this.addEventOfficialSubject$.pipe(mapTo(true)),
            addEventOfficials$.pipe(mapTo(false))
        ).pipe(startWith(false));

        const editEventOfficials$ = this.editEventOfficialSubject$.pipe(
            switchMap(x => this.activityReportService.editEventOfficial(monthlyReportId, x)),
            tap(() => this.modifiedTableEvent.emit()),
            share()
        );

        const editingEventOfficials$ = merge(
            this.editEventOfficialSubject$.pipe(mapTo(true)),
            editEventOfficials$.pipe(mapTo(false))
        ).pipe(startWith(false));

        const removeEventOfficials$ = this.removeEventOfficialSubject$.pipe(
            switchMap(x => this.activityReportService.removeEventOfficial(monthlyReportId, x)),
            tap(() => this.modifiedTableEvent.emit()),
            share()
        );

        const removingEventOfficials$ = merge(
            this.removeEventOfficialSubject$.pipe(mapTo(true)),
            removeEventOfficials$.pipe(mapTo(false))
        ).pipe(startWith(false));

        this.eventOfficials$ = merge(
            apiEventOfficials$,
            addEventOfficials$.pipe(filter(x => !!x), map(x => ({...x, type: UpdateType.Add}))),
            editEventOfficials$.pipe(filter(x => !!x), map(x => ({...x, type: UpdateType.Edit}))),
            removeEventOfficials$.pipe(filter(x => !!x), map(x => ({...x, type: UpdateType.Remove}))),
        ).pipe(
            scan((previous: EventOfficial[], value: EventOfficial[] | EventOfficialUpdate) => 
                {
                    if (value instanceof Array) return value as EventOfficial[];

                    const v = value as EventOfficialUpdate;
                    switch (v.type)
                    {
                        case UpdateType.Add: 
                            previous.push(value);
                            break;
                        case UpdateType.Edit:
                            previous = previous.map(x => x.id != v.id ? x : (v as EventOfficial));
                            break;
                        case UpdateType.Remove:
                            previous = previous.filter(x => x.id != v.id);
                            break;
                    }
                    return previous;
                },
                []
            ),
            map(x => x.map(y => ({
                ...y,
                costEvent: y.costEvent >= this.amountLimit ? y.costEvent : null
            }))),
        );

        this.loading$ = combineLatest([
            loadingEventOfficialsFromAPI$,
            addingEventOfficials$,
            editingEventOfficials$,
            removingEventOfficials$
        ]).pipe(map(x => x.some(y => y)));
    }

    protected getColumnWidth(title: string): number {
        const translated = this.i18n.translate(`report.eventOfficials.${title}`);
        return this.tablePagingService.calcColumnWidth(translated);
    }

    public addHandler(args: GridComponent): void {
        this.closeEditor(args);
        this.deletingItemId = undefined;
        this.editingRowIndex = -1;
        args.addRow(this.form);
    }

    public editHandler(args: EditEvent): void {
        this.closeEditor(args.sender);

        const toEdit = { ...args.dataItem, date: new Date(args.dataItem.date) };
        this.form.patchValue(toEdit);

        this.deletingItemId = undefined;
        this.editingRowIndex = args.rowIndex;

        args.sender.editRow(args.rowIndex, this.form);
    }

    public cancelHandler(args: GridComponent): void { this.closeEditor(args, this.editingRowIndex); }

    public saveHandler(sender: GridComponent): void {
        if (this.form.invalid) return;

        let object = {
            ...this.form.value,
            costEvent: this.form.value.costEvent ?? 0
        };
        const isNew = this.editingRowIndex === -1;

        (isNew ? this.addEventOfficialSubject$ : this.editEventOfficialSubject$).next(object);

        this.form.reset();
        sender.closeRow(this.editingRowIndex);
        this.editingRowIndex = undefined;
        this.deletingItemId = undefined;
    }

    public removeHandler(args: RemoveEvent): void { 
        if (this.editingRowIndex !== undefined) args.sender.closeRow(this.editingRowIndex);
        this.editingRowIndex = undefined;
        this.deletingItemId = args.dataItem.id; 
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editingRowIndex) {
        grid.closeRow(rowIndex);
        this.form.reset();
        this.editingRowIndex = undefined;
        this.deletingItemId = undefined;
    }

    deleteHandler() { 
        this.removeEventOfficialSubject$.next(this.deletingItemId); 
        this.deletingItemId = undefined;
    }
}
