import { Injectable, inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MasterDataService } from '@maplight/index';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserApiModel } from 'src/app/app.model';

// All Manage Profile pages have a form available so the user can change their First Name, Last Name or Phone.
// This service is designed to have all the logic related to that form in the same place. Also sharing a
// hasChanged$ property that will be used to say if some of the form fields has changed or not, allowing the 
// customer of this service to act accordingly.

// To set the value for the form, it is enough to call the set method with the corresponding User data.

@Injectable()
export class ManageUserFormService {
    private _user: UserApiModel;

    private readonly fb = inject(FormBuilder);
    private readonly formatting = inject(MasterDataService);

    user = this.fb.group({
        id: this.fb.control<string>(null, [Validators.required]),
        firstName: this.fb.control<string>(null, [Validators.required]),
        lastName: this.fb.control<string>(null, [Validators.required]),
        phone: this.fb.control<string>(null, [Validators.required, Validators.pattern(this.formatting.phoneNumberHyphenValidation)]),
    });

    public set (user: UserApiModel) 
    {
        this._user = user;
        this.user.patchValue(user);
    }

    hasChanged$: Observable<boolean> = this.user.valueChanges
    .pipe(
        map(change => 
            this._user.firstName !== change.firstName ||
            this._user.lastName !== change.lastName ||
            this._user.phone !== change.phone 
        ),
        startWith(false)
    );
}
