import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AccountService } from '../services/account.service';
import { InvitationDetails } from 'src/app/app.model';

export const loadInvitation: ResolveFn<InvitationDetails> = (route, _state) => {
    const token = route.queryParamMap.get('token');

    if (!token) return null;

    return inject(AccountService).loadInvitation(token);
};
