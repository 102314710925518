import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EditEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { filter, map, mapTo, scan, share, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { UpdateType } from 'src/app/modules/System-Management/models/update-type.model';
import { ExpenditureOnBehalf } from '../../models/expenditure-on-behalf.model';
import { ActivityReportService } from '../../services/activity-report.service';

type ExpenditureOnBehalfUpdate = ExpenditureOnBehalf & { type: UpdateType };

@Component({
    selector: 'app-expenditures-on-behalf-grid',
    templateUrl: './expenditures-on-behalf-grid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class ExpendituresOnBehalfComponent implements OnInit {
    @Output() modifiedTableEvent = new EventEmitter<undefined>();

    readonly amountLimit = 25;

    deletingItemId: string = undefined;
    editingRowIndex: number = undefined;
    form: FormGroup = this.fb.group({
        id: this.fb.control<string>(null),
        date: this.fb.control<Date>(null, [Validators.required]),
        nameOfficials: this.fb.control<string>(null, [Validators.required]),
        amount: this.fb.control<number>(null, [Validators.min(this.amountLimit)]),
        purpose: this.fb.control<string>(null, [Validators.required]),
    });

    expendituresOnBehalfs$: Observable<ExpenditureOnBehalf[]>;

    private readonly addExpendituresOnBehalfSubject$: Subject<ExpenditureOnBehalf> = new Subject<ExpenditureOnBehalf>();
    private readonly editExpendituresOnBehalfSubject$: Subject<ExpenditureOnBehalf> = new Subject<ExpenditureOnBehalf>();
    private readonly removeExpendituresOnBehalfSubject$: Subject<string> = new Subject<string>();

    loading$: Observable<boolean>;

    constructor(
        private readonly activityReportService: ActivityReportService,
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        const monthlyReportId = this.route.snapshot.params.filingId;

        const apiExpendituresOnBehalfs$ = this.activityReportService.getExpenditureOnBehalfs(monthlyReportId)
            .pipe(shareReplay(1));

        const loadingExpendituresOnBehalfsFromAPI$ = apiExpendituresOnBehalfs$.pipe(
            mapTo(false), 
            startWith(true)
        );

        const addExpendituresOnBehalfs$ = this.addExpendituresOnBehalfSubject$.pipe(
            switchMap(x => this.activityReportService.addExpendituresOnBehalf(monthlyReportId, x)),
            tap(() => this.modifiedTableEvent.emit()),
            share()
        );

        const addingExpendituresOnBehalfs$ = merge(
            this.addExpendituresOnBehalfSubject$.pipe(mapTo(true)),
            addExpendituresOnBehalfs$.pipe(mapTo(false))
        ).pipe(startWith(false));

        const editExpendituresOnBehalfs$ = this.editExpendituresOnBehalfSubject$.pipe(
            switchMap(x => this.activityReportService.editExpendituresOnBehalf(monthlyReportId, x)),
            tap(() => this.modifiedTableEvent.emit()),
            share()
        );

        const editingExpendituresOnBehalfs$ = merge(
            this.editExpendituresOnBehalfSubject$.pipe(mapTo(true)),
            editExpendituresOnBehalfs$.pipe(mapTo(false))
        ).pipe(startWith(false));

        const removeExpendituresOnBehalfs$ = this.removeExpendituresOnBehalfSubject$.pipe(
            switchMap(x => this.activityReportService.removeExpendituresOnBehalf(monthlyReportId, x)),
            tap(() => this.modifiedTableEvent.emit()),
            share()
        );

        const removingExpendituresOnBehalfs$ = merge(
            this.removeExpendituresOnBehalfSubject$.pipe(mapTo(true)),
            removeExpendituresOnBehalfs$.pipe(mapTo(false))
        ).pipe(startWith(false));

        this.expendituresOnBehalfs$ = merge(
            apiExpendituresOnBehalfs$,
            addExpendituresOnBehalfs$.pipe(filter(x => !!x), map(x => ({...x, type: UpdateType.Add}))),
            editExpendituresOnBehalfs$.pipe(filter(x => !!x), map(x => ({...x, type: UpdateType.Edit}))),
            removeExpendituresOnBehalfs$.pipe(filter(x => !!x), map(x => ({...x, type: UpdateType.Remove}))),
        ).pipe(
            scan((previous: ExpenditureOnBehalf[], value: ExpenditureOnBehalf[] | ExpenditureOnBehalfUpdate) => 
                {
                    if (value instanceof Array) return value as ExpenditureOnBehalf[];

                    const v = value as ExpenditureOnBehalfUpdate;
                    switch (v.type)
                    {
                        case UpdateType.Add: 
                            previous.push(value);
                            break;
                        case UpdateType.Edit:
                            previous = previous.map(x => x.id != v.id ? x : (v as ExpenditureOnBehalf));
                            break;
                        case UpdateType.Remove:
                            previous = previous.filter(x => x.id != v.id);
                            break;
                    }
                    return previous;
                },
                []
            ),
            map(x => x.map(y => ({
                ...y,
                amount: y.amount >= this.amountLimit ? y.amount : null
            }))),
        );

        this.loading$ = combineLatest([
            loadingExpendituresOnBehalfsFromAPI$,
            addingExpendituresOnBehalfs$,
            editingExpendituresOnBehalfs$,
            removingExpendituresOnBehalfs$
        ]).pipe(map(x => x.some(y => y)));
    }

    public addHandler(args: GridComponent): void {
        this.closeEditor(args);
        this.deletingItemId = undefined;
        this.editingRowIndex = -1;
        args.addRow(this.form);
    }

    public editHandler(args: EditEvent): void {
        this.closeEditor(args.sender);

        const toEdit = { ...args.dataItem, date: new Date(args.dataItem.date) };
        this.form.patchValue(toEdit);

        this.deletingItemId = undefined;
        this.editingRowIndex = args.rowIndex;
        args.sender.editRow(args.rowIndex, this.form);
    }

    public cancelHandler(args: GridComponent): void { this.closeEditor(args, this.editingRowIndex); }

    public saveHandler(sender: GridComponent): void {
        if (this.form.invalid) return;

        let object = {
            ...this.form.value,
            amount: this.form.value.amount ?? 0   
        };
        const isNew = this.editingRowIndex === -1;

        (isNew ? this.addExpendituresOnBehalfSubject$ : this.editExpendituresOnBehalfSubject$).next(object);

        this.form.reset();
        sender.closeRow(this.editingRowIndex);
        this.editingRowIndex = undefined;
        this.deletingItemId = undefined;
    }

    public removeHandler(args: RemoveEvent): void { 
        if (this.editingRowIndex !== undefined) args.sender.closeRow(this.editingRowIndex);
        this.editingRowIndex = undefined;
        this.deletingItemId = args.dataItem.id; 
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editingRowIndex) {
        grid.closeRow(rowIndex);
        this.form.reset();
        this.editingRowIndex = undefined;
        this.deletingItemId = undefined;
    }

    deleteHandler() { 
        this.removeExpendituresOnBehalfSubject$.next(this.deletingItemId); 
        this.deletingItemId = undefined;
    }
}
