import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-firm-action",
    templateUrl: "./firm-action.html",
    styleUrls: ["./firm-action.scss"],
})
export class FirmActionComponent implements OnInit {
    @Input() isEditing: boolean;
    @Output() edit: EventEmitter<void> = new EventEmitter();
    @Output() delete: EventEmitter<void> = new EventEmitter();
    @Output() cancel: EventEmitter<void> = new EventEmitter();

    constructor(
    ) {}

    ngOnInit(): void {
    }
}