import { Item, SectionTypeEnum, TotalSection } from '@maplight/models';
import {
    BehaviorSubject,
    combineLatest,
    forkJoin,
    merge,
    Observable,
    of,
    Subject,
} from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import {
    map,
    mapTo,
    share,
    shareReplay,
    startWith,
    switchMap,
    tap,
    withLatestFrom,
    take,
} from 'rxjs/operators';
import { ActivityReportService } from '../../services/activity-report.service';
import { TranslocoService } from '@ngneat/transloco';
import { PhonePipe } from '../../../../shared/pipes/format-phone.pipe';
import { SnackbarService } from '@maplight/snackbar/snackbar.service';
import { FilerInformationService } from '@maplight/services';
import { NewClientRegistrationService } from '../../../new-client-registration/services';
import { TargetTimePipe } from 'src/app/shared/pipes/denver-time.pipe';
import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'app-main-activity-report',
    templateUrl: './main-activity-report.component.html',
    styleUrls: ['./main-activity-report.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainActivityReportComponent implements OnInit {
    mode$: Observable<'draft' | 'amend'> = of('draft');
    headerText$: Observable<string>;
    fileButtonText$: Observable<string>;
    disableFileButton$: Observable<boolean>;
    modifiedReport = new BehaviorSubject<boolean>(false);

    //header information
    reportMonthName$: Observable<string>;
    registrationName$: Observable<string>;

    filingId$: Observable<string>;

    readonly sectionType = SectionTypeEnum;
    totalCompensation$: Observable<TotalSection>;
    totalExpenditure$: Observable<TotalSection>;
    totalExpenditureOnBehalfFamilyMembers$: Observable<TotalSection>;
    private readonly saveCompensationRequest$ = new Subject<TotalSection>();
    private readonly saveExpenditureRequest$ = new Subject<TotalSection>();
    private readonly saveBehalfFamilyMembers$ = new Subject<TotalSection>();
    loadingCompensation$: Observable<boolean>;
    loadingExpenditure$: Observable<boolean>;
    loadingBehalfFamilyMembers$: Observable<boolean>;

    private readonly saveFilingRequest$ = new Subject<void>();
    private readonly downloadActivityReportSubject$: Subject<void> =
        new Subject<void>();

    lastSaved$: Observable<Date | string>;
    loading$: Observable<boolean>;

    info$: Observable<{
        column1: Item[];
        column2: Item[];
    }>;

    readonly documents$ = this.activityReportService
        .getLegislativeDocuments()
        .pipe(share());

    constructor(
        private readonly activityReportService: ActivityReportService,
        private readonly clientRegistrationService: NewClientRegistrationService,
        private readonly route: ActivatedRoute,
        private readonly translocoService: TranslocoService,
        private readonly targetTimePipe: TargetTimePipe,
        private readonly phonePipe: PhonePipe,
        private readonly snackbar: SnackbarService,
        private readonly router: Router,
        private readonly filerInformationService: FilerInformationService,
    ) {}

    ngOnInit(): void {
        const filingId = this.route.snapshot.params.filingId;

        const activityReport = this.activityReportService
            .getMonthlyReportFilingInformation(filingId)
            .pipe(shareReplay(1));

        this.mode$ = activityReport.pipe(
            map((report) => report.amendment > 0 ? 'amend' : 'draft'),
            shareReplay<'draft' | 'amend'>(1)
        );

        this.headerText$ = this.mode$.pipe(
            map((mode) =>
                mode === 'amend' ? 'report.amendHeader' : 'report.header',
            ),
        );
        this.fileButtonText$ = this.mode$.pipe(
            map((mode) =>
                mode === 'amend' ? 'report.amendFile' : 'report.file',
            ),
        );

        this.lastSaved$ = merge(
            activityReport.pipe(map((r) => r.updatedOn)),
            this.activityReportService.activityReportUpdates$.pipe(
                map((_) => new Date().toISOString()),
            ),
        );

        const reportMonthPeriod$ = activityReport.pipe(
            map((f) => f.filingPeriod),
            shareReplay(1),
        );

        this.reportMonthName$ = reportMonthPeriod$.pipe(
            map((r) => r.name),
            shareReplay(1),
        );

        const clientRegistrationId$ = activityReport.pipe(
            map((r) => r.clientRegistrationId),
        );

        const clientRegistration$ = clientRegistrationId$.pipe(
            switchMap((c) =>
                this.clientRegistrationService.getClientRegistration(c),
            ),
            shareReplay(1),
        );

        this.registrationName$ = clientRegistration$.pipe(
            map((c) => c?.primaryLobbyist?.name + ' for ' + c?.client?.name),
        );

        const primaryLobbyistData$ = clientRegistration$.pipe(
            map((c) => c.primaryLobbyist),
            shareReplay(1),
        );

        const reportHeader$ =
            this.translocoService.selectTranslateObject('report');

        const column1 = combineLatest([
            this.translocoService.selectTranslateObject('report'),
            activityReport,
            primaryLobbyistData$,
        ]).pipe(
            map(([t, f, d]) => [
                {
                    label: t.primaryLobbyist,
                    value: `${d.primaryUser.firstName} ${d.primaryUser.lastName}`,
                },
                {
                    label: t.phoneNumber,
                    value: this.phonePipe.transform(d.primaryUser.phone),
                },
                { label: t.lobbyistEmail, value: d.primaryUser.email },
                { label: t.lobbyingFirm, value: d.firm?.name },
                {
                    label: t.firmAddress,
                    value: d.businessAddress.address1 + ' ' + (d.businessAddress.address2 ?? ''),
                },
                {
                    label: t.filingPeriod,
                    value:
                        this.targetTimePipe.transform(
                            f.filingPeriod.startDate,
                            'MM/dd/yyyy',
                        ) +
                        ' - ' +
                        this.targetTimePipe.transform(
                            f.filingPeriod.endDate,
                            'MM/dd/yyyy',
                        ),
                },
            ]),
            take(1),
        );

        const column2 = combineLatest([
            reportHeader$,
            clientRegistration$,
            reportMonthPeriod$,
        ]).pipe(
            map(([t, c, f]) => [
                { label: t.clientName, value: c.client.name },
                {
                    label: t.principalContactForClient,
                    value: c.client.contact,
                },
                { label: t.clientEmail, value: c.client.email },
                { label: t.clientPhone, value: this.phonePipe.transform(c.client.phone) },
                { label: t.clientAddress, value: `${c.client.address1} ${c.client.address2 ?? ''}` },
                {
                    label: t.dueDate,
                    value: this.targetTimePipe.transform(
                        f.dueDate,
                        'MM/dd/yyyy',
                    ),
                },
            ]),
            take(1),
        );

        this.info$ = forkJoin({ column1, column2 });

        this.totalCompensation$ = merge(
            this.activityReportService.getTotalCompensation(filingId),
            this.saveCompensationRequest$.pipe(
                switchMap((data) =>
                    this.activityReportService.saveTotalCompensation(
                        filingId,
                        data,
                    ),
                ),
            ),
        ).pipe(share());

        this.totalExpenditure$ = merge(
            this.activityReportService.getTotalExpenditure(filingId),
            this.saveExpenditureRequest$.pipe(
                switchMap((data) =>
                    this.activityReportService.saveTotalExpenditure(
                        filingId,
                        data,
                    ),
                ),
            ),
        ).pipe(share());

        this.totalExpenditureOnBehalfFamilyMembers$ = merge(
            this.activityReportService.getTotalExpenditureBehalfFamilyMembers(
                filingId,
            ),
            this.saveBehalfFamilyMembers$.pipe(
                switchMap((data) =>
                    this.activityReportService.saveTotalExpenditureBehalfFamilyMembers(
                        filingId,
                        data,
                    ),
                ),
            ),
        ).pipe(share());

        this.loadingCompensation$ = merge(
            this.saveCompensationRequest$.pipe(mapTo(true)),
            this.totalCompensation$.pipe(mapTo(false)),
        ).pipe(startWith(true), shareReplay(1));

        this.loadingExpenditure$ = merge(
            this.saveExpenditureRequest$.pipe(mapTo(true)),
            this.totalExpenditure$.pipe(mapTo(false)),
        ).pipe(startWith(true), shareReplay(1));

        this.loadingBehalfFamilyMembers$ = merge(
            this.saveBehalfFamilyMembers$.pipe(mapTo(true)),
            this.totalExpenditureOnBehalfFamilyMembers$.pipe(mapTo(false)),
        ).pipe(startWith(true), shareReplay(1));

        const downloadReport$ = this.downloadActivityReportSubject$.pipe(
            withLatestFrom(this.reportMonthName$, this.registrationName$),
            switchMap(([_, m, c]) =>
                this.activityReportService.downloadPreviewActivityReport(
                    `${m} - ${c} - Activity Report`,
                    filingId,
                ),
            ),
        );

        const downloadingReport$ = merge(
            this.downloadActivityReportSubject$.pipe(mapTo(true)),
            downloadReport$.pipe(mapTo(false)),
        ).pipe(startWith(false), shareReplay(1));

        const lobbyistId$ = this.filerInformationService.current$.pipe(
            map((f) => f.filerId),
        );

        const submitActivityReport$ = this.saveFilingRequest$.pipe(
            withLatestFrom(lobbyistId$),
            switchMap(([_, lobbyistId]) =>
                this.activityReportService.submitMonthlyReport(
                    filingId,
                    lobbyistId,
                ),
            ),
            tap((_) => this.notify(_ != null)),
            share(),
        );

        const savingFiling$ = merge(
            this.saveFilingRequest$.pipe(mapTo(true)),
            submitActivityReport$.pipe(mapTo(false)),
        ).pipe(startWith(false), shareReplay(1));

        this.loading$ = combineLatest([
            downloadingReport$,
            savingFiling$,
            this.loadingCompensation$,
            this.loadingExpenditure$,
            this.loadingBehalfFamilyMembers$,
        ]).pipe(
            map((x) => x.some((y) => y)),
            share(),
        );

        this.disableFileButton$ = combineLatest([
            this.loading$,
            activityReport,
            this.modifiedReport,
        ]).pipe(
            map(
                ([loading, report, modifiedReport]) =>
                    loading || (report.filingStatusId === 1 && !modifiedReport),
            ),
        );
    }

    save(value: TotalSection, sectionType: number) {
        switch (sectionType) {
            case SectionTypeEnum.Compensation:
                this.saveCompensationRequest$.next(value);
                break;
            case SectionTypeEnum.Expenditure:
                this.saveExpenditureRequest$.next(value);
                break;
            case SectionTypeEnum.OnBehalfFamilyMembers:
                this.saveBehalfFamilyMembers$.next(value);
        }

        this.modifiedReport.next(true);
    }

    preViewPDF() {
        this.downloadActivityReportSubject$.next();
    }

    private notify(result: boolean): void {
        if (result) {
            this.snackbar.snackbarSuccess(
                this.translocoService.translate('reportSavedSuccessfully'),
            );
            this.router.navigate(['/lobbyist']);
        } else
            this.snackbar.snackbarError(
                this.translocoService.translate('failedToSaveReport'),
            );
    }

    saveFiling() {
        this.saveFilingRequest$.next();
    }

    modifiedTableEvent(_: undefined) {
        this.modifiedReport.next(true);
    }
}
