import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FilerInformationService } from '../../../core';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { FilerTypeEnum, LobbyistUserTypeEnum, UserApiModel, UserFilers } from '../../models';
import { Menu } from '../../models/app-menu.model';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from 'src/app/core/services/user-service';
import { IUserSession } from 'src/app/core/api-services/authorization/auth.service';

export type PrincipalMenu = 
    'PUBLIC' | 
    'LOBBYIST' | 'LOBBYIST-AGENT' | 'LOBBYIST-ADMIN' |
    'AGENT' | 'AGENT-ADMIN' |
    'GRASSROOT' | 'GRASSROOT-ADMIN' |
    'LEGISLATIVE-DESIGNEE' | 'LEGISLATIVE-DESIGNEE-ADMIN' |
    'ADMIN';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppHeader implements OnInit {
    authenticated$: Observable<boolean>;
    menuItems$: Observable<Menu> | any;
    dashboardLink$: Observable<string[]>;
    menu: PrincipalMenu = 'PUBLIC';

    constructor(
        private readonly user: UserService,
        private readonly router: Router,
        private readonly filer: FilerInformationService,
        private readonly translocoService: TranslocoService,
    ) {}

    getStandardItems(manage: string, log_out: string) {
        return [
            { text: manage, path: '/account/manage/profile' },
            { text: log_out, path: '/account/logout' },
        ];
    }

    getDefaultMenu(log_in: string, register: string): Menu {
        this.menu = 'PUBLIC';
        return {
            text: log_in,
            items: [
                { text: register, path: '/account/signup' },
                { text: log_in, path: '/account/login' },
            ],
        };
    }

    private readonly dashboardRouting: Record<FilerTypeEnum, string[]> = {
        [FilerTypeEnum.Admin]: ['manage', 'committee'],
        [FilerTypeEnum.AllFilers]: ['/'],
        [FilerTypeEnum.Committee]: ['committee-info-dashboard'],
        [FilerTypeEnum.IndependentExpenditure]: ['dashboard', 'ie-home'],
        [FilerTypeEnum.Lobbyists]: ['lobbyist'],
        [FilerTypeEnum.AuthorizedAgent]: ['/'],
        [FilerTypeEnum.EthicsFiler]: ['ethics-dashboard'],
        [FilerTypeEnum.LegislativeDesignee]: ['/'],
        [FilerTypeEnum.Grassroot]: ['/'],
        [FilerTypeEnum.Unaffiliated]: ['/switch'],
    };

    private readonly buildMenu = (
        data: UserApiModel,
        hideSwitch: boolean = true,
        switch_committee: string,
        log_in: string,
        register: string,
        manage: string,
        log_out: string,
        filer: UserFilers,
    ): Menu => {
        if (!data) {
            this.menu = 'PUBLIC';
            return this.getDefaultMenu(log_in, register);
        } else {
            switch (filer?.filerTypeId) {
                case FilerTypeEnum.AuthorizedAgent:
                    this.menu = data.isAdmin ? 'AGENT-ADMIN' : 'AGENT';
                    break;
                case FilerTypeEnum.Lobbyists:
                    this.menu = data.isAdmin ? 'LOBBYIST-ADMIN' :
                        (filer.userTypeId === LobbyistUserTypeEnum.AuthorizedAgent) ? 
                        'LOBBYIST-AGENT' : 'LOBBYIST';
                    break;
                case FilerTypeEnum.Grassroot:
                    this.menu = data.isAdmin ? 'GRASSROOT-ADMIN' : 'GRASSROOT';
                    break;
                case FilerTypeEnum.LegislativeDesignee:
                    this.menu = data.isAdmin ? 'LEGISLATIVE-DESIGNEE-ADMIN' : 'LEGISLATIVE-DESIGNEE';
                    break;
                default:
                    this.menu = data.isAdmin ? 'ADMIN' : 'PUBLIC';
            }
        }

        const text =
            [data.firstName ?? '', data.lastName ?? ''].join(' ').trim() ||
            data?.email?.trim() ||
            'Admin';

        const items = this.getStandardItems(manage, log_out);

        return { text, items };
    };

    ngOnInit() {
        const userData$ = this.user.getUserStatus().pipe(
            map((s) => {
                if (s.status != 'LoggedIn') return null;
                else if (!!s.user) {
                    return {
                        firstName: s.user.firstName,
                        lastName: s.user.lastName,
                        isAdmin: s.user.isAdmin,
                    };
                } else return { email: (s.content as IUserSession).email };
            }),
        );

        const route$ = this.router.events.pipe(
            filter((_) => _ instanceof NavigationEnd),
            map((e: NavigationEnd) => e.url),
        );

        const switch_committee$ = this.translocoService.selectTranslate(
            'headerSection.switchCommittee',
        );
        const log_in$ = this.translocoService.selectTranslate(
            'headerSection.logIn',
        );
        const register$ = this.translocoService.selectTranslate(
            'headerSection.register',
        );
        const manage$ = this.translocoService.selectTranslate(
            'headerSection.manage',
        );
        const log_out$ = this.translocoService.selectTranslate(
            'headerSection.logOut',
        );

        this.menuItems$ = combineLatest([
            userData$,
            route$,
            switch_committee$,
            log_in$,
            register$,
            manage$,
            log_out$,
            this.filer.current$,
        ]).pipe(
            map(
                ([
                    data,
                    url,
                    switch_committee,
                    log_in,
                    register,
                    manage,
                    logout,
                    filer,
                ]) => {
                    return this.buildMenu(
                        data,
                        url.indexOf('switch') >= 0,
                        switch_committee,
                        log_in,
                        register,
                        manage,
                        logout,
                        filer,
                    );
                },
            ),
        );

        this.dashboardLink$ = combineLatest([
            this.user.isAuthenticated(),
            this.filer.current$,
        ]).pipe(
            map(([authenticated, filer]) => {
                if (!authenticated) return FilerTypeEnum.AllFilers;

                const hasAdmin = this.filer.isAdmin && FilerTypeEnum.Admin;

                return (filer?.filerTypeId || hasAdmin) as FilerTypeEnum;
            }),
            map((types) => this.dashboardRouting[types] ?? ['/']),
            startWith(['/']),
        );
    }

    switchLanguage() {
        if (this.translocoService.getActiveLang() === 'en') {
            this.translocoService.setActiveLang('es');
        } else {
            this.translocoService.setActiveLang('en');
        }
    }
}
