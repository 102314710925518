import { BaseTableInformation } from "src/app/shared/models/table-layout.model";
export interface SubmittedFiling extends BaseTableInformation {
    id: string;
    filingName?: string;
    filingPeriod?: string;
    submittedDate?: Date|string;
    amendment?: string;
    publicId: number;
    filingTypeId: number;
    startDate?: Date;
    endDate?: Date;
    registrationName: string;
    filingPeriodName: string;
    hasActivity: boolean;
    lobbyistName: string;
    status: number;
    version?: number; 
};