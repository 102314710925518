import { Injectable } from '@angular/core';
import { ClientService } from '../api-services/client.service';
import { catchError, map, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { MasterUrlService, SnackbarService } from '..';
import { ClientRegistrationStatusEnum } from 'src/app/modules/review-registration/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class RegistrationApprovalDashboardService {
    constructor(
        private readonly urls: MasterUrlService,
        private readonly client: ClientService,
        private readonly snackbarService: SnackbarService,
        private readonly translocoService: TranslocoService,
    ) {}

    private readonly ClientRegistrationStatusMapping: Record<ClientRegistrationStatusEnum, string> = {
        [ClientRegistrationStatusEnum.PendingReview]: 'pendingReview',
        [ClientRegistrationStatusEnum.Approved]: 'approved',
        [ClientRegistrationStatusEnum.Denied]: 'Denied',
        [ClientRegistrationStatusEnum.ConditionallyApproved]: 'conditionallyApproved',
        [ClientRegistrationStatusEnum.Terminated]: 'terminated',
        [ClientRegistrationStatusEnum.Unfiled]: 'unfiled',
    };
    getAllElements(data: {
        pageSize: number;
        pageNumber: number;
        sortBy: string;
        direction: string;
    }): Observable<GridDataResult> {
        return this.client
            .getWithData(this.urls.getRegistrationApprovals, data)
            .pipe(
                withLatestFrom(this.translocoService.selectTranslateObject('lobbyistDashboard.registrationStatuses')),
                map(([x, t]) => ({
                    data: x.items.map((y) => ({
                        ...y,
                        submissionDate: new Date(y.submissionDate),
                        status: t[this.ClientRegistrationStatusMapping[y.status]],
                    })),
                    total: x.total,
                })),
                catchError((_) => {
                    this.snackbarService.snackbarError(
                        'There was a problem while retrieving Registration Approvals. Please try again later.',
                    );
                    return of(null);
                }),
            );
    }

    showLobbyistClientRegistrationPdf(
        clientRegsitrationId: string,
        clientRegistrationVersion: number,
    ) {
        return this.client
            .showBlobAttachment(
                this.urls.getLobbyistClientRegistrationPdf(
                    clientRegsitrationId,
                    clientRegistrationVersion,
                ),
                'client_registration_report.pdf',
            )
            .pipe(
                catchError((error) => {
                    this.snackbarService.snackbarError(
                        this.translocoService.translate(
                            'expenditureReport.failedToDownload',
                        ),
                    );
                    return of(null);
                }),
                map(() => false),
            );
    }
}
