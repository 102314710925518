import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from '@angular/common';

@Pipe({ name: 'clampAsCurrency' })
export class ClampCurrencyPipe implements PipeTransform {
    private doFormat(it: number) {
        return formatCurrency(it, 'en_US', '$', 'USD');
    }

    transform(value: number, min: number = 1000) {
        const use = value || 0;

        if (use < min) return `< ${this.doFormat(min)}`;

        return this.doFormat(use);
    }
}
