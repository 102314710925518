import { BaseTableInformation } from "src/app/shared/models/table-layout.model";
export interface UnfiledAndLateReport extends BaseTableInformation {
    id: string;
    registrationId: string;
    clientName: string;
    reportName: string;
    lobbyistEmail: string;
    authorizedAgentEmails: string[];
    dueDate: string;
    dateReceived: string;
    submittedDate: string;
    status: string;
};