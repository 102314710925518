import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { ILobbyistFirmAddress } from "../../../shared/models/app-lobbyist-business";
import { ILobbyistFirm } from "../../../shared/models/app-lobbyist";
import { ClientService, MasterUrlService } from "../../../core";
import { catchError, map, tap } from "rxjs/operators";
import { Lobbyist } from "../../Lobbyist/lobbyist.model";
import { LobbyistFirmRequest } from "../models/ILobbyistData";
import { FirmTemplate } from "@maplight/models";

@Injectable({
    providedIn: "root",
})
export class LobbyistService {
    private _addFirmAddress = new Subject<ILobbyistFirmAddress>();
    addFirmAddress$ = this._addFirmAddress.asObservable();

    private _editFirmAddress = new BehaviorSubject<ILobbyistFirmAddress>(
        undefined
    );
    editBusinessAddress$ = this._editFirmAddress.asObservable();
    private firmAddressAdded: ILobbyistFirmAddress;

    private _addressSelectedSubject$ = new BehaviorSubject<ILobbyistFirmAddress>(null);
    addressSelected$ = this._addressSelectedSubject$.asObservable();

    private _firmAddressSelectedSubject = new BehaviorSubject<ILobbyistFirm>(null);
    firmAddressSelected$ = this._firmAddressSelectedSubject.asObservable();

    /***
     * to delete Firm
     * @private
     */
    private _deleteFirmAddress = new BehaviorSubject<boolean>(false);
    deleteBusinessAddress$ = this._deleteFirmAddress.asObservable();

    private _lobbyistFirmAddress: ILobbyistFirmAddress;
    private _newFirmWithAddress: ILobbyistFirm;

    public selectedFirmId: number;
    public selectedFirm: LobbyistFirmRequest;

    set addressSelected(value) {
        this._addressSelectedSubject$.next(value)
    }

    get addressSelected() {
        return this._addressSelectedSubject$.value;
    }

    set firmAddressSelected(value) {
        this._firmAddressSelectedSubject.next(value)
    }

    get firmAddressSelected() {
        return this._firmAddressSelectedSubject.value;
    }

    get newFirmWithAddress(): ILobbyistFirm {
        return this.firmAddressSelected;
    }

    set newFirmWithAddress(value: ILobbyistFirm) {
        this.firmAddressSelected = value;
    }

    public removeFirmWithAddress() {
        this.firmAddressSelected = null;
    }

    get lobbyistFirmAddress(): ILobbyistFirmAddress {
        return this._lobbyistFirmAddress;
    }

    constructor(
        private readonly clientService: ClientService,
        private readonly urlService: MasterUrlService
    ) {}

    public getLobbyistFirms(data?: string, filerId = null): Observable<FirmTemplate[]> {
        return this.clientService.getWithData(
            this.urlService.firmTemplates, 
            { name: data, filerId, pageSize: 100, pageNumber: 0 }
        ).pipe(
            map(x => x.items),
            catchError(_ => of([]))
        );
    }

    public getLobbyistById(lobbyistId: string): Observable<Lobbyist> {
        return this.clientService.getData(`${this.urlService.getLobbyist}${lobbyistId}`).pipe(
            catchError(_ => of(null))
        );
    }

    addNewFirmAddress(value: ILobbyistFirmAddress) {
        this._lobbyistFirmAddress = value;
        this.addressSelected = value;
        this._addFirmAddress.next(value);
        this._deleteFirmAddress.next(false);
        this._editFirmAddress.next(null);
        this.firmAddressAdded = value;
    }

    // if user select one address
    updateLobbyist(value) {
        this._lobbyistFirmAddress = value;
        this._deleteFirmAddress.next(false);
        this._editFirmAddress.next(null);
    }

    selectAddress(value) {
        this.addressSelected = value;
        this._lobbyistFirmAddress = this.addressSelected;
    }

    editNewFirm() {
        this._editFirmAddress.next(this._lobbyistFirmAddress);
    }

    delete() {
        this._deleteFirmAddress.next(true);
        this._addFirmAddress.next(null);
        this._editFirmAddress.next(null);
        this._lobbyistFirmAddress = null;
        if (this.firmAddressAdded === this.addressSelected)
            this.addressSelected = null;
    }

    cancel() {
        this._addFirmAddress.next(this._lobbyistFirmAddress);
        this._editFirmAddress.next(null);
    }
}
