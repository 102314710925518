import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core";
import { ILobbyistFirm } from "../../../../shared/models/app-lobbyist";
import { LobbyistService } from "../../services/lobbyist.service";
import { BehaviorSubject } from "rxjs";
import { filter, map } from "rxjs/operators";

@Component({
    selector: "app-create-account-firm-view",
    templateUrl: "./create-account-firm-view.component.html",
    styleUrls: ["./create-account-firm-view.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class CreateAccountFirmViewComponent {
    @Input() set firm(value: ILobbyistFirm) {
        this._firm = value;
        this._urlSubject$.next(value?.firmWebSite?.trim());
    }
    get firm() {
        return this._firm;
    }
    @Input() isEditing: boolean = false;
    @Output() deletedEmitter: EventEmitter<void> = new EventEmitter<void>();
    @Output() cancelEmitter: EventEmitter<void> = new EventEmitter<void>();

    private _firm: ILobbyistFirm;
    firmSelected: number = 1;
    private _urlSubject$ = new BehaviorSubject<string>(null);
    url$ = this._urlSubject$.pipe(
        filter((url) => !!url?.length),
        map((url) => url.startsWith("http") ? url : "https://" + url)
    )
    @Output() isEditEmitter = new EventEmitter<any>();

    constructor(private lobbyistService: LobbyistService, private cdr: ChangeDetectorRef,) {}

    ngOnInit(): void {
        if (this.lobbyistService.newFirmWithAddress) {
            this.firm = this.lobbyistService.newFirmWithAddress;
            this.firmSelected = 1;
        }
    }

    edit() {
        this.isEditEmitter.emit(this.firm);
        this.isEditing = true;
    }

    delete(): void {
        this.firmSelected = -1;
        this.isEditing = false;
        this.firm = null;
        this.deletedEmitter.emit();
    }

    cancel() {
        this.isEditing = false;
        this.cancelEmitter.emit(null);
    }
}
