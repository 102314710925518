import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map, shareReplay } from "rxjs/operators";
import { KeyedFiler } from "../switch-commitee.model";

@Injectable({ providedIn: "root" })
export class SwitchTargetService {
    private readonly value$ = new Subject<KeyedFiler>();
    current$: Observable<KeyedFiler> = this.value$.asObservable();
    target$: Observable<string>;

    constructor(private readonly route: ActivatedRoute) {
        this.target$ = this.route.queryParamMap.pipe(
            map((params) =>
                params.get("returnUrl")?.split("?selectedFilerId=")
            ),
            map((_) => (_?.length > 1 ? _[1] : null)),
            shareReplay(1)
        );
    }

    setFiler(target: KeyedFiler): void {
        this.value$.next(target);
    }
}
