import { Component, Input, inject } from '@angular/core';
import { BehaviorSubject, Observable, interval, merge, of } from 'rxjs';
import { map, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { AlertsDashboardService, FilerInformationService } from '@maplight/services';
import { AdminNotificationService } from 'src/app/modules/messages/admin/services';
import { PrincipalMenu } from '../app-header/app-header.component';

@Component({
  selector: 'app-badges',
  templateUrl: './app-badges.component.html',
  styleUrls: ['./app-badges.component.scss']
})
export class AppBadgesComponent {
  private readonly alertsDashboardService = inject(AlertsDashboardService);
  private readonly adminNotificationService = inject(AdminNotificationService);
  private readonly filerInformation = inject(FilerInformationService);

  @Input() set menu(value: PrincipalMenu) {
    this.menuSubject$.next(value);
  };

  private readonly TIMER = 60 * 1000

  private readonly menuSubject$ = new BehaviorSubject<PrincipalMenu>('PUBLIC');

  protected readonly loggedIn$: Observable<boolean> = this.menuSubject$.pipe(map((x) => x !== 'PUBLIC'));
  protected readonly isAdmin$: Observable<boolean> = this.menuSubject$.pipe(map((x) => this.checkForAdmin(x)));

  protected readonly messageLink$: Observable<string[]> = this.isAdmin$.pipe(
    map((isAdmin) => isAdmin ? ['/messages', 'inbox'] : ['/messages', 'view'])
  );

  protected readonly messageCount$ = merge(of(null), interval(this.TIMER)).pipe(
    withLatestFrom(this.isAdmin$, this.filerInformation.current$),
    switchMap(([_, isAdmin, filer]) => this.getUnreadMessagesCount(isAdmin, filer?.filerId)),
    map((data) => (data)),
    startWith({ count: 0 })
  );

  protected readonly alertCount$ = merge(of(null), interval(this.TIMER)).pipe(
    switchMap(() => this.alertsDashboardService.getAlertsCount()),
    map((data) => (data)),
    startWith({ count: 0 })
  );

  private getUnreadMessagesCount(isAdmin: boolean, filerId: string): Observable<{count: number;}> {
    const id = isAdmin ? null : filerId;

    return this.adminNotificationService.getUnreadMessagesCount(id);
  }

  private checkForAdmin(value: PrincipalMenu): boolean {
    switch(value) {
      case 'LOBBYIST-ADMIN':
      case 'AGENT-ADMIN':
      case 'GRASSROOT-ADMIN':
      case 'LEGISLATIVE-DESIGNEE-ADMIN':
      case 'ADMIN':
        return true;
      default:
        return false;
    }
  }
}