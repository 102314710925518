import { NgModule } from "@angular/core";
import { TargetTimePipe } from "../pipes/denver-time.pipe";
import { PhonePipe } from "../pipes/format-phone.pipe"
import { FormattingPipe } from "../pipes/formatting.pipe";
import { CompensationTypePipe } from "../pipes/compensation-type.pipe";
import { PaymentTypePipe } from "../pipes/payment-type.pipe";
import { JoinPipe } from "../pipes/join.pipe";
import { FilerToRoutePipe } from "../pipes/filer-to-route.pipe";
import { ZipPipe } from "../pipes/format-zip.pipe";
import { MonthNamePipe } from "../pipes/month-name.pipe";
import { BusinessAddressPipe } from "../pipes/format-business-address.pipe";


@NgModule({
    declarations: [
        TargetTimePipe,
        PhonePipe,
        ZipPipe,
        FormattingPipe,
        CompensationTypePipe,
        PaymentTypePipe,
        JoinPipe,
        FilerToRoutePipe,
        MonthNamePipe,
        BusinessAddressPipe,
    ],
    exports: [
        TargetTimePipe,
        PhonePipe,
        ZipPipe,
        FormattingPipe,
        CompensationTypePipe,
        PaymentTypePipe,
        JoinPipe,
        FilerToRoutePipe,
        MonthNamePipe,
        BusinessAddressPipe,
    ]
})
export class PipesModule {}
