import {Injectable} from "@angular/core";
import {IDataGrid} from "./IDataGrid";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ClientService, MasterUrlService} from "../../../core";

export enum FieldTitle {
    title = 1,
    ldNumber = 0,
}

@Injectable()
export class DataGridService {

    constructor(
        private readonly clientService: ClientService,
        private readonly urlService: MasterUrlService,
    ) {
    }

    search<T>(text: string, field: string): Observable<T[]> {
        let value = 0;

        switch (field) {
            case "title":
                value = FieldTitle.title;
                break;
            case "ldNumber":
                value = FieldTitle.ldNumber;
                break;
        }

        return this.clientService.getData(this.urlService.getLegislative(value, text))
    }

    addData<T>(data: T, url?: string): Observable<T> {
        return this.clientService.postData(this.urlService.getActivityReport + url, data)
    }

    getData<T>(url: string): Observable<T[]> {
        return this.clientService.getData(this.urlService.getActivityReport + url)
            .pipe(
                map((data) => data as T[]),
            );
    }

    removeData<T>(id: string, url: string): Observable<T> {
        return this.clientService.delete(this.urlService.getActivityReport + url + "/" + id)
    }

    updateData<T>(data: T, url: string): Observable<T> {
        return this.clientService.putData(this.urlService.getActivityReport + url, data
        )
    }
}