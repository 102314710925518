import type { AppFeature, PublicAppFeature } from '@maplight/models';

export const FEATURE_MAP: Record<AppFeature, any[]> = {
    'access-request': ['/lobbyist', 'manage-access'],
    'app-messages': ['/messages', 'view'],
    'new-user-registration': ['/account', 'create'],
    'invitation': ['/invitations']
};

export const PUBLIC_FEATURE_CHECK: Record<PublicAppFeature, boolean> = {
    'new-user-registration': true,
    'invitation': true
};
