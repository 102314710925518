import {Pipe, PipeTransform} from "@angular/core";
import { CompensationTypeEnum } from "@maplight/models/enums/compensation-enum";

@Pipe({name: "compensationType",pure: true})
export class CompensationTypePipe implements PipeTransform {
  transform(value: CompensationTypeEnum | number): string {
    switch(value){
        case CompensationTypeEnum.FlatRate:
            return "Flat Rate";
        case CompensationTypeEnum.Hourly:
            return "Hourly";
        case CompensationTypeEnum.InHouse:
            return "In House";
        default:
            return "Other";
    }
  }
}