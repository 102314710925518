import { FilingTypeEnum } from "src/app/app.model";

export const filingTypeTranslationMap = {
    [FilingTypeEnum.MonthlyReport]: "monthlyReport",
    [FilingTypeEnum.ExpenditureReport]: "expenditureReport",
    [FilingTypeEnum.GrassrootsLobbyingReport]: "grassrootsLobbyingReport",
    [FilingTypeEnum.TerminationStatement]: "terminationStatement",
    [FilingTypeEnum.HarassmentPreventionCertificate]: "harassmentPreventionCertificate",
    [FilingTypeEnum.LegislativeDesigneeRegistration]: "legislativeDesigneeRegistration",
    [FilingTypeEnum.NonSessionWaiver]: "nonSessionWaiver",
    [FilingTypeEnum.ClientRegistration]: "clientRegistration",
} as const;