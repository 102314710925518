import { HelpService } from './../help.service';
import {
    ChangeDetectionStrategy,
    Component,
    ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
    SnackbarService,
    SharedService,
    MasterDataService,
} from 'src/app/core';
import {
    map,
    switchMap,
    tap,
    catchError,
    share,
    startWith,
} from 'rxjs/operators';
import { Subject, combineLatest, merge, of } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { Message, TextDataModel } from '@maplight/models';

@Component({
    selector: 'app-help',
    templateUrl: './help.component.html',
    styleUrls: ['./help.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpComponent {
    editing: boolean = false;

    private readonly contactRequest$ = new Subject<Message>();
    private readonly model$ = new Subject<TextDataModel>();

    constructor(
        private readonly fb: FormBuilder,
        private readonly snackbar: SnackbarService,
        private readonly help: HelpService,
        private readonly i18n: TranslocoService,
        private readonly shared: SharedService,
        private readonly formatting: MasterDataService,
    ) {}

    readonly contact = this.fb.group({
        message: this.fb.control<string>(null, [Validators.required]),
        subject: this.fb.control<string>(null, [Validators.required]),
        fullName: this.fb.control<string>(null, [Validators.required]),
        email: this.fb.control<string>(null, [
            Validators.required,
            Validators.pattern(this.formatting.emailValidations),
        ]),
    });

    readonly isAdmin = this.shared.isAdminUser();
    readonly textData$ = this.help.getTextData();

    private readonly error = {
        code: 500,
        message: 'Something went wrong, please try again later.',
    } as const;

    readonly loading$ = merge(
        merge(
            this.contactRequest$.pipe(map(() => true)),
            this.contactRequest$.pipe(
                switchMap((it) =>
                    this.help
                        .sendMessage(it)
                        .pipe(catchError(() => of(this.error))),
                ),
                tap((res) => this.handleMessageResponse(res)),
                map(() => false),
            ),
        ),
        merge(
            this.model$.pipe(map(() => true)),
            this.model$.pipe(
                switchMap((it) =>
                    this.help
                        .sendDataModel(it)
                        .pipe(catchError(() => of(null))),
                ),
                tap((res) => this.handleModelResponse(res)),
                map(() => false),
            ),
        ),
    ).pipe(startWith(false), share());

    readonly disableSend$ = combineLatest([
        this.loading$,
        this.contact.statusChanges.pipe(
            startWith(this.contact.status),
            map((status) => status !== 'VALID'),
        ),
    ]).pipe(map(([loading, invalid]) => loading || invalid));

    private handleMessageResponse([{ code, message }, ..._]: {
        code: number;
        message: string;
    }[]) {
        if (code === 200) {
            this.clear();
            this.snackbar.snackbarSuccess(message);
        } else {
            this.snackbar.snackbarError(message);
        }
    }

    private handleModelResponse(it: unknown) {
        if (it) {
            this.editPage();
            this.snackbar.snackbarSuccess(
                this.i18n.translate('snackbarUpdate', {
                    item: 'Templates',
                }),
            );
        } else {
            this.snackbar.snackbarError(
                this.i18n.translate('snackbarUpdateFailed', {
                    item: 'Templates',
                }),
            );
        }
    }

    editPage() {
        this.editing = !this.editing;
    }

    save(data: TextDataModel) {
        this.model$.next(data);
    }

    send() {
        if (this.contact.invalid) {
            this.contact.markAllAsTouched();
            this.contact.updateValueAndValidity();

            return null;
        }

        this.contactRequest$.next({
            content: `User's name: ${this.contact.value.fullName}<br />
            Email: ${this.contact.value.email}<br />
            Message: <br />${this.contact.value.message}`,
            subject: this.contact.value.subject,
        });
    }

    clear() {
        this.contact.reset();
    }
}
