import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { ILobbyistFirmAddress } from "../../../shared/models/app-lobbyist-business";
import { ILobbyistFirm } from "../../../shared/models/app-lobbyist";
import { ClientService, MasterUrlService } from "../../../core";
import { catchError, map, tap } from "rxjs/operators";
import { Lobbyist } from "../../Lobbyist/lobbyist.model";
import { AuthorizedAgent } from "@maplight/models";

@Injectable({
    providedIn: "root",
})
export class AuthorizedAgentService {

    constructor(
        private readonly clientService: ClientService,
        private readonly urlService: MasterUrlService
    ) {}

    getAuthorizedAgentById(authorizedAgentId: string): Observable<AuthorizedAgent> {
        return this.clientService.getData(`${this.urlService.getAuthorizedAgent}/${authorizedAgentId}`).pipe(
            catchError(_ => of(null))
        );
    }
}
