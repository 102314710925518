import { Injectable } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ClientService, MasterUrlService } from "src/app/core";
import { BillsAndIssues } from "../models/bills-and-issues.model";
import { EventOfficial } from "../models/event-official-model";
import { ExpenditureOnBehalf } from "../models/expenditure-on-behalf.model";
import { GrassrootLobbying } from "../models/grassroots-lobbying.model";
import { LegislativeDocument } from "../models/legislative-document.model";
import { OriginalSource } from "../models/original-source.model";
import { TotalCompensation } from "../models/total-compensation.model";
import { TotalExpenditure } from "../models/total-expenditure.model";
import { Filing } from "@maplight/models";
@Injectable()
export class ActivityReportService {

    private readonly activityReportUpdatesSubject$: Subject<void> = new Subject<void>();
    readonly activityReportUpdates$: Observable<void> = this.activityReportUpdatesSubject$.asObservable();

    constructor(
        private readonly clientService: ClientService,
        private readonly urlService: MasterUrlService,
    ) {}

    getLobbyistData(id: string) {
        return this.clientService
            .getData(this.urlService.getLobbyist + id)
            .pipe(catchError((_) => of(null)));
    }

    submitNoActivityReport(
        lobbyistId: string,
        clientRegistrationId: string,
        filingPeriodId: number,
    ) {
        return this.clientService.postData(this.urlService.submitNoActivityReport, 
            {
                lobbyistId: lobbyistId,
                clientRegistrationId: clientRegistrationId,
                filingPeriodId: filingPeriodId,
                hasActivity: false,
            }).pipe(
                catchError((_) => of(null))
            );
    }

    getFilingPeriodData(filingPeriodId: number) {
        return this.clientService
            .getData(this.urlService.getFilingPeriod + filingPeriodId)
            .pipe(catchError((_) => of(null)));
    }

    getMonthlyReportFilingInformation(filingId: string): Observable<Filing & {clientRegistrationId: string}> {
        return this.clientService
            .getData(
                this.urlService.getMonthlyReportFilingInformation + filingId,
            )
            .pipe(catchError((_) => of(null)));
    }

    downloadNoActivityReport(
        filename: string,
        filingId: number,
        noActivityChecked: boolean,
    ) {
        return this.clientService
            .downloadBlobAttachment(
                this.urlService.getNoActivityReportPDF(filingId, noActivityChecked),
                filename,
            )
            .pipe(catchError((_) => of(null)));
    }

    downloadPreviewActivityReport(filename: string, filingId: string) {
        return this.clientService.downloadBlobAttachment(this.urlService.getPreviewActivityReportPDF(filingId), filename)
            .pipe(catchError((_) => of(null)));
    }

    getTotalCompensation(filingId: string) {
        return this.clientService
            .getData(this.urlService.totalCompensation(filingId))
            .pipe(catchError((_) => of(null)));
    }

    getTotalExpenditure(filingId: string) {
        return this.clientService
            .getData(this.urlService.totalExpenditure(filingId))
            .pipe(catchError((_) => of(null)));
    }

    getTotalExpenditureBehalfFamilyMembers(filingId: string) {
        return this.clientService
            .getData(
                this.urlService.totalExpenditureBehalfFamilyMembers(filingId),
            )
            .pipe(catchError((_) => of(null)));
    }

    saveTotalCompensation(filingId: string, data: TotalCompensation): Observable<TotalCompensation> {
        return this.clientService.putData(this.urlService.totalCompensation(filingId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    saveTotalExpenditure(filingId: string, data: TotalExpenditure): Observable<TotalExpenditure> {
        return this.clientService.putData(this.urlService.totalExpenditure(filingId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    saveTotalExpenditureBehalfFamilyMembers(filingId: string, data: TotalExpenditure): Observable<TotalExpenditure> {
        return this.clientService.putData(this.urlService.totalExpenditureBehalfFamilyMembers(filingId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    submitMonthlyReport(filingId: string, lobbyistId: string) {
        return this.clientService.postData(this.urlService.submitMonthlyReport(filingId, lobbyistId), []).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    getBillAndIssues(monthlyReportId: string): Observable<BillsAndIssues[]> { 
        return this.clientService.getData(this.urlService.billAndIssues(monthlyReportId)).pipe(
            catchError(_ => of([]))
        );
    }

    addBillsAndIssues(monthlyReportId: string, data: Partial<BillsAndIssues>): Observable<BillsAndIssues> {
        return this.clientService.postData(this.urlService.billAndIssues(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    editBillsAndIssues(monthlyReportId: string, data: Partial<BillsAndIssues>): Observable<BillsAndIssues> {
        return this.clientService.putData(this.urlService.billAndIssues(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    removeBillsAndIssues(monthlyReportId: string, billsAndIssuesId: string): Observable<BillsAndIssues> {
        return this.clientService.delete(this.urlService.billAndIssues(monthlyReportId) + `/${billsAndIssuesId}`).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    getGrassrootLobbyings(monthlyReportId: string): Observable<GrassrootLobbying[]> { 
        return this.clientService.getData(this.urlService.grassrootsLobbying(monthlyReportId)).pipe(
            catchError(_ => of([]))
        );
    }

    addGrassrootLobbyings(monthlyReportId: string, data: Partial<GrassrootLobbying>): Observable<GrassrootLobbying> {
        return this.clientService.postData(this.urlService.grassrootsLobbying(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    editGrassrootLobbyings(monthlyReportId: string, data: Partial<GrassrootLobbying>): Observable<GrassrootLobbying> {
        return this.clientService.putData(this.urlService.grassrootsLobbying(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    removeGrassrootLobbyings(monthlyReportId: string, grassrootLobbyingId: string): Observable<GrassrootLobbying> {
        return this.clientService.delete(this.urlService.grassrootsLobbying(monthlyReportId) + `/${grassrootLobbyingId}`).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    getLegislativeDocuments() : Observable<LegislativeDocument[]> { 
        return this.clientService.getData(this.urlService.getLegislativeDocuments).pipe(
            catchError(_ => of([]))
        );
    }

    getEventOfficials(monthlyReportId: string): Observable<EventOfficial[]> { 
        return this.clientService.getData(this.urlService.eventOfficial(monthlyReportId)).pipe(
            catchError(_ => of([]))
        );
    }

    addEventOfficial(monthlyReportId: string, data: EventOfficial): Observable<EventOfficial> {
        return this.clientService.postData(this.urlService.eventOfficial(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    editEventOfficial(monthlyReportId: string, data: EventOfficial): Observable<EventOfficial> {
        return this.clientService.putData(this.urlService.eventOfficial(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    removeEventOfficial(monthlyReportId: string, eventOfficialId: string): Observable<EventOfficial> {
        return this.clientService.delete(this.urlService.eventOfficial(monthlyReportId) + `/${eventOfficialId}`).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    getExpenditureOnBehalfs(monthlyReportId: string): Observable<ExpenditureOnBehalf[]> { 
        return this.clientService.getData(this.urlService.expendituresOnBehalf(monthlyReportId)).pipe(
            catchError(_ => of([]))
        );
    }

    addExpendituresOnBehalf(monthlyReportId: string, data: ExpenditureOnBehalf): Observable<ExpenditureOnBehalf> {
        return this.clientService.postData(this.urlService.expendituresOnBehalf(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    editExpendituresOnBehalf(monthlyReportId: string, data: ExpenditureOnBehalf): Observable<ExpenditureOnBehalf> {
        return this.clientService.putData(this.urlService.expendituresOnBehalf(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    removeExpendituresOnBehalf(monthlyReportId: string, expendituresOnBehalfId: string): Observable<ExpenditureOnBehalf> {
        return this.clientService.delete(this.urlService.expendituresOnBehalf(monthlyReportId) + `/${expendituresOnBehalfId}`).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    getOriginalSources(monthlyReportId: string): Observable<OriginalSource[]> { 
        return this.clientService.getData(this.urlService.originalSource(monthlyReportId)).pipe(
            catchError(_ => of([]))
        );
    }

    addOriginalSource(monthlyReportId: string, data: OriginalSource): Observable<OriginalSource> {
        return this.clientService.postData(this.urlService.originalSource(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    editOriginalSource(monthlyReportId: string, data: OriginalSource): Observable<OriginalSource> {
        return this.clientService.putData(this.urlService.originalSource(monthlyReportId), data).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }

    removeOriginalSource(monthlyReportId: string, originalSourceId: string): Observable<OriginalSource> {
        return this.clientService.delete(this.urlService.originalSource(monthlyReportId) + `/${originalSourceId}`).pipe(
            tap(x => !!x && this.activityReportUpdatesSubject$.next()),
            catchError((_) => of(null))
        );
    }
}
