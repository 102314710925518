import {Injectable} from '@angular/core';
import * as Cognito from 'amazon-cognito-identity-js';
import {environment} from '../../../../environments/environment';
import {Observable, of, throwError} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

/**
 * Authentication service. It uses Cognito to manage authentication concerns. It means to replace the authorize.service.ts service.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    /**
     * @private
     * Private field that stores the CognitoUser object during the MFA flow. This is needed because the
     * CognitoUser that started the MFA flow needs to be used to finalize the MFA flow.
     *
     * This field will contain the CognitoUser object that started the MFA challenge during the MFA flow, and
     * it will be used in the sendMfaCode method to complete the MFA flow.
     * It is set to null when the MFA flow is completed successfully.
     *
     * Should not be used outside of this context.
     */
    private _mfaSessionUser: Cognito.CognitoUser | null = null;

    /**
     * @private
     * Gets the user pool. Not intended to be used outside of this service.
     *
     * Notice the function is being constructed with an IIFE (Immediately Invoked Function Expression).
     * This is done to ensure that the _userPool is accessible from the _getUserPool that will work as
     * some sort of CognitoUserPool factory.
     *
     * @returns The user pool.
     */
    private readonly _getUserPool = (() => {
        let _userPool: Cognito.CognitoUserPool | null = null;

        return (rebuildWithStorageType?: 'local' | 'session') => {
            if (!_userPool || rebuildWithStorageType) {
                _userPool = new Cognito.CognitoUserPool({
                    UserPoolId: environment.cognito.userPoolId, ClientId: environment.cognito.clientId,
                    Storage: rebuildWithStorageType === 'session' ? sessionStorage : localStorage
                });
            }
            return _userPool;
        };
    })();

    constructor() {
    }

    /**
     * Changes the password of the current user.
     *
     * @param params The change password parameters.
     * @returns An observable that completes when the password is changed.
     */
    public changePassword(params: ChangePasswordParams): Observable<void> {
        const user = this._getCurrentSession();

        // Internal function that changes the password of the user.
        const _changePassword = (user: Cognito.CognitoUser | null): Observable<void> => {
            if (user === null) {
                return throwError(new Error('No user is currently logged in.'));
            }

            return new Observable((observer) => {
                user.changePassword(params.oldPassword, params.newPassword, (err, _) => {
                    if (err) {
                        observer.error(err);
                        return;
                    }

                    observer.next();
                    observer.complete();
                });
            });
        };

        return user.pipe(switchMap(_changePassword));
    }

    /**
     * Completes the forgot password flow. It changes the user's password and requires a code sent to the user's
     * primary attribute (phone or email). Note that in this instance the user is not yet logged in.
     *
     * @param params The forgot password parameters.
     * @returns An observable that completes when the password is changed.
     */
    public completeForgotPasswordFlow(params: CompleteForgotPasswordParams): Observable<void> {
        const pool = this._getUserPool();

        const user = new Cognito.CognitoUser({
            Username: params.email, Pool: pool,
        });

        return new Observable((observer) => {
            user.confirmPassword(params.code, params.newPassword, {
                onSuccess: () => {
                    observer.next();
                    observer.complete();
                }, onFailure: (err) => {
                    observer.error(err);
                }
            });
        });
    }

    /**
     * Confirms the user registration with the code sent to the user's primary attribute.
     * The primary attribute is either the email or the phone number, and it is configured in the Cognito user pool.
     *
     * A user is not able to sign in (to effectively get an authentication token) until the account is confirmed.
     *
     * @param params The confirmation parameters.
     * @returns An observable that completes when the user is confirmed.
     */
    public confirmRegistration(params: ConfirmRegistrationParams): Observable<void> {
        const pool = this._getUserPool();

        const user = new Cognito.CognitoUser({
            Username: params.email, Pool: pool,
        });

        return new Observable((observer) => {
            user.confirmRegistration(params.code, true, (err, _) => {
                if (err) {
                    observer.error(err);
                    return;
                }

                observer.next();
                observer.complete();
            });
        });
    }

    /**
     * Gets the current user session.
     *
     * @returns An observable that emits the current user session or null if there is no user logged in.
     */
    public getCurrentSession(): Observable<IUserSession | null> {
        return this._getCurrentSession().pipe(
            map((user) => {
                    const session = user?.getSignInUserSession();

                    return session != null ? new UserSession(session) : null;
                }
            ));
    }

    /**
     *  Starts the forgot password flow. It sends a code to the user's primary attribute.
     *  The primary attribute is either the email or the phone number, and it is configured in the Cognito user pool.
     *
     *  @param email The user's email.
     *  @returns An observable that emits a string that represents the code delivery information.
     */
    public initForgotPasswordFlow(email: string): Observable<string> {
        const pool = this._getUserPool();

        const user = new Cognito.CognitoUser({
            Username: email, Pool: pool,
        });

        return new Observable((observer) => {
            user.forgotPassword({
                onSuccess: (result) => {
                    observer.next(result + '');
                    observer.complete();
                }, onFailure: (err) => {
                    observer.error(err);
                }, inputVerificationCode: null
            });
        });
    }

    /**
     * Refreshes the current user's authentication token.
     *
     * @returns An observable that emits the current user session or null if there is no user logged in.
     */
    public refreshToken(): Observable<IUserSession | null> {
        const user = this._getCurrentSession();

        // Internal function that refreshes the user's authentication token.
        const _refreshToken = (user: Cognito.CognitoUser | null): Observable<IUserSession | null> => {
            return new Observable((observer) => {
                if (user === null) {
                    observer.next(null);
                    observer.complete();
                    return;
                }

                user.refreshSession(user.getSignInUserSession().getRefreshToken(), (err, newSession) => {
                    if (err) {
                        observer.error(err);
                        return;
                    }

                    observer.next(new UserSession(newSession));
                    observer.complete();
                });
            });
        };

        return user.pipe(switchMap(_refreshToken));
    }

    /**
     * Sends back to Cognito the MFA code that was sent to the user. Will return a new user session if the MFA code is valid.
     * Should only be called after receiving the MFA required challenge after a sign in attempt.
     *
     * @param code The MFA code.
     * @returns An observable that emits the user session if the MFA code is valid.
     */
    public sendMfaCode(code: string): Observable<IUserSession> {
        if (this._mfaSessionUser === null) {
            return throwError(new Error('No MFA session is currently active.'));
        }

        return new Observable((observer) => {
            this._mfaSessionUser.sendMFACode(code, {
                onSuccess: (result) => {
                    // mfa flow completed, clear the temporal variable
                    this._mfaSessionUser = null;
                    observer.next(new UserSession(result));
                    observer.complete();
                }, onFailure: (err) => {
                    observer.error(err);
                }
            });
        });
    }

    /**
     * Signs in the user with email and password.
     *
     * @param params The sign in parameters.
     * @returns An observable that emits the sign in result. The result can be either a success or an MFA required challenge.
     * If success the SignInResult contains the user session. If MFA required the SignInResult contains the challenge name.
     */
    public signIn(params: SignInParams): Observable<SignInResult> {
        // ensure the user is signed out before signing in again
        this.signOut();

        const pool = this._getUserPool(params.rememberMe ? 'local' : 'session');

        const user = new Cognito.CognitoUser({
            Username: params.email, Pool: pool,
            Storage: params.rememberMe ? localStorage : sessionStorage
        });

        const authenticationDetails = new Cognito.AuthenticationDetails({
            Username: params.email, Password: params.password
        });

        return new Observable((observer) => {
            user.authenticateUser(authenticationDetails, {
                onSuccess: (result) => {
                    observer.next({state: 'success', userSession: new UserSession(result)});
                    observer.complete();
                }, onFailure: (err) => {
                    observer.error(err);
                }, mfaRequired: (challengeName) => {
                    // mfa flow started, the cognito user that started the flow needs to be the same that finishes it by calling sendMfaCode
                    // so, we store it in a temporal variable
                    this._mfaSessionUser = user;
                    observer.next({state: 'mfaRequired', challengeName});
                    observer.complete();
                }
                // newPasswordRequired: (userAttributes, requiredAttributes) => { }//
            });
        });
    }

    /**
     * Signs out the current user. Does nothing if there is no current user.
     */
    public signOut(): void {
        const user = this._getCurrentUser();

        if (user) {
            user.signOut();
        }
    }

    /**
     * Signs up the user with email and password.
     *
     * @param params The sign up parameters.
     * @returns An observable that completes when the user is signed up.
     */
    public signUp(params: SignUpParams): Observable<void> {
        const pool = this._getUserPool();

        const attributeList = [new Cognito.CognitoUserAttribute({Name: 'phone_number', Value: params.phoneNumber})];

        return new Observable((observer) => {
            pool.signUp(params.email, params.password, attributeList, null, (err, _) => {
                if (err) {
                    observer.error(err);
                    return;
                }

                observer.next();
                observer.complete();
            });
        });
    }

    /**
     * Resends the confirmation code to the user's primary attribute, either the email or the phone number.
     *
     * @param email The user's email.
     * @returns An observable that completes when the code is sent, and returns a string that represents the code delivery information.
     */
    public resendConfirmationCode(email: string): Observable<string> {
        const pool = this._getUserPool();

        const user = new Cognito.CognitoUser({
            Username: email, Pool: pool,
        });

        return new Observable((observer) => {
            user.resendConfirmationCode((err, result) => {
                if (err) {
                    observer.error(err);
                    return;
                }

                observer.next(result);
                observer.complete();
            });
        });
    }

    /**
     * Update user attributes for the current authenticated user.
     *
     * @param attribute The attribute to update.
     * @returns An observable that completes when the attribute is updated.
     */
    updateAttribute(attribute: UserAttribute): Observable<void> {
        const user = this._getCurrentSession();

        // Internal function that updates the user's attribute defined by the attribute parameter.
        const _updateAttribute = (user: Cognito.CognitoUser | null): Observable<void> => {
            if (user === null) {
                return throwError(new Error('No user is currently logged in.'));
            }

            let attributeList = [new Cognito.CognitoUserAttribute({Name: attribute.name, Value: attribute.value})];

            return new Observable((observer) => {
                user.updateAttributes(attributeList, (err, _) => {
                    if (err) {
                        observer.error(err);
                        return;
                    }

                    observer.next();
                    observer.complete();
                });
            });
        };

        return user.pipe(switchMap(_updateAttribute));
    }

    /**
     * Verifies an attribute for the current authenticated user.
     *
     * @param params The parameters for the verification.
     * @returns An observable that completes when the attribute is verified.
     */
    verifyAttribute(params: VerifyAttributeParams): Observable<void> {
        const user = this._getCurrentSession();

        const _verifyAttribute = (user: Cognito.CognitoUser | null): Observable<void> => {
            if (user === null) {
                return throwError(new Error('No user is currently logged in.'));
            }

            return new Observable((observer) => {
                user.verifyAttribute(params.attributeName, params.code, {
                    onSuccess: () => {
                        observer.next();
                        observer.complete();
                    }, onFailure: (err) => {
                        observer.error(err);
                    }
                });
            });
        };

        return user.pipe(switchMap(_verifyAttribute));
    }

    /**
     * @private
     * Gets the active user session of the current CognitoUser and returns the user. This allows to call CognitoUser.getSignInUserSession()
     * and to perform authenticated operations on the user.
     *
     * Not intended to be used outside of this service.
     *
     * @returns An observable that emits the current user session or null if there is no user logged in.
     */
    private _getCurrentSession(): Observable<Cognito.CognitoUser | null> {
        const user = this._getCurrentUser();

        if (user === null) {
            return of(null);
        }

        return new Observable((observer) => {
            user.getSession((err, _) => {
                if (err !== null) {
                    observer.error(err);
                    return;
                }

                observer.next(user);
                observer.complete();
            });
        });
    }

    /**
     * @private
     * Gets the current user as a CognitoUser object from the local storage. Note that if the user is not null does not mean that it is
     * logged in. Must call getSession before using the user to perform authenticated operations.
     *
     * Not intended to be used outside of this service.
     *
     * @returns The current user or null if there is no user logged in.
     */
    private _getCurrentUser(): Cognito.CognitoUser | null {
        return this._getUserPool().getCurrentUser();
    }
}

/**
 * Parameters to sign in a user with email and password.
 */
export interface SignInParams {
    email: string;
    password: string;
    rememberMe: boolean;
}

/**
 * Parameters to sign up a user with email, password and phone number.
 */
export interface SignUpParams {
    email: string;
    password: string;
    phoneNumber: string;
}

/**
 * Confirmation parameters.
 */
export interface ConfirmRegistrationParams {
    code: string;
    email: string;
}

/**
 * Change password parameters.
 */
export interface ChangePasswordParams {
    newPassword: string;
    oldPassword: string;
}

/**
 * Forgot password parameters.
 */
export interface CompleteForgotPasswordParams {
    code: string;
    email: string;
    newPassword: string;
}

/**
 * The success result of the sign in operation.
 */
export type SignInSuccess = {
    state: 'success';
    userSession: IUserSession;
}

/**
 * The MFA required challenge result of the sign in operation.
 */
export type SignInMfaRequired = {
    state: 'mfaRequired';
    challengeName: Cognito.ChallengeName;
}

/**
 * Represents the result of the sign in operation.
 *
 * @property {'success' | 'mfaRequired'} state - The state of the sign-in operation. Can be 'success' or 'mfaRequired'.
 *
 * @property {IUserSession} [userSession] - The user session if the sign-in was successful.
 *
 * @property {Cognito.ChallengeName} [challengeName] - The name of the MFA challenge if MFA is required.
 *
 *  @example
 *  // Example usage:
 *  const signInResult: SignInResult = ...
 *  switch (signInResult.state) {
 *    case 'success':
 *      console.log('Sign-in successful:', signInResult.userSession);
 *      break;
 *    case 'mfaRequired':
 *      console.log('MFA required:', signInResult.challengeName);
 *      break;
 *  }
 */
export type SignInResult = SignInSuccess | SignInMfaRequired;

/**
 * Represents a cognito user attribute. For this application, we only use as name the email and phone number.
 */
export type UserAttribute = {
    name: UserAttributeName;
    value: string;
}

/**
 * Represents the name of a cognito user attribute. For this application, we only use the email and phone number.
 */
export type UserAttributeName = 'email' | 'phone_number';

/**
 * Facade for the Cognito user session.
 */
export interface IUserSession {
    readonly accessToken: string;
    readonly email: string;
    readonly emailVerified: boolean;
    readonly idToken: string;
    readonly isValid: boolean;
    readonly phoneNumber: string;
    readonly phoneNumberVerified: boolean;
}

/**
 * Internal implementation of IUserSession.
 */
class UserSession implements IUserSession {
    readonly accessToken: string;
    readonly email: string;
    readonly emailVerified: boolean;
    readonly idToken: string;
    readonly isValid: boolean;
    readonly phoneNumber: string;
    readonly phoneNumberVerified: boolean;

    constructor(session: Cognito.CognitoUserSession) {
        this.accessToken = session.getAccessToken().getJwtToken();
        this.idToken = session.getIdToken().getJwtToken();
        this.isValid = session.isValid();

        const idTokenPayload = session.getIdToken().decodePayload() as {
            email: string;
            email_verified: boolean;
            phone_number: string;
            phone_number_verified: boolean;
        };

        this.email = idTokenPayload.email;
        this.emailVerified = idTokenPayload.email_verified;
        this.phoneNumber = idTokenPayload.phone_number;
        this.phoneNumberVerified = idTokenPayload.phone_number_verified;
    }
}

/**
 * Parameters to verify an attribute.
 */
export interface VerifyAttributeParams {
    attributeName: UserAttributeName;
    code: string;
}
