import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HelpsRoutingModule } from "./helps-routing.module";
import { HelpComponent } from "./help/help.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SharedModule } from "src/app/shared/modules/shared.module";
import { FaqComponentComponent } from "./faq-component/faq-component.component";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { ComingSoonComponent } from "./coming-soon/coming-soon.component";
import { TranslocoModule } from "@ngneat/transloco";
import { ConcurrentWorkComponent } from "./concurrent-work/concurrent-work.component";
import { HelpService } from "./help.service";
import { EditHelpComponent } from './edit-help/edit-help.component';
@NgModule({
    declarations: [HelpComponent, FaqComponentComponent, ComingSoonComponent, ConcurrentWorkComponent, EditHelpComponent],
    imports: [
        CommonModule,
        HelpsRoutingModule,
        RouterModule,
        FormsModule,
        ButtonsModule,
        ReactiveFormsModule,
        SharedModule,
        TranslocoModule,
    ],
    providers: [
        HelpService
    ],
})
export class HelpsModule {}