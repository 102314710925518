// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
//import packageInfo from '../../package.json';

export const environment = {
    production: false,
    environment: "dev",
    version: "1.0.0", //TODO: Change it later
    snackBarDuration: 3000,
    baseUrl: "https://api-dev.maine-dev.maplight.link/",
    fillinUrl: "https://api-dev.maine-dev.maplight.link/",
    cognito: {
        region: "us-west-2",
        userPoolId: "us-west-2_EhkzR01GK",
        clientId: "f4netanfsp9oa9amkcf5hr6ja",
        host: "cfs-demo",
    },
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
