import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EditEvent, GridComponent, RemoveEvent } from '@progress/kendo-angular-grid';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import { filter, map, mapTo, scan, share, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { UpdateType } from 'src/app/modules/System-Management/models/update-type.model';
import { OriginalSource } from '../../models/original-source.model';
import { ActivityReportService } from '../../services/activity-report.service';

type OriginalSourceUpdate = OriginalSource & { type: UpdateType };

@Component({
    selector: 'app-original-sources-grid',
    templateUrl: './original-sources-grid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class OriginalSourcesComponent implements OnInit {
    @Output() modifiedTableEvent = new EventEmitter<undefined>();

    readonly amountLimit = 1000;

    deletingItemId: string = undefined;
    editingRowIndex: number = undefined;
    form: FormGroup = this.fb.group({
        id: this.fb.control<string>(null),
        name: this.fb.control<string>(null, [Validators.required]),
        amountReceived: this.fb.control<number>(null, [Validators.min(this.amountLimit)]),
    });

    originalSources$: Observable<OriginalSource[]>;

    private readonly addOriginalSourceSubject$: Subject<OriginalSource> = new Subject<OriginalSource>();
    private readonly editOriginalSourceSubject$: Subject<OriginalSource> = new Subject<OriginalSource>();
    private readonly removeOriginalSourceSubject$: Subject<string> = new Subject<string>();

    loading$: Observable<boolean>;

    constructor(
        private readonly activityReportService: ActivityReportService,
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        const monthlyReportId = this.route.snapshot.params.filingId;

        const apiOriginalSources$ = this.activityReportService.getOriginalSources(monthlyReportId)
            .pipe(shareReplay(1));

        const loadingOriginalSourcesFromAPI$ = apiOriginalSources$.pipe(
            mapTo(false), 
            startWith(true)
        );

        const addOriginalSources$ = this.addOriginalSourceSubject$.pipe(
            switchMap(x => this.activityReportService.addOriginalSource(monthlyReportId, x)),
            tap(() => this.modifiedTableEvent.emit()),
            share()
        );

        const addingOriginalSources$ = merge(
            this.addOriginalSourceSubject$.pipe(mapTo(true)),
            addOriginalSources$.pipe(mapTo(false))
        ).pipe(startWith(false));

        const editOriginalSources$ = this.editOriginalSourceSubject$.pipe(
            switchMap(x => this.activityReportService.editOriginalSource(monthlyReportId, x)),
            tap(() => this.modifiedTableEvent.emit()),
            share()
        );

        const editingOriginalSources$ = merge(
            this.editOriginalSourceSubject$.pipe(mapTo(true)),
            editOriginalSources$.pipe(mapTo(false))
        ).pipe(startWith(false));

        const removeOriginalSources$ = this.removeOriginalSourceSubject$.pipe(
            switchMap(x => this.activityReportService.removeOriginalSource(monthlyReportId, x)),
            tap(() => this.modifiedTableEvent.emit()),
            share()
        );

        const removingOriginalSources$ = merge(
            this.removeOriginalSourceSubject$.pipe(mapTo(true)),
            removeOriginalSources$.pipe(mapTo(false))
        ).pipe(startWith(false));

        this.originalSources$ = merge(
            apiOriginalSources$,
            addOriginalSources$.pipe(filter(x => !!x), map(x => ({...x, type: UpdateType.Add}))),
            editOriginalSources$.pipe(filter(x => !!x), map(x => ({...x, type: UpdateType.Edit}))),
            removeOriginalSources$.pipe(filter(x => !!x), map(x => ({...x, type: UpdateType.Remove}))),
        ).pipe(
            scan((previous: OriginalSource[], value: OriginalSource[] | OriginalSourceUpdate) => 
                {
                    if (value instanceof Array) return value as OriginalSource[];

                    const v = value as OriginalSourceUpdate;
                    switch (v.type)
                    {
                        case UpdateType.Add: 
                            previous.push(value);
                            break;
                        case UpdateType.Edit:
                            previous = previous.map(x => x.id != v.id ? x : (v as OriginalSource));
                            break;
                        case UpdateType.Remove:
                            previous = previous.filter(x => x.id != v.id);
                            break;
                    }
                    return previous;
                },
                []
            ),
            map(x => x.map(y => ({ 
                ...y, 
                amountReceived: y.amountReceived >= this.amountLimit ? y.amountReceived : null 
            })))
        );

        this.loading$ = combineLatest([
            loadingOriginalSourcesFromAPI$,
            addingOriginalSources$,
            editingOriginalSources$,
            removingOriginalSources$
        ]).pipe(map(x => x.some(y => y)));
    }

    public addHandler(args: GridComponent): void {
        this.closeEditor(args);
        this.deletingItemId = undefined;
        this.editingRowIndex = -1;
        args.addRow(this.form);
    }

    public editHandler(args: EditEvent): void {
        this.closeEditor(args.sender);
        this.form.patchValue(args.dataItem);
        this.deletingItemId = undefined;
        this.editingRowIndex = args.rowIndex;
        args.sender.editRow(args.rowIndex, this.form);
    }

    public cancelHandler(args: GridComponent): void { this.closeEditor(args, this.editingRowIndex); }

    public saveHandler(sender: GridComponent): void {
        if (this.form.invalid) return;

        let object = {
            ...this.form.value,
            amountReceived: this.form.value.amountReceived ?? 0
        };
        const isNew = this.editingRowIndex === -1;

        (isNew ? this.addOriginalSourceSubject$ : this.editOriginalSourceSubject$).next(object);

        this.form.reset();
        sender.closeRow(this.editingRowIndex);
        this.editingRowIndex = undefined;
        this.deletingItemId = undefined;
    }

    public removeHandler(args: RemoveEvent): void { 
        if (this.editingRowIndex !== undefined) args.sender.closeRow(this.editingRowIndex);
        this.editingRowIndex = undefined;
        this.deletingItemId = args.dataItem.id; 
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editingRowIndex) {
        grid.closeRow(rowIndex);
        this.form.reset();
        this.editingRowIndex = undefined;
        this.deletingItemId = undefined;
    }

    deleteHandler() { 
        this.removeOriginalSourceSubject$.next(this.deletingItemId); 
        this.deletingItemId = undefined;
    }
}
