import { Component, OnInit } from "@angular/core";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { startWith, tap } from "rxjs/operators";
import { AccountService } from "../../services/account.service";

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
@Component({
    selector: "app-logout",
    templateUrl: "./logout.component.html",
    styleUrls: ["./logout.component.css"],
})
export class LogoutComponent implements OnInit {
    processing$: Observable<boolean>;

    constructor(
        private readonly accountService: AccountService,
        private readonly router: Router,
    ) {}

    ngOnInit(): void {
        this.processing$ = of(false).pipe(
            tap(_ => {
                this.accountService.logoutUser();
                this.router.navigateByUrl('/public');
            }),
            startWith(true)
        );
    }
}
