import { debounceTime } from 'rxjs/operators';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { ErrorMessageService } from "../../../../configs/error-message";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { StateListResponseApiModel } from "../../../../shared/models";
import { ClientService, MasterDataService, MasterUrlService } from "../../../../core";
import { ILobbyistFirm } from "../../../../shared/models/app-lobbyist";
import { LobbyistService } from '../../services/lobbyist.service';

@Component({
    selector: "app-create-account-firm-form",
    templateUrl: "./create-account-firm-form.component.html",
    styleUrls: ["./create-account-firm-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountFirmFormComponent implements OnInit {
    _firmToEdit: ILobbyistFirm | null;
    _currentFirmToEdit: ILobbyistFirm;
    isEditingSameFirm = true;

    get firmToEdit() {
        return this._firmToEdit;
    }

    @Input()
    set firmToEdit(val: ILobbyistFirm) {
        if (!val) {
            // in delete option
            this._firmToEdit = null;
            this._currentFirmToEdit = null;
            this.addNewFirmForm.reset();
            return;
        } else {
            this.isEditingSameFirm = JSON.stringify(val) == JSON.stringify(this._currentFirmToEdit);
            this._currentFirmToEdit = val;
            this.addNewFirmForm.setValue(val);
            this._firmToEdit = val;
        }
    }

    @Output()
    newFirm: EventEmitter<ILobbyistFirm> = new EventEmitter<ILobbyistFirm>();
    @Output()
    cancelEdit: EventEmitter<void> = new EventEmitter<void>();

    addNewFirmForm: FormGroup;
    states$: Observable<StateListResponseApiModel>;
    firmSelected: ILobbyistFirm;
    oldFirm: ILobbyistFirm;

    update$: Observable<boolean> = new Observable<boolean>();

    constructor(
        public errorService: ErrorMessageService,
        private readonly formBuilder: FormBuilder,
        private service: ClientService,
        private urlService: MasterUrlService,
        private cdr: ChangeDetectorRef,
        private formatting: MasterDataService,
        private lobbyistService: LobbyistService
    ) {
        this.addNewFirmForm = this.formBuilder.group({
            firmName: [null, [Validators.required]],
            address1: [null, [Validators.required]],
            address2: [null],
            city: [null, [Validators.required, Validators.pattern(this.formatting.cityValidation)]],
            stateCode: [null, [Validators.required]],
            zip: [null, [Validators.required, Validators.pattern(this.formatting.zipcodePattern) ]],
            firmWebSite: [null, [
                Validators.pattern(this.formatting.websiteRegex),
            ]],
            firmPhoneNumber: [null, [Validators.required, Validators.pattern(this.formatting.phoneNumberHyphenValidation)]],
        });
    }

    ngOnInit(): void {
        //fill firms or initialize empty

        this.states$ = this.getStates();

        this.onChanges();
    }

    onChanges(): void {
        this.addNewFirmForm.valueChanges
            .pipe(debounceTime(200))
            .subscribe(val => {
                this.isEditingSameFirm = JSON.stringify(val) == JSON.stringify(this._currentFirmToEdit);
                this.cdr.detectChanges();
        });
      }

    submit() {
        if (this.addNewFirmForm.valid) {
            this.newFirm.emit(this.addNewFirmForm.value);
            this.addNewFirmForm.reset();
        } else {
            this.addNewFirmForm.markAllAsTouched();
            this.addNewFirmForm.updateValueAndValidity();
        }
    }

    getStates = () => this.service.getData(this.urlService.getStatesList);

    /*
     * delete Firm selected
     * and enable form again
     * */
    deletedFirm() {
        this.firmSelected = null;

        //remove all values from form
        this.addNewFirmForm.reset();
    }

    cancel() {
        this.cancelEdit.emit();
        this.addNewFirmForm.reset();
    }
}
