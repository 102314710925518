import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { TextDataModel } from "@maplight/models";

@Component({
  selector: "app-edit-help",
  templateUrl: "./edit-help.component.html",
  styleUrls: ["./edit-help.component.scss"]
})
export class EditHelpComponent {
  @Input() set textData(value: TextDataModel) {
    if (!value) return;

    this.textForm.reset({
      faqs: value.faqs,
      lawsAndRules: value.lawsAndRules,
      userManual: value.userManual
    });
  }
  @Input() disabled: boolean;
  @Output() readonly back = new EventEmitter();
  @Output() save = new EventEmitter<TextDataModel>();

  saveData() {
    this.save.emit({
      faqs: this.textForm.value.faqs,
      lawsAndRules: this.textForm.value.lawsAndRules,
      userManual: this.textForm.value.userManual
    } as TextDataModel);
  }

  constructor(private readonly fb: FormBuilder) {}

  readonly textForm = this.fb.group({
    faqs: [""],
    lawsAndRules: [""],
    userManual: [""]
  });
}