import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { UserData } from '../models/user-details.model';
import { catchError, skip, startWith } from 'rxjs/operators';
import { ClientService, MasterUrlService } from '../../../core';
import { ILegislative } from '../../../shared/models/legislative';
import { ILobbyistData } from '../models/ILobbyistData';

@Injectable()
export class CreateAccountDataService {
    // value to send to create user and lobyist
    value: UserData = {};

    //handle lobbyist information
    private _lobbyistOption = new BehaviorSubject<number>(null);
    lobbyistOption$ = this._lobbyistOption.asObservable();

    private _userData = new BehaviorSubject<any>(undefined);
    userData$ = this._userData.asObservable().pipe(skip(1));

    _success$ = new Subject<boolean>();
    success$ = this._success$.asObservable().pipe(startWith(false));

    _back$ = new BehaviorSubject<string>('SaveUserForm');
    back$ = this._back$.asObservable();

    token: string = '';

    constructor(
        private urlService: MasterUrlService,
        private service: ClientService,
    ) {}

    get userData(): Observable<any> {
        return this.userData$;
    }

    get lobbyistOption(): number {
        return this._lobbyistOption.value;
    }

    set lobbyistOption(value: number) {
        this._lobbyistOption.next(value);
    }

    success(value: boolean) {
        this._success$.next(value);
    }

    sendLobbyist(userData: UserData) {}

    saveData(userData: UserData) {
        this.value = userData;
        this._userData.next(userData);
    }

    getUserdata() {
        return this.service
            .getData(this.urlService.user)
            .pipe(catchError((err) => of(null)));
    }

    createFirmAddressUser(value: ILobbyistData) {
        return this.service.postData(this.urlService.addNewLobbyistUser, value);
    }

    createAuthorizedAgentUser() {
        return timer(5000);
    }

    createLegislative(value: ILegislative) {
        return timer(5000);
    }

    saveUser = (token?: string) => {
        const data = {
            firstName: this.value.name,
            lastName: this.value.lastName,
            phone: this.value.phone,
            token,
        };
        return this.service.postData(this.urlService.user, data);
    };

    back(chooseUserType: string) {
        this._back$.next(chooseUserType);
    }

    createGrassroots(data: any) {
        return timer(5000);
    }

    reset() {
        this.value = {};
    }
}
