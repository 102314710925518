import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import { map, take, tap } from 'rxjs/operators';
import { MasterDataService } from '../../configs/master-data';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SnackbarService } from '../snackbar/snackbar.service';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class ClientService {
    private baseUrl = environment.baseUrl;
    public NPIDataUrl: string;
    public manageForm: UntypedFormGroup;

    constructor(
        private http: HttpClient,
        private formatting: MasterDataService,
        private snackbarService: SnackbarService,
    ) {
        this.NPIDataUrl = '';
    }

    public formatQueryParams(data: Record<string, any>): string {
        let params = new URLSearchParams();
        if (!!data) {
            Object.entries(data)
                .filter(([_, value]) => !!value)
                .forEach(([key, value]) => {
                    if (value instanceof Array) value.forEach(x => params.append(key, x));
                    else params.append(key, value.toString())
                });
        }
        const queryParams = params.toString();
        return queryParams.length > 0 ? `?${queryParams}` : '';
    }

    getData(url: string): Observable<any> {
        this.formatting.isLoader = true;

        return this.http.get(this.baseUrl + url).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;

                if (typeof response !== 'object') return response;

                if (!response) return [];
                
                if (response.hasError && response.errorCode == 401) {
                    this.snackbarService.snackbarError(
                        response.message
                            ? response.message
                            : 'Unauthorized access.',
                    );

                    return;
                }

                return response;
            }),
        );
    }

    getWithData(url: any, data: any): Observable<any> {
        this.formatting.isLoader = true;

        const queryParams = this.formatQueryParams(data);

        return this.http.get(this.baseUrl + url + queryParams).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    if (response.hasError && response.errorCode == 401) {
                        this.snackbarService.snackbarError(
                            response.message
                                ? response.message
                                : 'Unauthorized access.',
                        );

                        return;
                    }
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    getWithDataBody(url: any, data: any): Observable<any> {
        this.formatting.isLoader = true;
        let params = new HttpParams().set(
            'requestData',
            encodeURIComponent(JSON.parse(data)),
        );
        return this.http.get(this.baseUrl + url, { params: params }).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    if (response.hasError && response.errorCode == 401) {
                        this.snackbarService.snackbarError(
                            response.message
                                ? response.message
                                : 'Unauthorized access.',
                        );

                        return;
                    }
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    postData(
        url: any,
        data: { [x: string]: any; OrderID?: any },
    ): Observable<any> {
        this.formatting.isLoader = true;
        return this.http.post(this.baseUrl + url, data).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    if (response.hasError && response.errorCode == 401) {
                        this.snackbarService.snackbarError(
                            response.message
                                ? response.message
                                : 'Unauthorized access.',
                        );

                        return;
                    }
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    postDataParams(url: any, data: any): Observable<any> {
        this.formatting.isLoader = true;
        return this.http.post(this.baseUrl + url, data).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    if (response.hasError && response.errorCode == 401) {
                        this.snackbarService.snackbarError(
                            response.message
                                ? response.message
                                : 'Unauthorized access.',
                        );

                        return;
                    }
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    postFormData(
        url: any,
        data: {
            DocumentID: string | Blob;
            DocumentName: string | Blob;
            PageRange: string | Blob;
        },
    ): Observable<any> {
        this.formatting.isLoader = true;
        const splitReq = new FormData();
        splitReq.append('DocumentID', data.DocumentID);
        splitReq.append('DocumentName', data.DocumentName);
        splitReq.append('Directory', 'SourceFile');
        splitReq.append('PageRange', data.PageRange);
        return this.http.post(this.baseUrl + url, splitReq).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    postForm(url: any, data: FormData): Observable<any> {
        this.formatting.isLoader = true;

        return this.http.post(this.baseUrl + url, data).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    postDocument(url: any, data: any): Observable<any> {
        this.formatting.isLoader = true;
        return this.http.post(this.baseUrl + url, data).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    putData(url: any, data: any): Observable<any> {
        this.formatting.isLoader = true;
        return this.http.put(this.baseUrl + url, data).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    patchData<T = any, R = any>(url: string, data?: T): Observable<R[]> {
        return this.http
            .patch(this.baseUrl + url, data)
            .pipe(
                map((res: R | Array<R>) =>
                    typeof res === 'object' && 'length' in res ? res : [res],
                ),
            );
    }

    delete(url: any): Observable<any> {
        this.formatting.isLoader = true;
        return this.http.delete(this.baseUrl + url).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    deleteWithData(
        url: any,
        data: {
            headers?:
                | HttpHeaders
                | { [header: string]: string | string[] }
                | undefined;
            observe?: 'body' | undefined;
            params?:
                | HttpParams
                | { [param: string]: string | string[] }
                | undefined;
            reportProgress?: boolean | undefined;
            responseType: 'arraybuffer';
            withCredentials?: boolean | undefined;
        },
    ): Observable<any> {
        this.formatting.isLoader = true;
        return this.http.delete(this.baseUrl + url, data).pipe(
            map((response: any) => {
                this.formatting.isLoader = false;
                if (response) {
                    return response;
                } else {
                    return [];
                }
            }),
        );
    }

    displayPDF(url: string, data: {}) {
        return this.http.post(this.baseUrl + url, data, {
            responseType: 'blob',
        });
    }

    downloadExcel(url: string) {
        return this.http.get(this.baseUrl + url, { responseType: 'blob' });
    }

    getBlobAttachment(url: string, fileName: string = null) {
        return this.http
            .get(this.baseUrl + url, {
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(
                map((res: HttpResponse<Blob>) => {
                    if (fileName == null) {
                        let disposition = res.headers.get(
                            'content-disposition',
                        );
                        fileName = this.getFileName(disposition);
                        if (fileName == null) fileName = 'attachment';
                    }
                    var url = window.URL.createObjectURL(res.body);
                    return {
                        url: url,
                        fileName: fileName,
                        mimeType: res.body.type,
                    };
                }),
                take(1),
            );
    }

    downloadBlobAttachment(url: string, fileName: string = null, params: Record<string, any> = {}) {
        return this.getBlobAttachment(url + this.formatQueryParams(params), fileName).pipe(
            tap((res) => {
                if (res != null) {
                    const nav = window.navigator as any;
                    if (nav.msSaveOrOpenBlob) {
                        nav.msSaveOrOpenBlob(res.url, fileName);
                    } else {
                        var a = document.createElement('a');
                        a.href = res.url;
                        a.download = res.fileName;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                    }
                    window.URL.revokeObjectURL(url);
                }
            }),
            take(1),
        );
    }

    getFileName(contentDispositionHeader: string) {
        var filename = null;
        if (
            contentDispositionHeader &&
            contentDispositionHeader.indexOf('attachment') !== -1
        ) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDispositionHeader);
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '');
            }
        }
        return filename;
    }

    setManageForm(manageForm: UntypedFormGroup) {
        this.manageForm = manageForm;
    }
    // Please Use this type of form data or Json creation inside the particular file itself.
    // We should not pass this as a seperate params. Its should be single param as json format.

    showBlobAttachment(url: string, fileName: string = null) {
        return this.getBlobAttachment(url, fileName).pipe(
            tap((res) => {
                if (res != null) {
                    // Open the blob URL in a new tab
                    window.open(res.url, '_blank');
                    // Revoke the blob URL after opening the new tab
                    URL.revokeObjectURL(res.url);
                }
            }),
            take(1),
        );
    }
}
