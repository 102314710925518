import {
    BaseTableInformation,
    TableAction,
} from 'src/app/shared/models/table-layout.model';
import { FilerTypeEnumMaine as Filers } from 'src/app/app.model';

export const ADMIN_DASHBOARD_VIEW_FILING_BUTTON: TableAction = {
    text: 'View Filing',
    type: 'solid',
    tooltip: 'View Filing',
    returnAction: 'view-filing',
    width: 95,
};

export const ADMIN_DASHBOARD_FILER_PROFILE_BUTTON: TableAction = {
    text: 'Filer Profile',
    type: 'outline',
    tooltip: 'Filer Profile',
    returnAction: 'filer-profile',
    width: 105,
};

export const ADMIN_DASHBOARD_SYSTEM_MANAGEMENT_CLIENT_BUTTON: TableAction = {
    text: 'System Mgmt.',
    type: 'outline',
    tooltip: 'System Maganement',
    returnAction: 'system-maganement-client',
    width: 115,
};

export const ADMIN_DASHBOARD_SYSTEM_MANAGEMENT_FIRM_BUTTON: TableAction = {
    text: 'System Mgmt.',
    type: 'outline',
    tooltip: 'System Maganement',
    returnAction: 'system-maganement-firm',
    width: 115,
};

export const ADMIN_DASHBOARD_SYSTEM_MANAGEMENT_CHECK_BUTTON: TableAction = {
    text: 'Mark as Complete',
    type: 'checkbox',
    tooltip: 'Mark as Complete',
    returnAction: 'mark-as-complete',
    width: 115,
};

export const ADMIN_DASHBOARD_SYSTEM_MANAGEMENT_REVIEW_BUTTON: TableAction = {
    text: 'Review',
    type: 'solid',
    tooltip: 'Review',
    returnAction: 'review',
    width: 115,
};

export const ADMIN_DASHBOARD_SYSTEM_MANAGEMENT_VIEW_BUTTON: TableAction = {
    text: 'View',
    type: 'solid',
    tooltip: 'View',
    returnAction: 'view',
    width: 95,
};

export const ADMIN_DASHBOARD_SYSTEM_MANAGEMENT_FILER_ACCOUNT_BUTTON: TableAction =
    {
        text: 'Filer Account',
        type: 'outline',
        tooltip: 'Filer Account',
        returnAction: 'filer-account',
        width: 115,
    };

export const ADMIN_DASHBOARD_SYSTEM_MANAGEMENT_VIEW_ACCOUNT_BUTTON: TableAction =
    {
        text: 'View Account',
        type: 'solid',
        tooltip: 'View Account',
        returnAction: 'view-account',
        width: 115,
    };

export const ADMIN_DASHBOARD_SPACE: TableAction = {
    text: '',
    type: 'space-necesary',
    width: null,
};

export enum AlertCategoryType {
    CompensationOther = 1,
    HarassmentPreventionTrainingExtension = 2,
    HarassmentPreventionTrainingExempt = 3,
    MonthlyReportAmended = 4,
    NewClientEntered = 5,
    ClientInformationUpdated = 6,
    NewFirmEntered = 7,
    FirmInformationUpdated = 8,
}

export enum AlertCategoryName {
    'Compensation - Other' = 1,
    'Harassment Prevention Training Extension' = 2,
    'Harassment Prevention Training Exempt' = 3,
    'Monthly Report Amended' = 4,
    'New Client Entered' = 5,
    'Client Information Updated' = 6,
    'New Firm Entered' = 7,
    'Firm Information Updated' = 8,
}

export interface AlertTableRow extends BaseTableInformation {
    category: string;
    date: string;
    filingUser: string;
    registration: string;
}

export interface RegistrationApprovalTableRow extends BaseTableInformation {
    approvalType: string;
    registrationName: string;
    submissionDate: string;
    status: string;
}

export interface RegistrationApprovalApiModel {
    clientRegistrationId: string;
    clientRegistrationVersion: number;
    status: number;
    approvalType: string;
    registrationName: string;
    submissionDate: Date;
}

export interface ActiveRegistrationTableRow extends BaseTableInformation {
    lobbyistName: string;
    clientName: string;
    registeredLobbyistAssociates: string;
    harassmentPreventionStatus: string;
    feePaid: string;
    feeAmount: string;
}


export interface ActiveRegistrationApiModel {
    clientRegistrationId: string;
    clientRegistrationVersion: number;
    registeredLobbyistAssociatesCount: number;
    clientName: string;
    lobbyistName: string;
    harassmentPreventionStatus: string;
    feePaid: boolean;
    feeAmount: number;
    primaryLobbyistId: string;
    filerTypeId: Filers;
}

export interface ManageFilersTableRow extends BaseTableInformation {
    filerName: string;
    filerType: string;
    createDate: string;
    lastActive: string;
    email: string;
    phone: string;
    authorizedAgent: string;
    authorizedAgentEmail: string;
    authorizedPhone: string;
}
