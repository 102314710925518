import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoModule } from '@ngneat/transloco';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { ToggeablePasswordFieldModule } from 'src/app/shared/modules/toggeable-password-field-module';
import { AccountRoutingModule } from './account-routing.module';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { ConfirmEmailFormModule } from 'src/app/shared/modules/confirm-email-form.module';
import { MfaFormModule } from 'src/app/shared/modules/mfa-form.module';
import { DialogModule, WindowModule } from '@progress/kendo-angular-dialog';
import { SectionHeadingModule } from 'src/app/shared/modules/section-heading.module';
import { JoinableEntitySelectorModule } from 'src/app/shared/modules/joinable-entity-selector.module';
import { CreateAccountComponent } from './components/create-account/create-account.component';
import { ManageComponent } from './components/manage/manage.component';
import { ManageProfileComponent } from './components/manage-profile/manage-profile.component';
import { ManagePasswordComponent } from './components/manage-password/manage-password.component';
import { ManageEmailComponent } from './components/manage-email/manage-email.component';
import { SignupComponent } from './components/signup/signup.component';
import { SignupFormComponent } from './components/signup-form/signup-form.component';
import { ForgotPasswordInitFlowComponent } from './components/forgot-password-init-flow/forgot-password-init-flow.component';
import { ForgotPasswordCompleteFlowComponent } from './components/forgot-password-complete-flow/forgot-password-complete-flow.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ManageUpdateAttributeDialogComponent } from './components/manage-update-attribute-dialog/manage-update-attribute-dialog.component';
import { CreateAccountFormComponent } from './components/create-account-form/create-account-form.component';
import { ChooseUserTypeComponent } from './components/create-account-choose-user-type/choose-user-type.component';
import { CreateAccountNewOrJoinComponent } from './components/create-account-new-or-join/create-account-new-or-join.component';
import { AccountService } from './services/account.service';
import { CreateAccountFirmOptionsComponent } from './components/create-account-firm-options/create-account-firm-options.component';
import { CreateAccountFirmFormComponent } from './components/create-account-firm-form/create-account-firm-form.component';
import { CreateAccountBusinessAddressComponent } from './components/create-account-business-address/create-account-business-address.component';
import { CreateAccountSuccessScreenComponent } from './components/create-account-success-screen/create-account-success-screen.component';
import { CreateAccountFirmAddress } from './components/create-account-firm-address/create-account-firm-address';
import { CreateAccountFirmComponent } from './components/create-account-firm/create-account-firm.component';
import { CreateAccountFirmViewComponent } from './components/create-account-firm-view/create-account-firm-view.component';
import { FirmBusinessAddressService } from './services/firm-business-address.service';
import { CreateAccountBusinessAddressViewComponent } from './components/create-account-business-address-view/create-account-business-address-view.component';
import { CreateAccountBusinessAddressFormComponent } from './components/create-account-business-address-form/create-account-business-address-form.component';
import { CreateAccountFirmAddressFormComponent } from './components/create-account-firm-address-form/create-account-firm-address-form.component';
import { CreateAccountLegislativeAgencyComponent } from './components/create-account-legislative-agency/create-account-legislative-agency.component';
import { CreateAccountAdditionalInformationComponent } from './components/create-account-additional-information/create-account-additional-information.component';
import { CreateAccountGrassrootsFilersComponent } from './components/create-account-grassroots-filers/create-account-grassroots-filers.component';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { GrassrootService } from './services/grassroot.service';
import { CreateAccountDataService } from './services/create-account-data.service';
import { FirmActionComponent } from './components/firm-action/firm-action';
import { BackNextButtonsComponent } from './components/back-next-buttons/back-next-buttons.component';
import { LobbyistService } from './services/lobbyist.service';
import { AuthorizedAgentService } from './services/authorized-agent.service';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { AsDefaultFilerChoicePipe } from './pipes';
import { ZipPipe } from "src/app/shared/pipes/format-zip.pipe";
import { PipesModule } from 'src/app/shared/modules/pipes.module';
import { ManageProfileLobbyistComponent } from './components/manage-profile-lobbyist/manage-profile-lobbyist.component';
import { ManageProfileGrassrootsComponent } from './components/manage-profile-grassroots/manage-profile-grassroots.component';
import { ManageProfileService } from './services/manage-profile.service';
import { ManageProfileLegislativeDesigneeComponent } from './components/manage-profile-legislative-designee/manage-profile-legislative-designee.component';
import { ManageProfileAdminComponent } from './components/manage-profile-admin/manage-profile-admin.component';
import { ManageFirmFormComponent } from './components/manage-firm-form/manage-firm-form.component';
import { ManageBusinessAddressFormComponent } from './components/manage-business-address-form/manage-business-address-form.component';
import { ManageUserFormService } from './services/manage-user-form.service';
import { ForgotPasswordSuccessComponent } from './components/forgot-password-success/forgot-password-success.component';
import { ManageMfaPhoneNumberComponent } from './components/manage-mfa-phone-number/manage-mfa-phone-number.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslocoModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        ToggeablePasswordFieldModule,
        AccountRoutingModule,
        RouterModule,
        ConfirmEmailFormModule,
        MfaFormModule,
        DialogModule,
        WindowModule,
        SectionHeadingModule,
        JoinableEntitySelectorModule,
        IndicatorsModule,
        DropDownsModule,
        InputsModule,
        ButtonsModule,
        PipesModule,
        NgxMaskDirective,
        NgxMaskPipe,
    ],
    declarations: [
        BackNextButtonsComponent,
        LoginComponent,
        LogoutComponent,
        LoginFormComponent,
        CreateAccountComponent,
        ManageComponent,
        ManageProfileComponent,
        ManagePasswordComponent,
        ManageEmailComponent,
        ManageMfaPhoneNumberComponent,
        SignupComponent,
        SignupFormComponent,
        ForgotPasswordInitFlowComponent,
        ForgotPasswordCompleteFlowComponent,
        ForgotPasswordComponent,
        ForgotPasswordSuccessComponent,
        ManageUpdateAttributeDialogComponent,
        CreateAccountFormComponent,
        ChooseUserTypeComponent,
        CreateAccountNewOrJoinComponent,
        CreateAccountFirmOptionsComponent,
        CreateAccountFirmFormComponent,
        CreateAccountBusinessAddressComponent,
        CreateAccountSuccessScreenComponent,
        CreateAccountFirmAddress,
        CreateAccountFirmComponent,
        CreateAccountFirmViewComponent,
        CreateAccountBusinessAddressComponent,
        CreateAccountBusinessAddressViewComponent,
        CreateAccountBusinessAddressFormComponent,
        CreateAccountFirmAddressFormComponent,
        CreateAccountLegislativeAgencyComponent,
        CreateAccountAdditionalInformationComponent,
        CreateAccountGrassrootsFilersComponent,
        FirmActionComponent,
        AsDefaultFilerChoicePipe,
        ManageProfileLobbyistComponent,
        ManageProfileGrassrootsComponent,
        ManageProfileLegislativeDesigneeComponent,
        ManageProfileAdminComponent,
        ManageFirmFormComponent,
        ManageBusinessAddressFormComponent,
    ],
    exports: [LoginComponent, LogoutComponent],
    providers: [
        AccountService,
        AuthorizedAgentService,
        LobbyistService,
        FirmBusinessAddressService,
        GrassrootService,
        CreateAccountDataService,
        ManageProfileService,
        ManageUserFormService,
        ZipPipe,
        provideNgxMask(),
    ],
})
export class AccountModule {}
