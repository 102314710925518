import { COLUMN_TYPE, DataTable } from "src/app/shared/models/grid-data.models";

export const lobbyistAssociatesTable: DataTable[] = [
    {
        title: "reviewRegistration.associateName",
        field: "name",
        type: COLUMN_TYPE.STRING,
        visible: true,
        width: "20%",
        height: "40px",
    },
    {
        title: "reviewRegistration.associateAddress",
        field: "address",
        type: COLUMN_TYPE.STRING,
        visible: true,
        width: "30%",
        height: "40px",
    },
    {
        title: "reviewRegistration.email",
        field: "email",
        type: COLUMN_TYPE.STRING,
        visible: true,
        width: "30%",
        height: "40px",
    },
    {
        title: "reviewRegistration.phone",
        field: "phone",
        format: (phone: string) => formatPhone(phone),
        type: COLUMN_TYPE.STRING,
        visible: true,
        width: "20%",
        height: "40px",
    },
];

export const authorizedAgentsTable: DataTable[] = [
    {
        title: "reviewRegistration.authorizedAgentName",
        field: "name",
        type: COLUMN_TYPE.STRING,
        visible: true,
        width: "40%",
        height: "40px",
    },
    {
        title: "reviewRegistration.email",
        field: "email",
        type: COLUMN_TYPE.STRING,
        visible: true,
        width: "40%",
        height: "40px",
    },
    {
        title: "reviewRegistration.phone",
        field: "phone",
        format: (phone: string) => formatPhone(phone),
        type: COLUMN_TYPE.STRING,
        visible: true,
        width: "20%",
        height: "40px",
    },
];

const formatPhone = (phone: string) => {
    if(phone === null ) return "";
    const l = phone.length;
    return `(${phone.substring(l-10,l-7)}) ${phone.substring(l-7,l-4)}-${phone.substring(l-4)}`;
}

export { ClientRegistrationStatus as ClientRegistrationStatusEnum } from "@maplight/models";

export enum ClientRegistrationStatusEnumName {
    "Pending Review" = 1,
    Approved = 2,
    Denied = 3,
    "Conditionally Approved" = 4,
    Terminated = 5,
}

export type PredefinedReason = {
    id: string;
    text: string;
}