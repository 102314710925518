import { FilerTypeEnumMaine, LobbyistUserTypeEnum } from 'src/app/app.model';

/**
 * An utility function for checking if a filer instance represents an Authorized Agent.
 * 
 * Passing `pure` as `true` will include only items for which the base filer type is an
 * anuthorized agent, while `false` (the default) will also return `true` for cases where
 * it is an authorized agent access to a lobbyist filer.
 * 
 * @param it the filer entity
 * @param pure whether to exclude access to other filers when performing the check
 * @returns if the target represents Authorized Agent access or not
 */
export const isAgent = (
    it: {
        filerTypeId?: FilerTypeEnumMaine;
        userTypeId?: LobbyistUserTypeEnum;
    },
    pure: boolean = false,
) =>
    it?.filerTypeId === FilerTypeEnumMaine.AuthorizedAgent ||
    (!pure && it?.userTypeId === LobbyistUserTypeEnum.AuthorizedAgent);
