import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, merge } from "rxjs";
import { map, shareReplay, switchMap, tap } from "rxjs/operators";
import { FilerInformationService, SharedService } from "src/app/core";
import { UserService } from "src/app/core/services/user-service";
import { Router } from "@angular/router";

@Injectable({ providedIn: "root" })
export class UserDataService {
    // Services

    private readonly shared = inject(SharedService);
    private readonly filer = inject(FilerInformationService);
    private readonly user = inject(UserService).getUserData();
    private readonly router = inject(Router);

    // Immutable State

    private readonly reload$ = new BehaviorSubject<void>(null);

    readonly data$ = merge(this.reload$, this.user).pipe(
        switchMap(() => this.shared.loadUserEntities(true)),

        tap((it) => this.filer.setAdminRights(it?.isAdmin === true)),
        tap((user) => {
            if (user.userFilers.length === 0 && !user.isAdmin) {
                this.router.navigate(["/account/create"]);
            }
        }),
        shareReplay(1)
    );

    readonly isAffiliated$ = this.data$.pipe(
        map((it) => it?.isAdmin || it?.userFilers?.length)
    );

    // Methods

    reloadData(): void {
        this.reload$.next();
    }
}
