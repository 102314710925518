import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
    AddEvent,
    CancelEvent,
    EditEvent,
    GridComponent,
    RemoveEvent,
    SaveEvent,
} from '@progress/kendo-angular-grid';
import { BehaviorSubject, combineLatest, merge, of, Subject } from 'rxjs';
import {
    catchError,
    map,
    scan,
    share,
    shareReplay,
    startWith,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { UpdateType } from 'src/app/modules/System-Management/models/update-type.model';
import { GrassrootLobbying as Model } from '../../models/grassroots-lobbying.model';
import { TranslocoService } from "@ngneat/transloco";
import { ActivityReportService } from '../../services/activity-report.service';
import { TablePagingService } from 'src/app/shared/services/table-paging.service';
import { LegislativeDocument } from '../../models/legislative-document.model';

type Update = Model & { type: UpdateType };

@Component({
    selector: 'app-grassroots-lobbying-grid',
    templateUrl: './grassroots-lobbying-grid.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class GrassrootsLobbyingComponent {
    constructor(
        private readonly i18n: TranslocoService,
        private readonly reportService: ActivityReportService,
        private readonly tablePagingService: TablePagingService,
        private readonly fb: FormBuilder,
        private readonly route: ActivatedRoute,
    ) {}

    @Output() readonly modifiedTableEvent = new EventEmitter<undefined>();
    @Input() set documents(value: LegislativeDocument[]) 
    {
        if (!value) return;
        this.documents$.next(value);
        this.documentsLookup = value.reduce((lookup, value) => {
            lookup[value.id] = value;
            return lookup;
        }, {} as { [key: string]: LegislativeDocument });
    }

    documentsLookup: { [key: string]: LegislativeDocument };
    private readonly documents$ = new Subject<LegislativeDocument[]>()

    readonly limit = 2000;
    readonly virtual = { itemHeight: 35 } as const;

    deletingItemId: string = undefined;
    editingRowIndex: number = undefined;

    private readonly zeroOrAboveLimit: ValidatorFn = (control) =>
        control.value && control.value < this.limit ? { min: true } : null;

    readonly form = this.fb.group(
        {
            id: this.fb.control<string>(null),
            document: this.fb.control<Partial<LegislativeDocument>>(null),
            issueAndInterest: this.fb.control<string>(null),
            totalSpent: this.fb.control<number>(null, {
                validators: this.zeroOrAboveLimit,
            }),
            description: this.fb.control<string>(null, {
                validators: Validators.required,
            }),
        },
        {
            validators: () => {
                if (!this.form) return null;

                const { document, issueAndInterest } = this.form.value;

                const hasDocument = !!document?.id?.length;
                const hasInterest = !!issueAndInterest?.length;

                if (hasDocument === hasInterest) return { multiple: true };

                return null;
            },
        },
    );

    protected getColumnWidth(title: string): number {
        const translated = this.i18n.translate(`report.grassroots.${title}`)
        return this.tablePagingService.calcColumnWidth(translated);
    }

    private readonly filterByLd$ = new BehaviorSubject<string>(null);
    private readonly filterByTitle$ = new BehaviorSubject<string>(null);

    ldNumberFilter(ldNumber: string) {
        this.filterByLd$.next(ldNumber);
    }

    billTitleFilter(billTitle: string) {
        this.filterByTitle$.next(billTitle);
    }

    readonly options$ = combineLatest([
        this.filterByLd$,
        this.filterByTitle$,
        this.documents$
    ]).pipe(
        map(([ld, title, documents]) =>
            documents.filter((it) => {
                if (!!ld?.length && !it.ldNumber.toString().includes(ld))
                    return false;

                if (!!title?.length && !it.billTitle.includes(title))
                    return false;

                return true;
            }),
        ),
    );

    private readonly reportId = this.route.snapshot.params.filingId;

    private readonly add$ = new Subject<Partial<Model>>();
    private readonly edit$ = new Subject<Partial<Model>>();
    private readonly delete$ = new Subject<string>();

    private readonly data$ = merge(
        this.reportService.getGrassrootLobbyings(this.reportId),
        this.add$.pipe(
            switchMap((it) =>
                this.reportService
                    .addGrassrootLobbyings(this.reportId, it)
                    .pipe(
                        map((data) => ({
                            ...data,
                            document: this.documentsLookup[it.document?.id],
                        })),
                        catchError(() => of({ id: null } as Model)),
                    ),
            ),
            map((it) => ({ type: UpdateType.Add, ...it })),
        ),
        this.edit$.pipe(
            switchMap((it) =>
                this.reportService
                    .editGrassrootLobbyings(this.reportId, it)
                    .pipe(
                        map((data) => ({
                            ...data,
                            document: this.documentsLookup[it.document?.id],
                        })),
                        catchError(() => of({ id: null } as Model)),
                    ),
            ),
            map((it) => ({ type: UpdateType.Edit, ...it })),
        ),
        this.delete$.pipe(
            switchMap((it) =>
                this.reportService
                    .removeGrassrootLobbyings(this.reportId, it)
                    .pipe(catchError(() => of({ id: null } as Model))),
            ),
            map((it) => ({ type: UpdateType.Remove, ...it })),
        ),
    ).pipe(
        scan((data, action: Model[] | Update) => {
            // loaded from remote
            if ('length' in action) return action;

            const { type, ...payload } = action;

            // an error
            if (!payload.id) return data;

            // from here on, successful updates to values
            // should notify
            this.modifiedTableEvent.emit();

            if (type == UpdateType.Add) return [...data, payload];

            if (type == UpdateType.Edit)
                return data.map((it) => (it.id !== payload.id ? it : payload));

            return data.filter((it) => it.id !== payload.id);
        }, [] as Model[]),
    );

    readonly model$ = merge(
        this.data$,
        merge(this.add$, this.edit$, this.delete$).pipe(
            map(() => true),
            startWith(true),
        ),
        this.options$.pipe(map((it) => ({ options: it }))),
    ).pipe(
        scan(
            (state, change) => {
                if (typeof change === 'boolean') {
                    state.loading = change;
                } else if ('options' in change) {
                    state.options = change.options;
                } else {
                    state.data = change;
                    state.loading = false;
                }

                return state;
            },
            {
                data: [] as Model[],
                options: [] as LegislativeDocument[],
                loading: true,
            },
        ),
    );

    selectDocument(it: Partial<LegislativeDocument>) {
        this.form.controls.document.setValue(it);
    }

    addHandler({ sender }: Partial<AddEvent>): void {
        this.closeEditor(sender);

        this.deletingItemId = undefined;
        this.editingRowIndex = -1;

        sender.addRow(this.form);
    }

    editHandler(args: EditEvent): void {
        this.closeEditor(args.sender);

        this.form.patchValue(args.dataItem);
        this.deletingItemId = undefined;
        this.editingRowIndex = args.rowIndex;

        args.sender.editRow(args.rowIndex, this.form);
    }

    cancelHandler(args: Partial<CancelEvent>): void {
        this.closeEditor(args.sender, this.editingRowIndex);
    }

    public saveHandler(args: Partial<SaveEvent>): void {
        if (this.form.invalid) return;

        const data = this.form.value;

        data.totalSpent ??= 0;

        if (!!data.document) {
            data.document = {
                id: data.document.id,
                version: data.document.version,
            };
        }

        const isNew = this.editingRowIndex === -1;

        (isNew ? this.add$ : this.edit$).next(data);

        this.closeEditor(args.sender);
    }

    public removeHandler(args: RemoveEvent): void {
        if (this.editingRowIndex !== undefined)
            args.sender.closeRow(this.editingRowIndex);

        this.editingRowIndex = undefined;
        this.deletingItemId = args.dataItem.id;
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editingRowIndex) {
        grid.closeRow(rowIndex);
        this.form.reset();
        this.editingRowIndex = undefined;
        this.deletingItemId = undefined;
    }

    deleteHandler() {
        this.delete$.next(this.deletingItemId);
        this.deletingItemId = undefined;
    }
}
