import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { LobbyistService } from "../../services/lobbyist.service";
import { merge, Observable, of, Subject } from "rxjs";
import {
    distinctUntilChanged,
    map,
    mapTo,
    shareReplay,
    switchMap,
    tap,
} from "rxjs/operators";
import { ILobbyistFirmAddress } from "../../../../shared/models/app-lobbyist-business";
import { LobbyistFirmRequest } from "../../models/ILobbyistData";

@Component({
    selector: "app-create-account-firm-address",
    templateUrl: "./create-account-firm-address.html",
    styleUrls: ["./create-account-firm-address.scss"],
})
export class CreateAccountFirmAddress implements OnInit {
    formGroup: FormGroup;
    lobbyist$: Observable<LobbyistFirmRequest[]>;
    addressFromFirm$: Observable<ILobbyistFirmAddress[]>;
    address$ = new Subject<any>();
    firmList: LobbyistFirmRequest[] = [];
    lobbyistSelected: string;
    load$: Observable<boolean>;
    newAddress$: Observable<ILobbyistFirmAddress>;
    addressAvailable$: Observable<boolean>;
    edit$: Observable<ILobbyistFirmAddress>;
    addressSelected: ILobbyistFirmAddress;
    textFilter$ = new Subject<string>();
    loadingFilter$: Observable<boolean>;
    showForm: boolean;

    constructor(
        private readonly fb: FormBuilder,
        private readonly lobbyistService: LobbyistService,
    ) {}

    edit() {
        this.lobbyistService.editNewFirm();
    }

    cancel() {
        this.lobbyistService.cancel();
    }

    delete() {
        this.lobbyistService.delete();
    }

    ngOnInit(): void {
        this.formGroup = this.fb.group({
            search: [""],
        });

        this.edit$ = this.lobbyistService.editBusinessAddress$;

        const startFilter$ = this.textFilter$.pipe(
            distinctUntilChanged()
        );

        // get the list of firms
        this.lobbyist$ = startFilter$.pipe(
            switchMap((text) => this.lobbyistService.getLobbyistFirms(text)),
            tap((value) => {
                if (value.length === 0) this.showForm = false;
                this.firmList = value;
            }),
            shareReplay()
        );
        const closeFilter$ = this.lobbyist$.pipe(mapTo(false));

        this.loadingFilter$ = merge(
            startFilter$.pipe(mapTo(true)),
            closeFilter$
        ).pipe(shareReplay());

        this.addressFromFirm$ = this.address$.pipe(
            map((value) => value.addresses),
            shareReplay()
        );
        const start$ = this.address$.pipe(mapTo(true));
        const close$ = this.addressFromFirm$.pipe(shareReplay());
        this.load$ = merge(start$, close$.pipe(mapTo(false))).pipe(
            shareReplay()
        );

        this.newAddress$ = this.lobbyistService.addFirmAddress$.pipe(
            tap((value) => {
                this.addressSelected = value ?? null;
            })
        );
    }

    addressSelectedAvailable() {
        this.addressSelected = this.lobbyistService.addressSelected
        return this.addressSelected ? [this.addressSelected] : []
    }

    selectionChange(firmId: number) {
        if (!!firmId) {
            const data = this.firmList.find(firm => firm.id === firmId);
            if (!!data) {
                this.address$.next(data);
                this.lobbyistSelected = data.name;
                this.lobbyistService.selectedFirmId = firmId;
                this.lobbyistService.selectedFirm = data;
                this.showForm = true;
            }
        }
    }

    selectValue($event: any) {
        this.lobbyistService.selectAddress($event)
    }

    filterChange(value: string) {
        if (!value) {
            this.lobbyistSelected = undefined;
            this.lobbyistService.selectedFirmId = undefined;
            this.showForm = false;
        } else {
            this.textFilter$.next(value);
        }
    }
}
