import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Router } from "@angular/router";
import { merge, Observable, partition, Subject } from "rxjs";
import {
    filter,
    map,
    share,
    switchMap,
    mapTo,
    startWith,
    shareReplay,
    tap,
} from "rxjs/operators";
import { MasterUrlService } from "src/app/core";
import { AccountService } from "../../services/account.service";
import {FilerTypeEnum} from '../../../../shared/models';

@Component({
    selector: "app-create-account-new-or-join",
    templateUrl: "./create-account-new-or-join.component.html",
    styleUrls: ["./create-account-new-or-join.component.scss"],
})
export class CreateAccountNewOrJoinComponent implements OnInit {
    @Input() filerTypeId: FilerTypeEnum;
    @Output() back: EventEmitter<void> = new EventEmitter<void>();

    newOrJoinData$: Observable<any>;
    joiningEntity: boolean = false;

    selectedFilers: any[] = [];
    private sendJoinRequestSubject$: Subject<boolean> = new Subject<boolean>();
    sendingJoinRequest$: Observable<boolean>;

    constructor(
        private readonly accountService: AccountService,
        private readonly urlService: MasterUrlService,
        private readonly router: Router
    ) {}

    ngOnInit(): void {
        this.newOrJoinData$ = this.accountService.getUserData().pipe(
            map(({ id }) => this.getFilerObjects(id, this.filerTypeId)),
            shareReplay()
        );

        const sendJoin$ = this.sendJoinRequestSubject$.pipe(
            filter((x) => x),
            switchMap(this.sendJoinRequest),
            share()
        );

        const [success$, fail$] = partition(sendJoin$, (x) => x);

        this.sendingJoinRequest$ = merge(
            success$.pipe(
                tap((_) => this.router.navigate(["switch"])),
                mapTo(false)
            ),
            fail$.pipe(mapTo(false)),
            this.sendJoinRequestSubject$.pipe(filter((x) => x))
        ).pipe(startWith(false));
    }

    private getFilerObjects = (id: string, filerTypeId: number) => {
        switch (filerTypeId) {
            case 1:
                return {
                    join: "manageAccount.selectCreateLobbyist.selectEntity",
                    new: "manageAccount.selectCreateLobbyist.createEntity",
                    description:
                        "manageAccount.selectCreateLobbyist.description",
                    searchUrl: this.urlService.SearchLobbyistByName + "?name=",
                    createUrl: ["/lobbyist/createlobbyist"],
                    queryParams: { userId: id },
                };

            case 2:
                return {
                    join: "manageAccount.selectCreateIndependentexpenditure.selectIeEntity",
                    new: "manageAccount.selectCreateIndependentexpenditure.createEntity",
                    description:
                        "manageAccount.selectCreateIndependentexpenditure.description",
                    searchUrl:
                        this.urlService.SearchIndependentExpenditureByName,
                    createUrl: [
                        "/independent-expenditure/independent-expenditure-registration",
                    ],
                };

            case 3:
                return {
                    join: "manageAccount.selectCreateCommitee.selectEntity",
                    new: "manageAccount.selectCreateCommitee.createEntity",
                    description:
                        "manageAccount.selectCreateCommitee.description",
                    searchUrl: this.urlService.SearchCommitteeByName,
                    createUrl: ["/committee/committee-registration"],
                    queryParams: { userId: id },
                };
        }
    };

    sendJoinRequest = () => this.accountService.requestAffiliation(this.selectedFilers);

    requestSendJoin = () => this.sendJoinRequestSubject$.next(true);

    selectionChange = (e) => (this.selectedFilers = e);

    backButton = () => this.back.emit();
}
