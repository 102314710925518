import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationService } from '@progress/kendo-angular-notification';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class SnackbarService {

    constructor(
        private readonly notificationService: NotificationService,
        private readonly translocoService: TranslocoService,
    ) { }

    snackbarSuccess(message: any) {
        this.notificationService.show({
            content: message,
            cssClass: 'success-snackbar',
            animation: { type: "fade", duration: 400 },
            position: { horizontal: "center", vertical: "bottom" },
            type: { style: "info", icon: true },
            hideAfter: environment.snackBarDuration
        });
    }

    snackbarAddUpdate(moduleName: string, isAdded: boolean = true) {
        this.snackbarSuccess(this.translocoService.translate(isAdded ? 'snackbarAdd' : 'snackbarUpdate', { item: moduleName }));
    }

    snackbarError(message: string) {
        this.notificationService.show({
            content: message,
            cssClass: 'error-snackbar',
            animation: { type: "fade", duration: 400 },
            position: { horizontal: "center", vertical: "bottom" },
            type: { style: "error", icon: true },
            hideAfter: environment.snackBarDuration
        });
    }

    snackbarInfo = (message: string) => this.snackbarSuccess(message);

    snackbarWarning(message: any) {
        if (message) {
            this.notificationService.show({
                content: message,
                cssClass: 'wraning-snackbar',
                animation: { type: "fade", duration: 400 },
                position: { horizontal: "center", vertical: "bottom" },
                type: { style: "warning", icon: true },
                hideAfter: environment.snackBarDuration
            });
        }
    }

    snackbarDefault(message: any) {
        this.notificationService.show({
            content: message,
            cssClass: 'default-snackbar',
            animation: { type: "fade", duration: 400 },
            position: { horizontal: "center", vertical: "bottom" },
            type: { style: "none", icon: true },
            hideAfter: environment.snackBarDuration
        });
    }

    snackbarBulkUpload = (message: string) => this.snackbarSuccess(message);

}
