import { inject, Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";
import { DialogService, DialogCloseResult } from "@progress/kendo-angular-dialog";
import { map } from "rxjs/operators";
import { SnackbarService } from "src/app/core";
import { ConfirmationDialogComponent } from "src/app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { ViewMessageComponent } from "../components/view-message/view-message.component";
@Injectable()
export class AdminAlertService {
    private readonly i18n = inject(TranslocoService);
    private readonly snackbar = inject(SnackbarService);
    private readonly dialog = inject(DialogService);

    notificationScheduled() {
        this.snackbar.snackbarSuccess(
            this.i18n.translate("notification.notificationScheduled")
        );
    }

    notificationRemoved() {
        this.snackbar.snackbarSuccess(
            this.i18n.translate("notification.notificationRemoved")
        );
    }

    notificationRemoveFailed() {
        this.snackbar.snackbarError(
            this.i18n.translate("notification.errorRemovingNotification")
        );
    }

    scheduleFailed() {
        this.snackbar.snackbarError(
            this.i18n.translate("notification.failedToScheduleNotification")
        );
    }

    templateSaved() {
        this.snackbar.snackbarSuccess(
            this.i18n.translate("notification.saveMessage")
        );
    }

    templateSaveFailed() {
        this.snackbar.snackbarError(
            this.i18n.translate("notification.serrorSaveTemplateMessage")
        );
    }

    templateRemoved() {
        this.snackbar.snackbarSuccess(
            this.i18n.translate("notification.theTemplateWasRemoved")
        );
    }

    testMessageSent() {
        this.snackbar.snackbarSuccess(
            this.i18n.translate("notification.messageSentSuccessfully")
        );
    }

    operationFailed() {
        this.snackbar.snackbarError(
            this.i18n.translate("notification.operationIsFailed")
        );
    }

    confirmDeleteNotification(id: number, name: string) {
        var dialogRef = this.dialog.open({
          content: ConfirmationDialogComponent,
          width: 400
        });
        const component = dialogRef.content.instance as ConfirmationDialogComponent;
    
        component.header = this.i18n.translate(
          "notification.unscheduleMessage"
        );
        (component.actionName = this.i18n.translate(
          "unschedule"
        )),
          (component.objectName = name);
        
        return dialogRef.result.pipe(
          map((x) => x instanceof DialogCloseResult ? null : id)
        );
    }

    confirmDeleteTemplate(id: string|number, name: string) {
        var dialogRef = this.dialog.open({
          content: ConfirmationDialogComponent,
          width: 400
        });
        const component = dialogRef.content.instance as ConfirmationDialogComponent;
    
        component.header = this.i18n.translate(
          "notification.deleteTemplate"
        );
        (component.actionName = this.i18n.translate(
          "delete"
        )),
          (component.objectName = name);
        
        return dialogRef.result.pipe(
          map((x) => x instanceof DialogCloseResult ? null : id)
        );
    }
    
    viewArchivedMessage(subject: string, message: string, sentTime: Date) {
        var dialogRef = this.dialog.open({
          content: ViewMessageComponent,
          width: 420
        });
        const component = dialogRef.content.instance as ViewMessageComponent;
    
        component.header = this.i18n.translate(
          "notification.message"
        );

        (component.subject = subject);
        (component.text = message);
        (component.sentTime = sentTime);
        
        return dialogRef.result.pipe(
          map((x) => x instanceof DialogCloseResult ? null : subject)
        );
    }
}
