import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AccessEthicsTypesEnum, AccessEthicsUserTypesEnum } from '../../shared/models';
import { FilerInformationService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class OnlyOfficerGuard implements CanActivate {
    constructor(private readonly filer: FilerInformationService) { }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        return this.filer.current$.pipe(
            map(
                (x) => x?.filerTypeId === AccessEthicsTypesEnum.Appointed ||
                    x?.filerTypeId === AccessEthicsTypesEnum.Elected   
            )
        );
    }
  
}
