import { Injectable } from "@angular/core";
import { TranslocoService } from "@ngneat/transloco";

@Injectable()
export class ErrorMessageService {
    constructor(private translocoService: TranslocoService) {}

    checkErrorMessage(formType: any, type: string) {
        switch (type) {
            case "password":
                return formType.hasError("required")
                    ? this.translocoService.translate("thisFieldIsRequired")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate("errorService.invalidPassword")
                    : this.translocoService.translate("errorService.passwordDontMatch");
            case "organizationName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.organizationName"
                      )
                    : "";
            case "isDataConfirmed":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.affirmationRequired"
                      )
                    : "";
            case "fullName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.fullNameRequired"
                      )
                    : "";
            case "loginEmail":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.emailReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.emailPattern"
                      )
                    : "";
            case "loginPassword":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.passwordReq"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.passwordLength"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.passwordPattern"
                      )
                    : "";
            case "forgotemail":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.emailReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.emailPattern"
                      )
                    : "";
            case "emailValidation":
                return formType.hasError("pattern")
                    ? this.translocoService.translate("errorService.userId")
                    : "";
            case "verificationCode":
                return formType.hasError("required")
                    ? this.translocoService.translate("manageAccount.profile.verificationCodeRequired")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate("manageAccount.profile.badFormat")
                    : "";
            case "contactfirstName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.firstNameReq"
                      )
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.alphaNumericCharactersOnly"
                      )
                    : "";
            case "contactlastName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lastNameReq"
                      )
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.lastNamePattern"
                      )
                    : "";
            case "mailingAdd1":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.mailAdd1Req"
                      )
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.address1Pattern"
                      )
                    : "";
            case "mailingAdd2":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.mailAdd2Req"
                      )
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.address2Pattern"
                      )
                    : "";
            case "message":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.messageRequired"
                      )
                    : "";
            case "contactzipcode":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.zipCodeReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.zipCodePattern"
                      )
                    : "";
            case "contactcity":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.cityReq")
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.cityReqMinLength"
                      )
                    : "";
            case "contactState":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.stateReq")
                    : "";
            case "contactphones":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.phoneReq")
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.phonePattern"
                      )
                    : "";
            case "year":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.yearReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.yearPattern"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.yearPattern"
                      )
                    : formType.hasError("max")
                    ? this.translocoService.translate(
                          "errorService.yearPattern"
                      )
                    : "";
            case "lobbyType":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lobbyistReq"
                      )
                    : "";
            case "lobbyistTypeId":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lobbyistTypeReq"
                      )
                    : "";
            case "firstName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.firstNameReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.alphaNumericCharactersOnly"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.firstNameMinLength"
                      )
                    : "";
            case "lastName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lastNameReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.alphaNumericCharactersOnly"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.lastNameMinLength"
                      )
                    : "";
            case "signEmail":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.emailReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.emailPattern"
                      )
                    : "";
            case "orgName":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.orgNameReq")
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.OrgNameMinLength"
                      )
                    : "";
            case "address1":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.address1Req"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.address1Pattern"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.address1MinLength"
                      )
                    : "";
            case "address2":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.address2Req"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.address1Pattern"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.address2MinLength"
                      )
                    : "";
            case "phone":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.phoneReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.phonePattern"
                      )
                    : "";
            case "lobbyistPhone":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.phoneReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.phonePattern"
                      )
                    : "";
            case "city":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.cityReq")
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.cityReqMinLength"
                      )
                    : formType.invalid && (formType.dirty || formType.touched)
                    ? this.translocoService.translate(
                          "errorService.invalidValueOrFormat"
                      )
                    : "";
            case "state":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.stateReq")
                    : "";
            case "stateCode":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.stateCodeReq"
                      )
                    : "";
            case "zipCode":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.zipCodeReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.zipCodePattern"
                      )
                    : "";
            case "natureOfBusiness":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.alphabeticCharactersOnly"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.natureOfBusinessMinLength"
                      )
                    : "";
            case "legislativeMatters":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.legislativeMattersReq"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.legislativeMattersMinLength"
                      )
                    : "";
            case "setpasswordemail":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.emailReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.emailPattern"
                      )
                    : "";
            case "setpasswordpassword":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.passwordReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.invalidPassword"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.passwordMinLength"
                      )
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.passwordMaxLength"
                      )
                    : "";
            case "setoldpassword":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.oldPasswordReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.passwordPattern"
                      )
                    : "";
            case "setpasswordconfirmpassword":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.cnfPassword"
                      )
                    : "";
            case "lobbyistId":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lobbyistReq"
                      )
                    : "";
            case "officialName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.officialNameReq"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.officialNameMinLength"
                      )
                    : "";
            case "officialTitle":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.officialTitleReq"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.officialTitleMinLength"
                      )
                    : "";

            case "relationship":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.relationshipReq"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.relationshipMinLength"
                      )
                    : "";
            case "entityName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.entityNameReq"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.entityNameMinLength"
                      )
                    : "";
            case "type":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.subContractorTypeReq"
                      )
                    : "";
            case "subject":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.subjectRequired"
                      )
                    : "";
            case "SubcontractorName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.subContractorNameReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.alphabeticCharactersOnly"
                      )
                    : "";
            case "first":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.firstNameReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.alphabeticCharactersOnly"
                      )
                    : "";
            case "last":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lastNameReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.alphabeticCharactersOnly"
                      )
                    : "";
            case "phone":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.phoneReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.phoneNumberPattern"
                      )
                    : "";
            case "lobbyist":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lobbyistReq"
                      )
                    : "";
            case "firstName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.firstNameReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.alphabeticCharactersOnly"
                      )
                    : "";
            case "lastName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lastNameReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.alphabeticCharactersOnly"
                      )
                    : "";
            case "contactphone":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.phoneReq")
                    : "";
            case "client":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.clientReq")
                    : "";
            case "clientName":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.clientName")
                    : "";
            case "principalContact":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.principalContact")
                    : "";
            case "principalEmail":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.principalEmail")
                    : formType.hasError("email")
                    ? this.translocoService.translate("errorService.emailPattern")
                    : "";
            case "principalPhone":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.principalPhone")
                    : "";
            case "organisationName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.companyNameReq"
                      )
                    : "";
            case "lobbyist":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lobbyistReq"
                      )
                    : "";
            case "BusinessName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.natureOfBusinessReq"
                      )
                    : "";
            case "remarks":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.legislativeMattersReq"
                      )
                    : "";
            case "bankName":
                return formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.alphabeticCharactersOnly"
                      )
                    : "";
            case "committeeName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.committeeNameReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.committeeNamePattern"
                      )
                    : formType.hasError("invalid")
                    ? this.translocoService.translate(
                          "errorService.committeeexists"
                      )
                    : "";
            case "purpose":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.purposeReq")
                    : "";
            case "committeeType":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.committeeTypeReq"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.committeeTypeMinLenght"
                      )
                    : "";
            case "electionDate":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.electionDateReq"
                      )
                    : "";
            case "purpose":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.positionReq"
                      )
                    : "";
            case "ballotIssue":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.ballotIssueReq"
                      )
                    : "";
            case "bankName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.bankNameReq"
                      )
                    : "";
            case "Address1":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.address1Req"
                      )
                    : "";
            case "Address2":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.address2Req"
                      )
                    : "";
            case "city":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.cityReq")
                    : "";
            case "state":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.stateReq")
                    : "";
            case "email":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.emailReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.emailPattern"
                      )
                    : formType.hasError("failed")
                    ? this.translocoService.translate(
                          "errorService.emailFailed"
                      )
                    : formType.hasError("email")
                    ? this.translocoService.translate(
                          "errorService.emailPattern"
                      )
                    : formType.hasError("invalid")
                    ? this.translocoService.translate("errorService.emailExist")
                    : "";
            case "committee-address1":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.address1Req"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.address1Pattern"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.address1MinLength"
                      )
                    : "";
            case "committee-address2":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.address2Req"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.address2Pattern"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.address1MinLength"
                      )
                    : "";
            case "city":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.cityReq")
                    : "";
            case "campPhone":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.phoneReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.phonePattern"
                      )
                    : "";
            case "state":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.stateReq")
                    : "";
            case "campEmail":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.emailReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.campEmailPattern"
                      )
                    : "";
            case "altPhone":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.phoneReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.phonePattern"
                      )
                    : "";
            case "altEmail":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.emailIsRequired"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.altemailPattern"
                      )
                    : "";
            case "commWebsite":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.commWebsiteReq"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.commWebsitePattern"
                      )
                    : "";
            case "officerType":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.officeTypeReq"
                      )
                    : "";
            case "occupation":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.occupationReq"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.occupationMinLength"
                      )
                    : "";
            case "employer":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.employerReq"
                      )
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.employerMinLength"
                      )
                    : "";
            case "searchFilers":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.searchFilersReq"
                      )
                    : "";
            case "filerType":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.filerTypeReq"
                      )
                    : "";
            case "filerStatus":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.filerStatusReq"
                      )
                    : "";
            case "issueNumber":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.issueNumberReq"
                      )
                    : "";
            case "issueName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.issueNameReq"
                      )
                    : "";
            case "description":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.descriptionReq"
                      )
                    : "";
            case "electionCycle":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.electionCycleReq"
                      )
                    : "";
            case "election_cycle":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.electionCycleReq"
                      )
                    : "";
            case "election_type":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.electionTypeReq"
                      )
                    : "";
            case "qualifying_offices":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.qualifyingOfficesReq"
                      )
                    : "";
            case "available":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.availableReq"
                      )
                    : "";
            case "ratio":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.ratioReq")
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.ratioPattern"
                      )
                    : "";
            case "qualifying_contribution":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.qualifyingContributionReq"
                      )
                    : "";
            case "number_of_req":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.numberOfReq"
                      )
                    : "";
            case "contr_limit":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.contrLimitReq"
                      )
                    : "";
            case "contr_amount":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.contrAmountReq"
                      )
                    : "";
            case "start_date":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.startDateRequired"
                      )
                    : "";
            case "end_date":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.endDateRequired"
                      )
                    : formType.hasError("matDatepickerMin")
                    ? this.translocoService.translate("errorService.minError")
                    : "";
            case "title":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.titleRequired"
                      )
                    : "";
            case "candidate_first_name":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.candidateFirstNameReq"
                      )
                    : "";
            case "candidate_last_name":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.candidateLastNameReq"
                      )
                    : "";
            case "office_sought":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.officeSoughtReq"
                      )
                    : "";
            case "district":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.districtReq"
                      )
                    : "";
            case "office":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.officesReq")
                    : "";
            case "donor":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.donorReq")
                    : "";
            case "contribution_limit":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.contrLimitReq"
                      )
                    : "";
            case "committee":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.committeeReq"
                      )
                    : "";
            case "name":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.nameReq")
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : "";
            case "amount":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.amountReq")
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : formType.hasError("minError")
                    ? this.translocoService.translate(
                          "errorService.amountPositive"
                      )
                    : formType.hasError("min")
                    ? this.translocoService.translate(
                          "errorService.amountNegative"
                      )
                    : "";
            case "duedate":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.dueDateReq")
                    : formType.hasError("matDatepickerMin") ||
                      formType.hasError("matDatepickerFilter")
                    ? this.translocoService.translate(
                          "errorService.duedateMinValue"
                      )
                    : "";
            case "invoicedate":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.invoiceDateReq"
                      )
                    : "";
            case "frequency":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.frequencyReq"
                      )
                    : "";
            case "graceperiod":
                return formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : "";
            case "contributionAmount":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.contrAmountReq"
                      )
                    : "";
            case "refundAmount":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.amountReq")
                    : "";
            case "reasonForrefund":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.reasonReq")
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.reasonMinLength"
                      )
                    : "";
            case "voterId":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.voterReq")
                    : formType.hasError("minlength")
                    ? this.translocoService.translate(
                          "errorService.voterMinLength"
                      )
                    : "";
            case "invoice_number":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.invoiceNumberReq"
                      )
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : "";
            case "type":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.typeReq")
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : "";
            case "date":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.dateReq")
                    : "";
            case "user":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.userReq")
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : "";
            case "payment_method":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.paymentMethodReq"
                      )
                    : formType.hasError("maxlength")
                    ? this.translocoService.translate(
                          "errorService.maximumLimit"
                      )
                    : "";
            case "contributionType":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.contributionTypeReq"
                      )
                    : "";
            case "payee":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.payeeReq")
                    : "";
            case "exp-cat":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.expReq")
                    : "";
            case "paid_date":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.paidDateReq"
                      )
                    : "";
            case "endorser":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.endorserReq"
                      )
                    : "";
            case "guaranteed":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.amountGuaranteedReq"
                      )
                    : "";
            case "termsOrRate":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.termsOrRateReq"
                      )
                    : "";
            case "dueDate":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.dueDateReq")
                    : "";
            case "loanType":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.loanTypeReq"
                      )
                    : "";
            case "lenderType":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.lenderTypeReq"
                      )
                    : "";
            case "dateLoan":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.dateLoanReq"
                      )
                    : "";
            case "sub_Trans_Type":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.subTransTypeReq"
                      )
                    : "";
            case "monetaryType":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.monetaryTypeReq"
                      )
                    : "";
            case "dateReceived":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.dateReceivedReq"
                      )
                    : "";
            case "refundDate":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.refundDateReq"
                      )
                    : "";
            case "methodOfCommunication":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.methodReq")
                    : "";
            case "datePaid":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.datePaidReq"
                      )
                    : "";
            case "candidateName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.candidateNameReq"
                      )
                    : "";
            case "org_Name":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.orgNameReq")
                    : "";
            case "employer_Name":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.employerReq"
                      )
                    : "";
            case "occupation_Name":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.occupationReq"
                      )
                    : "";
            case "contribution_Amount":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.contributionAmountReq"
                      )
                    : "";
            case "estimatedDollarValue":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.estimatedDollarValueReq"
                      )
                    : "";
            case "coveredOfficialName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.coveredOfficialNameValueReq"
                      )
                    : "";
            case "official_title":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.officialTitleReq"
                      )
                    : "";
            case "expenditure_category":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.expenditureCategoryReq"
                      )
                    : "";
            case "estimatedValue":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.estimatedValueReq"
                      )
                    : formType.hasError("min")
                    ? this.translocoService.translate(
                          "errorService.giftEstimatedValueMinValue"
                      )
                    : "";
            case "giftTypeId":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.giftTypeIdReq"
                      )
                    : "";
            case "dateGiftReceived":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.giftReceivedReq"
                      )
                    : "";
            case "donorsOrganization":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.donorOrganizationReq"
                      )
                    : "";
            case "organizationType":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.donorOrganizationTypeReq"
                      )
                    : "";
            case "donorName":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.donorNameReq"
                      )
                    : "";
            case "reimbursement":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.reimbursementReq"
                      )
                    : "";
            case "donorCity":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.donorCityReq"
                      )
                    : "";
            case "donorStateCode":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.donorStateCodeReq"
                      )
                    : "";
            case "giftDescription":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.descriptionReq"
                      )
                    : "";
            //firmName
            case "firmName":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.firmName")
                    : "";

            case "firmWebsiteReq":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.firmWebSiteReq"
                      )
                    : "";

            case "firmPhoneNumberReq":
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.firmPhoneNumberReq"
                      )
                    : "";

            //legislative design
            case "agencyName":
                return formType.hasError("required")
                    ? this.translocoService.translate("errorService.agencyName")
                    : "";
            default:
                return formType.hasError("required")
                    ? this.translocoService.translate(
                          "errorService.thisFieldIsRequired"
                      )
                    : formType.hasError("pattern")
                    ? this.translocoService.translate(
                          "errorService.invalidValueOrFormat"
                      )
                    : formType.invalid && (formType.dirty || formType.touched)
                    ? this.translocoService.translate(
                          "errorService.invalidError"
                      )
                    : "";
        }
    }

    isRequired(fieldName: string) {
        return fieldName + " Required.";
    }
}
