import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, Subject } from 'rxjs';
import {
    filter,
    switchMap,
    shareReplay,
    mapTo,
    startWith,
    tap,
} from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import { CurrentPageEnumerator } from '../../models/current-page.model';

@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SignupComponent implements OnInit {
    public currentPage$: Observable<CurrentPageEnumerator>;

    public CurrentPageEnum = CurrentPageEnumerator;
    public email: string;

    private password: string;
    private loginSubject$: Subject<boolean> = new Subject<boolean>();
    private emailConfirmationSubject$: Subject<boolean> =
        new Subject<boolean>();
    private mfaVerificationSubject$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private readonly router: Router,
        private readonly accountService: AccountService,
        private readonly route: ActivatedRoute,
    ) {}

    private readonly token = this.route.snapshot.queryParamMap.get('token');

    ngOnInit(): void {
        const loginUser$ = this.loginSubject$.pipe(
            filter((x) => x),
            switchMap(this.loginUser),
            shareReplay(),
        );

        const successfullLoginStatus$ =
            this.accountService.getUserStatusType('LoggedIn');
        const requiresMfaStatus$ =
            this.accountService.getUserStatusType('RequiresMFA');
        const fail$ = loginUser$.pipe(filter((x) => !x));

        this.currentPage$ = merge(
            successfullLoginStatus$.pipe(
                tap(() => {
                    if (!this.token) {
                        this.router.navigate(['switch'], {
                            queryParams: { login: 1 },
                        });
                    } else {
                        this.router.navigate(['/account/create'], {
                            queryParams: { token: this.token },
                        });
                    }
                }),
                mapTo(this.CurrentPageEnum.Loading),
            ),
            requiresMfaStatus$.pipe(
                mapTo(this.CurrentPageEnum.MFAVerificationForm),
            ),
            fail$.pipe(mapTo(this.CurrentPageEnum.SignupForm)),
            this.loginSubject$.pipe(
                filter((x) => x),
                mapTo(this.CurrentPageEnum.Loading),
            ),
            this.emailConfirmationSubject$.pipe(
                filter((x) => x),
                mapTo(this.CurrentPageEnum.EmailConfirmationForm),
            ),
            this.mfaVerificationSubject$.pipe(
                filter((x) => x),
                mapTo(this.CurrentPageEnum.Loading),
            ),
        ).pipe(startWith(this.CurrentPageEnum.SignupForm));
    }

    registrationSuccess = (e) => {
        const { email, password } = e;
        this.email = email;
        this.password = password;
        this.emailConfirmationSubject$.next(true);
    };

    confirmationSuccess = () => this.loginSubject$.next(true);
    loginUser = () =>
        this.accountService.loginUser(
            this.email,
            this.password,
            false,
            'login.invalidLoginAttempt',
        );
    mfaVerificationSuccess = () => this.mfaVerificationSubject$.next(true);
}
