import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Component
import { HomeLayoutComponent } from './shared/components/home-layout/home-layout.component';
import { FilerLayoutComponent } from './shared/components/filer-layout/filer-layout.component';
import {
    OnlyPublicGuard,
    AuthorizeGuard,
    FilerRequiredGuard,
    AdminUserRequiredGuard,
    requiresFilerDataLoaded,
    requiresActiveNonAgentFiler,
    requiresFeatureTarget,
    skipAuthForPublicFeature,
} from '@maplight/guards';
import { ComingSoonComponent } from './modules/helps/coming-soon/coming-soon.component';
import { AuthenticationRedirectGuard } from '@maplight/guards/authentication-redirect.guard';
import { SimpleLayoutComponent } from './shared/components/simple-layout/simple-layout.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [OnlyPublicGuard],
        component: FilerLayoutComponent,
    },
    {
        path: 'home',
        component: FilerLayoutComponent,
    },
    {
        path: 'lobbyist',
        loadChildren: () =>
            import('./modules/Lobbyist/lobbyist.module').then(
                (m) => m.LobbyistModule,
            ),
    },
    {
        path: 'switch',
        redirectTo: 'authorized-agent',
    },
    {
        path: 'external-landing',
        canActivate: [
            skipAuthForPublicFeature,
            AuthorizeGuard,
            requiresFilerDataLoaded,
            requiresActiveNonAgentFiler,
            requiresFeatureTarget,
        ],
        component: FilerLayoutComponent,
    },
    {
        path: 'invitations',
        loadChildren: () =>
            import('./modules/invitations/invitations.module').then(
                (_) => _.InvitationsModule,
            ),
    },
    {
        path: 'authorized-agent',
        canActivate: [
            AuthorizeGuard,
            requiresFilerDataLoaded,
            AuthenticationRedirectGuard,
        ],
        loadChildren: () =>
            import('./modules/authorized-agent/authorized-agent.module').then(
                (m) => m.AuthorizedAgentModule,
            ),
    },
    {
        path: 'system-management',
        canActivate: [AuthorizeGuard, AdminUserRequiredGuard],
        loadChildren: () =>
            import('./modules/System-Management/system-management.module').then(
                (m) => m.SystemManagementModule,
            ),
    },
    {
        path: 'calendar',
        component: HomeLayoutComponent,
        canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import('./modules/calendar/calendar.module').then(
                (m) => m.CalendarModule,
            ),
    },
    {
        path: 'public',
        component: SimpleLayoutComponent,
        loadChildren: () =>
            import('./modules/public/public.module').then(
                (m) => m.PublicModule,
            ),
    },
    {
        path: 'advanced-search',
        loadChildren: () =>
            import('./modules/advanced-search/advanced-search.module').then(
                (m) => m.AdvancedSearchModule,
            ),
    },
    {
        path: 'legislative-designee',
        loadChildren: () =>
            import('./modules/Legislative-Designee/legislative-designee.module').then(
                (m) => m.LegislativeDesigneeModule
            )
    },
    {
        path: 'grassroot',
        loadChildren: () =>
            import('./modules/grassroot-lobbyist/grassroots-lobbying.module').then(
                (m) => m.GrassrootsLobbyingModule,
            ),
    },
    {
        path: 'help',
        //canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import('./modules/helps/helps.module').then((m) => m.HelpsModule),
    },
    {
        path: 'fines-and-fees',
        loadChildren: () =>
            import('./modules/fines-and-fees/fines-and-fees.module').then(
                (m) => m.FinesAndFeesModule,
            )
    },
    {
        path: 'payments',
        loadChildren: () =>
            import('./modules/payments/payments.module').then(
                (m) => m.PaymentsModule,
            ),
    },
    {
        path: 'account',
        // component: HomeLayoutComponent,
        loadChildren: () =>
            import('./modules/account/account.module').then(
                (m) => m.AccountModule,
            ),
    },
    {
        path: 'activity-report',
        loadChildren: () =>
            import('./modules/activity-report/activity-report.module').then(
                (m) => m.ActivityReportModule,
            ),
    },
    {
        path: 'expenditure-report',
        loadChildren: () =>
            import(
                './modules/expenditure-report/expenditure-report.module'
            ).then((m) => m.ExpenditureReportModule),
    },
    {
        path: 'grassroots-lobbying-report',
        loadChildren: () =>
            import(
                './modules/grassroots-lobbying-report/grassroots-lobbying-report.module'
            ).then((m) => m.GrassrootsLobbyingReportModule),
    },
    {
        path: 'messages',
        loadChildren: () =>
            import('./modules/messages/messages.module').then(
                (m) => m.MessagesModule,
            ),
    },
    {
        path: 'new-client-registration',
        canActivate: [AuthorizeGuard],
        loadChildren: () =>
            import(
                './modules/new-client-registration/new-client-registration.module'
            ).then((m) => m.FilerRegistrationModule),
    },
    {
        path: 'review-registration',
        loadChildren: () =>
            import(
                './modules/review-registration/review-registration.module'
            ).then((m) => m.ReviewRegistrationModule),
    },
    {
        path: 'harassment-prevention-certificate',
        loadChildren: () =>
            import(
                './modules/harassment-prevention-certificate/harassment-prevention-certificate.module'
            ).then((m) => m.HarassmentPreventionCertificateModule),
    },
    {
        path: 'non-session-waiver',
        loadChildren: () =>
            import(
                './modules/non-session-waiver/non-session-waiver.module'
            ).then((m) => m.NonSessionWaiverModule),
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./modules/profile-pages/profile-pages.module').then(
                (m) => m.ProfilePagesModule,
            ),
    },
    {
        path: 'review-filing',
        canActivate: [AuthorizeGuard, AdminUserRequiredGuard],
        loadChildren: () =>
            import('./modules/review-filing/review-filing.module').then(
                (m) => m.ReviewFilingModule,
            ),
    },
    {
        path: 'coming-soon',
        component: ComingSoonComponent,
    },
    {
        path: '**',
        redirectTo: 'coming-soon',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
