import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Menu } from '../../models/app-menu.model';

@Component({
  selector: 'app-account-menu',
  templateUrl: './app-account-menu.component.html',
  styleUrls: ['./app-account-menu.component.scss']
})
export class AppAccountMenuComponent {
  @ViewChild('loginButton', {read: ElementRef}) loginButton: ElementRef;

  @Input() set data(value: Menu){
    this.dataReceived$.next(value);
  }

  protected openMenu: boolean = false;
  protected identifier: string = "app-account-menu-toggler";

  private readonly dataReceived$: ReplaySubject<Menu> = new ReplaySubject<Menu>(1);

  protected menuItems$: Observable<Menu> = this.dataReceived$.pipe(
    map((menu) => menu)
  );

  protected onToggle(): void {
    this.openMenu = !this.openMenu;
  }

  @HostListener("document:keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      this.openMenu = false;
    }
  }

  @HostListener("document:click", ["$event"])
  public clickOutside(event: any): void {
    if(!event.target?.classList.contains(this.identifier)){
      this.openMenu = false;
    }
  }
}
