import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { PageInformationCardComponent } from '../components/page-information-card/page-information-card.component';
import { TranslocoModule } from '@ngneat/transloco';


@NgModule({
    declarations: [PageInformationCardComponent],
    imports: [
        CommonModule,
        TranslocoModule,
    ],
    exports: [PageInformationCardComponent]
})
export class PageInformationCardModule {}
