import { LegislativeExpenditureCategory } from './../modules/grassroots-lobbying-report/models/legislative-expenditure-category-model';
import { LegislativeActionsDocumentComponent } from './../modules/grassroots-lobbying-report/components/legislative-actions-document/legislative-actions-document.component';
import { Injectable } from '@angular/core';
import { MessageThreadFilter } from '../shared/models/app-message.models';
import { BillsSortMode } from '../modules/public/public.models';

export type IEContributionRoute =
    | 'MonetaryContribution'
    | 'InKindContributions'
    | 'RefundTransactions'
    | 'IE';

@Injectable()
export class MasterUrlService {

    getDraftLegislativeDesigneeRegistrations(filerId: string) {
        return `api/legislative-designee/${filerId}/registration/draft`;
    }
    removeDraftLegislativeDesigneeRegistration(filerId: string, registrationId: string) {
        return `api/legislative-designee/${filerId}/registration/${registrationId}`;
    }

    getFiledLegislativeDesigneeRegistrations(filerId: string) {
        return `api/legislative-designee/${filerId}/registration/filed`;
    }

    getActiveLegislativeDesigneeRegistration(filerId: string) {
        return `api/legislative-designee/${filerId}/registration/active`;
    }

    getOrCreateDraftRegistration(filerId: string) {
        return `api/legislative-designee/${filerId}/registration/draft`;
    }

    getNotificationInstance(filerId: string, notificationId: number): string {
        return `api/messaging/${filerId}/notification/${notificationId}`;
    }

    getAccessEthicsFiledReports(filerId: string): string {
        return `api/Filing/PreviousAccessEthicsReportsByFilerId?filerId=${filerId}`;
    }

    readonly getSystemDates = 'api/notification/getSystemDates';

    getUnreadNotificationCount(filerId: string): string {
        return !filerId
            ? 'api/Messaging/unread/admin'
            : `api/Messaging/unread/${filerId}`;
    }

    checkContributionLimits(data: {
        filerId: number;
        contactId: number;
        contributionAmount: number;
        contactTypeId: number;
        previousValue?: number;
        refundAmount?: number;
        previousRefundAmount?: number;
        transactionDate: string;
        fairElections?: boolean;
        electionCycleId: number;
    }): string {
        const options = Object.entries(data)
            .filter(([_, value]) => value !== undefined && value !== null)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        return `${this.checkContributionLimit}?${options}`;
    }

    getMessageThreads(
        isAdmin: boolean,
        filerId?: string,
    ): string {
        return isAdmin
            ? 'api/messaging/messages'
            : `api/messaging/${filerId}`;
    }

    constructor() { }

    getFirmProfilePage = 'firm-page';

    loginUrl = 'api/UserManagement/Authenticate';
    getUserDetailUrl = 'api/UserManagement/GetLoggedInUserInfo';
    blobAttachment = 'api/Attachment';
    user = 'api/UserManagement/user';

    storageDocuments = 'api/Attachment/Storage?fileType=';

    // API Token getting details
    grantType = 'password';
    clientId = 'ro.client';
    clientSecret = 'secret';
    scope = 'api1';
    TokenUrl = '/identityServer/connect/token';

    private applyParams(route: string, bundle: any): string {
        const params = Object.entries(bundle)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        return `${route}?${params}`;
    }

    //User Management
    deleteFilerEntityUser = (_: {
        userId: string | number;
        filerId: string;
        filerTypeId: number;
    }): string =>
        this.applyParams('api/UserManagement/DeleteFilerEntityUser', _);

    requestAffiliation = 'api/filer/affiliation';

    authorizedAgentsOf(lobbyistId: string) {
        return `api/lobbyist/${lobbyistId}/authorized-agents`;
    }

    authorizedAgentSearchBy(fullName: string) {
        const params = new URLSearchParams({ fullName });

        return `api/authorizedAgent/search?${params.toString()}`;
    }

    authorizedAgentAccessFor(agentId: string) {
        return `api/authorizedAgent/${agentId}/access`;
    }

    agentAccessRequestsFor(lobbyistId: string) {
        return `api/lobbyist/${lobbyistId}/authorized-agents/requests`;
    }

    authorizedAgentTargetFor(lobbyistId: string, user: string) {
        return `api/lobbyist/${lobbyistId}/authorized-agents/${user}`;
    }

    authorizedAgentInvitationsInto(lobbyistId: string) {
        return `api/lobbyist/${lobbyistId}/authorized-agents/invitations`;
    }

    authorizedAgentRequestTargetFor(lobbyistId: string, requestId: number) {
        return `api/lobbyist/${lobbyistId}/authorized-agents/requests/${requestId}`;
    }

    authorizedAgentLobbyists(agentId: string) {
        return `api/authorizedAgent/${agentId}/lobbyists`;
    }

    getFilerEntitiesByType = 'api/filer/type';
    getAllFilerEntities = 'api/filer/all';
    searchFiler = 'api/filer/search';
    getAlertsCount = 'api/alert/count';
    getAllAlerts = 'api/alert';
    createCoe = 'api/Committee/createCoveredOfficials?contactId=';
    CreateContactInformation = 'api/UserManagement/CreateContactInformation';
    loginInformation = 'api/UserManagement/LoginInformation';
    committeeGetList = 'api/LookUp/getLookUps';
    ballotInformation = 'api/Committee/ballotissue/getList';
    lookupGetList = 'api/LookUp/getLookUps';
    getallUserType = 'api/UserManagement/GetAllUserType';
    getFilerTypes = 'api/LookUp/getLookUps?lookUpTypeCode=';
    chooseUserType = 'api/UserManagement/ChooseUserType';
    committeeInformation = 'api/committee/createDetails';
    createSmallDonorCommittee = 'api/Committee/createSmallDonorCommittee';
    createPACCommittee = 'api/Committee/createPACCommittee';
    createIssueCommittee = 'api/Committee/createIssueCommittee';
    GetBankInfo = 'api/Committee/GetBankInfo?committeeId=';
    GetCommitteeContactInfo = 'api/Committee/GetCommitteeContactInfo';

    deleteOfficer = 'api/Committee/DeleteOfficer?contactid=';
    lobbyistInformation = 'api/Lobbyist/createLobbyist';
    iefAdditionalInfo = 'api/UserManagement/UpdateIEFAdditionalInfo';
    confirmSubmit = 'api/UserManagement/GetUserAccountConfirmAndSubmit';
    getClients = 'api/Lobbyist/Getclient';
    validateEmailCheck = 'api/UserManagement/VaidateEmailCheck?UserEmailID=';
    validateAspNetUserEmailCheck = (
        email: string,
        forAdmin: boolean = false,
    ): string =>
        `api/UserManagement/validate-email/${email}${forAdmin ? '?forAdmin=true' : ''
        }`;
    getUserByEmail = 'api/UserManag2ement/User';
    validateFilerName = 'api/UserManagement/ValidateFilerName';
    AddNewAspNetUser = 'api/UserManagement/User';
    UpdateAspNetUser = 'api/UserManagement/User';
    getOfficers = 'api/UserManagement/getOfficers?name=';
    getEmployees = 'api/UserManagement/getEmployees?name=';

    getEmployeesByEntity(name: string, entityId: number = 0) {
        return `api/UserManagement/getEmployeesByEntity?name=${name}&entityId=${entityId}`;
    }

    getRegistrationDetails(entityId: number = 0, filerTypeId: number = 0) {
        return `api/UserManagement/GetRegistrationDetails?entityId=${entityId}&filerTypeId=${filerTypeId}`;
    }

    //Public
    getCandidatesElectionCycleList =
        'api/LookUp/getCandidatesElectionCycleList';
    getElectionCyclesByFiler = 'api/Lookup/getElectionCyclesByFiler?filerId=';
    getBallotIssuesElectionCycleList =
        'api/LookUp/getBallotIssuesElectionCycleList';
    getOfficeByElectionCycle =
        'api/LookUp/getOfficesByElectionCycle?electionCycleId=';
    getBallotChartByElectionCycle =
        'api/Committee/GetBallotIssuesGraphByElectionCycle?electionCycleId=';
    getUnaffiliatedCommitteesByElectionCycle =
        'api/Committee/GetUnaffiliatedCommitteeByElectionCycle?electionCycleId=';

    getCandidateGraph(electionCycleId: number = 0, officeId: number = 0) {
        return `api/Committee/GetCandidateGraphByElectionCycleAndOffice?electionCycleId=${electionCycleId}&officeId=${officeId}`;
    }

    // Legislative Designee
    legislativeDesigneeRegistration = (filerId: string) => `api/legislative-designee/${filerId}/registration`;
    legislativeDesigneeRegistrationAssociate = (filerId: string, registrationId: string) => `api/legislative-designee/${filerId}/registration/${registrationId}/associate`;

    // officer
    getOfficerTableList = 'api/Committee/GetOfficersList?committeeid=';
    getFileExtensionWhiteList = 'api/LookUp/GetFileExtensionWhiteList';

    //Lobbyist
    saveLobbyistNotes = 'api/Lobbyist/Notes';
    getLookuplist = 'api/LookUp/getLookUps?lookUpTypeCode=';
    getEmployeeLobbyist = 'api/Lobbyist/getLobbyistEntities';
    getLobbyistEmployees = 'api/Lobbyist/Employees';
    getLobbyistSearchEmployees = 'api/Lobbyist/SearchEmployees';
    getLobbyistSubcontractors = 'api/Lobbyist/Subcontractors';
    getLobbyistClients = 'api/Lobbyist/Clients';
    getLobbyistRelationships = 'api/Lobbyist/Relationships';
    getLobbyistPenalties = 'api/Lobbyist/Penalties';
    getLobbyistYears = 'api/Lobbyist/LobbyistYears';
    getExpenseFilingPeriods = 'api/Lobbyist/FilingPeriods';
    getLobbyistExpenses = 'api/Lobbyist/Expenses';
    getAllLobbyistClients = 'api/Lobbyist/AllClients?filerId=';
    getSearchLobbyistByUserFullName =
        'api/Lobbyist/SearchLobbyistByUserFullName';
    getBusinessAddressByFirm = 'api/Lobbyist/BusinessAddressByFirm';
    getAllRegisteredFirms = 'api/firm/lobbyist-firms';
    getAllRegisteredFirmsCsv = 'api/firm/csv';
    getAllGrassrootLobbyistFiledReports = (grassrootId: string) =>
        `api/grassroots-lobbying-report/${grassrootId}/filed`;
    getAllGrassrootLobbyistDraftedReports = (grassrootId: string) =>
        `api/grassroots-lobbying-report/${grassrootId}/drafts`;

    getPublicFilingInfo = 'api/Filing';

    getLobbiestById = 'api/Lobbyist/GetLobbyist?lobbyistId=';
    getLobbyist = 'api/Lobbyist/';
    getSearchLobbyist = 'api/Lobbyist/getLobbyistEntitiesbyName';
    getLobbyistListByUserId = 'api/Lobbyist/GetLobbyistListByUserId';
    getLobbyisAlltListByUserIdFilerId =
        'api/Lobbyist/getLobbyisAlltListByUserIdFilerId';
    getLobbyistEmployee = 'api/Lobbyist/GetLobbyistEmployee';
    getLobbyistAssociates(lobbyistId: string) {
        return `api/Lobbyist/${lobbyistId}/associates`;
    }
    getCurrentLobbyistUserData = 'api/Lobbyist/GetCurrentLobbyistUserData';
    getSearchAuthorizedAgentsByUserFullName =
        'api/Lobbyist/SearchAuthorizedAgentsByUserFullName';
    getAllLegislativesCommittees = 'api/LegislativeCommittee';
    getStateAgencies = 'api/StateAgency';
    getAuthorizedAgent = 'api/AuthorizedAgent';

    // Archived Files
    archivedFiles = 'api/ArchivedFile';

    // Client
    getAllClientsByName = 'api/client/all';

    // Client Registration
    getRegistrationApprovals = 'api/ClientRegistration/approvals';
    getActiveRegistrations = 'api/ClientRegistration/active-registrations';
    getActiveRegistrationsCsv =
        'api/ClientRegistration/active-registrations/csv';

    getLobbyistClientRegistrationPdf = (
        clientRegistrationId: string,
        version: number = 1,
    ) =>
        `api/ClientRegistration/${clientRegistrationId}/version/${version}/pdf`;
    /**
     * Url to create a new client registration.
     * @param owner A Lobbyist ID representing the owner of the client registration.
     */
    addClientRegistration = `api/ClientRegistration`;

    amendClientRegistration = (clientRegistrationId: string) =>
        `api/ClientRegistration/${clientRegistrationId}`;

    // Nature Of Business
    getNatureOfBusiness = 'api/NatureOfBusiness';

    //calendar
    saveElectionCycle = 'api/Calendar/saveElectionCycle';
    deleteElectionCycle = 'api/Calendar/deleteElectionCycle?electionCycleId=';
    getElectionCycleById = 'api/Calendar/getElectionCycleById?electionCycleId=';
    getElectionCycles = 'api/Calendar/getElectionCycles';
    getCandidateElectionCycles =
        'api/Calendar/getElectionCycles/type=Candidate';
    saveEvents = 'api/Calendar/SaveEvents';
    deleteEvents = 'api/Calendar/DeleteEvents?eventId=';
    getEvents = 'api/Calendar/GetEvents';
    saveFilingPeriod = 'api/Calendar/SaveFilingPeriod';
    deleteFilingPeriod = 'api/Calendar/DeleteFilingPeriod';
    getFilingPeriods = 'api/Calendar/GetFilingPeriods';
    getFilingPeriodsById = 'api/Calendar/GetFilingPeriodById?filingperiodid=';
    getEventsById = 'api/Calendar/GetEventsById?eventId=';
    getCityItemFilingPeriods = 'api/CityItem/FilingPeriods';
    getCityItemFilingInformation = 'api/CityItem/FilingInformation';

    //switchCommittee
    getUserEntitiesDetails = 'api/Filer/';
    getCommiteesListNew = 'api/Committee/GetCommitteeByName';
    addCommitteeAffiliation = 'api/Committee/SaveCommitteeAffiliation';

    //Calendar API's
    getCalendarList = 'api/Calendar/getCalendarEvents?';
    getPublicCalendarList = 'api/Calendar/getPublicCalendarEvents?';
    getFilerCalendarList = 'api/Calendar/getFilerCalendarEvents?';
    latefine = 'api/Payments/SaveFine';
    deleteFine = 'api/Payments/DeleteFine';
    getFines = 'api/Payments/GetFines';
    addfees = 'api/Payments/SaveFees';
    getfees = 'api/Payments/GetFees';
    deleteFees = 'api/Payments/DeleteFees';

    //download calendar
    downloadCalendar = 'api/Calendar/DownloadCalendar';

    // paymenyts and Waivers
    getAdminOutStandingFeeSummary =
        'api/Payments/GetAdminOutStandingFeeSummary';
    getOustandingSummary = 'api/Payments/GetOutStandingFeeSummary?filerid=';
    getOutstandingGrid = 'api/Payments/GetInvoices';
    updateStatus = 'api/Payments/UpdateInvoiceStatus';
    getFilerInvoices = 'api/Payments/GetFilerInvoices';
    savePenalty = 'api/Payments/SavePenalty';
    saveTransaction = 'api/Payments/SaveTransaction';
    getpaymentHistory = 'api/Payments/getPaymentHistory';
    dwnldPaymentHistory = 'api/Payments/DownloadPaymentHistory';
    getInvoiceFromId = 'api/Payments/GetInvoiceInfoFromId?invoiceid=';
    getEntityPaymentHistory = 'api/Payments/getEntityPaymentHistory';
    getExpenditures = 'api/Filing/getTransactionsByFiler';
    getloans = 'api/Filing/GetfilerLoandetails';
    getInvoiceStatus = 'api/Payments/GetInvoiceStatus';

    // Payment gateway
    newPaymentSession(invoice: number) {
        return `api/payment-gateway/invoice/${invoice}/session`;
    }

    // Transaction
    saveContribution = 'api/Transaction/saveContribution';
    saveIe = 'api/Transaction/saveIE';
    downloadUploadTransactionsTemplate =
        'api/transaction/DownloadUploadTransactionsTemplate';
    uploadTransactionsTemplate = 'api/transaction/uploadTransaction';
    getMonetaryContributionsByFiler =
        'api/transaction/GetMonetaryContributionTransactionsByFiler';
    getInKindContributionsByFiler =
        'api/transaction/GetInKindContributionTransactionsByFiler';
    getFairElectionsFundingTransactionsByFiler =
        'api/transaction/GetFairElectionsFundingTransactionsByFiler';
    getRefundTransactionsByFiler =
        'api/transaction/GetRefundTransactionsByFiler';
    getExpenditureTransactionsByFiler =
        'api/transaction/GetExpenditureTransactionsByFiler';
    getUnpaidObligationTransactionsByFiler =
        'api/transaction/GetUnpaidObligationTransactionsByFiler';
    getFEFQualifyingContributionTransactionsByFiler =
        'api/transaction/GetFEFQualifyingContributionTransactionsByFiler';
    getLoanTransactionsByFiler = 'api/transaction/GetLoanTransactionsByFiler';
    getMonetaryContributionUnitemizedTotal =
        'api/transaction/getCampaignFinanceReportMonetaryContributionUnitemizedTotalByFilerAndFilingPeriod';
    getInKindContributionUnitemizedTotal =
        'api/transaction/getCampaignFinanceReportInKindContributionUnitemizedTotalByFilerAndFilingPeriod';
    getExpendituresUnitemizedTotal =
        'api/transaction/getCampaignFinanceReportExpenditureUnitemizedTotalByFilerAndFilingPeriod';
    deleteTransaction = 'api/transaction/DeleteTransaction?transactionid=';
    deleteTransactionRefund =
        'api/transaction/DeleteTransactionRefund?parentTransactionId=';

    deleteLoan = 'api/transaction/DeleteLoan?loanId=';
    //transactions-loan
    saveLoan = 'api/Transaction/SaveLoan';
    saveExpenditure = 'api/Transaction/saveExpenditure';
    saveUnpaidObligation = 'api/Transaction/saveUnpaidObligation';
    saveIETransaction = 'api/Transaction/saveIE';
    GetFilingSearchTransactions = 'api/Transaction/GetFilingSearchTransactions';

    //FEF Withdrawal
    getAdminFEFWithdrawalInfoByFiler =
        'api/FEFWithdrawal/getAdminFEFWithdrawalQualifyForPublicFundingUserEntityDetailsByFiler?filerId=';
    getFEFWithdrawalInfoByFiler =
        'api/FEFWithdrawal/getFEFWithdrawalQualifyForPublicFundingUserEntityDetailsByFiler?filerId=';
    saveFEFWithdrawalQualifyForPublicFunding =
        'api/FEFWithdrawal/SaveFEFWithdrawalQualifyForPublicFunding';
    saveFEFWithdrawalQualifyForPublicFundingRecordDetail =
        'api/FEFWithdrawal/SaveFEFWithdrawalQualifyForPublicFundingRecordDetail';

    // FEF Qualifying-contributions
    linkFEFDisbursementWithContributions =
        'api/FEFQualifyingContributions/linkFEFDisbursementWithContributions';
    saveFEFDisbursement = 'api/FEFDisbursement/';
    getFEFDisbursementInfoByFiler = 'api/FEFDisbursement/';
    deleteFEFDisbursement = 'api/FEFDisbursement/';

    //FEFApplication
    getFEFQualifyingContributions = 'api/FEFQualifyingContributions/';
    getFEFReport = 'api/FEFQualifyingContributions/report?filingId=';
    getFEFReportPDF = 'api/FEFQualifyingContributions/PDFreport?filingId=';

    getFEFApplicationFilingInfouserDetailsByFilerandElection(
        filerId: number,
        filingId: number = null,
    ) {
        if (filingId) {
            return `api/FEFApplication/getFEFApplicationQualifyingContributionFilingInfoUserEntityDetailsByFiler?filerId=${filerId}&filingId=${filingId}`;
        }
        return `api/FEFApplication/getFEFApplicationQualifyingContributionFilingInfoUserEntityDetailsByFiler?filerId=${filerId}`;
    }

    getFEFApplicationSummaryInfo =
        'api/FEFApplication/getFEFApplicationSummaryInfoByFiler?filerId=';
    getFEFApplicationSummaryInfoSubmitted =
        'api/FEFApplication/getFEFApplicationSummaryInfoByFilerSubmitted?filerId=';
    getFEFApplicationTransactionsByFiler =
        'api/FEFApplication/getFEFApplicationTransactionsByFiler?filerId=';
    saveFEFApplicationQualifyForPublicFunding =
        'api/FEFApplication/SaveFEFApplicationQualifyForPublicFunding';
    GetFEFApplicationFilingbyFilerId =
        'api/FEFApplication/GetFEFAdminApplicationFilingbyFilerId?filerId=';
    GetFEFAdminApplicationFilingInformationbyFilingId =
        'api/FEFApplication/getFEFAdminApplicationFilingInformationbyFilingId?filingId=';
    GetFEFApplicationSummaryInfoByFiling =
        'api/FEFApplication/getFEFApplicationSummaryInfoByFiling?filingId=';
    GetFEFApplicationTransactionsByFiling =
        'api/FEFApplication/getFEFApplicationTransactionsByFiling?filingId=';
    GetAdminFEFApplicationTransactionsByFiling =
        'api/FEFApplication/getAdminFEFApplicationTransactionsByFiling?filingId=';

    //fefparticipationstatus
    getParticipationStatuscurrentbyFilingId =
        'api/fefparticipationstatus/currentbyFilingId';

    //FEF Qualifying Contributions
    GetFEFQualifyingContributions = 'api/FEFQualifyingContributions/';
    GetFEFQualifyingContributionsDownload =
        'api/FEFQualifyingContributions/download';

    //FEF FEFTransactionStatus
    updateFEFTransactionStatus = 'api/FEFTransactionStatus/';

    //Admin manage users
    getAdministratorUser = 'api/UserManagement/administrators';
    getFilerDetailsByName = 'api/UserManagement/GetFilerDetailsByName';
    getUserAffiliations = 'api/UserManagement/GetUserAffiliations';
    adminInviteUser = 'api/UserManagement/invite-user';
    addNewAdministratorAspNetUser = 'api/UserManagement/invite-user';
    revokeAdministratorUserAccess(userId: string) {
        return 'api/UserManagement/revoke-access/' + userId;
    }
    updateFilerStatus(filerId: string, statusId: number) {
        return `api/filer/${filerId}/status/${statusId}`;
    }
    getUserList = 'api/UserManagement/UserList';
    getManageUsers = 'api/UserManagement';
    getApplicationUserStatus = 'api/LookUp/getApplicationUserStatusesList';
    approveFilerFEFParticipationStatus = 'api/fefparticipationstatus/approve';
    revokeFilerFEFParticipationStatus = 'api/fefparticipationstatus/revoke';
    denyFilerFEFParticipationStatus = 'api/fefparticipationstatus/deny';

    //Filing
    changePublicationStatus = 'api/Filing/PublicationStatus';
    GetTransactionDetailsById = 'api/Filing/GetTransactionDetailsById?txnId=';
    getLoanById = 'api/Filing/EditLoan?loanId=';
    getFilingInfo = 'api/Filing/getFilingInfo';
    SubmitFiling = 'api/Filing/SaveFilingCampaignFinanceReport';
    SubmitContributionReport =
        'api/Filing/SaveFillingQualifyingContributionReport';
    updateFilingStatus = 'api/Filing/UpdateFilingStatus';
    updateFilingNoteOrStatus = 'api/Filing/UpdateFilingNoteOrStatus';
    updateFilingNote = 'api/Filing/UpdateFilingNote';
    getFilingCyclById = 'api/Filing/GetFilingPeriodsByElectionCycle';
    getSummaryInfo = 'api/Filing/GetSummaryInfo';
    getSummaryInfoByFiling = 'api/filing/GetSummaryInfoByFiling';
    getTransactionsByFiler = 'api/Filing/getTransactionsByFiler';
    getMonetaryContributionsByFiling =
        'api/transaction/GetMonetaryContributionTransactionsByFiling';
    getInKindContributionsByFiling =
        'api/transaction/GetInKindContributionTransactionsByFiling';
    getIEContributionsByFiling =
        'api/transaction/GetIEContributionTransactionsByFiling';
    getFEFQualifyingContributionTransactionsByFiling =
        'api/transaction/GetFEFQualifyingContributionTransactionsByFiling';
    getFairElectionsFundingTransactionsByFiling =
        'api/transaction/GetFairElectionsFundingTransactionsByFiling';
    getRefundTransactionsByFiling =
        'api/transaction/GetRefundTransactionsByFiling';
    getExpenditureTransactionsByFiling =
        'api/transaction/GetExpenditureTransactionsByFiling';
    getCampaignFinanceReportExpenditureUnitemizedTotalByFiling =
        'api/transaction/GetCampaignFinanceReportExpenditureUnitemizedTotalByFiling';
    getCampaignFinanceReportMonetaryContributionUnitemizedTotalByFiling =
        'api/transaction/GetCampaignFinanceReportMonetaryContributionUnitemizedTotalByFiling';
    getCampaignFinanceReportInKindContributionUnitemizedTotalByFiling =
        'api/transaction/GetCampaignFinanceReportInKindContributionUnitemizedTotalByFiling';
    getUnpaidObligationTransactionsByFiling =
        'api/transaction/GetUnpaidObligationTransactionsByFiling';
    getLoanTransactionsByFiling = 'api/transaction/GetLoanTransactionsByFiling';
    getFillingCampaignFinanceExistingReports =
        'api/transaction/GetExistingReport';
    addFillingNote = 'api/filing/SaveFilingMappingRecordNote';
    getUserEntityDetailsByFiling =
        'api/UserManagement/GetUserEntityDetailsByFiling';
    getCampaignFinanceReportFilingInfoByFiler =
        'api/filing/getCampaignFinanceReportFilingInfoByFiler?filerId=';
    GetMCRTransactionsbyFiler = 'api/transaction/GetMCRTransactions';
    //Upload
    uploadSave = 'api/Attachment/upload';
    uploadSaveAttachments = 'api/Attachment/SaveAttachments';
    uploadRemove = 'api/Attachment/delete';

    // Filings
    GetReviewFilingsCampaignFinanceReport =
        'api/Filing/GetReviewFilingsCampaignFinanceReport';

    getReviewFilingsCampaignFinanceReportByFilerIdbyGroupFilings(
        filerId: string,
        filingTypeId: number = 0,
    ) {
        if (filingTypeId > 0) {
            return `api/Filing/ReviewFilingCampaignFinanceReport/Filer/?filerId=${filerId}&filingTypeId=${filingTypeId}`;
        }
        return `api/Filing/ReviewFilingCampaignFinanceReport/Filer/?filerId=${filerId}`;
    }

    getCampaignFilingByCommittee =
        'api/Filing/GetCampaignFilingByCommittee/Committee/?committeeId=';
    GetReviewFilingsLobbyingReport =
        'api/Filing/GetReviewFilingsLobbyingReport';
    getReviewFilingsLateReport = 'api/Filing/GetReviewFilingsLateReport';
    getReviewFilingsRepeatLateOffendersReport =
        'api/Filing/GetReviewFilingsRepeatLateOffendersReport';
    getReviewFilingsEthicsReport = 'api/Filing/GetReviewFilingsEthicsReport';
    downloadEthicsBoard = 'api/filing/EthicsBoard';
    getFEFParticipationStatusByFiling =
        'api/Filing/GetFEFParticipationStatusByFiling';

    // City
    getCityItemRecords = 'api/CityItem/records';
    saveCityItemReport = 'api/CityItem/Report';

    //chat
    GetUserChatList = 'api/Chat/GetUserChatList';

    //Access Ethics
    getAccessEthicsByFilerId =
        'api/AccessEthics/GetAccessEthicsByFilerId?filerId=';
    GetAccessEthicsById = 'api/AccessEthics/GetAccessEthicsById';
    saveAccessEthicsRegistration =
        'api/AccessEthics/saveAccessEthicsRegistration';
    joinAccessEthicsRegistration =
        'api/AccessEthics/joinAccessEthicsRegistration';
    //Gift
    saveGift = 'api/Gift/saveGift';
    deleteGift = 'api/Gift/deleteGift?giftId=';
    getGiftById = 'api/Gift/getGiftById?giftId=';

    saveGiftRefund = 'api/Gift/saveGiftRefund';
    getGiftRefundList = 'api/Gift/GetGiftRefunds';
    deleteGiftRefund = 'api/Gift/deleteGiftRefund?giftRefundId=';
    getGiftRefundById = 'api/Gift/getGiftRefundById?giftRefundId=';

    //FinancialDisclosure
    saveFinancialDisclosure = 'api/FinancialDisclosure';
    submitFinancialDisclosure = 'api/FinancialDisclosure/submit';
    getFinancialDisclosureById =
        'api/FinancialDisclosure/getFinancialDisclosureById?filingId=';
    getFinancialDisclosureBusinessInterestsByFinancialDisclosure =
        'api/FinancialDisclosure/getFinancialDisclosureBusinessInterestByFiling?filingId=';
    getFinancialDisclosureSourceOfIncomesByFinancialDisclosure =
        'api/FinancialDisclosure/getFinancialDisclosureSourceOfIncomeByFiling?filingId=';
    getFinancialDisclosureLobbyingCompensationsByFinancialDisclosure =
        'api/FinancialDisclosure/getFinancialDisclosureLobbyingCompensationByFiling?filingId=';
    getFinancialDisclosurePrivateTrustsByFinancialDisclosure =
        'api/FinancialDisclosure/getFinancialDisclosurePrivateTrustByFiling?filingId=';
    getFinancialDisclosurePropertyOwnerShipsByFinancialDisclosure =
        'api/FinancialDisclosure/getFinancialDisclosurePropertyOwnerShipByFiling?filingId=';
    getFinancialDisclosureAssetTransfersByFinancialDisclosure =
        'api/FinancialDisclosure/getFinancialDisclosureAssetTransferByFiling?filingId=';
    getFinancialDisclosureCreditorsByFinancialDisclosure =
        'api/FinancialDisclosure/getFinancialDisclosureCreditorsByFiling?filingId=';
    getFinancialDisclosureEmployersByFinancialDisclosure =
        'api/FinancialDisclosure/getFinancialDisclosureEmployersByFiling?filingId=';
    getContactInformationFinancialDisclosure =
        'api/FinancialDisclosure/ContactInformation?filerId=';
    getContactInformationFinancialDisclosureByFiling =
        'api/FinancialDisclosure/ContactInformationByFiling?filingId=';
    getExistingFinancialDisclosure = (filerId: string, committeeId: string) =>
        'api/FinancialDisclosure?filerId=' +
        filerId +
        '&committeeId=' +
        committeeId;
    getFEFParticipationStatusTypesList =
        'api/LookUp/getFEFParticipationStatusTypesList';
    SaveFEFIntentQualifyForPublicFundingRecordNoteAndStatus =
        'api/FEFIntent/SaveFEFIntentQualifyForPublicFundingRecordNoteAndStatus';

    getEthicsContactInformationByFiler =
        'api/EthicsFinancialDisclosure/contact-information/filer/';
    getEthicsContactInformationByFiling =
        'api/EthicsFinancialDisclosure/contact-information/filing/';
    getEthicsFinancialDisclosureReport = 'api/EthicsFinancialDisclosure/report';
    submitEthicsFinancialDisclosureReport =
        'api/EthicsFinancialDisclosure/submit';
    saveEthicsFinancialDisclosureReport = 'api/EthicsFinancialDisclosure/save';

    getCurrentFEFParticipationStatus = 'api/fefparticipationstatus/current';
    getCurrentFEFParticipationStatusByDate =
        'api/fefparticipationstatus/currentbydate';
    GetFairElectionFundingByFilerId =
        'api/filing/getFairElectionFundingByFilerId?filerId=';
    getFEFIntentQualifyForPublicFundingUserEntityDetailsByFilerId =
        'api/FEFIntent/getFEFIntentQualifyForPublicFundingUserEntityDetailsByFiler?filerId=';
    saveIntent = 'api/FEFIntent/SaveFEFIntentQualifyForPublicFunding';
    verifyFEFAddress = 'api/FEFAddressVerification/VerifyAddress';

    //TransactionSearch
    SearchExpenditureTransactions =
        'api/Transaction/SearchExpenditureTransactions';
    SearchContributionTransactions =
        'api/Transaction/SearchContributionTransactions';
    SearchPenalties = 'api/Transaction/SearchPenalties';

    //Transactions
    updateTransactionAdminNotes = 'api/Transaction/notes/admin/';

    //AdminDocuments
    adminDocumentsApi = 'api/AdminDocument';
    adminDocumentsViewer = 'api/AdminDocument/Viewer?adminDocumentId=';
    adminDocumentsByFilerIdApi = 'api/AdminDocument/ByFilerId';
    getComplaintStatuses = 'api/AdminDocument/GetComplaintStatuses';
    getAdminDocTypes = 'api/AdminDocument/GetAdminDocTypes';

    //Candidate
    candidateApi = 'api/Candidate';
    searchCandidateApi = 'api/Candidate?name=';

    searchCandidateApiByElectionCycleId(name: string, electionCycleId: number) {
        return `${this.searchCandidateApi}${name}&electionCycleId=${electionCycleId}`;
    }

    candidateApiByElectionCycleId(electionCycleId: number) {
        return `${this.candidateApi}?electionCycleId=${electionCycleId}`;
    }

    //Filer
    filerApi = 'api/Filer';
    getFilerById = 'api/Filer/filer/';
    filingSnapshot = 'api/filer/snapshot';
    terminateFiler = 'api/Filer/terminate';

    getFilerIEInfoFiler = 'api/filer/getFilerIEInfoFiler';

    //FilerTypes
    Lobbyists = 1;
    IE = 2;
    Committee = 3;
    EthicsFiler = 4;

    // Local Storage Keys
    UserFilers = 'userfilers';
    AdminSelectedUserFilers = 'adminSelectedUserFiler';
    PublicSelectedUserFilers = 'publicSelectedUserFiler';
    UserEntity = 'userEntity';
    UserData = 'UserData';
    CurrentCommittee = 'CurrentCommittee';

    //Election Type
    Candidate = 1;
    Issue = 2;
    Special = 3;

    //Election Type
    ElectionCycleStatusActive = 1;
    ElectionCycleStatusCompleted = 2;
    ElectionCycleStatusCanceled = 3;

    fairElectionParticipantByElectionCycle =
        'api/Committee/GetFairElectionsParticipatingCandidatesByElectionCycle?electionCycleId=';
    getContributionLocationByElectionCycle =
        'api/Committee/GetContributionsLocationByElectionCycle?electionCycleId=';
    getFairElectionDistributionsByElectionCycle =
        'api/Committee/GetFairElectionsDistributionsByElectionCycle?electionCycleId=';
    getElectionCycleByTypeId =
        'api/LookUp/getElectionCycleByType?electionTypeId=';
    getElectionCycleByTypes =
        'api/LookUp/getElectionCycleByTypes?electionTypeIds=';
    getElectionCyclesFromCommitteeByFiler =
        'api/LookUp/getElectionCyclesFromCommitteeByFiler?filerId=';
    dashboardItems = 'api/LookUp/dashboard-items';

    //Committee
    committeeList = 'api/Committee/getListByName';
    committeeAditionalInfo = 'api/committee/UpdateCommitteeAdditionalInfo';
    committeeByName = 'api/Committee/GetCommitteeByName';
    getCurrentCommitteeLastedCreatedForFiler =
        'api/Committee/getCurrentCommitteeLastedCreatedForFiler';
    SearchCommitteeByName =
        'api/Committee/SearchCommitteeByName?searchCommittee=';
    SearchCommitteeByNameAndType =
        'api/Committee/SearchCommitteeByNameAndType?searchCommittee=';
    SearchLobbyistByName = 'api/lobbyist/search';
    searchLobbyistClientByName =
        'api/Lobbyist/SearchLobbyistClientByName?client=';
    getCommitteeById = 'api/Committee/GetCommitteeById?filerId=';
    saveCommitteeAffiliation = 'api/Committee/SaveCommitteeAffiliation';
    getAllCommitteesAndCandidate =
        'api/Committee/getAllCommitteesAndCandidate?search=';

    getRejectedAffiliationRequests = (_: { filerId: string }): string =>
        this.applyParams(
            'api/UserManagement/GetRejectedAffiliationRequests',
            _,
        );

    getAffiliationRequests = (_: { filerId: string }): string =>
        this.applyParams('api/UserManagement/GetAffiliationRequests', _);

    getPendingInvitationsByFiler = (_: { filerId: string }): string =>
        this.applyParams('api/UserManagement/GetPendingInvitationsByFiler', _);

    getInvitationDetails = (_: { token: string }): string =>
        this.applyParams('api/UserManagement/GetInvitationDetails', _);

    resolveInvitation = 'api/UserManagement/ResolveInvitation';
    setAsPrimary = 'api/UserManagement/MakePrimaryUser';
    adminSetAsPrimary = 'api/UserManagement/AdminSetAsPrimaryUser';

    saveLoginEvent = 'api/Usermanagement/login-event';

    getFilerDashboardTotals = 'api/committee/Totals';
    getCurrentCommitteeByFiler = 'api/committee/getcurrentbyfiler';
    getCurrentCommitteeFefstatusIdByFiler =
        'api/committee/GetCurrentCommitteeFefstatusIdByFiler';
    approveDenyAffiliation = 'api/UserManagement/ApproveDenyAffiliation';
    GetCommitteeByUserId = 'api/Committee/GetCommitteeByUserId?userId=';
    editCommittee = 'api/Committee/EditCommittee';
    GetAllUserEntityListByUserId =
        'api/UserManagement/GetAllUserEntityListByUserId?userId=';
    getCommitteeUserTypeList = 'api/LookUp/getCommitteeUserTypeList';
    GetCommitteeRegistrationById =
        'api/Committee/GetCommitteeRegistrationById/';
    GetCurrentByFiler = 'api/Committee/GetCurrentByFiler?filerId=';

    //Contact

    getContacts = 'api/Contact/getContacts?name=';
    searchContacts = 'api/Contact/SearchContacts';
    getContactDetailsById = 'api/Contact/getContactDetailsById?contactId=';
    getCandidatesByElectionCycle =
        'api/contact/GetCandidatesByElectionCycle?electionCycleId=';

    getOpposingCandidates(
        electionCycleId: number,
        filerId: number,
        office: string,
        district: string,
    ) {
        return `api/contact/GetOpposingCandidates?electionCycleId=${electionCycleId}&filerId=${filerId}&office=${office}&district=${district}`;
    }

    editContact = 'api/contact/EditContact';
    editContactRecordStatusId = 'api/contact/editContactRecordStatusId';
    //Committee Office
    getStatelist = 'api/common/state/getList';
    getStatusList = 'api/LookUp/getStatusList';
    getOfficerTypeList = 'api/LookUp/getLookUps';
    getAllOfficeList = 'api/LookUp/getLookUps?lookUpTypeCode=OFF';
    getList = 'api/LookUp/getLookUps';
    getCommitteeList = 'api/Committee/getList';
    getOfficeSearchByName = 'api/Committee/GetOfficersListByName?officerName=';
    getCommitteeDetails = 'api/Committee/GetCommitteeDetail';
    getManageCommitteeList = 'api/UserManagement/GetManageFilerDetail';
    downloadCSV = 'api/Committee/DownloadManageFilerList';
    // getOfficerTypesList = "api/LookUp/getOfficerTypesList";
    saveOfficers = 'api/Officer/saveOfficers';
    addNewCommitteeUser = 'api/Committee/addNewCommitteeUser';
    //Ballot Issue
    getBallotIssueById = 'api/Committee/GetBallotIssuedById?ballotIssueId=';
    getSupportingCommitteesByBallotIssued =
        'api/Committee/GetSupportingCommitteesByBallotIssued?ballotIssueId=';
    getOpposingCommitteesByBallotIssued =
        'api/Committee/GetOpposingCommitteesByBallotIssued?ballotIssueId=';
    getSupportingContributorsByBallotIssued =
        'api/Committee/GetSupportingContributorsByBallotIssued?ballotIssueId=';
    getLinkedIssueCommitteesBallots =
        'api/SystemManagement/GetLinkedIssueCommitteesBallots?searchKeyword=';
    updateLinkedIssueCommitteeBallot =
        'api/SystemManagement/UpdateLinkedIssueCommitteeBallot';

    //getSupportingContributorsByCommittee = 'api/Committee/GetSupportingContributorsByCommittee?filerId=';
    getSupportingContributorsByCommittee(
        filerId: number,
        electionCycleId: number,
    ) {
        return `api/Committee/GetSupportingContributorsByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }

    getOpposingContributorsByBallotIssued =
        'api/Committee/GetOpposingContributorsByBallotIssued?ballotIssueId=';
    getSupportingIndependentSpendersByBallotIssued =
        'api/Committee/GetSupportingIndependentSpendersByBallotIssued?ballotIssueId=';

    //getSupportingIndependentSpendersByCommittee = 'api/Committee/GetSupportingorOpposingIndependentSpendersByCommittee?comitteeId=';
    getSupportingIndependentSpendersByCommittee(
        filerId: number,
        electionCycleId: number,
        positionType: number,
    ) {
        return `api/Committee/GetSupportingorOpposingIndependentSpendersByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}&positionType=${positionType}`;
    }

    getOpposingIndependentSpendersByCommittee(
        filerId: number,
        electionCycleId: number,
        positionType: number,
    ) {
        return `api/Committee/GetSupportingorOpposingIndependentSpendersByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}&positionType=${positionType}`;
    }

    getOpposingIndependentSpendersByBallotIssued =
        'api/Committee/GetOpposingIndependentSpendersByBallotIssued?ballotIssueId=';

    getSupportingExpendituresByBallotIssued =
        'api/Committee/GetSupportingExpendituresByBallotIssued?ballotIssueId=';

    //getExpendituresByCommittee = 'api/Committee/getExpendituresByCommittee?committeeId=';
    getExpendituresByCommittee(filerId: number, electionCycleId: number) {
        return `api/Committee/getExpendituresByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }

    getOpposingExpendituresByBallotIssued =
        'api/Committee/GetOpposingExpendituresByBallotIssued?ballotIssueId=';
    getSupportingExpendituresTotalByBallotIssued =
        'api/Committee/GetSupportingExpendituresTotalByBallotIssued?ballotIssueId=';

    //getExpendituresTotalByCommittee = 'api/Committee/GetExpendituresTotalByCommittee?committeeId=';
    getExpendituresTotalByCommittee(filerId: number, electionCycleId: number) {
        return `api/Committee/getExpendituresTotalByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }

    getContributionsTotalByCommittee(filerId: number, electionCycleId: number) {
        return `api/Committee/getContributionsTotalByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }

    getFEFContributionsTotalByCommittee(
        filerId: number,
        electionCycleId: number,
    ) {
        return `api/Committee/GetFEFContributionTotalByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }

    getFEFExpendituresTotalByCommittee(
        filerId: number,
        electionCycleId: number,
    ) {
        return `api/Committee/getFEFExpendituresTotalByCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }

    getOpposingExpendituresTotalByBallotIssued =
        'api/Committee/GetOpposingExpendituresTotalByBallotIssued?ballotIssueId=';
    getFinancialOverviewByBallotIssued =
        'api/committee/GetFinancialOverviewByBallotIssued?ballotIssueId=';

    getFinancialOverviewByCandCommittee(
        filerId: number,
        electionCycleId: number,
    ) {
        return `api/committee/getFinancialOverviewByCandCommittee?filerId=${filerId}&electionCycleId=${electionCycleId}`;
    }

    getCashOnHand = 'api/committee/GetCashOnHand?filerId=';
    readonly getFinancialOverviewByCommittee =
        this.getFinancialOverviewByCandCommittee;

    //Committee  to support , Second Committee ,Neighbors Opposed
    getCampaignFilingFilersByBallotIssue =
        'api/Committee/GetCampaignFilingFilerIds?ballotIssueId=';
    getCampaignFilingFiler =
        'api/Committee/GetCampaignFilingFilerByFilerId?filerId=';
    getCampaignFilingsByFiling =
        'api/Committee/GetCampaignFilingByFilingId?filingId=';
    getCampaignFilingByBallotIssue =
        'api/Committee/GetCampaignFilingByBallotIssueId?ballotIssueId=';

    //lobbyist
    updatelobbyList = 'api/Lobbyist/UpdateLobbyistAdditionalInfo';
    lobbyListDetails = 'api/Lobbyist/GetLobbyistDetail?lobbyistId=';
    lobbyEntities = 'api/Lobbyist/getLobbyistEntities?lobbyistId=';
    updateLobbyistStatus = 'api/Lobbyist/UpdateLobbyistStatus';
    updateCommitteeorLobbyistStatus =
        'api/Committee/UpdateCommitteeorLobbyistStatus';
    saveLobbyistAffiliation = 'api/Lobbyist/SaveLobbyistAffiliation';
    addNewLobbyistUser = 'api/Filer';
    editLobbyist = 'api/Lobbyist';

    addNonSessionWaiver = 'api/non-session-waiver';

    cancelNonSessionWaiver(nonSessionWaiverId: string) {
        return `api/non-session-waiver/${nonSessionWaiverId}/cancel`;
    }

    downloadNonSessionWaiver(
        nonSessionWaiverId: string,
        nonSessionWaiverVersion: number,
    ) {
        return `api/non-session-waiver/${nonSessionWaiverId}/version/${nonSessionWaiverVersion}/pdf`;
    }

    nswTemplate = 'api/non-session-waiver/template';

    //Indipendent expenduture
    SearchIndependentExpenditureByName =
        'api/IndependentExpenditure/SearchIndependentExpenditureByName?searchIndependentExpenditure=';
    saveNoteIe = 'api/IndependentExpenditure/saveNoteIe';
    GetIEReportSummaryInfo =
        'api/IndependentExpenditure/GetIEReportSummaryInfo';

    GetIEReportFilingInfoUserEntityDetailsByFiling = (_: {
        filingId: number;
    }): string =>
        this.applyParams(
            'api/IndependentExpenditure/getIEReportFilingInfoUserEntityDetailsByFiling',
            _,
        );

    GetIEReportSummayInfoByFiling = (_: { filingId: number }): string =>
        this.applyParams(
            'api/IndependentExpenditure/GetIEReportSummaryInfoByFiling',
            _,
        );

    readonly GetIEContributionByFiling = (
        type: IEContributionRoute,
        _: { filingId: number },
    ) =>
        this.applyParams(
            `api/IndependentExpenditure/GetIndependentExpenditure${type}ContributionTransactionsByFiling`,
            _,
        );
    GetIndependentExpenditureMonetaryContributionTransactions =
        'api/IndependentExpenditure/GetIndependentExpenditureMonetaryContributionTransactions';
    GetIndependentExpenditureInKindContributionTransactions =
        'api/IndependentExpenditure/GetIndependentExpenditureInKindContributionTransactions';
    GetIndependentExpenditureRefundContributionTransactions =
        'api/IndependentExpenditure/GetIndependentExpenditureRefundContributionTransactions';
    SaveFillingIEReport = 'api/IndependentExpenditure/SaveFillingIEReport';
    GetIndependentExpenditureIEContributionTransactions =
        'api/IndependentExpenditure/GetIndependentExpenditureIEContributionTransactions';
    GetIndependentExpenditureExistingReport =
        'api/IndependentExpenditure/GetIndependentExpenditureExistingReport';
    // auth APIs
    login = 'api/UserManagement/Authenticate';
    resetPassword(email: string) {
        return 'api/UserManagement/reset-password/' + email;
    }
    forgotPassword = 'api/UserManagement/ForgotPassword';

    //System Management
    createBallot = 'api/SystemManagement/CreateBallotIssues';
    getBallot = 'api/SystemManagement/GetBallotIssuesList?searchKeyword=';

    getBallotByElectionCycleId(electionCycleId: number) {
        return `${this.getBallot}&electionCycleId=${electionCycleId}`;
    }

    getSearchBallot =
        'api/SystemManagement/GetBallotIssuesByName?searchKeyword=';
    addNewIndependentExpenditureUser =
        'api/IndependentExpenditure/addNewIndependentExpenditureUser';
    editIndependentExpenditure = 'api/IndependentExpenditure/';
    getBallotIssuesByElectionCycle =
        'api/SystemManagement/GetBallotIssuesByElectionCycle?electionCycle=';
    getBallotIssuesById =
        'api/SystemManagement/getBallotIssuesById?ballotIssueId=';
    updateBallotIssue = 'api/SystemManagement/UpdateBallotIssues';
    deleteBallotIssue = 'api/SystemManagement/DeleteBallotIssues';
    validateContributionLimits =
        'api/SystemManagement/ValidateContributionLimits';
    createContribution = 'api/SystemManagement/CreateContributionLimits';
    updateContribution = 'api/SystemManagement/UpdateContributionLimits';
    deleteContribution = 'api/SystemManagement/DeleteContributionLimits';
    getContribution = 'api/SystemManagement/GetContributionLimitsList';
    getFilerTypeList = 'api/SystemManagement/GetFillerTypeList';
    getDonorTypeList = 'api/SystemManagement/GetDonorTypeList';
    getElectionList = 'api/SystemManagement/GetElectionList';
    addCommitteeType = 'api/SystemManagement/SaveCommitteeType';
    updateCommitteeType = 'api/SystemManagement/UpdateCommitteType';
    deleteCommitteeType = 'api/SystemManagement/DeleteCommitteeType';
    getCommitteeTypeList = 'api/SystemManagement/GetCommitteeTypeList';
    validateCommitteeType = 'api/SystemManagement/ValidateCommitteeType';
    validateOffice = 'api/SystemManagement/ValidateOffice';
    addOffice = 'api/SystemManagement/SaveOffice';
    updateOffice = 'api/SystemManagement/UpdateOffice';
    deleteOffice = 'api/SystemManagement/DeleteOffice';
    getallOfficeList = 'api/SystemManagement/GetallOfficeList';
    GetBallotIssuesList = 'api/SystemManagement/GetBallotIssuesList';
    GetLookUpTypeList = 'api/LookUp/GetLookUpTypeList';
    addMatchingLimits = 'api/SystemManagement/AddMatchingLimits';
    updateMatchingLimits = 'api/SystemManagement/UpdateMatchingLimits';
    deleteMatchingLimits = 'api/SystemManagement/DeleteMatchingLimits';
    getMatchingLimitsList = 'api/SystemManagement/GetMatchingLimitsList';
    createModifyForm = 'api/SystemManagement/CreateModifyFormImageUpload';
    updateModifyForm = 'api/SystemManagement/UpdateModifyFormImageUpload';
    permissionList = 'api/SystemManagement/GetUserPermissionList';
    getCommitteeListBallot =
        'api/SystemManagement/GetCommiteeListMatchingBallotCode';
    getModifyForms = 'api/SystemManagement/GetModifyFormImageById';
    getModifyFormImage = '/api/SystemManagement/GetModifyFormImage';
    checkContributionLimit =
        'api/SystemManagement/ValidateContributionLimitByContactAndElectionCycle';

    getTreasurerDocument = 'api/Committee/TreasurerDocument';
    getCandidateAffidavit = 'api/Committee/CandidateAffidavit';

    // Switch Committee
    CommitteeLobbyList = 'api/Committee/GetCommitteeandLobbyistbyUser?id=';
    CommitteeLobbyDetail = 'api/Committee/GetCommitteeorLobbyistbyID?id=';
    //getCommiteeDetail = 'api/Committee/GetOfficersList?committeeid=';
    GetCommitteeOfficersList =
        'api/Committee/GetCommitteeOfficersList?committeeid=';
    //getUserEntityDetails = 'api/UserManagement/getUserEntityDetails';
    GetCommitteeDetailsByFiler =
        'api/Committee/GetCommitteeDetailsByFiler?filerId=';
    CommitteeDetail = 'api/Filer/';
    getCurrentUserList = 'api/UserManagement/GetFilerUsers';
    deleteCurrentUser = 'api/UserManagement/DeleteFilerContact';
    makeUserTreasurer = 'api/UserManagement/SaveMakeTreasurer';
    sendFilerInvite = 'api/UserManagement/FilerInviteUser';
    addUserToFiler = 'api/UserManagement/addUserToFiler';
    LobbyistDetail = 'api/Committee/GetSwitchLobbyistDetails?id=';
    lobbyistContactInformation = 'api/Lobbyist/Contact';
    IEDetail = 'api/Committee/GetSwitchIEDetails?id=';
    getLobbyDetails = 'api/Lobbyist/GetLobbyistDetail?lobbyistId=';
    // Send / Join APIs
    sendCommittee = 'api/Committee/SendCommittee';
    sendLobbyist = 'api/Lobbyist/SendLobbyist';
    //manage Committee
    updateCommitteeStatus = 'api/Committee/UpdateCommitteeStatus';
    getsearchFilter = 'api/UserManagement/getFilerNamesByName';
    getFilerUserNamesByName = 'api/UserManagement/getFilerUserNamesByName';

    //UserManagement
    getManageFilerDetail = 'api/UserManagement/GetManageFilerDetail';
    getManageFiler = 'api/UserManagement/getManageFilers';
    addNewUserCommitteeJoinRequest =
        'api/UserManagement/addNewUserCommitteeJoinRequest';
    addNewUserLobbyistJoinRequest =
        'api/UserManagement/addNewUserLobbyistJoinRequest';
    addNewUserAccessEthicsJoinRequest = 'api/AccessEthics';
    addNewUserIndependentExpenditureJoinRequest =
        'api/UserManagement/addNewUserIndependentExpenditureJoinRequest';
    getLookUps = 'api/LookUp/getLookUps?lookUpTypeCode=';
    getCommitteeListAll = 'api/Committee/GetCommitteeByName';
    getLookUpStateList = 'api/LookUp/getStatesList';
    getTemplatesList = 'api/LookUp/getTemplatesList';
    getNotificationUserTypesList = 'api/LookUp/getNotificationUserTypesList';
    GetEmailScheduledNotifications =
        'api/notification/GetEmailScheduledNotifications';
    GetEmailPastNotifications = 'api/notification/GetEmailPastNotifications';
    saveEmailNotification = 'api/notification/saveEmailNotification';
    deleteNotification = 'api/notification/deleteNotification';
    GetTemplates = 'api/notification/GetTemplates';
    saveTemplate = 'api/notification/saveTemplate';
    deleteTemplate = 'api/notification/deleteTemplate?templateId=';
    archivedNotifications = 'api/notification/archive';
    notificationEmailTest = 'api/email/notificationEmailTest';
    helpEmail = 'api/email/helpEmail';
    AdminHelp = 'api/Help';
    templateNameExists = 'api/notification/TemplateNameExists?name=';

    //Lookups
    getStatesList = 'api/LookUp/getStatesList';
    getCityStatesList = 'api/LookUp/GetCityStatesList';
    getElectionCycleByType = 'api/LookUp/getElectionCycleByType';
    getFutureElectionCycleList = 'api/LookUp/getFutureElectionCycleList';
    getElectionDates = 'api/LookUp/getElectionDates';
    getTheNewElectionDatesByCommittee =
        'api/LookUp/getTheNewElectionDatesByCommittee';
    getApplicationUserStatusesList =
        'api/LookUp/getApplicationUserStatusesList';
    getCommitteeTypesList = 'api/LookUp/getCommitteeTypesList';
    getContactRolesList = 'api/LookUp/getContactRolesList';
    getContactTypesList = 'api/LookUp/getContactTypesList';
    getContributorTypesList = 'api/LookUp/getContributorTypesList';
    getDistrictsList = 'api/LookUp/getDistrictsList';
    getElectionCycleStatusesList = 'api/LookUp/getElectionCycleStatusesList';
    getElectionTypesList = 'api/LookUp/getElectionTypesList';
    getExpenditureCategoriesList = 'api/LookUp/getExpenditureCategoriesList';
    getFilerStatusesList = 'api/LookUp/getFilerStatusesList';
    getFilerTypesList = 'api/LookUp/getFilerTypesList';
    getFilingStatusesList = 'api/LookUp/getFilingStatusesList';
    getFilingStatusesListExcludingDraft =
        'api/LookUp/getFilingStatusesListExcludingDraft';
    getFineFrequencyTypesList = 'api/LookUp/getFineFrequencyTypesList';
    getIndependentExpenditureTargetTypesList =
        'api/LookUp/getIndependentExpenditureTargetTypesList';
    getInvoiceStatusesList = 'api/LookUp/getInvoiceStatusesList';
    getLoanSubTransactionTypesList =
        'api/LookUp/getLoanSubTransactionTypesList';
    getIndependentExpenditureFilerTypesList =
        'api/LookUp/getIndependentExpenditureFilerTypesList';
    getLoanTypesList = 'api/LookUp/getLoanTypesList';
    getLobbyistTypesList = 'api/LookUp/lobbyist-types';
    getMonetaryTypesList = 'api/LookUp/getMonetaryTypesList';
    getOfficeTypesList = 'api/LookUp/getOfficeTypesList';
    getPaymentMethodTypeList = 'api/LookUp/getPaymentMethodTypeList';
    getPositionTypesList = 'api/LookUp/getPositionTypesList';
    getTransactionSubTypesList = 'api/LookUp/getTransactionSubTypesList';
    getTransactionTypesList = 'api/LookUp/getTransactionTypesList';
    getContributionTransactionTypes =
        'api/LookUp/getContributionTransactionTypesList';
    getAppointingAuthoritiesList = 'api/LookUp/getAppointingAuthoritiesList';
    getDepartmentsList = 'api/LookUp/departments';
    getFutureFilingPeriodsList = 'api/LookUp/getFutureFilingPeriodsList';
    getLookUpTypeList = 'api/LookUp/getLookUps?lookUpTypeCode=';
    getGiftTypesList = 'api/LookUp/getGiftTypesList';
    getElectionCycleList = 'api/LookUp/getElectionCycleList';
    getAllActiveElectionCycleList = 'api/LookUp/getAllActiveElectionCycleList';

    getPublicEntities = (name: string) => `api/Public/entity-name/${name}`;

    getActivityReport = 'api/activity-report/';

    getLegislative(fields: number, text: string) {
        const params = new URLSearchParams({ field: fields.toString() });
        const sanitizedParams = text?.trim();

        if (sanitizedParams) {
            params.append('text', sanitizedParams);
        }

        return `api/legislativeDocument?${params.toString()}`;
    }

    getLegislativeDocuments = 'api/legislative-documents';

    getElectionCycleListByFiler(
        filerId: number = null,
        entityId: number = null,
        filerTypeId: number = null,
    ) {
        return `api/LookUp/getElectionCycleList?filerId=${filerId}&entityId=${entityId}&filerTypeId=${filerTypeId}`;
    }

    getElectionCyclesByIeTargetTypeId(ieTargetTypeId: number) {
        return `api/LookUp/GetElectionCycles?ieTargetTypeId=${ieTargetTypeId}`;
    }

    getCampaignFinanceReportElectionCycleListByFiler =
        'api/LookUp/getCampaignFinanceReportElectionCycleListByFiler?filerId=';

    getCampaignFinanceFilingPeriodsByElectionCycle(
        electionCycleId: number,
        filerId: string,
    ) {
        return `api/LookUp/getCampaignFinanceReportFilingPeriodListByElectionCycle?electionCycleId=${electionCycleId}&filerId=${filerId}`;
    }

    getAddressVerification(contactId: number) {
        return `api/FEFAddressVerification/GetAddressVerification/${contactId}`;
    }

    getFilingByElectionCycleAndFiler(electionCycleId: number, filerId: string) {
        return `api/LookUp/getFilingByElectionCycleAndFiler?electionCycleId=${electionCycleId}&filerId=${filerId}`;
    }

    GetIfTheCurrentReportHasChanges =
        'api/LookUp/GetIfTheCurrentReportHasChanges';

    //public
    getpublicPenalties = 'api/Payments/getpublicPenalties/';
    getPublicComplaints = 'api/Committee/getPublicComplaints/';
    getPublicAudits = 'api/Committee/getPublicAudits/';

    getMessageByThreadId(filerId: string, threadId: string) {
        return `api/messaging/${filerId}/thread/${threadId}`;
    }

    addNewMessage(filerId: string) {
        return `api/messaging/${filerId}`;
    }

    GetLobbyistClientsById(lobbyistId: number) {
        return `api/Lobbyist/GetLobbyistClientsById?lobbyistId=${lobbyistId}`;
    }

    GetLobbyistContacsById(lobbyistId: number) {
        return `api/Lobbyist/GetLobbyistContactsById?lobbyistId=${lobbyistId}`;
    }

    addLobbyistExpenses(filerId: number) {
        return `api/LobbyistExpenses/${filerId}`;
    }

    updateLobbyistExpenses(filerId: number) {
        return `api/LobbyistExpenses/${filerId}`;
    }

    GetLobbyistExpensesByFiler(
        filerId: number,
        filerTypeId: number,
        filingPeriodId: number,
    ) {
        return `api/LobbyistExpenses/GetLobbyistExpensesByFiler?filerId=${filerId}&filerTypeId=${filerTypeId}&filingPeriodId=${filingPeriodId}`;
    }

    GetLobbyistHasDraft(
        filerId: number,
        filerTypeId: number,
        filingPeriodId: number,
    ) {
        return `api/LobbyistExpenses/GetLobbyistHasDraft?filerId=${filerId}&filerTypeId=${filerTypeId}&filingPeriodId=${filingPeriodId}`;
    }

    GetLobbyistHasFiling(
        filerId: number,
        filerTypeId: number,
        filingPeriodId: number,
    ) {
        return `api/LobbyistExpenses/GetLobbyistHasFiling?filerId=${filerId}&filerTypeId=${filerTypeId}&filingPeriodId=${filingPeriodId}`;
    }

    GetLobbyistReportFilingPeriodList(filerId: number) {
        return `api/LookUp/getLobbyistReportFilingPeriodList?filerId=${filerId}`;
    }

    GetLobbyistById =
        'api/LobbyistExpenses/getLobbyistExpensesById?lobbyistExpensesId=';

    DeleteLobbyistExpenses(
        filerId: number,
        lobbyistId: number,
        filingPeriodId,
        filingId,
    ) {
        return `api/LobbyistExpenses/${filerId}/lobbyist/${lobbyistId}/filingPeriod/${filingPeriodId}/filingId/${filingId != null ? filingId : 0
            }`;
    }

    SaveFillingLobbyistByMonthlyReport =
        'api/Filing/SaveFillingLobbyistByMonthlyReport';

    BiMonthlyReports = 'api/Lobbyist/BiMonthlyReports';

    getFilingByFilingPeriodAndFiler(filingPeriodId: number, filerId: number) {
        return `api/LookUp/getFilingByFilingPeriodAndFiler?filingPeriodId=${filingPeriodId}&filerId=${filerId}`;
    }

    getLobbyistExpensesByFiling =
        'api/LobbyistExpenses/GetLobbyistExpensesByFiling';

    GetlobbyistFilingInformation(filerId: number, filingId: string) {
        return `api/LobbyistExpenses/LobbyistFilingInfo?filerId=${filerId}&filingId=${filingId}`;
    }

    GetFilingPeriods(filer: number, filingPeriodId: number) {
        return `api/LobbyistExpenses/GetFilingPeriods?filerId=${filer}&filingPeriodId=${filingPeriodId}`;
    }

    getClientToLobbyistLinks(year: number = 0, client: number = 0): string {
        return `api/lobbyist/clientstolobbyists?year=${year}&clientId=${client}`;
    }

    readonly getYearsWithLobbyistRegistrations = 'api/lobbyist/years';
    readonly getFilingPeriodsByYear = 'api/lookup/filingperiodsbyyear';
    readonly getLobbyistExpensesByClientAndPeriod =
        'api/lobbyistexpenses/byclientandperiod';
    readonly getLobbyistExpensesByClientPeriodAndYear =
        'api/LobbyistExpenses/ByClientPeriodAndYear';
    readonly getLobbyistClientById = 'api/lobbyist/client';
    readonly getAdminUnreadMessages: string = 'api/messaging/admin/unread';
    readonly getAdminUnreadMessagesCount: string = 'api/messaging/unread/admin';
    getOfficerByEmail = 'api/UserManagement/getOfficerByEmail?UserEmailID=';

    readonly getTemplate: string = 'api/messaging/admin/template';

    getLobbyistUnreadMeassagesCount(filerId: string): string {
        return `api/messaging/unread/${filerId}`;
    }

    getAllScheduledNotifications(): string {
        return 'api/notification/getemailschedulednotifications';
    }

    getScheduledNotification(id: number): string {
        return `api/notification/getemailnotificationbyid?notificationId=${id}`;
    }

    GetReportButtonOptionByElectionCycleAndFilingPeriod(
        filerId: string,
        electionCycleId: number,
        filingPeriodId: number,
        filingTypeId: number,
    ) {
        return `api/Filing/GetReportButtonOptionByElectionCycleAndFilingPeriod?filerId=${filerId}&electionCycleId=${electionCycleId}&filingPeriodId=${filingPeriodId}&filingTypeId=${filingTypeId}`;
    }

    /*Gift*/
    GetGiftFilingInformation = 'api/gift/FilingInformation';

    GetEthicsReportFilingPeriodList(filerId: number) {
        return `api/lookUp/getEthicsReportFilingPeriodList?filerId=${filerId}`;
    }

    GetGiftByFilerAndFilingPeriod(filerId: number, filingPeriodId: number) {
        return `api/Gift/GetByFilerAndFilingPeriod?filerId=${filerId}&filingPeriodId=${filingPeriodId}`;
    }

    GetGiftHasFiling(filerId: number, filingPeriodId: number) {
        return `api/Gift/GetGiftHasFiling?filerId=${filerId}&filingPeriodId=${filingPeriodId}`;
    }

    GetGiftHasDraft(filerId: number, filingPeriodId: number) {
        return `api/Gift/GetGiftHasDraft?filerId=${filerId}&filingPeriodId=${filingPeriodId}`;
    }

    SaveFilingGiftReport = 'api/Gift/SaveFilingGiftReport';

    DeleteGift(filerId: number, giftId: number, filingPeriodId, filingId) {
        return `api/Gift/${filerId}/gift/${giftId}/filingPeriod/${filingPeriodId}/filingId/${filingId != null ? filingId : 0
            }`;
    }

    GetGiftById = 'api/Gift/getGiftById?giftId=';

    GetGiftRefundByFiling = 'api/gift/getGiftRefundByFiling';

    getGiftByFiling = 'api/Gift/GetGiftByFiling';

    getGiftRecords = 'api/Gift/records';
    saveGiftReport = 'api/Gift/Report';

    /*Gift*/

    saveMajorContributionReport(filerId: number) {
        return `api/filing/SaveFilingMajorContributionReport/${filerId}`;
    }

    getMCRTransactions = 'api/transaction/getMCRTransactions?filerId=';

    getMCRTransactionsByFiling = 'api/transaction/getMCRTransactionsByFiling';

    checkIfExistElectionCycleActive =
        'api/transaction/CheckIfExistElectionCycleActive';
    editFEFDisbursement = 'api/FEFDisbursement/';

    getClientRegistration = 'api/ClientRegistration/';
    getClientRegistrationCsv = 'api/ClientRegistration/csv';
    updateClientRegistrationStatus = (id: string) =>
        `api/ClientRegistration/${id}/status`;
    clientRegistrationResponse = 'api/ClientRegistration/PreSetResponse';

    getRegistrationPaymentHistory = (id: string) =>
        `api/ClientRegistration/${id}/payment-history`;

    submitNoActivityReport = 'api/MonthlyReport/no-activity';

    getNoActivityReportPDF(
        filingId: number,
        noActivityChecked: boolean = false,
    ) {
        return (
            [
                'api/MonthlyReport',
                filingId,
                'preview',
            ].join('/') +
            '?noActivityChecked=' +
            noActivityChecked
        );
    }

    getCompensationTotals = (year: number) =>
        `api/MonthlyReport/compensation/${year}`;

    getLobbiedBills = (year: number) =>
        `api/MonthlyReport/lobbied-bills/${year}`;

    getDashboardAdminFilerCSV = 'api/Filer/csv';

    getDashboardAdminFilerExtendCSV = 'api/Filer/csv?extended=true';

    getPreviewActivityReportPDF(filingId: string) {
        return [
            'api/MonthlyReport',
            filingId,
            'preview'
        ].join('/');
    }

    getFilingPeriod = 'api/FilingPeriod/';

    getMonthlyReportFilingInformation = 'api/MonthlyReport/';

    authorizeMonthlyReportByFilingId(filingId: string) {
        return ['api', 'monthly-report', filingId, 'lock'].join('/');
    }

    authorizeMonthlyReportByClientRegistration(clientRegistrationId: string) {
        return [
            'api',
            'client-registration',
            clientRegistrationId,
            'monthly-report',
            'lock',
        ].join('/');
    }

    authorizeLegislativeDesigneeRegistration(filerId: string) {
        return [
            'api',
            'legislative-designee',
            filerId,
            'registration',
            'lock',
        ].join('/');
    }

    harassmentPreventionCertificate = 'api/harassment-prevention-certificate';
    
    harassmentPreventionCertificateCSV = 'api/harassment-prevention-certificate/csv';

    downloadHarassmentPreventionCertificate(filingId: string, version: number) {
        return `api/harassment-prevention-certificate/${filingId}/version/${version}/pdf`;
    }

    getUnfiledMonthlyReports(lobbyistId: string) {
        return `api/MonthlyReport/lobbyist/${lobbyistId}/unfiled`;
    }

    totalCompensation(id: string) {
        return `api/activity-report/${id}/total-compensation`;
    }
    totalExpenditure(id: string) {
        return `api/activity-report/${id}/total-expenditure`;
    }
    totalExpenditureBehalfFamilyMembers(id: string) {
        return `api/activity-report/${id}/family-member`;
    }
    getHarassmentPreventionCertificateStatus(lobbyistId: string) {
        return `api/harassment-prevention-certificate/lobbyist/${lobbyistId}/training-status`;
    }

    getLobbyistClientRegistrations(lobbyistId: string) {
        return 'api/ClientRegistration/lobbyist/' + lobbyistId;
    }

    getNonSessionWaiverStatus = 'api/non-session-waiver/is-active';

    getLobbyistFiledReports(lobbyistId: string) {
        return `api/filing/lobbyist/${lobbyistId}/filed`;
    }

    getClientRegistrationFiledReports(clientId: string, year: number) {
        return `api/ClientRegistration/${clientId}/year/${year}/filed`;
    }

    getClientRegistrationLegislativeDocuments(clientId: string, year: number) {
        return `api/ClientRegistration/${clientId}/year/${year}/legislative-documents`;
    }

    getClientRegistrationYearlyTotals(clientId: string, year: number) {
        return `api/ClientRegistration/${clientId}/year/${year}/totals`;
    }

    getClientRegistrationOriginalSources(clientId: string, year: number) {
        return `api/ClientRegistration/${clientId}/year/${year}/original-sources`;
    }

    getLobbyistDraftReports(lobbyistId: string) {
        return `api/filing/lobbyist/${lobbyistId}/draft`;
    }

    downloadMonthlyReport(filingId: string, version: number) {
        return `api/MonthlyReport/${filingId}/version/${version}/pdf`;
    }

    removeDraftReport(filingId: string) {
        return `api/Filing/${filingId}/draft`;
    }
    getClientProfileById(clientId: string) {
        return `api/client/${clientId}`;
    }

    getClientProfileYearsById(clientId: string) {
        return `api/client/${clientId}/years`;
    }

    getClientTemplateInformation(clientId: string, lobbyingYear: number) {
        return `api/client/${clientId}/year/${lobbyingYear}/information`;
    }

    changeClientRegistrationStatus(
        clientRegistrationId: string,
        statusId: number,
    ) {
        return `api/ClientRegistration/${clientRegistrationId}/status/${statusId}`;
    }

    terminateRegistration = `api/ClientRegistration/terminate`;

    downloadTerminationStatement(filingId: string, filingVersion: number) {
        return `api/ClientRegistration/termination-statement/${filingId}/version/${filingVersion}/pdf`;
    }

    submitMonthlyReport = (id: string, lobbyistId: string) =>
        `api/monthlyReport/${id}/lobbyist/${lobbyistId}/file`;

    getClientTemplate(id: string) {
        return `api/client/${id}`;
    }

    getClientPendingChanges = 'api/client/pending-changes';

    acceptClientPendingChange(id: number) {
        return `api/client/pending-changes/${id}/accept`;
    }

    rejectClientPendingChange(id: number) {
        return `api/client/pending-changes/${id}/reject`;
    }

    clientController = 'api/client';

    downloadExpenditureReport(
        filerId: string,
        filingId: string,
        version: number,
    ) {
        let url = `api/expenditureReport/${filingId}/version/${version}/pdf`;
        if (!!filerId) url += `?lobbyistId=${filerId}`;
        return url;
    }

    previewExpenditureReport(
        filingId: string,
    ) {
        return `api/expenditureReport/${filingId}/preview`;
    }

    saveExpenditureReport(filerId: string, filingId: string) {
        return `api/expenditureReport/publish/${filingId}/${filerId}`;
    }

    getExpenditureReportFilingPeriods(filerId: string) {
        return `api/expenditureReport/lobbyist/${filerId}/filing-periods`;
    }

    getExpenditureReportBase = 'api/expenditure-report/';

    getOrCreateExpenditureDraftReport(
        filerId: string | number,
        filingPeriodId: string | number,
    ) {
        return `api/expenditureReport/lobbyist/${filerId}/filingPeriod/${filingPeriodId}`;
    }

    getExpenditureReportInfo(filingId: string, lobbyistId: string) {
        return `api/ExpenditureReport/${filingId}/lobbyist/${lobbyistId}`;
    }

    allSubmittedFilings = 'api/filing/filings';

    allSubmittedFilingsCSV = 'api/filing/csv';

    unfiledAndLateReport = 'api/monthlyReport/unfiled-late';

    unfiledAndLateReportCSV = 'api/monthlyReport/unfiled-late/csv';

    getFirmsPendingChanges = 'api/firm/pending-changes';

    acceptFirmPendingChange(id: number) {
        return `api/firm/pending-changes/${id}/accept`;
    }

    rejectFirmPendingChange(id: number) {
        return `api/firm/pending-changes/${id}/reject`;
    }

    firmTemplates = 'api/firm';

    billAndIssues(monthlyReportId: string) {
        return `api/activity-report/${monthlyReportId}/bill-and-issue`;
    }

    grassrootsLobbying(monthlyReportId: string) {
        return `api/activity-report/${monthlyReportId}/grassroots-lobbying`;
    }

    eventOfficial(monthlyReportId: string) {
        return `api/activity-report/${monthlyReportId}/event-official`;
    }

    expendituresOnBehalf(monthlyReportId: string) {
        return `api/activity-report/${monthlyReportId}/expenditure-on-behalf`;
    }

    originalSource(monthlyReportId: string) {
        return `api/activity-report/${monthlyReportId}/original-source`;
    }

    updateLateMonthlyReports(filingId: string, submittedDate: string) {
        return `api/monthlyReport/${filingId}/submittedDate/${submittedDate}`;
    }

    unfileReport(
        filingId: string,
        version: string | number,
        isFiling: boolean,
    ) {
        return `api/filing/${filingId}/version/${version}?isFiling=${isFiling}`;
    }

    lobbyistYears = 'api/lobbyist/lobbyistsYears';

    allLobbyist = 'api/lobbyist';
    allLobbyistCsv = 'api/lobbyist/csv';

    markCompleteById(id: number) {
        return `api/alert/complete/${id}`;
    }

    markCompleteAll() {
        return `api/alert/complete/all`;
    }

    lobbyingYearsWithActiveRegistration(id: string) {
        return `api/lobbyist/${id}/client-registration-years`;
    }
    filingsNoRelatedWithClientRegistration(lobbyistId: string, year: number) {
        return `api/filing/lobbyist/${lobbyistId}/year/${year}/unrelated-filings`;
    }

    lobbyistClientCompensationAndExpenditures(
        lobbyistId: string,
        year: number,
    ) {
        return `api/clientRegistration/lobbyist/${lobbyistId}/year/${year}/compensation-and-expenditures`;
    }

    lobbyistClientLobbyistAssociates(lobbyistId: string, year: number) {
        return `api/clientRegistration/lobbyist/${lobbyistId}/year/${year}/associates`;
    }

    GetLobbyistNatureBussiness(id: string, year: number) {
        return `api/lobbyist/${id}/year/${year}/nature-business`;
    }

    clientRegistrationYears(clientId: string) {
        return `api/ClientRegistration/${clientId}/years`;
    }

    advancedSearch = {
        compensationForLobbying:
            'api/advanced-search/monthly-report/compensation-for-lobbying',
        legislativeDocumentsAndIssues:
            'api/advanced-search/monthly-report/bills-and-issues',
        expenditures: 'api/advanced-search/monthly-report/expenditures',
        events: 'api/advanced-search/monthly-report/event-officials',
        grassrootsLobbying:
            'api/advanced-search/monthly-report/grassroots-lobbying',
        originalSources: 'api/advanced-search/monthly-report/original-sources',
        lobbyingYears: 'api/advanced-search/monthly-report/lobbying-years',
        legislativeDocumentsWithPublishedReports:
            'api/advanced-search/monthly-report/legislative-documents',
        legislativeCommitteesWithPublishedReports:
            'api/advanced-search/monthly-report/legislative-committees',
        legislatures: 'api/advanced-search/monthly-report/legislatures',
        legislativeDocuments: 'api/advanced-search/legislative-documents',
        legislatureYears:
            'api/advanced-search/legislative-documents/legislature-years',
        legislativeCommittees:
            'api/advanced-search/legislative-documents/legislative-committees',
        lobbyingClients: 'api/advanced-search/clients',
        lobbyistsAndAssociates: 'api/advanced-search/lobbyists-and-associates',
        lobbyingFirms: 'api/advanced-search/firms',
    };

    getEventLogs = 'api/event-logs';
    legislativeCommittees = 'api/LegislativeCommittee';

    legislativeActionsDocument(reportId: string) {
        return `api/grassroots-lobbying-report/${reportId}/legislative-actions-document`;
    }

    legislativeActionsCategory(reportId: string) {
        return `api/grassroots-lobbying-report/${reportId}/legislative-actions-category`;
    }

    grassrootsOriginalSource(reportId: string) {
        return `api/grassroots-lobbying-report/${reportId}/original-source`;
    }

    getGrassrootsLobbyingReportInfo = 'api/grassroots-lobbying-report';

    spenderInformation(id: string) {
        return `api/grassroots-lobbying-report/${id}/spender-information`;
    }

    grassrootsLobbyingTotalExpenditure(id: string) {
        return `api/grassroots-lobbying-report/${id}/total-expenditure`;
    }

    LegislativeExpenditureCategory() {
        return `api/legislative-expenditure-category/`;
    }

    submitGrassrootsLobbyingReport = (id: string, lobbyistId: string) =>
        `api/grassroots-lobbying-report/${id}/lobbyist/${lobbyistId}/file`;

    getFirmClients(firmTemplateId: string, year: number): string {
        return `api/firm/${firmTemplateId}/year/${year}/clients`;
    }

    getFirmLobbyistEmployees(firmTemplateId: string, year: number): string {
        return `api/firm/${firmTemplateId}/year/${year}/lobbyists`;
    }

    getFirmTemplateInfo(firmTemplateId: string): string {
        return `api/firm/${firmTemplateId}`;
    }

    getLobbyistYearsByFirmTemplateId(firmTemplateId: string): string {
        return `api/firm/${firmTemplateId}/years`;
    }

    getPdfPreviewGrassrootsFilerReport = (filingId: string) =>
        `api/grassroots-lobbying-report/${filingId}/preview`;

    getPdfGrassrootsLobbyingReport = (filingId: string, version: number) =>
        `api/grassroots-lobbying-report/${filingId}/version/${version}/pdf`;

    getLegislativeDocumentsForFilerAndYear = (filerId: string, year: number) =>
        `api/grassroots-lobbying-report/filer/${filerId}/year/${year}/legislative-documents`;

    getGrassrootLobbyingYears = (filerId: string) =>
        `api/grassroots-lobbying-report/filer/${filerId}/years`;

    getLatestGrassrootLobbyingReport = (filerId: string, year: number) =>
        `api/grassroots-lobbying-report/filer/${filerId}/year/${year}/latest`;

    getFiledGrassrootLobbyingReportsForFilerAndYear = (filerId: string, year: number) =>
        `api/grassroots-lobbying-report/filer/${filerId}/year/${year}/filed`;

    agencyProfileYears = (id: string) => `api/StateAgency/${id}/years`;
    agencyProfile = (id: string, year: number) => `api/StateAgency/${id}/year/${year}`;

    getLegislativeDesigneeArchivedRegistrations = (legislativeDesigneeId: string) =>
        `api/legislative-designee/${legislativeDesigneeId}/registration/archive`;

    invoicing = {
        unresolved: 'api/invoicing/unresolved',
        downloadUnresolvedCSV: 'api/invoicing/unresolved/download',
        assessment: 'api/invoicing/assessment',
        searchInvoice: 'api/invoicing/invoice/search',
        downloadInvoicePDF: (id: number) => `api/invoicing/invoice/${id}/download`,
        markAsDirectPayment: (id: number) => `api/invoicing/invoice/${id}/direct-payment`,
        transaction: 'api/invoicing/transaction',
        downloadTransactionCSV: 'api/invoicing/transaction/download',
        fine: 'api/invoicing/fine',
        fineById: (id: string) => `api/invoicing/fine/${id}`,
        filerUnresolved: (id: string) => `api/invoicing/filer/${id}/unresolved`,
        filerTransactions: (id: string) => `api/invoicing/filer/${id}/transaction`,
    }

    getLegislativeDesigneeRegistrationPDF = (registrationId: string, version: number) =>
        `api/legislative-designee/registration/${registrationId}/version/${version}/pdf`;

    getLegislativeDesigneeRegistrationPDFPreview = (filerId: string, registrationId: string) =>
        `api/legislative-designee/${filerId}/registration/${registrationId}/preview`;
}
