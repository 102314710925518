import { zip } from 'rxjs';
import { map, share, shareReplay, switchMap, filter } from 'rxjs/operators';
import { Component, ViewEncapsulation } from '@angular/core';
import { FilerDataService } from 'src/app/modules/switch-committee/services';
import { FilerTypeEnumMaine } from 'src/app/app.model';
import { ManageProfileService } from '../../services/manage-profile.service';
import { SharedService } from '@maplight/services';

@Component({
    selector: 'app-manage-profile',
    templateUrl: './manage-profile.component.html',
    styleUrls: ['./manage-profile.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ManageProfileComponent {
    constructor(
        private readonly filerData: FilerDataService,
        private readonly entityService: ManageProfileService,
        private readonly sharedService: SharedService,
    ) {}

    readonly isAdmin = this.sharedService.isAdminUser()

    protected readonly entity$ = zip(
        this.filerData.authorizedAgent$,
        this.filerData.current$,
    ).pipe(
        // If user is an admin, don't get any filer information.
        filter((_) => !this.isAdmin),
        map(([authorizedAgent, current]) => authorizedAgent ?? current),
        filter((x) => !!x),
        switchMap((x) => this.entityService.getFilerInformation(x.filerId)),
        shareReplay(1),
    );

    protected readonly filerType$ = this.entity$.pipe(
        map((current) => current.filerTypeId),
        share(),
    );

    protected filerTypeEnum = FilerTypeEnumMaine;
}
