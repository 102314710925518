import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MasterUrlService } from "../../../../configs/api-urls";
import { ClientService, ErrorMessageService, MasterDataService } from "../../../../core";
import { LobbyistService } from "../../services/lobbyist.service";
import { tap } from "rxjs/operators";

@Component({
    selector: "app-create-account-firm-address-form",
    templateUrl: "./create-account-firm-address-form.component.html",
    styleUrls: ["./create-account-firm-address-form.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateAccountFirmAddressFormComponent implements OnInit {
    @Input() firmName: string;
    firmAddressForm: FormGroup;

    states$: any;
    private deleteUnsubscribe$: any;
    edit$: any;
    private editUnsubscribe$: any;

    constructor(
        private readonly fb: FormBuilder,
        private urlService: MasterUrlService,
        private service: ClientService,
        public errorService: ErrorMessageService,
        private readonly firmAddressService: LobbyistService,
        private readonly formatting: MasterDataService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.firmAddressForm = this.fb.group({
            address1: [
                this.firmAddressService.lobbyistFirmAddress?.address1 ?? null,
                [Validators.required],
            ],
            address2: [
                this.firmAddressService.lobbyistFirmAddress?.address2 ?? null,
            ],
            city: [
                this.firmAddressService.lobbyistFirmAddress?.city ?? null,
                [Validators.required, Validators.pattern(this.formatting.cityValidation)],
            ],
            stateCode: [
                this.firmAddressService.lobbyistFirmAddress?.stateCode ?? null,
                [Validators.required],
            ],
            zip: [
                this.firmAddressService.lobbyistFirmAddress?.zip ?? null,
                [Validators.required, Validators.pattern(this.formatting.zipcodePattern)],
            ],
        });

        this.states$ = this.getStates();

        this.deleteUnsubscribe$ = this.deleteUnsubscribe$ =
            this.firmAddressService.deleteBusinessAddress$.subscribe((data) => {
                if (data) {
                    this.firmAddressForm.reset();
                }
            });

        this.edit$ = this.firmAddressService.editBusinessAddress$.pipe(
            tap((value) => {
                if (value) {
                    this.firmAddressForm.setValue(value);
                } else {
                    this.firmAddressForm.reset();
                }
            })
        );
        this.cdr.detectChanges()
    }

    getStates = () => this.service.getData(this.urlService.getStatesList);

    ngOnDestroy(): void {
        this.deleteUnsubscribe$?.unsubscribe();
        this.editUnsubscribe$?.unsubscribe();
    }

    submit() {
        if (this.firmAddressForm.valid) {
            this.firmAddressService.addNewFirmAddress(
                this.firmAddressForm.value
            );
            this.firmAddressForm.reset();
        } else {
            this.firmAddressForm.markAllAsTouched();
            this.firmAddressForm.updateValueAndValidity();
        }
        this.cdr.detectChanges()
    }

    cancel() {
        this.firmAddressService.cancel();
        this.firmAddressForm.reset();
    }
}
