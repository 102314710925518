import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { combineLatest, merge, Observable, of, partition } from "rxjs";
import {
    map,
    shareReplay,
    withLatestFrom,
    switchMap,
    filter,
    startWith,
    share,
    catchError,
    tap,
} from "rxjs/operators";
import { FilerTypeEnum, UserApiModel, UserFilers } from "src/app/app.model";
import { ClientService, MasterUrlService, SharedService } from "src/app/core";
import { UserService } from "src/app/core/services/user-service";
import {
    Entity,
    Filer,
    FilerGroup,
    FilerItems,
    FilerName,
    KeyedEntity,
    KeyedFiler,
} from "../switch-commitee.model";
import { BusinessAddressTemplate, Filer as MaineFiler, Firm, FirmTemplate } from "@maplight/models";
import { FilerDataCacheService } from "./filer-data-cache.service";
import { findMatch, takeFirst } from "./helper";
import { SwitchTargetService } from "./switch-target.service";
import { UserDataService } from "./user-data.service";
import { BusinessAddress } from "@maplight/models";

type FilerModel = MaineFiler & { firm?: Firm | FirmTemplate; businessAddress?: BusinessAddress | BusinessAddressTemplate; }

@Injectable({ providedIn: "root" })
export class FilerDataService {
    private readonly filerToName = {
        [Filer.Committee]: FilerName.Committee,
        [Filer.EthicsFiler]: FilerName.EthicsFiler,
        [Filer.IndependentExpenditure]: FilerName.IndependentExpenditure,
        [Filer.Lobbyists]: FilerName.Lobbyists,
        [Filer.Admin]: FilerName.Admin,
    };

    isMultiEntity$: Observable<boolean>;
    isAdmin$: Observable<boolean>;
    authorizedAgent$: Observable<KeyedFiler>;
    isEthic$: Observable<boolean>;
    isMulti$: Observable<boolean>;
    entity$: Observable<KeyedEntity>;
    header$: Observable<{ head: string; sub: string }>;
    groupedEntities$: Observable<FilerItems[]>;
    sortedGroupedEntities$: Observable<FilerItems[]>;
    sameAsCurrent$: Observable<FilerItems[]>;
    current$: Observable<KeyedFiler>;
    form$: Observable<UntypedFormGroup>;
    order = {
        Administrator: 0,
        Committee: 1,
        "Independent Expenditure": 2,
        Lobbyists: 3,
        "Ethics Filer": 4,
    };

    constructor(
        private readonly urls: MasterUrlService,
        private readonly client: ClientService,
        private readonly userData: UserDataService,
        private readonly shared: SharedService,
        private readonly route: ActivatedRoute,
        private readonly switchTo: SwitchTargetService,
        private readonly builder: UntypedFormBuilder,
        private readonly cache: FilerDataCacheService,
        private readonly userService: UserService,
    ) {
        const entityList$ = this.userData.data$.pipe(
            withLatestFrom(this.userService.getUserData()),
            map(([_, u]): UserFilers[] => {
                const items = _?.userFilers ?? [];
                return _?.isAdmin
                    ? [this.buildAdminEntity(u), ...items]
                    : items;
            }),
            map((_) => this.cache.toKeyed(_ ?? [])),
            shareReplay(1)
        );
        
        this.authorizedAgent$ = entityList$.pipe(
            map(entities => entities.find(e => e.filerTypeId === Filer.AuthorizedAgent)),
        )

        const isLogin$ = this.route.queryParamMap.pipe(
            map((params) => !!params.get("login"))
        );

        this.isMultiEntity$ = entityList$.pipe(
            map((_) => _.length > 5),
            shareReplay(1)
        );

        this.groupedEntities$ = entityList$.pipe(
            map(this.groupEntitiesByType),
            map(this.groupToArray),
            map((_) =>
                _.sort((a, b) => this.order[a.type] - this.order[b.type])
            ),
            shareReplay(1)
        );
        this.isMulti$ = this.groupedEntities$.pipe(map((_) => (_.length > 1 || (_.length === 1 && _[0].items.length>5))));

        // The selected value (radio button) on component initialization
        // Either loaded from storage or the first value in the filer list
        const firstLoad$ = entityList$.pipe(
            withLatestFrom(isLogin$),
            map(([_, isLogin]) => {
                const storedFiler =
                    this.shared.getCurrentCommittee()?.selectedFiler;

                return isLogin || !storedFiler
                    ? takeFirst(_)
                    : findMatch(storedFiler.filerId, _) ?? takeFirst(_);
            }),
            shareReplay(1)
        );

        // The currently selected filer */
        // The second part might not be necessary, since we are going away anyway
        // once the switch is complete
        this.current$ = merge(firstLoad$, this.switchTo.current$).pipe(
            shareReplay(1)
        );

        this.sortedGroupedEntities$ = combineLatest([
            this.current$,
            this.groupedEntities$,
        ]).pipe(
            map(
                ([filer, grouped]) =>
                    [
                        filer?.filerId,
                        grouped && grouped.sort(this.prioritizeType(filer?.filerTypeId)),
                    ] as [string, FilerItems[]]
            ),
            map(([currentId, grouped]) => {
                if(!grouped || grouped.length === 0) return [];
                const [{ type, items }, ...rest] = grouped;
                return [{type, items: items.sort(this.prioritizeFiler(currentId))}, ...rest]}
            )
        );

        this.sameAsCurrent$ = this.sortedGroupedEntities$.pipe(
            map((grouped) => {
                let required = 5;
                const result: FilerItems[] = [];

                for (const { type, items } of grouped) {
                    const take =
                        items.length < required ? items.length : required;
                    required = required - take;
                    result.push({ type, items: items.slice(0, take) });
                    if (!required) break;
                }

                return result;
            })
        );

        this.form$ = firstLoad$.pipe(
            map((_) => this.builder.group({ filer: this.builder.control(_) })),
            shareReplay(1)
        );

        this.isAdmin$ = this.userData.data$.pipe(map(({ isAdmin }) => isAdmin));
        this.isEthic$ = entityList$.pipe(
            map((data) => data.some((_) => _.filerTypeId === 4))
        );

        const formChanges$: Observable<{ filer: KeyedFiler }> = this.form$.pipe(
            switchMap((_) => _.valueChanges.pipe(startWith(_.value)))
        );

        // Caching. Filer details need only be retrieved once
        const keyed$ = formChanges$.pipe(
            map(({ filer }) => filer),
            shareReplay()
        );

        const [cached$, needFetch$] = partition(
            keyed$,
            (_) => _ && !!this.cache.get(_?.key)
        );

        const fetched$ = needFetch$.pipe(
            filter((_) => !!_),
            switchMap((filer) =>
                (filer.filerTypeId !== FilerTypeEnum.Admin
                    ? this.loadEntity(
                          filer.filerId,
                          filer.filerTypeId,
                          filer.isPrimary
                      )
                    : this.buildAdminEntry(filer)
                )
                .pipe(map((_) => ({ key: filer.key })))
            )
        );

        const load$ = merge(cached$, fetched$).pipe(
            map(({ key }) => this.cache.get(key))
        );

        const blur$ = needFetch$.pipe(map(() => null));

        this.entity$ = merge(blur$, load$).pipe(share());
    }

    loadEntity(
        entityId: string,
        filerType: number,
        isPrimary: boolean = false
    ): Observable<Entity> {
        const url = `${this.urls.getUserEntitiesDetails}${entityId}`;

        return this.client
            .getData(url)
            .pipe(map((_) => this.cache.intoCache({ ..._, isPrimary })));
    }

    buildAdminEntry(data: KeyedFiler): Observable<Entity> {
        return of(
            this.cache.intoCache({
                filerId: data.filerId,
                name: data.name,
                filerTypeName: "Administrator",
                filerTypeId: data.filerTypeId,
                filerVersion: 0,
                isActive: true,
                isPrimary: true,
                createdAt: null,
                filerStatusId: 0,
                filerStatusName: null,
                userTypeId: 0,
                userTypeName: null
            })
        );
    }

    private readonly groupEntitiesByType = (items: KeyedFiler[]) =>
        items.reduce<FilerGroup>(
            (dict, _) => (dict[_.filerTypeId as Filer].push(_), dict),
            {
                [Filer.Committee]: [],
                [Filer.EthicsFiler]: [],
                [Filer.IndependentExpenditure]: [],
                [Filer.Lobbyists]: [],
                [Filer.AuthorizedAgent]: [],
                [Filer.LegislativeDesignee]: [],
                [Filer.Grassroot]: [],
                [Filer.Admin]: [],
                [Filer.Unaffiliated]: []
            }
        );

    private readonly groupToArray = (group: FilerGroup): FilerItems[] =>
        Object.entries(group)
            .filter(([_, items]) => !!items.length)
            .map(([_, items]) => ({ type: this.filerToName[+_], items }));

    private buildAdminEntity(user: UserApiModel): UserFilers {
        return {
            filerId: null,
            filerTypeId: Filer.Admin,
            isActive: true,
            isPrimary: true,
            name: [user.firstName, user.lastName]
                .map((_) => _?.trim())
                .filter((_) => !!_?.length)
                .join(" "),
        };
    }

    private readonly prioritizeType = (filerType: number) => {
        const type = this.filerToName[filerType];

        const comparer = (a: FilerItems, b: FilerItems): number => {
            if (a.type === b.type) return 0;
            if (a.type === type) return -1000;
            if (b.type === type) return 1000;

            return this.order[a.type] - this.order[b.type];
        };

        return comparer;
    };

    private readonly prioritizeFiler =
        (filerId: string) =>
        (a: KeyedFiler, b: KeyedFiler): number => {
            if (a.filerId === b.filerId) return 0;
            if (a.filerId === filerId) return -1;
            if (b.filerId === filerId) return 1;

            return a.filerId < b.filerId ? -1 : 1;
        };

    public updateFiler(data: FilerModel): Observable<boolean> {
        return this.client.putData(`${this.urls.filerApi}`, data).pipe(
            map(() => true),
            catchError(() => of(false))
        );
    }
}
