import { Component, HostListener, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslocoService } from "@ngneat/transloco";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { Observable, partition, merge, Subject } from "rxjs";
import {
    filter,
    switchMap,
    mapTo,
    startWith,
    share,
    tap,
} from "rxjs/operators";
import { ErrorMessageService, MasterDataService } from "src/app/core";
import { UserAttributeName } from "src/app/core/api-services/authorization/auth.service";
import { AccountService } from "src/app/modules/account/services/account.service";
import { CurrentPageEnumerator } from "src/app/modules/account/models/current-page.model";

@Component({
    selector: "app-manage-update-attribute-dialog",
    templateUrl: "./manage-update-attribute-dialog.component.html",
    styleUrls: ["./manage-update-attribute-dialog.component.scss"],
})
export class ManageUpdateAttributeDialogComponent implements OnInit {
    @Input() oldValue: string;
    @Input() attributeName: UserAttributeName;

    headings: { title: string; oldValue: string; newValue: string };
    form: FormGroup;
    public CurrentPageEnum = CurrentPageEnumerator;
    currentPage$: Observable<CurrentPageEnumerator>;
    sendingUpdateAttribute$: Observable<boolean>;
    sendingVerifyAttribute$: Observable<boolean>;
    private updateAttributeSubject$: Subject<boolean> = new Subject<boolean>();
    private verifyAttributeSubject$: Subject<boolean> = new Subject<boolean>();

    constructor(
        public dialog: DialogRef,
        private readonly formBuilder: FormBuilder,
        private readonly accountService: AccountService,
        private readonly formatting: MasterDataService,
        public errorService: ErrorMessageService,
        private readonly translocoService: TranslocoService
    ) {}

    ngOnInit(): void {
        this.headings = this.translocoService.translateObject(
            `manageAccount.profile.${
                this.attributeName == "email" ? "email" : "phoneNumber"
            }`
        );

        this.form = this.formBuilder.group({
            oldValue: [this.oldValue],
            newValue: [
                null,
                this.attributeName == "email"
                    ? [Validators.required, Validators.email]
                    : [
                          Validators.required,
                          Validators.pattern(this.formatting.onlyNumberPattern),
                      ],
            ],
            code: [null, Validators.required],
        });

        const sendUpdateAttribute$ = this.updateAttributeSubject$.pipe(
            filter((x) => x),
            switchMap(this.updateAttribute),
            share()
        );

        const [updateSuccess$, updateFail$] = partition(
            sendUpdateAttribute$,
            (x) => x
        );

        this.sendingUpdateAttribute$ = merge(
            updateSuccess$.pipe(mapTo(false)),
            updateFail$,
            this.updateAttributeSubject$.pipe(filter((x) => x))
        ).pipe(startWith(false));

        const sendVerifyAttribute$ = this.verifyAttributeSubject$.pipe(
            filter((x) => x),
            switchMap(this.verifyAttribute),
            share()
        );

        const [verifySuccess$, verifyFail$] = partition(
            sendVerifyAttribute$,
            (x) => x
        );

        this.sendingVerifyAttribute$ = merge(
            verifySuccess$.pipe(mapTo(false)),
            verifyFail$,
            this.verifyAttributeSubject$.pipe(filter((x) => x))
        ).pipe(startWith(false));

        this.currentPage$ = merge(
            updateFail$.pipe(mapTo(this.CurrentPageEnum.EditAttributeForm)),
            updateSuccess$.pipe(mapTo(this.CurrentPageEnum.ConfirmationCodeForm)),
            verifyFail$.pipe(mapTo(this.CurrentPageEnum.ConfirmationCodeForm)),
            verifySuccess$.pipe(
                tap((_) => this.dialog.close({ text: "ok" })),
                mapTo(this.CurrentPageEnum.EditAttributeForm)
            )
        ).pipe(startWith(this.CurrentPageEnum.EditAttributeForm), share());
    }

    public onCancelAction = () => this.dialog.close();

    @HostListener("keydown.esc")
    public onEsc = () => this.onCancelAction();

    public requestUpdateAttribute = () =>
        this.updateAttributeSubject$.next(true);

    public requestVerifyAttribute = () =>
        this.verifyAttributeSubject$.next(true);

    private updateAttribute = () =>
        this.accountService.updateUserAttribute(
            this.attributeName,
            this.form.value.newValue
        );

    private verifyAttribute = () =>
        this.accountService.verifyUserAttribute(
            this.attributeName,
            this.form.value.code
        );
}
