import { Component, ViewEncapsulation, inject } from "@angular/core";
import { MasterScrollService } from "@maplight/services/master-scroll.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    private readonly scrollService = inject(MasterScrollService);
}
