import {Pipe, PipeTransform} from "@angular/core";
import { PaymentTypeEnum } from "@maplight/models";

@Pipe({name: "paymentType",pure: true})
export class PaymentTypePipe implements PipeTransform {
  transform(value: PaymentTypeEnum | number): string {
    switch(value){
        case PaymentTypeEnum.PayOnline:
            return "Online Payment";
        case PaymentTypeEnum.SendToMEC:
            return "MEC Payment";
        default:
            return "Unknown Payment";
    }
  }
}