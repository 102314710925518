import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ErrorMessageService, MasterDataService } from "@maplight/index";
import { Observable, Subject, merge, partition } from "rxjs";
import { filter, mapTo, shareReplay, startWith, switchMap, tap } from "rxjs/operators";
import { AccountService } from "../../services/account.service";
import { SnackbarService } from '../../../../core/snackbar/snackbar.service';
import { TranslocoService } from "@ngneat/transloco";
@Component({
    selector: "app-manage-password",
    templateUrl: "./manage-password.component.html",
    styleUrls: ["./manage-password.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ManagePasswordComponent implements OnInit {

    readonly formGroup = this.fb.group({
        currentPassword: this.fb.control<string>(null, [
            Validators.required
        ]),
        newPassword: this.fb.control<string>(null, [
            Validators.required,
            Validators.pattern(this.masterDataService.password),
            this.masterDataService.matchValidator("repeatPassword", true),
        ]),
        repeatPassword: this.fb.control<string>(null, [
            Validators.required,
            this.masterDataService.matchValidator("newPassword"),
        ]),
    });
    showCurrentPassword = false;
    showNewPassword = false;
    showRepeatPassword = false;
    sendingUpdatePassword$: Observable<boolean>;

    private readonly changePasswordSubject$ = new Subject<boolean>();
    
    constructor(
        private readonly fb: FormBuilder,
        private readonly masterDataService: MasterDataService,
        public readonly errorService: ErrorMessageService,
        private readonly accountService: AccountService,
        private readonly snackbarService: SnackbarService,
        private readonly translocoService: TranslocoService,
    ) {}

    ngOnInit(): void {
        const sendPasswordUpdate$ = this.changePasswordSubject$.pipe(
            filter(x => !!x),
            switchMap(_ => this.accountService.changePassword(
                this.formGroup.value.currentPassword,
                this.formGroup.value.newPassword
            )),
            shareReplay()
        );

        const [updateSuccess$, updateFail$] = partition(
            sendPasswordUpdate$,
            (x) => !!x
        );

        this.sendingUpdatePassword$ = merge(
            this.changePasswordSubject$.pipe(filter(x => !!x)),
            updateSuccess$.pipe(
                tap(_ => {
                    this.snackbarService.snackbarInfo(
                        this.translocoService.translate(
                            "changePassword.yourPasswordWasSuccessfullyChanged"
                        )
                    );
                    this.formGroup.reset();
                }),
                mapTo(false)
            ),
            updateFail$
        ).pipe(startWith(false));
    }

    updatePassword() {
        if (this.formGroup.valid)
            this.changePasswordSubject$.next(true);
        else {
            this.formGroup.markAllAsTouched();
            this.formGroup.updateValueAndValidity();
        }
    }
}
