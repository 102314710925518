import { ClientsApiModel, LobbyistUserTypeEnum } from "src/app/app.model";
import { Lobbyist } from "../../Lobbyist/lobbyist.model";

export type UserDetails = {
    filerId: any;
    filerVersion: number;
    name: string;
    firstName?: string;
    lastName?: string;
    userId: string;
    email: string;
    firmName?: string;
    city: string;
    zip: string;
    stateCode: string;
    state?: string;
    address1: string;
    address2?: string;
    phone?: string;
};

/**
 * @deprecated Use Lobbyist instead
 */
export type LobbyistUser = UserDetails & {
    id: string;
    lobbyistTypeId: LobbyistNatureEnum;
    userType: LobbyistUserTypeEnum;
}

export type AuthorizedAgent = UserDetails & {
    authorizedAgentId?: string;

    // Only when is a lobbyist
    lobbyistTypeId?: LobbyistNatureEnum;
    userType?: LobbyistUserTypeEnum;
}

export type PrimaryLobbyistInformation = {
    primaryLobbyistNature: LobbyistNatureEnum;
} & Lobbyist;

export type NatureOfBusiness = {
    id: string;
    name: string;
    description?: string;
};

export type LegislativeCommittee = {
    id : string;
    name : string;
};

export type LobbyingActivities = {
    hasCommenced: boolean;
    dateExceeded?: Date;
    compensationType: CompensationTypeEnum;
    compensationRate?: number;
    compensationDescription?: string;
    legislativeCommitteeIds: string[];
}

export type StepTwoStruct = {
    clientInformation: ClientsApiModel,
    businessDescription?: string,
    interestDescription?: string,
    natureOfBusiness: NatureOfBusiness,
    clientsModified: { edited?: ClientsApiModel[], added?: ClientsApiModel },
}

export type ClientRegistration = {
    /** A Lobbyist ID representing the lobbyist creating the registration.*/
    createdBy: string;
    primaryLobbyistId: string;

    client: ClientsApiModel;
    natureOfBusinessId?: string;
    natureOfBusinessDescription?: string;
    businessDescription?: string;
    interestDescription?: string;

    lobbyistAssociateIds: string[];
    authorizedAgentIds: string[];

    hasCommenced: boolean;
    dateExceeded?: Date;
    compensationType: CompensationTypeEnum;
    compensationRate?: number;
    compensationDescription?: string;
    legislativeCommitteeIds: string[];

    feeAmount: number;
    paymentMethod: PaymentType;
    feePaid: boolean;
};

export enum CompensationTypeEnum {
    FlatRate = 1,
    InHouse = 2,
    Hourly = 3,
    Other = 4
}

export enum LobbyistNatureEnum {
    Contract = 1,
    InHouse = 2
}

export enum PaymentType {
    PayOnline = 1,
    SendToMEC = 2,
    Unknown = 3,
}