import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GridModule } from "@progress/kendo-angular-grid";
import { PipesModule } from "./pipes.module";

// directives
import { KeyPreventDirective } from "../directives/prevent-key-typing.directive";

import { ConfirmPopupComponent } from "../components/confirm-popup/confirm-popup.component";
import { ClickOutsideDirective } from "../directives/clicked-outside.directive";
import { NavigatescreenComponent } from "../components/navigatescreen/navigatescreen.component";
import { NumericDirective } from "../directives/numeric.directive";
import { NavigationModule } from "@progress/kendo-angular-navigation";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { MenusModule } from "@progress/kendo-angular-menu";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { LabelModule } from "@progress/kendo-angular-label";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { ListViewModule } from "@progress/kendo-angular-listview";
import {
    DateInputsModule,
    DatePickerModule,
} from "@progress/kendo-angular-dateinputs";
import { ChartModule } from "@progress/kendo-angular-charts";
import { IconsModule } from "@progress/kendo-angular-icons";
import { UploadsModule } from "@progress/kendo-angular-upload";

import { AppFooter } from "../components/app-footer/app-footer.component";
import { DialogModule, WindowModule } from "@progress/kendo-angular-dialog";
import { TooltipModule } from "./tooltip.module";
import { SectionHeadingModule } from "./section-heading.module";
import { TranslocoModule } from "@ngneat/transloco";
import { TableLayoutComponent } from "../components/table-layout/table-layout.component";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { TablePagerComponent } from "../components/table-pager/table-pager.component";
import { SystemManageBackComponent } from "../components/system-manage-back/system-manage-back.component";

import { TablePagingService } from "../services/table-paging.service";
import { StickyHeaderDirective } from 'src/app/shared/directives/sticky-headers.directive';
import { LinkButtonDirective } from '../directives/link-button.directive';
import { AppBadgesComponent } from "../components/app-badges/app-badges.component";
import { AlertsDashboardService } from "@maplight/services";
import { AdminNotificationService } from "src/app/modules/messages/admin/services";

import { SyncTabIndexDirective } from "../directives/sync-tab-index.directive";

@NgModule({
    declarations: [
        AppFooter,
        KeyPreventDirective,
        ClickOutsideDirective,
        NumericDirective,
        ConfirmPopupComponent,
        NavigatescreenComponent,
        TableLayoutComponent,
        TablePagerComponent,
        StickyHeaderDirective,
        SystemManageBackComponent,
        AppBadgesComponent,
        SyncTabIndexDirective,
    ],
    imports: [
        RouterModule,
        PipesModule,
        DialogModule,
        NavigationModule,
        DateInputsModule,
        DatePickerModule,
        MenusModule,
        DropDownsModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        GridModule,
        LayoutModule,
        LabelModule,
        ButtonsModule,
        ListViewModule,
        InputsModule,
        LinkButtonDirective,
        IconsModule,
        UploadsModule,
        TooltipModule,
        DropDownsModule,
        ChartModule,
        WindowModule,
        SectionHeadingModule,
        TranslocoModule,
        HttpClientModule,
        HttpClientJsonpModule,
        IndicatorsModule,
    ],
    exports: [
        PipesModule,
        AppFooter,
        WindowModule,
        IconsModule,
        UploadsModule,
        GridModule,
        KeyPreventDirective,
        ClickOutsideDirective,
        NumericDirective,
        LayoutModule,
        InputsModule,
        ButtonsModule,
        LabelModule,
        DateInputsModule,
        DatePickerModule,
        DropDownsModule,
        ChartModule,
        TooltipModule,
        ListViewModule,
        SectionHeadingModule,
        NavigatescreenComponent,
        TableLayoutComponent,
        TablePagerComponent,
        StickyHeaderDirective,
        SystemManageBackComponent,
        LinkButtonDirective,
        AppBadgesComponent,
        SyncTabIndexDirective,
    ],
    providers: [
        AdminNotificationService,
        AlertsDashboardService,
        TablePagingService
    ],
})
export class SharedModule {}
