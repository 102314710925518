import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { ErrorMessageService, SnackbarService } from 'src/app/core';
import { MasterDataService } from '../../../../configs/master-data';
import {
    FilerTypeEnumMaine as Filers,
    InvitationDetails,
    LobbyistTypeEnum,
    UserTypeEnum,
} from '../../../../shared/models';
import { AccountService } from '../../services/account.service';
import { CurrentPage } from '../../models/current-page.model';
import { UserData } from '../../models/user-details.model';
import { CreateAccountDataService } from '../../services/create-account-data.service';
import { LobbyistService } from '../../services/lobbyist.service';
import { TranslocoService } from '@ngneat/transloco';
import { FormGroup } from '@angular/forms';
import { ILobbyistData } from '../../models/ILobbyistData';
import { FirmBusinessAddressService } from '../../services/firm-business-address.service';
import {
    catchError,
    map,
    mapTo,
    scan,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs/operators';
import { UserService } from '../../../../core/services/user-service';
import { GrassrootService } from '../../services/grassroot.service';
import { LegislativeService } from '../../services/legislative.service';

export abstract class BaseClassWithForm {
    abstract isValid: boolean;
    abstract form: FormGroup;
}

@Component({
    selector: 'app-create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['./create-account.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateAccountComponent implements OnInit {
    @ViewChild(BaseClassWithForm) container: BaseClassWithForm;

    loadingRequest$: Observable<boolean>;

    disableButton: any;

    userData: UserData = {};
    page: CurrentPage[] = [
        'SaveUserForm',
        'ChooseUserType',
        'AdditionalInformation',
    ];

    filerTypeId: Filers;
    readonly legislativeDesignee = LobbyistTypeEnum.LegislativeDesignee;
    readonly grassroot = LobbyistTypeEnum.Grassroot;
    success$: Observable<boolean>;
    private submit$ = new Subject();
    user$: Observable<any>;

    navigationSubject$: ReplaySubject<string> = new ReplaySubject<string>();
    navigationState$: Observable<number> = new Observable<number>();

    adminInvitationActive$: Observable<boolean>;

    hasUser: boolean = false;
    buttonText: { [key: string]: string } = {};
    private data: ILobbyistData = {};

    readonly invitation: InvitationDetails = this.route.snapshot.data.details;
    readonly isAdmin = this.invitation?.expectedUserType === UserTypeEnum.Admin;

    private readonly token = this.route.snapshot.queryParamMap.get('token');

    constructor(
        public formatting: MasterDataService,
        public errorService: ErrorMessageService,
        public accountService: AccountService,
        public router: Router,
        public snackbar: SnackbarService,
        private readonly route: ActivatedRoute,
        private readonly translocoService: TranslocoService,
        private readonly createAccountDataService: CreateAccountDataService,
        public readonly lobbyist: LobbyistService,
        public readonly businessAddressService: FirmBusinessAddressService,
        private readonly user: UserService,
        private readonly grassrootsService: GrassrootService,
        private readonly legislativeService: LegislativeService,
    ) {
        // initialize the button text
        this.buttonText = {
            SaveUserForm: 'next',
            ChooseUserType: 'next',
            AdditionalInformation: 'submit',
        };

        this.user$ = this.createAccountDataService.getUserdata().pipe(
            switchMap((user) => {
                if (user) {
                    this.hasUser = true;
                    this.createAccountDataService.value.name = user.firstName;
                    this.createAccountDataService.value.lastName =
                        user.lastName;
                    this.createAccountDataService.value.id = user.id;
                    this.createAccountDataService.value.email = user.email;
                }
                return of(true);
            }),
        );

        this.navigationState$ = this.navigationSubject$.pipe(
            scan((state, direction) => {
                if (direction === 'next') {
                    let isValid: boolean = true;
                    if (
                        this.invitation?.expectedUserType === UserTypeEnum.Admin
                    ) {
                        isValid = this.container?.isValid;
                        if (this.container?.isValid) {
                            this.createAccountDataService.value.name =
                                this.container.form.value.firstName;
                            this.createAccountDataService.value.lastName =
                                this.container.form.value.lastName;
                            this.createAccountDataService.value.phone = 
                                this.container.form.value.businessPhone;
                            this.submit$.next();
                        } else {
                            this.container?.form.markAllAsTouched();
                            this.container?.form.markAsDirty();
                        }
                    } else {
                        switch (state) {
                            //Profile
                            case 1: {
                                isValid = this.container?.isValid;
                                if (this.container?.isValid) {
                                    this.createAccountDataService.value.name =
                                        this.container.form.value.firstName;
                                    this.createAccountDataService.value.lastName =
                                        this.container.form.value.lastName;
                                    this.createAccountDataService.value.phone = 
                                        this.container.form.value.businessPhone;
                                } else {
                                    this.container?.form.markAllAsTouched();
                                    this.container?.form.markAsDirty();
                                }
                                break;
                            }
                            case 2:
                                isValid = this.filerTypeId ? true : false;
                                break;
                            //Account type
                            case 3: {
                                switch (this.filerTypeId) {
                                    case 1:
                                    case 5:
                                        const option =
                                            this.createAccountDataService
                                                .lobbyistOption;
                                        isValid = this.submit(option);
                                        break;
                                    case 6: {
                                        // legislative
                                        isValid =
                                            this.legislativeService.form.valid;
                                        if (
                                            this.legislativeService.form.valid
                                        ) {
                                            this.data = {
                                                filerTypeId:
                                                    Filers.LegislativeDesignee,
                                                stateAgencyId:
                                                    this.legislativeService.form
                                                        .value.stateAgencyId,
                                            };
                                            this.submit$.next();
                                        } else {
                                            this.legislativeService.form.markAllAsTouched();
                                            this.legislativeService.form.markAsDirty();
                                        }
                                        break;
                                    }
                                    case 7: //grassroots
                                        isValid =
                                            this.grassrootsService.form.valid;
                                        if (this.grassrootsService.form.valid) {
                                            this.data = {
                                                filerTypeId: Filers.Grassroot,
                                                organizationName:
                                                    this.grassrootsService.form
                                                        .value.name,
                                            };
                                            this.submit$.next();
                                        } else {
                                            this.grassrootsService.form.markAllAsTouched();
                                            this.grassrootsService.form.markAsDirty();
                                        }
                                        break;
                                }
                            }
                        }
                    }
                    return isValid &&
                        this.invitation?.expectedUserType != UserTypeEnum.Admin
                        ? Math.min(state + 1, 3)
                        : state;
                } else if (direction === 'back') {
                    return Math.max(state - 1, 1);
                }
            }, 1),
            shareReplay(1),
        );

        this.success$ = this.createAccountDataService.success$;

        const startLoad$ = this.submit$.pipe(mapTo(true));
        const closeLoad$ = startLoad$.pipe(
            switchMap(() =>
                this.hasUser
                    ? of({ id: this.createAccountDataService.value.id })
                    : this.createAccountDataService.saveUser(this.token).pipe(
                          //update the user data
                          tap((user) => {
                              this.hasUser = true;
                              this.createAccountDataService.value.name =
                                  user.firstName;
                              this.createAccountDataService.value.lastName =
                                  user.lastName;
                              this.createAccountDataService.value.id = user.id;
                          }),
                      ),
            ),
            switchMap((_) => {
                return this.invitation?.expectedUserType == UserTypeEnum.Admin
                    ? of(_)
                    : this.createAccountDataService.createFirmAddressUser(
                          this.data,
                      );
            }),
            map((data) => {
                return this.invitation?.expectedUserType == UserTypeEnum.Admin
                    ? data.UserId !== null
                        ? this.createAccountDataService.success(true)
                        : this.snackbar.snackbarError(
                              this.translocoService.translate(
                                  'manageAccount.contactInfo.errorSavingUserData',
                              ),
                          )
                    : !data
                    ? this.snackbar.snackbarError(
                          this.translocoService.translate(
                              'manageAccount.contactInfo.errorSavingUserData',
                          ),
                      )
                    : this.createAccountDataService.success(true);
            }),
            tap(() => this.user.forceReload()),
            switchMap((_) => this.accountService.saveLoginEvent()),

            catchError((_) => {
                this.snackbar.snackbarError(
                    this.translocoService.translate(
                        'manageAccount.contactInfo.errorSavingUserData',
                    ),
                );
                return of(false);
            }),
            mapTo(false),
        );

        this.loadingRequest$ = merge(startLoad$, closeLoad$).pipe(
            shareReplay(),
        );
    }

    ngOnInit(): void {
        this.next();
    }

    filerTypeSelected = (filerTypeId) => {
        this.filerTypeId = filerTypeId;
    };

    next() {
        this.navigationSubject$.next('next');
    }

    back() {
        this.navigationSubject$.next('back');
    }

    //create Lobbyist
    submit(option: number) {
        this.data = { filerTypeId: this.filerTypeId };
        switch (option) {
            /**
             * add new address to existing firm
             */
            case 1: {
                const value = this.lobbyist.lobbyistFirmAddress;
                if (!value || !this.lobbyist.selectedFirmId) return false;

                this.data.firm = this.lobbyist.selectedFirm;
                if (value.id) {
                    // in case the user selects an existing address

                    this.data.businessAddress = {
                        id: value.id,
                        address1: value.address1,
                        address2: value.address2,
                        city: value.city,
                        stateCode: value.stateCode,
                        zip: value.zip,
                        phoneNumber: value.phoneNumber,
                    };
                } else {
                    // in case the user creates an address
                    this.data.businessAddress = {
                        address1: value.address1,
                        address2: value.address2,
                        city: value.city,
                        stateCode: value.stateCode,
                        zip: value.zip,
                    };
                }
                break;
            }

            /**
             * create new firm case
             */
            case 2: {
                const value = this.lobbyist.newFirmWithAddress;
                if (!value) return false;
                this.data.businessAddress = {
                    address1: value.address1,
                    address2: value.address2,
                    city: value.city,
                    stateCode: value.stateCode,
                    zip: value.zip,
                    phoneNumber: value.firmPhoneNumber,
                };
                this.data.firm = {
                    name: value.firmName,
                    phoneNumber: value.firmPhoneNumber,
                    webSite: value.firmWebSite,
                };
                break;
            }
            case 3: {
                const value = this.businessAddressService.businessAddress;
                if (!value) return false;
                this.data.businessAddress = {
                    address1: value.address1,
                    address2: value.address2,
                    city: value.city,
                    stateCode: value.stateCode,
                    zip: value.zip,
                    phoneNumber: value.phoneNumber,
                };
                break;
            }
        }
        this.submit$.next();
    }

    onActivate(event) {
        window.scrollTo(0, 0);
    }
}
