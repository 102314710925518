import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FEATURE_MAP, PUBLIC_FEATURE_CHECK } from '@maplight/utils';

/**
 * A function-based route guard for checking when we require authentication for an externally
 * routed app feature.
 *
 * While it makes sense to require having a logged in user for some features (like accessing messages
 * or going to check on new access requests), the same does not hold for all cases.
 * For example, creating a new user does not require an existing user, and so we would like to skip
 * on those checks and lead the user directly to the request feature.
 *
 * @param route the activated route
 * @param _state the current router state (unused)
 * @returns whether we should redirect to a public route or continue the auth check process
 */
export const skipAuthForPublicFeature: CanActivateFn = (route, _state) => {
    const router = inject(Router);

    // get the target feature name and any additional arguments
    const { feature, ...queryParams } = route.queryParams;

    // continue with auth checks if the target feature is
    // not considered public
    if (!PUBLIC_FEATURE_CHECK[feature]) return true;

    const target = FEATURE_MAP[feature];

    // target the public landing page if we cannot resolve the
    // target feature
    if (!target) return router.createUrlTree(['/public']);

    return router.createUrlTree(FEATURE_MAP[feature], { queryParams });
};
