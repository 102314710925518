import { UserFilers } from "src/app/app.model";
import { KeyedFiler } from "../switch-commitee.model";

export function takeFirst<T extends UserFilers>([f, ..._]: T[]): T {
    return f;
}

export function findMatch(id: string, filers: KeyedFiler[]): KeyedFiler {
    return filers.find(({ filerId }) => filerId === id);
}
