import { Injectable } from '@angular/core';

export enum GridPageSize  {
    Small = 10,
    Medium = 25,
    Large = 50,
    ExtraLarge = 100,
}

export const gridPageSizes: ReadonlyArray<GridPageSize> = Object.freeze([
    GridPageSize.Small,
    GridPageSize.Medium,
    GridPageSize.Large,
    GridPageSize.ExtraLarge
]);

@Injectable()
export class TablePagingService {
    constructor() {}

    public calcColumnWidth(title: string): number {
        const padding = 48;
        const avgCharWidth = 12;
        return padding + title.length * avgCharWidth;
    }

    /**
     * Saves the selected page size for a specific table ID in session storage.
     * @param tableId The identifier of the table.
     * @param size The table size to save.
     */
        public storePageSize(tableId: string, size: GridPageSize): void {
            if (this.isValidTableId(tableId)) {
                sessionStorage.setItem(
                    this.buildStorageKey(tableId), 
                    size.toString()
                );
            }
        }

    /**
     * Retrieves the stored page size for a specific table ID from session storage if present.
     * @param tableId The identifier of the table.
     * @param defaultSize the initial size of the table if no stored size.
     * @returns The stored page size, defaultSize from GridPageSize, or smallest GridPageSize.
     */
    public getStoredPageSize(tableId: string, defaultSize?: GridPageSize): GridPageSize {
        if(!this.isValidTableId(tableId)) return GridPageSize.Small;

        const storedSize = sessionStorage.getItem(
            this.buildStorageKey(tableId)
        );

        if(storedSize) return parseInt(storedSize, 10);
        if(defaultSize) return defaultSize;
        return GridPageSize.Small;
    }

    private buildStorageKey(tableId: string): string {
        return 'selectedPageSize-' + tableId;
    }

    private isValidTableId(tableId: string): boolean {
        return tableId && typeof tableId === 'string';
    }
}