import {
    ChangeDetectionStrategy,
    Component,
    Input,
    Optional,
    Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { SnackbarService, UserService } from '@maplight/services';
import { TranslocoService } from '@ngneat/transloco';
import { of, merge, Subject, Observable } from 'rxjs';
import { catchError, filter, switchMap, share, map } from 'rxjs/operators';
import { AccountService } from 'src/app/modules/account/services/account.service';

@Component({
    selector: 'app-mfa-form',
    templateUrl: './mfa-form.component.html',
    styleUrls: ['./mfa-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfaFormComponent {
    @Input() isLoginTrigger = false;

    constructor(
        private readonly userService: UserService,
        @Optional() private readonly accountService: AccountService,
        private readonly snackbarService: SnackbarService,
        private readonly translocoService: TranslocoService,
    ) {}

    readonly form = new FormControl<string>(null, {
        validators: Validators.required,
    });

    private readonly send$ = new Subject<string>();

    private readonly action$ = this.send$.pipe(
        switchMap((code) =>
            this.userService.sendMfaCode(code).pipe(
                map(() => true),
                catchError(() => of(this.onError())),
            ),
        ),
        share(),
    );

    readonly loading$ = merge(
        this.send$.pipe(map(() => true)),
        this.action$.pipe(map(() => false)),
    );

    @Output() readonly success = this.action$.pipe(
        filter((it) => !!it),
        switchMap(() => this.recordLogin()),
    );

    submit() {
        this.form.markAllAsTouched();
        this.form.updateValueAndValidity();

        if (!this.form.valid) return;

        this.send$.next(this.form.value);
    }

    private recordLogin(): Observable<void> {
        if (!this.isLoginTrigger) of(null);

        return this.accountService
            ?.saveLoginEvent()
            ?.pipe(catchError(() => of(null)));
    }

    private onError() {
        this.snackbarService.snackbarError(
            this.translocoService.translate('mfaForm.invalidCode'),
        );

        return false;
    }
}
