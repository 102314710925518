import { Injectable } from "@angular/core";
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { retry, catchError } from "rxjs/operators";
import { SnackbarService } from '../snackbar/snackbar.service';
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
    providedIn: "root",
})
export class HttpErrorManyRequestInterceptor implements HttpInterceptor {
    constructor(
        private readonly snackbarService: SnackbarService,
        private readonly transloco: TranslocoService,
    ) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if(error.status === 429) {
                    this.snackbarService.snackbarError(
                        this.transloco.translate('rateLimitError')
                    );
                }
                return throwError(error.error);
            })
        );
    }
}
