import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import {
    CancelEvent,
    EditEvent,
    GridComponent,
    GridDataResult,
    PageChangeEvent,
    SaveEvent,
    ScrollMode
} from "@progress/kendo-angular-grid";

import { TranslocoService } from "@ngneat/transloco";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
    BehaviorSubject,
    Observable,
    ReplaySubject,
    combineLatest,
} from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import {
    Action,
    BaseTableInformation,
    TableEvent,
    TableInformation,
} from "../../models/table-layout.model";
import { CheckBoxRounded, InputSize } from '@progress/kendo-angular-inputs';
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridPageSize, TablePagingService } from 'src/app/shared/services/table-paging.service';

@Component({
    selector: 'app-table-layout',
    templateUrl: './table-layout.component.html',
    styleUrls: ['./table-layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TableLayoutComponent<T extends BaseTableInformation>
    implements OnInit
{
    constructor(
        private readonly i18n: TranslocoService,
        private readonly formBuilder: FormBuilder,
        private readonly tablePagingService: TablePagingService
    ) {}

    @Input() loading: boolean;
    @Input() maxHeight: number;
    @Input() scrollable: ScrollMode = 'scrollable';
    @Input() setWidth: boolean = false;
    @Input() set data(value: TableInformation<T>) {
        this.dataReceived$.next(value);
    }
    @Input() set tableId(value: string) {
        this._tableId = value;
      if(this.initPageSize){
        this.initPageSizeSubject();
      }
    }
    @Input() set defaultPageSize(value: GridPageSize) {
        this.initPageSize = value;
        if(this._tableId) {
            this.initPageSizeSubject();
        }
    }

    @Output() readonly actionTriggered: EventEmitter<TableEvent> =
        new EventEmitter<TableEvent>();

    @ViewChild(GridComponent) grid!: GridComponent;

    protected  tableStyle: string = '';
    protected formGroup: FormGroup;
    protected readonly rounded: CheckBoxRounded = 'small';
    protected readonly size: InputSize = 'large';

    private initPageSize: GridPageSize = GridPageSize.Small;
    private _tableId: string;
    private editedRowIndex: number;

    protected readonly dataReceived$: ReplaySubject<TableInformation<T>> =
        new ReplaySubject<TableInformation<T>>();

    private readonly pageSizeSubject$: BehaviorSubject<number> = 
        new BehaviorSubject<number>(this.initPageSize);
    private readonly pageNumberSubject$: BehaviorSubject<number> =
        new BehaviorSubject<number>(0);
    private readonly skipSubject$: BehaviorSubject<number> =
        new BehaviorSubject<number>(0);
    private readonly sortSubject$: BehaviorSubject<SortDescriptor[]> =
        new BehaviorSubject<SortDescriptor[]>([]);

    protected formValues$: Observable<any[]>;
    protected view$: Observable<GridDataResult>;
    protected readonly pageSize$: Observable<number> = this.pageSizeSubject$
        .asObservable();
    protected readonly skip$: Observable<number> = this.skipSubject$
        .asObservable();
    protected readonly sort$: Observable<SortDescriptor[]> = this.sortSubject$
        .asObservable();

    protected cancelHandler(args: CancelEvent): void {
        this.closeEditor(args.sender, args.rowIndex);
    }

    protected translateColumnName(name: string): string {
        return this.i18n.translate(name);
    }

    protected tableAction(eventName: Action, item: { id: unknown }): void {
        this.actionTriggered.emit({ eventName, id: item?.id, data: item });
    }

    protected pageChange(state: PageChangeEvent) {
        this.tablePagingService.storePageSize(this._tableId, state.take);
        this.pageNumberSubject$.next(state.skip / state.take);
        this.skipSubject$.next(state.skip);
        this.pageSizeSubject$.next(state.take);
    }

    protected sortChange(sort: SortDescriptor[]): void {
        this.sortSubject$.next(sort);
    }

    protected setTableStyle(): string {
        this.maxHeight !== undefined || this.maxHeight !== null 
            ? this.tableStyle = `max-height: ${this.maxHeight}px`
            : this.tableStyle;

        return this.tableStyle;
    }

    protected addHandler(): void {
        this.closeEditor(this.grid);
        this.formGroup.reset();
        this.grid.addRow(this.formGroup);
    }

    protected getColumnWidth(title: string): number {
        const translated = this.translateColumnName(title);
        const width =  this.tablePagingService.calcColumnWidth(translated);
        
        return width;
    }

    protected editHandler(args: EditEvent): void {
        this.closeEditor(args.sender);
        this.editedRowIndex = args.rowIndex;
        this.formGroup.addControl('id', this.formBuilder.control(''));
        this.formGroup.patchValue(args.dataItem);
        args.sender.editRow(args.rowIndex, this.formGroup);
    }

    protected saveHandler(data: SaveEvent): void {
        const {sender, rowIndex, formGroup, isNew} = data;  
        const value: any = formGroup.value;

        isNew
            ? this.actionTriggered.emit({
                  id: rowIndex.toString(),
                  eventName: 'new',
                  data: value,
              })
            : this.actionTriggered.emit({
                  id: rowIndex.toString(),
                  eventName: 'edit-inline',
                  data: value,
              });

        sender.closeRow(rowIndex);
    }

    private initPageSizeSubject(): void {
        const pageSize = this.tablePagingService
            .getStoredPageSize(this._tableId, this.initPageSize);
        this.pageSizeSubject$.next(pageSize);
    }

    private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex): void {
        // close the editor
        grid.closeRow(rowIndex);
        // reset the helpers
        this.editedRowIndex = undefined;
        //this.formGroup = undefined;
    }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({});
        this.view$ = combineLatest([
            this.dataReceived$,
            this.pageSizeSubject$,
            this.pageNumberSubject$,
            this.skipSubject$,
            this.sortSubject$,
        ]).pipe(
            debounceTime(5),
            map(([items, pageSize, pageNumber, skip, sort]) => {
                let offset = skip;
                if (sort.length != 0) {
                    items.information = orderBy(items.information, sort);
                }

                if (pageNumber > 0 && skip >= items.information.length) {
                    offset = 0;
                    this.skipSubject$.next(0);
                    this.pageNumberSubject$.next(0);
                } 
                
                return pageSize > 0 
                    ? {
                        data: items.information.slice(offset, offset + pageSize),
                        total: items.information.length,
                    }
                    : {
                        data: items.information,
                        total: items.information.length,
                    };
            }),
            shareReplay(1),
        );

        this.formValues$ = this.dataReceived$.pipe(
            map((data) => {
                if (!data) return null;
                let properties = data.tableProperties.map((prop) => prop);
                data.tableProperties.forEach((prop) => {
                    if (prop.editable) {
                        this.formGroup.addControl(
                            prop.name,
                            this.formBuilder.control(''),
                        );
                    }
                });
                return properties;
            }),

            shareReplay(1),
        );
    }
}