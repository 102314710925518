import { Component, inject, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { combineLatest, merge, Subject } from 'rxjs';
import {
    map,
    mapTo,
    share,
    startWith,
    switchMap,
    take,
    tap,
} from 'rxjs/operators';

import { UserService } from '@maplight/services';
import { AccountService } from '../../services/account.service';

@Component({
    selector: 'app-manage-profile-admin',
    templateUrl: './manage-profile-admin.component.html',
    styleUrls: ['./manage-profile-admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ManageProfileAdminComponent {
    private readonly userService = inject(UserService);
    private readonly accountService = inject(AccountService);

    readonly form = new FormGroup({
        firstName: new FormControl(null, [Validators.required]),
        lastName: new FormControl(null, [Validators.required]),
    });

    readonly user$ = this.userService.getUserData().pipe(
        tap((user) => {
            this.form.patchValue({
                firstName: user.firstName,
                lastName: user.lastName,
            });
        }),
        take(1),
    );
    readonly loadingUser$ = this.user$.pipe(mapTo(false), startWith(true));

    private readonly invalidForm$ = this.form.statusChanges.pipe(
        map((status) => status !== 'VALID'),
        startWith(true),
    );

    private readonly updateProfileSubject$ = new Subject<{
        firstName: string;
        lastName: string;
    }>();
    private readonly updateProfile$ = this.updateProfileSubject$.pipe(
        switchMap((data) => this.accountService.updateUser(data)),
        share(),
    );
    private readonly updatingProfile$ = merge(
        this.updateProfileSubject$.pipe(mapTo(true)),
        this.updateProfile$.pipe(mapTo(false)),
    ).pipe(startWith(false));
    private readonly disableFormOnSuccess$ = merge(
        this.updateProfile$,
        this.form.valueChanges.pipe(mapTo(false)),
    );

    readonly disableForm$ = combineLatest([
        this.loadingUser$,
        this.invalidForm$,
        this.updatingProfile$,
        this.disableFormOnSuccess$,
    ]).pipe(
        map((x) => x.some((y) => y)),
        startWith(true),
    );

    updateProfile() {
        this.updateProfileSubject$.next(
            this.form.value as Required<{
                firstName: string;
                lastName: string;
            }>,
        );
    }
}
