import { Component, Input } from '@angular/core';
import { Item } from '../../models/page-information-card.models';

@Component({
  selector: 'app-page-information-card',
  templateUrl: './page-information-card.component.html',
  styleUrls: ['./page-information-card.component.scss']
})
export class PageInformationCardComponent {
  @Input() header: string = null;
  @Input() column1: Item[] = [];
  @Input() column2: Item[] = [];
}
