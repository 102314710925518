import { Component, ViewEncapsulation } from "@angular/core";
import { of } from "rxjs";

@Component({
    selector: "app-manage",
    templateUrl: "./manage.component.html",
    styleUrls: ["./manage.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ManageComponent {
    private readonly links = [
        ['manageAccount.profile.accountInformation', "profile", "account_circle"],
        ["email", "email", "mail_outline"],
        // Commented out in order to not include MFA pages in the 
        // production release (Date: June 12th)
        // ["manageAccount.multiFactorAuthentication", "mfa", "phone"],
        ["login.password", "password", "lock"],
    ] as const;

    protected readonly pages$ = of(this.links.map(([tag, link, icon]) => ({ tag, link, icon })));
}